import { createDraftSafeSelector } from '@reduxjs/toolkit';
export const select = state => state.tags_filter;

export const makeSelectShowKeyPopUp = createDraftSafeSelector(select, state => state?.showKeyPopUp || false);

export const makeSelectShowValuePopUp = createDraftSafeSelector(select, state => state?.showValuePopUp || false);

export const makeSelectSearchKey = createDraftSafeSelector(select, state => state?.searchKey || '');

export const makeSelectSearchValue = createDraftSafeSelector(select, state => state?.searchValue || '');

export const makeSelectSelectedTag = createDraftSafeSelector(select, state => state?.selectedTag || '');

export const makeSelectSelectedTagCloudPlatform = createDraftSafeSelector(
  select,
  state => state?.selectedTagCloudPlatform || '',
);

export const makeSelectSelectedKeyValue = createDraftSafeSelector(select, state => state?.selectedKeyValue || []);

export const makeSelectPropsFilter = createDraftSafeSelector(select, state => state?.propsFilter || []);

export const makeSelectTagResourcesValue = createDraftSafeSelector(select, state => state?.tagResourcesValue || []);

export const makeSelectTagResourcesLoading = createDraftSafeSelector(
  select,
  state => state?.tagResourcesLoading || false,
);
