const REGEX_URL =
  /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|[\]{}<>^'"$?!:;,.*]*\)|[-A-Z0-9+&@#/%=~_|[\]{}<>^'"$?!:;,.*])*(?:\([-A-Z0-9+&@#/%=~_|[\]{}<>^'"$?!:;,.*]*\)|[A-Z0-9+&@#/%=~_|[\]{}<>^"'$*])/gim;
const REGEX_NEW_LINE = /\n\r?/g;

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return (s.charAt(0) || '').toUpperCase() + s.slice(1);
};

export const hasDuplicates = arr => {
  if (!arr.filter(Boolean).length) return false;
  const arrSet = new Set(arr);
  return arrSet.size !== arr.length;
};

export const getObjectKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const getNestedProp = (obj, path) => {
  return path.split('.').reduce((obj, prop) => (obj && obj[prop] ? obj[prop] : null), obj);
};

export function extractS3FileName(url) {
  let str = url.substr(url.indexOf('_') + 1);
  str = str.substr(0, str.indexOf('?'));
  return decodeURIComponent(str);
}

export const customStyles = {
  content: {
    margin: 'auto',
  },
};

export const convertToHtml = (str = '') => {
  let value = String(str);

  value = value.replace(REGEX_URL, replacement => `<a href=${replacement} target="_blank">${replacement}</a>`);
  value = value.replace(REGEX_NEW_LINE, '<br/>');

  return value;
};

export const isSettingsScreen = currentRoute => {
  if (
    ['/v3/settings', '/v3/settings/', '/v3/settings/manageAccount/', '/v3/settings/manageAccount-hidden/'].includes(
      currentRoute,
    )
  ) {
    return true;
  }
  return false;
};

export const checkNopsEmail = email =>
  // email.includes('@nclouds.com') ||
  email.includes('@nops.io');

export const hasWorkloadV1 = email =>
  (email || '').includes('@nclouds.com') ||
  (email || '').includes('@bloomip.com') ||
  (email || '').includes('@lyrasis.org') ||
  (email || '').includes('@kibsi.com') ||
  (email || '').includes('@aurainteractiva.com') ||
  (email || '').includes('@edalex.com') ||
  (email || '').includes('@ingrammicro.com') ||
  (email || '').includes('@eluminaelearning.com.au');

export const noSpendPendo = {
  aws_mtd_spend: 'none',
  aws_last_month_spend: 'none',
};
