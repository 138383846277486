import React from 'react';
import { EmptySvg } from 'assets/images';
import { NumericFormat } from 'react-number-format';
import { countDecimals } from 'utils/number';
import SlackSection from './slackSection';
import orderBy from 'lodash/orderBy';
import MultiCreatableSelectDropDown from 'components/Dropdown/MultiCreatableSelectDropDown';

const BudgetSection = ({
  inputData: { budget, emailList, notify, notify_threshold, notify_slack },
  change,
  emailChange,
  changeThreshold,
  radioChange,
  errors,
  disabled,
  allow,
  redirect,
  allUserData,
}) => {
  // const BUDGET_TYPES = [
  //   { key: 'monthly', label: 'Monthly' },
  //   { key: 'quarterly', label: 'Quarterly' },
  //   { key: 'yearly', label: 'Yearly' },
  // ];
  const handleThresholdValidation = values => values.value <= 100 && countDecimals(values.value) < 3;

  const slackSectionProps = {
    inputData: { notify_slack },
    change,
    allow,
    redirect,
  };
  return (
    <>
      <label className="filtersLabel">SET BUDGET</label>
      <div className="np-input-group">
        <label className="np-label">
          Set Monthly Budget Limit <span className="red">*</span>
        </label>
        <input
          type="number"
          name="budget"
          className="np-input"
          placeholder="Enter $ amount"
          value={budget}
          onChange={change}
        />
        {errors.budget && <span className={'red'}>{errors.budget}</span>}
      </div>
      {/* <div className="np-input-group">
        <label className="np-label">
          Select basis for comparison <span className="red">*</span>
        </label>
        <div className="np-budget-type-container">
          {BUDGET_TYPES.map(item => (
            <div
              id={item.key}
              key={item.key}
              className={`np-radio-container ${
                disabled ? 'disable-radio' : ''
              }`}
            >
              <div className="np-radio">
                <input
                  id={`np-radio-${item.key}`}
                  name="budget_type"
                  type="radio"
                  value={item.key}
                  checked={item.key === budget_type}
                  onChange={radioChange}
                  disabled={disabled}
                />
                <label
                  className="np-radio-label"
                  htmlFor={`np-radio-${item.key}`}
                />
              </div>
              <label
                className="np-filterLabel"
                htmlFor={`np-radio-${item.key}`}
              >
                {item.label}
              </label>
            </div>
          ))}
        </div>
      </div> */}
      <div className="np-input-group">
        <div className="np-check">
          <input
            id="np-notify-check3"
            type="checkbox"
            name="notify"
            checked={notify}
            onChange={change}
            disabled={disabled}
          />
          <label className={`np-check-label ${disabled ? 'disable-notify' : ''}`} htmlFor={`np-notify-check3`}>
            <EmptySvg />
          </label>
          <span className="np-check-title np-notify-txt">Notify me via email or slack</span>
        </div>
      </div>
      {notify && (
        <>
          <div className="np-input-group">
            <label className="np-label">
              Email Address <small>(Receive alerts of overbudget) </small>
              <span className="red">*</span>
            </label>
            <MultiCreatableSelectDropDown
              data={orderBy(
                allUserData.map(item => ({
                  value: item.email,
                  label: item.email,
                })),
                'email',
              )}
              placeHolder="Email"
              onChange={detail => emailChange(detail)}
              isClearable={true}
              defaultData={emailList}
            />
            {errors.emailList && <span className="red">{errors.emailList}</span>}
          </div>
          <div className="np-input-group">
            <label className="np-label">
              Send Alert When Threshold Crosses by <span className="red">*</span>
            </label>
            <NumericFormat
              className="np-input"
              placeholder="e.g 10%"
              allowNegative={false}
              suffix="%"
              isAllowed={handleThresholdValidation}
              onValueChange={values => changeThreshold(values, 'notify_threshold')}
              value={notify_threshold}
            />
            {errors.notify_threshold && <span className="red">{errors.notify_threshold}</span>}
          </div>
          <SlackSection {...slackSectionProps} />
        </>
      )}
    </>
  );
};

export default BudgetSection;
