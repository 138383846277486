import get from 'lodash/get';
import { createSelector } from 'reselect';
import { getTagsData, getUntaggedData } from 'utils/helper';

export const selectCost = state => state?.['COST'] || {};

export const makeSelectClusterStatus = () => createSelector(selectCost, state => state.clusterStatus);

export const makeSelectCostForAll = () => createSelector(selectCost, state => state.costForAll);

export const makeSelectSetFilters = () => createSelector(selectCost, state => state.selectedFilters);

export const makeMRCSelectCostForAll = () => createSelector(selectCost, state => state.mrcData);

export const makeSelectUsageTypeData = () => createSelector(selectCost, state => state.usageTypeData);

export const makeSelectOperationData = () => createSelector(selectCost, state => state.operationData);

export const makeSelectSavingData = () => createSelector(selectCost, state => state.spot || null);

export const makeSelectUnusedSavingData = () => createSelector(selectCost, state => state.unused || null);

export const makeSelectUnusedAzureSavingData = () => createSelector(selectCost, state => state.unusedAzure || null);

export const makeSelectUnderutilizedSavingData = () => createSelector(selectCost, state => state.underutilized || null);

export const makeSelectUnderutilizedAzureSavingData = () =>
  createSelector(selectCost, state => state.underutilizedAzure || null);

export const makeSelectRRSavingData = () => createSelector(selectCost, state => state.rightsizing || null);

export const makeSelectSavingLoading = () => createSelector(selectCost, state => state.savingLoading || {});

export const makeSelectCostFilters = () => createSelector(selectCost, state => state.filters);

export const makeSelectCostFiltersCloud = () => createSelector(selectCost, state => state.filtersCloud);

export const makeSelectCostDefaultFilters = () => createSelector(selectCost, state => state.defaultFilters);

export const makeSelectCostDefaultFiltersCloud = () => createSelector(selectCost, state => state.defaultFiltersCloud);

export const makeSelectCostDefaultFiltersLoading = () =>
  createSelector(selectCost, state => state.defaultFilterLoading);

export const makeSelectDataLoading = () => createSelector(selectCost, state => state && state.loading);

export const makeSelectChargebackData = () => createSelector(selectCost, state => state?.chargebackData || []);

export const makeSelectChargebackLoading = () => createSelector(selectCost, state => state.chargebackLoading);

export const makeSelectFilterLoading = () => createSelector(selectCost, state => state.filterLoading);

export const summaryLoadingSelector = () => createSelector(selectCost, state => state.summaryLoading);

export const summaryDataSelector = () => createSelector(selectCost, state => state.summaryData);

export const summaryCreditsDataSelector = () => createSelector(selectCost, state => state.summaryCreditsData);

export const summaryFilterDataSelector = () => createSelector(selectCost, state => state.summaryFilter);

export const makeSelectCostDetail = () => createSelector(selectCost, state => state && state.detailCostAll);

export const makeSelectCostDetailLoading = () => createSelector(selectCost, state => state && state.detailLoading);

export const makeSelectCMLoading = () => createSelector(selectCost, state => state.cmLoading);

export const makeSelectCMData = () => createSelector(selectCost, state => state.CMData);

export const makeSelectTagsLoading = () => createSelector(selectCost, state => state.tagsLoading);

export const makeTagsDataFilterSelector = () => createSelector(selectCost, state => state.tagsFilter);

export const tagsDataSelector = createSelector(selectCost, state => state.tagsData);

export const makeSelectUntaggedResources = () => createSelector(selectCost, state => state.untaggedResources);

export const tagsValuesSelector = createSelector(tagsDataSelector, items =>
  (items || []).map(item => get(item, 'arp_tags_values.buckets', [])),
);

export const tagsKeysSelector = createSelector(tagsDataSelector, items =>
  (items || []).map(item => getTagsData(item, 'keys')),
);

export const makeSelectTagsData = () =>
  createSelector(tagsKeysSelector, tagsValuesSelector, (keys, values) =>
    (keys || []).map((key, idx) => ({
      ...key,
      values: (values?.[idx] || []).map(value => getTagsData(value, 'values')),
    })),
  );

export const makeSelectAllTagsData = () =>
  createSelector(makeSelectTagsData(), makeSelectUntaggedResources(), (taggedData, untaggedData) => [
    ...getUntaggedData(untaggedData),
    ...taggedData.sort((tagValue1, tagValue2) => tagValue1.totalCost > tagValue2.totalCost),
  ]);
