import React from 'react';
import { CloseIcon } from 'assets/images/modal';
import SingleSelectDropDown from 'components/Dropdown/SingleSelectDropDown';
import CreatableSelectDropDown from 'components/Dropdown/CreatableSelectDropDown';

const TagsSection = ({ tags, tagKeys, onChange, addTags, removeTags, disabled, cloudPlatform }) => {
  return (
    <>
      <div className="np-input-group">
        <hr />
      </div>
      {tagKeys.length ? (
        <>
          <label className="filtersLabel">Select Tags </label>
          <div className="np-row">
            <div className="npxs-6">
              {tagKeys?.length ? (
                <div id="tagBucket-0" className="np-input-group">
                  <label className="np-label">Select Tag Name</label>
                  <SingleSelectDropDown
                    data={tagKeys.map(tag => ({
                      value: tag.key,
                      label: tag.key,
                      cloudPlatform: tag.cloudPlatform,
                    }))}
                    type="tagKey"
                    placeHolder="Select tag key"
                    onChange={onChange}
                    defaultData={tags[0]?.tagKey || {}}
                    extraProps={{ index: 0 }}
                    isDisabled={disabled}
                    cloudPlatform={cloudPlatform}
                  />
                  {tags[0]?.errorKey ? (
                    <span id="errorKey" className="red">
                      {tags[0].errorKey}
                    </span>
                  ) : null}
                </div>
              ) : null}
            </div>

            <div className="npxs-6">
              <div id="tagBucketValue-0" className="np-input-group">
                <label className="np-label">
                  Select Tag Value <span className="red">*</span>
                </label>
                <CreatableSelectDropDown
                  data={tags[0]?.selectedTagsAllValue || []}
                  type="tagValue"
                  placeHolder="Select tag value"
                  onChange={onChange}
                  defaultData={tags[0]?.tagValue || {}}
                  isLoading={tags[0]?.tagKey && tags[0]?.loading}
                  extraProps={{ index: 0 }}
                  isDisabled={tags[0]?.selectedTagsAllValue?.length === 0 || disabled}
                />
                {tags[0]?.errorValue ? (
                  <span id="errorValue" className="red">
                    {tags[0].errorValue}
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          {tags?.length > 1
            ? tags.map((t, index) => {
                if (index === 0) return null;

                return (
                  <div key={index} className="np-tagsMulti">
                    <div className="removeTags-container">
                      {!disabled && (
                        <span
                          id={'removeTags-' + index}
                          className="remove-tagsMulti"
                          title="Remove tag"
                          onClick={removeTags(index)}
                        >
                          <i className="np-svgIcon">
                            <CloseIcon />
                          </i>
                        </span>
                      )}
                    </div>
                    <div className="np-row">
                      <div className="npxs-6">
                        <div id={`tagBucket-${index}`} className="np-input-group">
                          <label className="np-label">
                            Select Tag Name <span className="red">*</span>
                          </label>
                          <SingleSelectDropDown
                            data={tagKeys.map(tag => ({
                              value: tag.key,
                              label: tag.key,
                              cloudPlatform: tag.cloudPlatform,
                            }))}
                            type="tagKey"
                            placeHolder="Select tag key"
                            onChange={onChange}
                            defaultData={tags[index]?.tagKey || {}}
                            extraProps={{ index }}
                            isDisabled={disabled}
                          />
                          {tags[index]?.errorKey ? (
                            <span id={`tagKey-${index}`} className="red">
                              {tags[index].errorKey}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="npxs-6">
                        <div id={`tagBucketValue-${index}`} className="np-input-group">
                          <label className="np-label">
                            Select Tag Value <span className="red">*</span>
                          </label>
                          <CreatableSelectDropDown
                            data={tags[index]?.selectedTagsAllValue || []}
                            type="tagValue"
                            placeHolder="Select tag value"
                            onChange={onChange}
                            defaultData={tags[index]?.tagValue || {}}
                            isLoading={tags[index]?.tagKey && tags[index]?.loading}
                            extraProps={{ index: index }}
                            isDisabled={tags[index]?.selectedTagsAllValue?.length === 0 || disabled}
                          />
                          {tags[index]?.errorValue ? (
                            <span id={`tagValue-${index}`} className="red">
                              {tags[index].errorValue}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          {tags.length < 10 && !disabled ? (
            <div className="np-input-group">
              <span id="addTag" onClick={addTags} className="np-anchor">
                <b>+ Add Another Tag</b>
              </span>
            </div>
          ) : null}
        </>
      ) : (
        <label className="filtersLabel">No Tags For This Cloud Platform</label>
      )}

      <div className="np-input-group">
        <hr />
      </div>
    </>
  );
};

export default TagsSection;
