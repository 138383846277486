export const LOAD_SEARCH_REQUEST = 'SEARCH/LOAD_SEARCH_REQUEST';
export const LOAD_SEARCH_SUCCESS = 'SEARCH/LOAD_SEARCH_SUCCESS';
export const LOAD_SEARCH_ERROR = 'SEARCH/LOAD_SEARCH_ERROR';

export const LOAD_SEARCH_CM_DATA = 'SEARCH/LOAD_SEARCH_CM_DATA';
export const LOAD_SEARCH_CM_DATA_SUCCESS = 'SEARCH/LOAD_SEARCH_CM_DATA_SUCCESS';
export const LOAD_SEARCH_CM_DATA_ERROR = 'SEARCH/LOAD_SEARCH_CM_DATA_ERROR';

export const LOAD_SEARCH_FILTER_REQUEST = 'SEARCH/LOAD_SEARCH_FILTER_REQUEST';
export const LOAD_SEARCH_FILTER_SUCCESS = 'SEARCH/LOAD_SEARCH_FILTER_SUCCESS';
export const LOAD_SEARCH_FILTER_ERROR = 'SEARCH/LOAD_SEARCH_FILTER_ERROR';

export const LOAD_SEARCH_RESOURCE_DETAIL_REQUEST = 'SEARCH/LOAD_SEARCH_RESOURCE_DETAIL_REQUEST';
export const LOAD_SEARCH_RESOURCE_DETAIL_SUCCESS = 'SEARCH/LOAD_SEARCH_RESOURCE_DETAIL_SUCCESS';
export const LOAD_SEARCH_RESOURCE_DETAIL_ERROR = 'SEARCH/LOAD_SEARCH_RESOURCE_DETAIL_ERROR';

export const LIST_AGGREGATION = 'search_results_list';
export const LIST_AGGREGATION_CLOUD = 'search_results_list_cloud_type';
export const WAR_SUMMARY_AGGREGATION = 'search_war_summary_by_type';
export const FILTER_COUNT_AGGREGATION = 'search_counters';
export const FILTER_COUNT_AGGREGATION_CLOUD_TYPE = 'search_counters_cloud_type';

export const RESOURCES_TABS = {
  RESOURCES_DETAILS: 'Resources Details',
  COST_HISTORY: 'Cost History',
};
