import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

export const useDeepCompareEffect = (callback, dependencies) => {
  const currentDepsRef = useRef();

  if (!isEqual(currentDepsRef.current, dependencies)) {
    callback();
  }

  currentDepsRef.current = dependencies;
};
