import {
  LOAD_PARTNER_INITIAL_REQUEST,
  LOAD_PARTNER_PROFILE_SUCCESS,
  LOAD_PARTNER_PROFILE_ERROR,
  LOAD_PARTNER_CLIENT_REQUEST,
  LOAD_PARTNER_CLIENT_SUCCESS,
  LOAD_PARTNER_CLIENT_ERROR,
  LOAD_PARTNER_DETAIL_REQUEST,
  LOAD_PARTNER_DETAIL_SUCCESS,
  LOAD_PARTNER_DETAIL_ERROR,
  LOAD_PARTNER_STATUS_SUCCESS,
  LOAD_PARTNER_STATUS_ERROR,
} from './constants';

export function loadPartnerInitial() {
  return {
    type: LOAD_PARTNER_INITIAL_REQUEST,
  };
}

export function loadPartnerProfileSuccess(response) {
  return {
    type: LOAD_PARTNER_PROFILE_SUCCESS,
    response,
  };
}

export function loadPartnerProfileError() {
  return {
    type: LOAD_PARTNER_PROFILE_ERROR,
  };
}

export function loadPartnerClientInitial() {
  return {
    type: LOAD_PARTNER_CLIENT_REQUEST,
  };
}

export function loadPartnerClientSuccess(response) {
  return {
    type: LOAD_PARTNER_CLIENT_SUCCESS,
    response,
  };
}

export function loadPartnerClientError() {
  return {
    type: LOAD_PARTNER_CLIENT_ERROR,
  };
}

export function loadPartnerDetail() {
  return {
    type: LOAD_PARTNER_DETAIL_REQUEST,
  };
}

export function loadPartnerDetailSuccess(response) {
  return {
    type: LOAD_PARTNER_DETAIL_SUCCESS,
    response,
  };
}

export function loadPartnerDetailError() {
  return {
    type: LOAD_PARTNER_DETAIL_ERROR,
  };
}

export function loadPartnerStatusSuccess(response) {
  return {
    type: LOAD_PARTNER_STATUS_SUCCESS,
    response,
  };
}

export function loadPartnerStatusError() {
  return {
    type: LOAD_PARTNER_STATUS_ERROR,
  };
}
