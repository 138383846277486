export const getColorsPalettes = dataLength => {
  const colorPalettes = [];
  let i = 190;

  do {
    colorPalettes.push({
      backgroundColor: `rgb(255, ${i}, ${i})`,
      borderColor: '#FFFFFF',
    });
    i += 3;
  } while (colorPalettes.length <= dataLength);

  return colorPalettes;
};
