import React, { Component } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import forIn from 'lodash/forIn';

import { getNewQueryString, getParamFromQueryString, removeQueryString } from 'utils/queryString';
import {
  IsJsonValid,
  IsURIValid,
  checkIsEqual,
  returnTypeForAPI,
  setDefaultFilterData,
  keyToCloudObj,
} from 'utils/helper';
import { getFilterParams, getFilterQuery } from 'utils/filter';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import NoDataIcon from 'assets/images/no-data.svg';
import NoViolationsIcon from 'assets/images/no-violations.svg';

import { Anchor } from 'components/Custom';
import ErrorBoundary from 'components/Common/ErrorBoundary';
import Loader from 'components/LoadingIndicator/Loader';

import CloudAccountBanner from 'containers/BasePage/cloudAccBanner';
import globalReducer from 'containers/App/reducer';
import globalSaga from 'containers/App/saga';
import FilterComponent from 'containers/Filters';
import SelectedFilters from 'components/SelectedFilters';
import {
  makeAccountFilter,
  makeGlobalLoading,
  makeSelectClientLoading,
  makeSelectHasProject,
  makeSelectOverrideTimeStamp,
  makeUserData,
  selectFilter,
} from 'containers/App/selectors';
import { makeVPCNames, makeVPCCloudNames } from 'containers/Filters/selectors';
import { RULES_DESCRIPTION_TEXT } from 'utils/RuleDetail';

import { RULES_PILLAR, RULE_DETAILS_MAPPING, TABLE_DATA } from '../utils';
import ChangeManagement from './changeManagemnet';
import { loadCMDataRequest, loadData, loadRuleFilterDataRequest } from '../action';
import reducer from '../reducer';
import saga from '../saga';
import {
  makeChartDataStore,
  makeFilterStore,
  makeSelectChangeManagement,
  makeSelectRuleFilterCount,
  makeSelectRuleLoading,
  makeSelectRuleTimeStamp,
  makeSelectRuleViolations,
  makeSelectRulesCMLoading,
  makeSelectRulesCMLoadingViolation,
  makeSelectRulesFilterLoading,
  makeSelectRuleFilterCountCloud,
} from '../selectors';
import { RightPageContent } from './rightPageContent';
import { chartFilterData, getDisplayData } from './helper';
import { renderHeader, renderNoViolationElement } from './Header';
import { NEW_DETECTION, PILLARS_COLUMNS } from './Table/columns';
import { filterProps, IS_SHOW, RULE_DETAIL_PATHS } from '../constants';
import LoadingWrapper from 'components/ContentLoader/LoadingWrapper';
import { LandingPageLoader, DetailsCMPageLoader } from 'components/ContentLoader/RulesLoaders';
import NoData, { NO_DATA_CONTENT } from 'components/NoData';
import BillingInconsistencyBanner from 'containers/BasePage/inconsistencyBanner';

export const getColumns = tab => {
  const columns = [...PILLARS_COLUMNS];
  const rest = columns.slice(1, columns.length);
  return [columns[0], NEW_DETECTION, ...rest];
};

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: TABLE_DATA,
      activeTab: RULES_PILLAR.SECURITY,
      payload: {},
      chartData: {},
    };
  }

  componentDidMount = () => {
    const { hasProject, location } = this.props;
    let pillarActive = getParamFromQueryString(location.search, 'tab') || RULES_PILLAR.SECURITY;
    const activeRule = getParamFromQueryString(location.search, 'rule') || '';
    if (activeRule) {
      pillarActive = RULES_DESCRIPTION_TEXT?.[activeRule]?.pillar;
    }
    this.setState({
      pillarActive,
      activeRule,
      columns: getColumns(pillarActive),
    });
    if (hasProject) {
      const pData = getParamFromQueryString(location.search, 'data');

      if (!isEmpty(pData)) this.setFilterData();
      else this.compareAndCall();
    }
  };

  componentDidUpdate = prevProps => {
    const {
      hasProject,
      userData: { projects = [] },
      changeData = {},
      counts = {},
      ruleViolations = [],
      location,
      countsCloud = {},
      configCatAzure,
      cmLoading,
      cmLoadingViolation,
    } = this.props;
    const {
      ruleViolations: oldRuleViolations = [],
      changeData: oldChangeData = {},
      userData: { projects: oldProjects = [] },
      hasProject: oldHasProject,
      counts: prevCounts = {},
      location: oldLocation,
      countsCloud: prevCountsCloud = {},
    } = prevProps;

    const tabQueryValue = getParamFromQueryString(location.search, 'tab');
    const dataQueryValue = getParamFromQueryString(location.search, 'data');
    const oldDataQueryValue = getParamFromQueryString(oldLocation.search, 'data');

    const pillarActive = tabQueryValue || this.state.pillarActive || RULES_PILLAR.SECURITY;

    if (this.state.pillarActive !== pillarActive) {
      this.setState({
        pillarActive,
        columns: getColumns(pillarActive),
      });
    }

    if (
      (ruleViolations.length > 0 && checkIsEqual(false, ruleViolations, oldRuleViolations)) ||
      (projects.length > 0 && checkIsEqual(false, projects, oldProjects)) ||
      (checkIsEqual(false, changeData, {}) && checkIsEqual(false, changeData, oldChangeData)) ||
      cmLoading !== prevProps.cmLoading ||
      cmLoadingViolation !== prevProps.cmLoadingViolation ||
      (configCatAzure ? checkIsEqual(false, countsCloud, prevCountsCloud) : checkIsEqual(false, counts, prevCounts))
    ) {
      this.displayRuleData();
    }

    if (checkIsEqual(false, dataQueryValue, oldDataQueryValue)) {
      if (!isEmpty(dataQueryValue)) this.setFilterData();
      else this.compareAndCall();
    }

    if (hasProject && !oldHasProject) {
      this.setFilterData();
    }
  };

  compareAndCall = (filters = {}, workload = '*', payload = {}) => {
    const {
      filterStore = {},
      ruleViolations = [],
      rulesTimeStamp = '',
      overrideTimeStamp = '',
      loadData,
      hasProject,
      loadCMDataRequest,
      loadRuleFilterDataRequest,
      configCatAzure = false, // TEMP
      hideAzure = false,
    } = this.props;
    // const configCatAzure = false; // temp until rules are ready
    const flag = (!ruleViolations.length && isEmpty(filterStore)) || !isEqual(payload, filterStore);

    if (flag || !ruleViolations.length || rulesTimeStamp !== overrideTimeStamp) {
      const newPayload = {
        filters,
        workload,
        tagData: payload?.tagData,
        cit: payload?.cit,
        selectedAutoScaling: payload?.selectedAutoScaling,
      };
      const params = setDefaultFilterData(newPayload);
      if (hasProject) {
        const paramToPass = {
          params: {
            ...params,
            type: returnTypeForAPI(params.filters, params.workload),
          },
          time: overrideTimeStamp,
          payload,
          configCatAzure,
          hideAzure,
        };
        loadData(paramToPass);
        loadCMDataRequest(paramToPass);
        loadRuleFilterDataRequest(paramToPass);
      }
    } else {
      this.displayRuleData();
    }
  };

  setFilterData = () => {
    const { history: { location = {} } = {} } = this.props;
    let newPayload = {};
    let data = getParamFromQueryString(location.search, 'data') || '';
    let workload = '*',
      filter = {};
    data = IsJsonValid(IsURIValid(data));
    if (!isEmpty(data)) {
      newPayload = data?.payload || {};
      workload = getFilterQuery(data?.payload || {});
      const filters = getFilterParams(data?.payload || {});
      filter = { ...filters };
    }
    this.setState(
      {
        payload: newPayload,
      },
      () => {
        this.compareAndCall(filter, workload, newPayload);
      },
    );
  };

  displayRuleData = () => {
    const {
      ruleViolations,
      counts,
      countsCloud,
      changeData,
      loading,
      cmLoading,
      cmLoadingViolation,
      filterLoading,
      accountFilter,
      vpcNames,
      vpcNamesCloud,
      configCatAzure,
    } = this.props;
    const { activeRule, payload } = this.state;
    if (loading || cmLoading || cmLoadingViolation || filterLoading || !ruleViolations?.length) return;
    const cps = (payload?.cloudPlatformState || []).length > 0 ? payload.cloudPlatformState[0] : '';
    const hideAzure = cps === 'aws';
    const hideAWS = cps === 'azure';

    const ruleMapping = cloneDeep(RULE_DETAILS_MAPPING(RULES_DESCRIPTION_TEXT));

    const tableData_clone = getDisplayData({
      ruleViolations,
      countBucket: configCatAzure
        ? countsCloud?.violations_counts_history_per_type?.buckets ||
          countsCloud?.violations_counts_history_per_service_type?.buckets ||
          []
        : counts?.violations_counts_history_per_type?.buckets || [],
      changeData,
      ruleMapping,
      activeRule,
      isUnusedRepeat: true,
      isLink: true,
      configCatAzure,
      hideAzure,
      hideAWS,
    });

    // create new rules mappings to match data displayed in table
    const pillar_wise_rules = {
      Cost: [],
      Operations: [],
      Performance: [],
      Reliability: [],
      Security: [],
    };
    forIn(ruleMapping, (rule_detail, rule_key) => {
      forIn(rule_detail.pillars_name || [rule_detail.pillar], (pillar_detail, pillar_key) => {
        pillar_wise_rules[pillar_detail] = [...pillar_wise_rules[pillar_detail], rule_key];
      });
    });

    const chartData = chartFilterData(
      configCatAzure ? countsCloud : counts,
      accountFilter,
      configCatAzure ? vpcNamesCloud : vpcNames,
      pillar_wise_rules,
    );
    this.setState({ tableData: tableData_clone, chartData });
  };

  handleActivePillar = pillarName => {
    const searchString = removeQueryString(this.props.location.search, 'cmTab');
    this.props.history.push({
      pathname: '/v3/rules/',
      search: getNewQueryString(searchString, { tab: pillarName }),
    });
  };

  clearFiltration = () => {
    this.setState({ payload: {} }, () => {
      this.props.history.push('/v3/rules/');
    });
  };

  handleViewRule = path => {
    this.props.history.push({
      pathname: path.path,
      search: this.props.location.search,
    });
  };

  onClicked = data => {
    // if (!data?.count || 0) return;

    const uri = this.props.location.search ? this.props.location.search + '&' + data.path : data.path;
    let search = removeQueryString(uri, 'data');
    const state = {
      payload: cloneDeep(this.state.payload || {}),
    };

    // change cloud type when selecting rules
    if (this.props.configCatAzure) {
      state.payload.cloudPlatformState = [data.cloudPlatform];
      state.payload.cloudPlatformState_values = [keyToCloudObj(data.cloudPlatform)];
    }

    const stateString = JSON.stringify(state);
    const newEncodedURI = encodeURIComponent(stateString);
    search = getNewQueryString(search, { data: newEncodedURI });

    this.props.history.push({
      pathname: RULE_DETAIL_PATHS,
      search,
    });
  };

  getDataToDisplay = () => {
    const { pillarActive, tableData } = this.state;
    return cloneDeep(tableData[pillarActive]?.data || []);
  };

  updateFilter = payload => {
    let search = removeQueryString(this.props.location.search, 'data');
    const state = {
      payload,
    };
    const stateString = JSON.stringify(state);
    const newEncodedURI = encodeURIComponent(stateString);
    search = getNewQueryString(search, { tab: this.state.pillarActive });
    search = `${search}&data=${newEncodedURI}`;
    this.props.history.push({
      pathname: '/v3/rules/',
      search: search,
    });
  };

  redirectToNotification = () => {
    this.props.history.push('/v3/settings?tab=Notifications+Center&subTab=nOps%20Rules');
  };

  renderPillars = () => {
    const { tableData, pillarActive, activeRule } = this.state;
    if (!pillarActive) return;
    const pillars = [];
    if (activeRule) {
      pillars.push(
        <li className="active">
          <Anchor id={pillarActive}>
            {pillarActive} {tableData[pillarActive].count > 0 ? `(${tableData[pillarActive].count})` : ''}
          </Anchor>
        </li>,
      );
    } else {
      forIn(tableData, (data, pillar) => {
        pillars.push(
          <li
            id={'handleActivePillar-' + pillar}
            key={pillar}
            className={pillarActive === pillar ? 'active' : ''}
            onClick={() => this.handleActivePillar(pillar)}
          >
            <Anchor id={data.name}>
              {data.name} {data.count > 0 ? `(${data.count})` : ''}
            </Anchor>
          </li>,
        );
      });
    }

    return pillars;
  };

  renderContent = () => {
    const { tableData, pillarActive, columns, chartData } = this.state;
    const {
      ruleViolations = [],
      changeData = {},
      userData: { projects = [] },
      location: { search = '' } = {},
      reliabilityChartData,
      filterLoading,
      cmLoading,
      cmLoadingViolation,
      loading,
      configCatAzure,
    } = this.props;
    return (
      <div className="np-rightContent">
        <SelectedFilters availableFilters={configCatAzure ? [...IS_SHOW, 'cloudPlatform', 'product'] : IS_SHOW} />
        <LoadingWrapper
          isLoading={loading || filterLoading || cmLoading || cmLoadingViolation}
          customLoader={pillarActive === RULES_PILLAR.CM ? DetailsCMPageLoader : LandingPageLoader}
          customLoadingClassName="loading-wrapper-rules"
        >
          {pillarActive === RULES_PILLAR.CM ? (
            <ChangeManagement {...this.props} data={changeData} projects={projects} configCatAzure={configCatAzure} />
          ) : !ruleViolations?.length ? (
            renderNoViolationElement(
              <img src={NoDataIcon} alt="noData" />,
              'Unable to fetch result. Data ingestion status will update soon.',
            )
          ) : !tableData[pillarActive]?.data?.length ? (
            renderNoViolationElement(
              <img src={NoViolationsIcon} alt="noViolation" />,
              `No violations related to ${pillarActive} in your infrastructure. Great job!`,
            )
          ) : (
            <RightPageContent
              search={search}
              updateFilter={this.updateFilter}
              chartData={chartData[pillarActive]}
              tab={pillarActive}
              columns={columns}
              count={tableData[pillarActive].count}
              lastCount={tableData[pillarActive].lastCount}
              data={this.getDataToDisplay()}
              pillarActive={pillarActive}
              onClicked={this.onClicked}
              reliabilityChartData={reliabilityChartData}
              configCatAzure={configCatAzure}
            />
          )}
        </LoadingWrapper>
      </div>
    );
  };

  render() {
    const { pillarActive, payload } = this.state;
    const {
      counts = {},
      clientLoading = false,
      countsCloud = {},
      userData = {},
      hasProject,
      completeSetup,
      configCatAzure = false,
    } = this.props;
    const mainClass = classNames('np-content np-rules np-rulesN ', {
      'np-nops-loading': clientLoading,
    });
    return (
      <div className={mainClass}>
        <Loader />
        <div className="np-container-fluid">
          <BillingInconsistencyBanner userData={userData} />
          {!userData?.profile?.has_project && userData?.profile?.is_admin ? (
            <CloudAccountBanner
              onCompleteSetup={completeSetup}
              sharesave_allowed={userData?.sharesave_allowed}
              has_marketplace_subscription_or_contract={userData?.profile?.has_marketplace_subscription_or_contract}
            />
          ) : null}
          {renderHeader(userData?.isAdmin, this.redirectToNotification)}
          <div className="np-row">
            <div className="npxs-12">
              <div className="np-wrapper">
                <div className="np-contentMain full">
                  <div className="np-tabs">
                    <ul className="tabs">{this.renderPillars()}</ul>
                  </div>
                  <div className="page">
                    {!hasProject ? (
                      <NoData {...NO_DATA_CONTENT[`RULES_${pillarActive?.replace(/\s/g, '')}`]} />
                    ) : (
                      <>
                        <div className="np-filters">
                          <ErrorBoundary>
                            <FilterComponent
                              history={this.props.history}
                              dataFilters={counts}
                              dataFiltersCloud={countsCloud}
                              clearFiltration={this.clearFiltration}
                              rule="Rules"
                              isShow={IS_SHOW}
                              filterProps={filterProps}
                              updateFilter={this.updateFilter}
                              filters={payload}
                              configCatAzure={configCatAzure}
                            />
                          </ErrorBoundary>
                        </div>
                        {this.renderContent()}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const key = 'RULES';
const globalKey = 'global';

const mapStateToProps = createStructuredSelector({
  loading: makeSelectRuleLoading(),
  cmLoading: makeSelectRulesCMLoading(),
  cmLoadingViolation: makeSelectRulesCMLoadingViolation(),
  filterLoading: makeSelectRulesFilterLoading(),
  userData: makeUserData(),
  ruleViolations: makeSelectRuleViolations(),
  counts: makeSelectRuleFilterCount(),
  countsCloud: makeSelectRuleFilterCountCloud(),
  filters: selectFilter,
  globalLoading: makeGlobalLoading(),
  clientLoading: makeSelectClientLoading(),
  changeData: makeSelectChangeManagement(),
  overrideTimeStamp: makeSelectOverrideTimeStamp(),
  filterStore: makeFilterStore(),
  rulesTimeStamp: makeSelectRuleTimeStamp(),
  hasProject: makeSelectHasProject(),
  reliabilityChartData: makeChartDataStore(),
  accountFilter: makeAccountFilter(),
  vpcNames: makeVPCNames(),
  vpcNamesCloud: makeVPCCloudNames(),
});

const withConnect = connect(mapStateToProps, {
  loadData,
  loadCMDataRequest,
  loadRuleFilterDataRequest,
});

const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });
const withGlobalReducer = injectReducer({
  key: globalKey,
  reducer: globalReducer,
});
const withGlobalSaga = injectSaga({
  key: globalKey,
  saga: globalSaga,
});

export default compose(withReducer, withSaga, withGlobalReducer, withGlobalSaga, withConnect)(LandingPage);
