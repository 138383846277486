import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTagResourceValueData } from 'api/global';
export const initialState = {
  showKeyPopUp: false,
  showValuePopUp: false,
  searchKey: '',
  searchValue: '',
  selectedTag: '',
  selectedTagCloudPlatform: '',
  selectedKeyValue: [],
  propsFilter: [],
  tagResourcesValue: [],
  tagResourcesLoading: false,
};

const SLICE_NAME = 'tags_filter';

//async CALLS
export const loadTagValue = createAsyncThunk(`${SLICE_NAME}/getTagResourceValueData`, async payload => {
  const response = await getTagResourceValueData(payload);
  const records = response?.data?.aggregations?.arp_resource_tag_value_list?.filter?.terms?.buckets || [];
  const values = records.length ? records.filter(x => x.key) : [];
  return {
    key: payload.key,
    data: values,
    cloudPlatform: payload.cloudPlatform,
    configCatAzure: payload.configCatAzure,
  };
});

const tagFilterSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setShowKeyPopUp: (state, { payload }) => {
      state.showKeyPopUp = payload;
    },
    setShowValuePopUp: (state, { payload }) => {
      state.showValuePopUp = payload;
    },
    setSearchKey: (state, { payload }) => {
      state.searchKey = payload;
    },
    setSearchValue: (state, { payload }) => {
      state.searchValue = payload;
    },
    setSelectedTag: (state, { payload }) => {
      state.selectedTag = payload;
    },
    setSelectedTagCloudPlatform: (state, { payload }) => {
      state.selectedTagCloudPlatform = payload;
    },
    setSelectedKeyValue: (state, { payload }) => {
      state.selectedKeyValue = payload;
    },
    setPropsFilter: (state, { payload }) => {
      state.propsFilter = payload;
    },
    setInitialState: state => {
      state.showKeyPopUp = false;
      state.showValuePopUp = false;
      state.searchKey = '';
      state.searchValue = '';
      state.selectedTag = '';
      state.selectedTagCloudPlatform = '';
      state.selectedKeyValue = [];
      state.propsFilter = [];
    },
  },
  extraReducers: {
    [loadTagValue.pending]: (state, action) => {
      state.tagResourcesLoading = true;
    },
    [loadTagValue.fulfilled]: (state, { payload }) => {
      state.tagResourcesLoading = false;
      const index = state.tagResourcesValue.findIndex(
        tag =>
          tag.key === payload.key &&
          (!payload.configCatAzure || (payload.configCatAzure && tag.cloudPlatform === payload.cloudPlatform)),
      );
      if (index === -1) state.tagResourcesValue.push(payload);
    },
    [loadTagValue.rejected]: state => {
      state.tagResourcesLoading = false;
    },
  },
});

export const {
  setPropsFilter,
  setSearchKey,
  setSearchValue,
  setSelectedKeyValue,
  setSelectedTag,
  setSelectedTagCloudPlatform,
  setShowKeyPopUp,
  setShowValuePopUp,
  setInitialState,
} = tagFilterSlice.actions;

export default tagFilterSlice.reducer;
