import axiosObj from './config';

/************************ JIRA Integration start *****************************/
export const jiraIntegration = () => {
  return axiosObj({
    method: 'GET',
    url: '/c/jira/settings/',
  });
};

export const jiraOAuth2Integration = () => {
  return axiosObj({
    method: 'GET',
    url: '/c/v2/jira/oauth-data/',
  });
};

export const setJiraIntegration = (id, payload) => {
  let method = 'POST';
  let url = '/c/jira/settings/';
  if (id !== -1) {
    method = 'PATCH';
    url = `/c/jira/settings/${id}`;
  }
  return axiosObj({
    method,
    url,
    data: payload,
  });
};
/************************ JIRA Integration end *******************************/

/*************** JIRA Data(Project, issue type, priorities) start ************/
export function getJiraData(param) {
  let url = '/c/jira/data/';
  if (param) url = url + param;
  return axiosObj({
    method: 'GET',
    url,
  });
}
/*************** JIRA Data(Project, issue type, priorities) end **************/

/************************** JIRA Credentials status start ********************/
export function getJiraCredentialsData(old_auth_only = false) {
  return axiosObj({
    method: 'GET',
    url: `/c/jira/auth/credentials/${old_auth_only ? '?old_auth_only=1' : ''}`,
  });
}

export const getJiraLink = () => {
  return axiosObj({
    method: 'POST',
    url: '/c/jira/auth/credentials/',
  });
};
/************************** JIRA Credentials status end **********************/

/************************** JIRA OAuth2 Credentials start ********************/
export const getJiraOAuth2Link = () => {
  return axiosObj({
    method: 'GET',
    url: '/c/v2/jira/get-auth-url/',
  });
};
/************************** JIRA OAuth2 Credentials end **********************/

/************************** JIRA all issue list start ************************/

export function getAllDetailBasedOnProject(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/jira/project/${id}/`,
  });
}

/************************** JIRA all issue list end ************************/

/************************** JIRA issue transitions start ************************/

export function getIssueTransitions(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/jira/issue/${id}/transitions/`,
  });
}

/************************** JIRA issue transitions end ************************/

/************************** JIRA issue transitions start ************************/

export function IssuePerformTransition(id, transition_id) {
  return axiosObj({
    method: 'POST',
    url: `/c/jira/issue/${id}/transitions/`,
    data: { transition_id: transition_id },
  });
}

/************************** JIRA issue transitions end ************************/

/************************** JIRA create meta by issue type start ************************/

export function getCreateMetaOfIssueType(project_key, issue_type_id) {
  return axiosObj({
    method: 'GET',
    url: `/c/jira/v2/project/${project_key}/issue_type/${issue_type_id}/fields/`,
  });
}

/************************** JIRA create meta by issue type end ************************/

/************************** JIRA all issue list start ************************/
export function getJiraIssueList(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/jira/project/${id}/issue/`,
  });
}

export function getJiraIssueTypeList(project_key) {
  return axiosObj({
    method: 'GET',
    url: `/c/jira/v2/project/${project_key}/issue_type/`,
  });
}

// export function getJiraStatusList(id) {
//   return axiosObj({
//     method: 'GET',
//     url: `/c/jira/issues/statuses/${id}/`
//   })
// }
/************************** JIRA all issue list end **************************/

/************************** JIRA V2 create issue start **************************/
export function createJiraIssueV2(id, payload) {
  return axiosObj({
    method: 'POST',
    url: `/c/jira/v2/project/${id}/issue/`,
    data: payload,
  });
}
/************************** JIRA create issue list end ***********************/

/************************** JIRA create issue start **************************/
export function editJiraIssue(id, ticket, payload) {
  return axiosObj({
    method: 'PATCH',
    url: `/c/jira/project/${id}/issue/${ticket}/`,
    data: payload,
  });
}
/************************** JIRA create issue list end ***********************/

/************************ JIRA get specific issue start **********************/
export function getJiraIssueById(id, ticket) {
  return axiosObj({
    method: 'GET',
    url: `/c/jira/project/${id}/issue/${ticket}/`,
  });
}
/************************ JIRA get specific issue end ************************/

// /***************** JIRA get assignee for some project start ******************/
export function getAssignee(key, username = '') {
  // Key: Jira project key
  return axiosObj({
    method: 'GET',
    url: `/c/jira/available-assignee/?project_key=${key}&username=${username}`,
  });
}
// /***************** JIRA get assignee for some project end ********************/

/************************ JIRA Issue comments start **************************/
export const getAllJiraIssueComments = issueKey => {
  return axiosObj({
    method: 'GET',
    url: `/c/jira/issue/${issueKey}/comments/`,
  });
};

export const setJiraIssueComments = (issueKey, payload) => {
  return axiosObj({
    method: 'POST',
    url: `/c/jira/issue/${issueKey}/comments/`,
    params: {
      parameters: payload,
    },
  });
};
/*************************** JIRA Issue comments end *************************/

/***************** JIRA Board data ******************/
export function getJiraBoard() {
  return axiosObj({
    method: 'GET',
    url: '/c/jira/issue/',
  });
}
/***************** JIRA Board data ********************/

export const createJiraForResource = (payload, id) => {
  return axiosObj({
    url: `/c/jira/resource/${id}/attach_jira/`,
    method: 'POST',
    data: payload,
  });
};

export const deleteJiraSettings = () => {
  return axiosObj({
    method: 'DELETE',
    url: `/c/jira/auth/credentials/`,
  });
};

export const deleteJiraV2Auth = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/c/v2/jira/oauth-data/${id}/`,
  });
};

export const setMainWorkspaceJira = id => {
  return axiosObj({
    method: 'POST',
    url: `/c/v2/jira/oauth-data/${id}/set_main/`,
  });
};
