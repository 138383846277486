export const LOAD_PARTNER_PROFILE_REQUEST = 'PARTNER/LOAD_PARTNER_PROFILE_REQUEST';
export const LOAD_PARTNER_PROFILE_SUCCESS = 'PARTNER/LOAD_PARTNER_PROFILE_SUCCESS';
export const LOAD_PARTNER_PROFILE_ERROR = 'PARTNER/LOAD_PARTNER_PROFILE_ERROR';

export const LOAD_PARTNER_CLIENT_REQUEST = 'PARTNER/LOAD_PARTNER_CLIENT_REQUEST';
export const LOAD_PARTNER_CLIENT_SUCCESS = 'PARTNER/LOAD_PARTNER_CLIENT_SUCCESS';
export const LOAD_PARTNER_CLIENT_ERROR = 'PARTNER/LOAD_PARTNER_CLIENT_ERROR';

export const LOAD_PARTNER_DETAIL_REQUEST = 'PARTNER/LOAD_PARTNER_DETAIL_REQUEST';
export const LOAD_PARTNER_DETAIL_SUCCESS = 'PARTNER/LOAD_PARTNER_DETAIL_SUCCESS';
export const LOAD_PARTNER_DETAIL_ERROR = 'PARTNER/LOAD_PARTNER_DETAIL_ERROR';

export const LOAD_PARTNER_STATUS_REQUEST = 'PARTNER/LOAD_PARTNER_STATUS_REQUEST';
export const LOAD_PARTNER_STATUS_SUCCESS = 'PARTNER/LOAD_PARTNER_STATUS_SUCCESS';
export const LOAD_PARTNER_STATUS_ERROR = 'PARTNER/LOAD_PARTNER_STATUS_ERROR';

export const LOAD_PARTNER_INITIAL_REQUEST = 'PARTNER/LOAD_PARTNER_INITIAL_REQUEST';

export const LOAD_CONFIGCAT_REQUEST = 'PARTNER/LOAD_CONFIGCAT_REQUEST';
export const LOAD_CONFIGCAT_SUCCESS = 'PARTNER/LOAD_CONFIGCAT_SUCCESS';
export const LOAD_CONFIGCAT_ERROR = 'PARTNER/LOAD_CONFIGCAT_ERROR';
