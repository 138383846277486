import produce from 'immer';
import {
  LOAD_RULE_VIOLATION_DATA,
  LOAD_RULE_VIOLATION_DATA_ERROR,
  LOAD_RULE_VIOLATION_DATA_SUCCESS,
  LOAD_RULE_DETAIL_VIOLATION_DATA,
  LOAD_RULE_DETAIL_VIOLATION_DATA_SUCCESS,
  LOAD_RULE_DETAIL_VIOLATION_DATA_ERROR,
  LOAD_RULE_FILTER_DATA,
  LOAD_RULE_FILTER_DATA_SUCCESS,
  LOAD_RULE_FILTER_DATA_ERROR,
  LOAD_CM_RULE_VIOLATION_DATA_REQUEST,
  LOAD_CM_RULE_VIOLATION_DATA_SUCCESS,
  LOAD_CM_RULE_VIOLATION_DATA_ERROR,
  LOAD_CM_RULE_DETAIL_VIOLATION_DATA_REQUEST,
  LOAD_CM_RULE_DETAIL_VIOLATION_DATA_SUCCESS,
  LOAD_CM_RULE_DETAIL_VIOLATION_DATA_ERROR,
  LOAD_RULE_DETAIL_FILTER_DATA,
  LOAD_RULE_DETAIL_FILTER_DATA_SUCCESS,
  LOAD_RULE_DETAIL_FILTER_DATA_ERROR,
} from './constants';
import { pivotAggregation, extractViolationChanges, calcCloudPlatformData } from 'utils/helper';

export const initialState = {
  ruleViolations: [],
  loading: false,
  counts: {},
  countsCloud: {},
  resultData: {},
  ruleDetail: [],
  ruleDetailCounts: {},
  changeData: {},
  changeDataDetail: {},
  resources: [],
  filterStore: {},
  ruleTimeStamp: '',
  chartData: {},
  cmLoading: false,
  cmLoadingViolation: false,
  filterLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const ruleReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_RULE_VIOLATION_DATA:
        draft.ruleViolations = [];
        draft.changeData = {};
        draft.loading = true;
        draft.filterStore = {};
        draft.ruleTimeStamp = '';
        draft.chartData = {};
        break;
      case LOAD_RULE_VIOLATION_DATA_SUCCESS:
        {
          const aggs = action?.response?.data?.aggregations;
          if (aggs?.violations_counts_per_service_type_all_cloud_type) {
            const violations_counts_history_per_service_type = (
              aggs.violations_counts_per_service_type_all_cloud_type?.buckets || []
            ).flatMap(cloudBucket =>
              (cloudBucket.violations_counts_per_service_type?.buckets || []).map(v => ({
                ...v,
                cloudPlatform: cloudBucket.key,
              })),
            );
            draft.ruleViolations = violations_counts_history_per_service_type;

            const ec2_availability_zones = (
              aggs.violations_counts_per_service_type_all_cloud_type?.buckets || []
            ).flatMap(cloudBucket =>
              (cloudBucket.ec2_availability_zones?.results?.buckets || []).map(v => ({
                ...v,
                cloudPlatform: cloudBucket.key,
              })),
            );

            const ec2_types = (aggs.violations_counts_per_service_type_all_cloud_type?.buckets || []).flatMap(
              cloudBucket =>
                (cloudBucket.ec2_types?.buckets || []).map(v => ({
                  ...v,
                  cloudPlatform: cloudBucket.key,
                })),
            );

            draft.chartData = {
              ec2_availability_zones,
              ec2_types,
            };
          } else {
            draft.ruleViolations = aggs?.violations_counts_per_service_type?.buckets || [];
            draft.chartData = {
              ec2_availability_zones: aggs?.ec2_availability_zones?.results?.buckets || [],
              ec2_types: aggs?.ec2_types?.buckets || [],
            };
          }

          draft.filterStore = action?.response?.filters || {};
          draft.ruleTimeStamp = action?.response?.time || '';
          draft.loading = false;
        }
        break;
      case LOAD_RULE_VIOLATION_DATA_ERROR:
        draft.loading = false;
        break;
      case LOAD_RULE_FILTER_DATA:
        draft.filterLoading = true;
        draft.counts = {};
        draft.countsCloud = {};
        break;
      case LOAD_RULE_FILTER_DATA_SUCCESS:
        {
          draft.filterLoading = false;
          const aggs = action?.response?.counts?.aggregations;
          if (
            aggs?.violations_counts_history_per_service_type_cloud_type ||
            aggs?.violations_counts_per_vpc_cloud_type ||
            aggs?.violations_counts_per_region_cloud_type ||
            aggs?.violations_counts_per_project_cloud_type
          ) {
            const violations_counts_history_per_service_type = {
              buckets: pivotAggregation(
                aggs,
                'violations_counts_history_per_service_type_cloud_type',
                'violations_counts_history_per_type',
              ),
            };

            const violations_counts_per_region = {
              buckets: pivotAggregation(
                aggs,
                'violations_counts_per_region_cloud_type',
                'violations_counts_per_region',
              ),
            };

            const violations_counts_per_project = {
              buckets: pivotAggregation(
                aggs,
                'violations_counts_per_project_cloud_type',
                'violations_counts_per_project',
              ),
            };

            const violations_counts_per_vpc = {
              buckets: pivotAggregation(aggs, 'violations_counts_per_vpc_cloud_type', 'violations_counts_per_vpc'),
            };

            const countsCloud = {
              violations_counts_history_per_service_type,
              violations_counts_per_region,
              violations_counts_per_project,
              violations_counts_per_vpc,
            };

            // the all appear to return the same value
            countsCloud.cloud_data = calcCloudPlatformData(aggs, [
              'violations_counts_history_per_service_type_cloud_type',
              'violations_counts_per_project_cloud_type',
              'violations_counts_per_region_cloud_type',
              'violations_counts_per_vpc_cloud_type',
            ]);

            draft.countsCloud = countsCloud;
          } else {
            draft.counts = action?.response?.counts?.aggregations || {};
          }
        }
        break;
      case LOAD_RULE_FILTER_DATA_ERROR:
        draft.filterLoading = false;
        break;
      case LOAD_CM_RULE_VIOLATION_DATA_REQUEST:
        draft.cmLoadingViolation = true;
        draft.changeData = {};
        break;
      case LOAD_CM_RULE_VIOLATION_DATA_SUCCESS:
        {
          const newAgg = action?.response?.data?.violations_changes_cloud_type;
          const violations_changes_results = extractViolationChanges(newAgg);
          draft.cmLoadingViolation = false;
          if (newAgg?.buckets) {
            draft.changeData = violations_changes_results;
          } else {
            draft.changeData = action?.response?.data?.violations_changes?.violations_changes_results || {};
          }
        }
        break;
      case LOAD_CM_RULE_VIOLATION_DATA_ERROR:
        draft.cmLoadingViolation = false;
        break;
      case LOAD_RULE_DETAIL_VIOLATION_DATA:
        draft.ruleDetail = [];
        draft.changeDataDetail = {};
        draft.loading = true;
        break;
      case LOAD_RULE_DETAIL_VIOLATION_DATA_SUCCESS:
        draft.ruleDetail =
          action?.response?.data?.aggregations?.violations?.violations_details?.violation_type?.buckets || [];
        draft.resources = action?.response?.data?.aggregations?.resources?.daily?.daily_data?.buckets || [];
        draft.loading = false;
        break;
      case LOAD_RULE_DETAIL_VIOLATION_DATA_ERROR:
        draft.loading = false;
        break;
      case LOAD_RULE_DETAIL_FILTER_DATA:
        draft.filterLoading = true;
        break;
      case LOAD_RULE_DETAIL_FILTER_DATA_SUCCESS:
        draft.ruleDetailCounts = action?.response?.counts || {};
        draft.filterLoading = false;
        break;
      case LOAD_RULE_DETAIL_FILTER_DATA_ERROR:
        draft.filterLoading = false;
        break;
      case LOAD_CM_RULE_DETAIL_VIOLATION_DATA_REQUEST:
        draft.cmLoading = true;
        break;
      case LOAD_CM_RULE_DETAIL_VIOLATION_DATA_SUCCESS:
        draft.changeDataDetail =
          action?.response?.data?.aggregations?.violations_changes?.violations_changes_results || {};
        draft.cmLoading = false;
        break;
      case LOAD_CM_RULE_DETAIL_VIOLATION_DATA_ERROR:
        draft.cmLoading = false;
        break;
    }
  });

export default ruleReducer;
