import axiosObj from './config';
import isEmpty from 'lodash/isEmpty';

export const getData = payload => {
  const { workload = '*', filters = {} } = payload?.params || {};
  const aggregations =
    payload?.configCatAzure && !payload?.hideAzure
      ? 'violations_counts_per_service_type_all_cloud_type'
      : 'violations_counts_per_service_type_all';

  const parameters = {
    q: workload,
    filters: filters,
    aggregations,
  };

  if (payload?.time) parameters.time = payload.time;
  if (payload?.configCatAzure && !payload?.hideAzure) parameters.cloud_type = 'all';

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters,
    },
  });
};

export const getCMData = payload => {
  const { workload, filters, type } = payload.params;
  const url = type === 'cached' ? '/c/ac/cached' : '/c/ac/query';
  const aggregations =
    payload?.configCatAzure && !payload?.hideAzure ? 'violations_changes_cloud_type' : 'violations_changes';

  const parameters = {
    q: workload ? workload : '*',
    filters: filters,
    aggregations,
  };

  if (payload?.configCatAzure && !payload?.hideAzure) parameters.cloud_type = 'all';

  return axiosObj({
    method: 'GET',
    url,
    params: {
      parameters,
    },
  });
};

export const getRulesData = payload => {
  const { workload = '*', filters = {}, range = '', customParams = {} } = payload;
  let parameters = {
    q: workload,
    filters: filters,
    cost_forecast: true,
    aggregations: 'violations_all:arp_daily',
  };
  if (!isEmpty(customParams)) parameters = { ...parameters, ...customParams };
  if (range) parameters.range = range;
  if (payload?.configCatAzure) parameters.cloud_type = 'all';
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters,
    },
  });
};

export const getCMRulesData = payload => {
  const { workload = '*', filters = {}, range, time, customParams = {}, configCatAzure = false } = payload;

  const parameters = {
    q: workload,
    filters: filters,
    aggregations: 'violations_changes',
    ...customParams,
  };
  if (range) parameters.range = range;
  if (time) parameters.time = time;
  if (configCatAzure) {
    parameters.aggregations = 'violations_changes_cloud_type';
    parameters.cloud_type = 'all';
  }

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters,
    },
  });
};

export const getCommonData = payload => {
  const { filters = {}, aggregations, workload = '' } = payload;
  const parameters = {
    q: workload || '*',
    filters: filters,
    aggregations: aggregations,
    cloud_type: 'all',
  };
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters,
    },
  });
};

export const getHistoryRulesData = payload => {
  const { workload = '*', filters = {} } = payload || {};
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters: {
        q: workload,
        filters: filters,
        aggregations: 'violations_counts_history_per_service_type',
        cloud_type: 'all',
      },
    },
  });
};

export const getFilterData = payload => {
  const { workload = '', filters = {} } = payload.params;
  const agg =
    payload.configCatAzure && !payload?.hideAzure
      ? [
          'violations_counts_history_per_service_type_cloud_type',
          'violations_counts_per_project_cloud_type',
          'violations_counts_per_region_cloud_type',
          'violations_counts_per_vpc_cloud_type',
        ]
      : [
          'violations_counts_history_per_service_type',
          'violations_counts_per_project',
          'violations_counts_per_region',
          'violations_counts_per_vpc',
        ];
  const parameters = {
    q: workload,
    service: 'all_services',
    scope: 'client',
    filters: filters,
    counters: true,
    aggregations: agg.join(':'),
  };
  if (payload.time) parameters.time = payload.time;
  if (payload.range) parameters.range = payload.range;
  if (payload?.configCatAzure && !payload?.hideAzure) parameters.cloud_type = 'all';
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters: { ...parameters },
    },
  });
};

export const overwriteRule = (payload, partner_scope_id) => {
  let url = '/c/ac/overwrite/';
  if (partner_scope_id) url = url + `?partner_scope_id=${partner_scope_id}`;
  return axiosObj({
    method: 'POST',
    url: url,
    data: payload,
  });
};

export const getOverwriteRule = (payload, partner_scope_id) => {
  let url = '/c/ac/overridden-violations-info/';
  if (partner_scope_id) url = url + `?partner_scope_id=${partner_scope_id}`;
  return axiosObj({
    method: 'POST',
    url: url,
    data: payload,
  });
};

export const getEIPCostData = payload => {
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters: {
        q: '*',
        service: 'all_services',
        show_active: null,
        scope: 'client',
        filters: {},
        counters: true,
        aggregations: 'unused_eip_cost',
        cloud_type: 'all',
      },
    },
  });
};

export const getAllRulesData = (payload, isPartner) => {
  const { workload = '*', filters = {} } = payload?.params || {};
  const parameters = {
    q: workload,
    filters: filters,
    aggregations: 'violations_counts_per_service_type_all;violations_counts_history_per_service_type',
    scope: isPartner ? 'partner' : 'client',
    cloud_type: 'all',
  };
  if (payload?.time) parameters.time = payload.time;
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters: { ...parameters },
    },
  });
};
