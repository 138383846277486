function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export default function request(url, options) {
  return fetch(url, {
    headers: new Headers({
      Authorization: 'Token 589d7ab9d3e4988e8855bb28e3b12ad4186125ee',
    }),
    ...options,
  })
    .then(checkStatus)
    .then(parseJSON);
}

export function logMyErrors(customMessage, error) {
  //eslint-disable-next-line
  console.log(customMessage, error);
}
