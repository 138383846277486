import React from 'react';
import some from 'lodash/some';
import groupBy from 'lodash/groupBy';
import forIn from 'lodash/forIn';
import orderBy from 'lodash/orderBy';
import ReactPaginate from 'react-paginate';
import { numberWithCommasAndDecimal } from 'utils/helper';
import { currencyFormat } from 'utils/number';
import { NpMultipleCubes } from 'assets/images/figma-imgs';
import { TagIcon } from '../../Search/icons';
import moment from 'moment';

export const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const getOpportunityPosition = {
  Rightsizing: 0,
  Storage: 1,
  Scheduler: 2,
  'EKS Clusters': 3,
  'Auto Scaling Groups': 4,
  'Risk-Free Commitment': 5,
  Graviton: 6,
};

export const getDiff = endDate => {
  const sDate = new Date();
  const eDate = endDate ? new Date(moment(endDate).format('YYYY/MM/DD')) : new Date();
  const diffTime = Math.abs(eDate - sDate);
  let diff = !endDate ? 0 : Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (sDate.toDateString() !== eDate.toDateString()) diff++;
  return diff;
};

export const getSummaryTotal = (totals, diffMultiply = 1) => {
  return {
    daily_savings: totals?.daily_savings * diffMultiply || 0,
    daily_fee_cost: totals?.daily_fee_cost * diffMultiply || 0,
    daily_net_savings: totals?.daily_net_savings * diffMultiply || 0,
    annualized_net_savings: totals?.annualized_net_savings || 0,
    total_lost_opportunity: totals?.total_lost_opportunity || 0,
    daily_current_cost: totals?.daily_current_cost * diffMultiply || 0,
  };
};

export const getTotals = data => {
  if (!data?.length) {
    return {
      daily_savings: 0,
      daily_fee_cost: 0,
      daily_net_savings: 0,
      annualized_net_savings: 0,
      total_lost_opportunity: 0,
      daily_current_cost: 0,
    };
  } else {
    return {
      daily_savings: data.reduce((prev, next) => prev + +next.daily_savings, 0),
      daily_fee_cost: data.reduce((prev, next) => prev + +next.daily_fee_cost, 0),
      daily_net_savings: data.reduce((prev, next) => prev + +next.daily_net_savings, 0),
      annualized_net_savings: data.reduce((prev, next) => prev + +next.annualized_net_savings, 0),
      total_lost_opportunity: data.reduce((prev, next) => prev + +next.total_lost_opportunity, 0),
      daily_current_cost: data.reduce((prev, next) => prev + +next?.daily_current_cost, 0) || 0,
    };
  }
};

export const getDisplayRiskFreeData = (riskFreeCommitmentData, accounts, projects, opportunities) => {
  return {
    riskFreeCommitment: getRiskFreeData(riskFreeCommitmentData, accounts, projects, opportunities),
  };
};

export const getDisplayData = (
  riskFreeCommitmentData,
  gravitonData,
  schedulerData,
  rdsSummary,
  rightsizingSummary,
  clusterSummary,
  asgSummary,
  ebsSummary,
  accounts,
  projects,
  opportunities,
  diff,
  integrated,
) => {
  return {
    riskFreeCommitment: getRiskFreeData(riskFreeCommitmentData, accounts, projects, opportunities),
    graviton: getGravitonData(gravitonData, accounts, opportunities),
    scheduler: getSchedulerData(
      {
        [schedulerData.service]: schedulerData,
        [rdsSummary.service]: rdsSummary,
      },
      accounts,
      opportunities,
      diff,
    ),
    clusterData: getClusterData(clusterSummary, accounts, opportunities),
    rightsizing: getRightsizingData(rightsizingSummary, accounts, opportunities, diff, integrated),
    asgData: getAsgData(asgSummary, accounts, opportunities),
    ebsData: getEBSData(ebsSummary, accounts, opportunities),
  };
};

export const getRiskFreeData = (data, accounts, projects, opportunities) => {
  if (!data?.length || (opportunities.length && !opportunities.includes('Risk-Free-Commitment'))) return [];
  let data_clone = [...data];
  if (accounts?.length) {
    data_clone = data_clone.filter(item => accounts.includes(+item.project_id));
  }
  if (!data_clone?.length) {
    return [];
  }
  const isPayer = some(data_clone, item => item.recommendation_parameters.AccountScope === 'Payer');
  if (projects?.length > 1) {
    if (accounts?.length > 1) {
      data_clone = data_clone.filter(item => item.recommendation_parameters.AccountScope === 'Linked');
    } else if (!accounts?.length) {
      if (isPayer) {
        data_clone = data_clone.filter(item => item.recommendation_parameters.AccountScope === 'Payer');
      } else {
        data_clone = data_clone.filter(item => item.recommendation_parameters.AccountScope === 'Linked');
      }
    }
    const projectIds = projects.flatMap(arr => arr.id);
    data_clone = data_clone.filter(item => projectIds.includes(item.project_id));
  }
  // setSelectedProjects(uniq(data.map(({ project_id }) => project_id)));
  const oppoData = groupBy(
    data_clone.map(savings => ({
      ...savings,
      ...(savings?.cost || {}),
      ...(savings?.sharesave || {}),
      ...(savings?.instance_details || {}),
      ...(savings?.recommendation_details || {}),
      ...(savings?.recommendation_parameters || {}),
      number_of_instances_to_purchase: +savings?.number_of_instances_to_purchase || 0,
      normalized_units_to_purchase:
        savings?.normalized_units_to_purchase === 'NA' ? 0 : +savings?.normalized_units_to_purchase || 0,
      Family:
        savings?.service === 'ec2'
          ? savings?.instance_details?.SizeFlexEligible
            ? savings?.instance_details?.Family
            : savings?.instance_details?.InstanceType
          : savings?.instance_details?.Family || '',
    })),
    'opportunity_name',
  );

  const list = [];
  forIn(oppoData, (value, key) => {
    const data = groupBy(value, 'service');
    const data_list = [];
    forIn(data, (value_data, value_key) => {
      data_list.push({
        service: value_key,
        row_id: `Risk-free-commitment-${value_key}`,
        data: value_data,
        row_totals: getTotals(value_data),
      });
    });
    list.push({
      opportunity_name: key,
      value: data_list,
      id: 'Risk-free-commitment',
      totals: getTotals(value),
    });
  });

  return list;
};

export const getData = (schedulerData, accounts, opportunities, diff) => {
  if (!schedulerData?.total_rows || (opportunities.length && !opportunities.includes('scheduler'))) return [];

  const data_list = (schedulerData?.services || []).map(item => {
    const {
      total_daily_savings = 0,
      sharesave_fee_per_day = 0,
      net_savings_per_day = 0,
      total_daily_cost = 0,
      total_rows = 0,
    } = schedulerData?.response?.[item]?.[0] || {};
    return {
      service: item,
      row_id: item,
      row_totals: {
        total_rows: total_rows || 0,
        daily_savings: total_daily_savings || 0,
        daily_fee_cost: sharesave_fee_per_day || 0,
        daily_net_savings: net_savings_per_day || 0,
        annualized_net_savings: net_savings_per_day * 365 || 0,
        total_lost_opportunity: 0,
        daily_current_cost: total_daily_cost || 0,
      },
    };
  });

  return [
    {
      opportunity_name: 'nSwitch Scheduler',
      value: data_list,
      id: 'ec2',
      totals: {
        daily_savings: schedulerData?.total_daily_savings || 0,
        daily_fee_cost: schedulerData?.daily_fee_cost || 0,
        daily_net_savings: schedulerData?.daily_net_savings || 0,
        annualized_net_savings: schedulerData?.daily_net_savings * 365 || 0,
        total_lost_opportunity: schedulerData?.daily_net_savings * 60 || 0,
        daily_current_cost: schedulerData?.total_daily_cost || 0,
      },
    },
  ];
};

export const getSchedulerData = (schedulerData, accounts, opportunities, diff) => {
  if (
    (opportunities.length && !opportunities.includes('scheduler')) ||
    Object.values(schedulerData).every(item => !item.total_rows || item.total_rows === 0)
  )
    return [];
  const data_list = [];
  forIn(schedulerData || [], (data, key) => {
    const {
      total_daily_savings = 0,
      sharesave_fee_per_day = 0,
      net_savings_per_day = 0,
      total_daily_cost = 0,
      total_rows = 0,
    } = data?.response?.[key]?.[0] || {};
    data_list.push({
      service: key,
      row_id: key,
      row_totals: {
        total_rows: total_rows || 0,
        daily_savings: total_daily_savings || 0,
        daily_fee_cost: sharesave_fee_per_day || 0,
        daily_net_savings: net_savings_per_day || 0,
        annualized_net_savings: net_savings_per_day * 365 || 0,
        total_lost_opportunity: 0,
        daily_current_cost: total_daily_cost || 0,
      },
    });
  });

  let totals = {
    daily_savings: 0,
    daily_fee_cost: 0,
    daily_net_savings: 0,
    annualized_net_savings: 0,
    total_lost_opportunity: 0,
    daily_current_cost: 0,
  };

  data_list.forEach(item => {
    totals = {
      daily_savings: totals.daily_savings + schedulerData[item.service]?.total_daily_savings || 0,
      daily_fee_cost: totals.daily_fee_cost + schedulerData[item.service]?.daily_fee_cost || 0,
      daily_net_savings: totals.daily_net_savings + schedulerData[item.service]?.daily_net_savings || 0,
      annualized_net_savings: totals.annualized_net_savings + schedulerData[item.service]?.daily_net_savings * 365 || 0,
      total_lost_opportunity: totals.total_lost_opportunity + schedulerData[item.service]?.daily_net_savings * 60 || 0,
      daily_current_cost: totals.daily_current_cost + schedulerData[item.service]?.total_daily_cost || 0,
    };
  });

  return [
    {
      opportunity_name: 'Scheduler',
      value: data_list,
      id: 'scheduler',
      totals: totals,
    },
  ];
};

export const getOneTimeASData = (oneTimeData, accounts, opportunities, diff) => {
  if (!oneTimeData?.total_rows || (opportunities.length && !opportunities.includes('asg-one-time'))) {
    return [];
  }
  let data_clone = [...oneTimeData?.data];
  if (accounts?.length) {
    data_clone = data_clone.filter(item => accounts.includes(+item.project_id));
  }
  if (!data_clone?.length) {
    return [];
  }
  data_clone = data_clone.map(item => {
    return {
      ...item,
      opportunity_name: 'Auto Scaling Groups - One Time',
      daily_savings: item?.one_time_savings_per_given_period || 0,
      daily_fee_cost: item?.one_time_sharesave_fee_per_given_period || 0,
      daily_net_savings: item?.one_time_net_savings_per_given_period || 0,
      annualized_net_savings: item?.one_time_net_savings_per_day_average * 365 || 0,
      total_lost_opportunity: 0,
      daily_current_cost: item?.daily_current_cost * diff || 0,
    };
  });

  const data_list = [
    {
      service: 'one-time',
      row_id: 'auto-scaling-groups-one-time',
      data: data_clone,
      row_totals: {
        daily_savings: oneTimeData?.total_daily_savings || 0,
        daily_fee_cost:
          oneTimeData?.sharesave_fee_per_day - oneTimeData?.diff_sharesave_fee_per_given_period / diff || 0,
        daily_net_savings:
          oneTimeData?.net_savings_per_day - oneTimeData?.diff_net_savings_per_given_period / diff || 0,
        annualized_net_savings:
          (oneTimeData?.net_savings_per_day - oneTimeData?.diff_net_savings_per_given_period / diff) * 365 || 0,
        total_lost_opportunity:
          (oneTimeData?.net_savings_per_day - oneTimeData?.diff_net_savings_per_given_period / diff) * 60 || 0,
        daily_current_cost: oneTimeData?.total_daily_cost || 0,
      },
    },
  ];

  return [
    {
      opportunity_name: 'Auto Scaling Groups - One Time',
      value: data_list,
      id: 'auto-scaling-groups-one-time',
      totals: {
        daily_savings: data_list?.[0]?.row_totals.daily_savings || 0,
        daily_fee_cost: data_list?.[0]?.row_totals.daily_fee_cost || 0,
        daily_net_savings: data_list?.[0]?.row_totals.daily_net_savings || 0,
        annualized_net_savings: data_list?.[0]?.row_totals.annualized_net_savings || 0,
        total_lost_opportunity: data_list?.[0]?.row_totals?.total_lost_opportunity || 0,
        daily_current_cost: data_list?.[0]?.row_totals?.daily_current_cost || 0,
      },
    },
  ];
};

export const getDynamicASData = (dynamicData, accounts, opportunities, diff) => {
  if (!dynamicData?.total_rows || (opportunities.length && !opportunities.includes('asg-dynamic'))) {
    return [];
  }
  let data_clone = [...dynamicData?.data];
  if (accounts?.length) {
    data_clone = data_clone.filter(item => accounts.includes(+item.project_id));
  }
  if (!data_clone?.length) {
    return [];
  }
  data_clone = data_clone.map(item => {
    return {
      ...item,
      opportunity_name: 'Auto Scaling Groups - Dynamic',
      daily_savings: item?.savings_per_given_period || 0,
      daily_fee_cost: item?.sharesave_fee_per_given_period || 0,
      daily_net_savings: item?.net_savings_per_given_period || 0,
      annualized_net_savings: item?.net_savings_per_day_average * 365 || 0,
      total_lost_opportunity: 0,
      daily_current_cost: item?.daily_current_cost * diff || 0,
    };
  });

  const data_list = [
    {
      service: 'dynamic',
      row_id: 'auto-scaling-groups-dynamic',
      data: data_clone,
      row_totals: {
        daily_savings: dynamicData?.total_daily_savings || 0,
        daily_fee_cost: dynamicData?.daily_fee_cost || 0,
        daily_net_savings: dynamicData?.daily_net_savings || 0,
        annualized_net_savings: dynamicData?.daily_net_savings * 365 || 0,
        total_lost_opportunity: dynamicData?.daily_net_savings * 60 || 0,
        daily_current_cost: dynamicData?.total_daily_cost || 0,
      },
    },
  ];

  return [
    {
      opportunity_name: 'Auto Scaling Groups - Dynamic',
      value: data_list,
      id: 'auto-scaling-groups-dynamic',
      totals: {
        daily_savings: data_list?.[0]?.row_totals.daily_savings || 0,
        daily_fee_cost: data_list?.[0]?.row_totals.daily_fee_cost || 0,
        daily_net_savings: data_list?.[0]?.row_totals.daily_net_savings || 0,
        annualized_net_savings: data_list?.[0]?.row_totals.annualized_net_savings || 0,
        total_lost_opportunity: data_list?.[0]?.row_totals?.total_lost_opportunity || 0,
        daily_current_cost: data_list?.[0]?.row_totals?.daily_current_cost || 0,
      },
    },
  ];
};

export const getRDSData = (rdsData, accounts, opportunities) => {
  if (!rdsData?.total_rows || (opportunities.length && !opportunities.includes('scheduler'))) return [];

  const data_list = (rdsData?.services || []).map(item => {
    const {
      total_daily_savings = 0,
      sharesave_fee_per_day = 0,
      net_savings_per_day = 0,
      total_daily_cost = 0,
      total_rows = 0,
    } = rdsData?.response?.[item]?.[0] || {};
    return {
      service: item,
      row_id: item,
      row_totals: {
        total_rows: total_rows || 0,
        daily_savings: total_daily_savings || 0,
        daily_fee_cost: sharesave_fee_per_day || 0,
        daily_net_savings: net_savings_per_day || 0,
        annualized_net_savings: net_savings_per_day * 365 || 0,
        total_lost_opportunity: 0,
        daily_current_cost: total_daily_cost || 0,
      },
    };
  });

  return [
    {
      opportunity_name: 'nSwitch Scheduler',
      value: data_list,
      id: 'rds',
      totals: {
        daily_savings: rdsData?.total_daily_savings || 0,
        daily_fee_cost: rdsData?.daily_fee_cost || 0,
        daily_net_savings: rdsData?.daily_net_savings || 0,
        annualized_net_savings: rdsData?.daily_net_savings * 365 || 0,
        total_lost_opportunity: rdsData?.daily_net_savings * 60 || 0,
        daily_current_cost: rdsData?.total_daily_cost || 0,
      },
    },
  ];
};

export const getRightsizingData = (rightsizingData, accounts, opportunities, diff, integrated) => {
  if (opportunities.length && !opportunities.includes('rightsizing')) {
    return [];
  }
  const data_list = (rightsizingData?.services || []).map(item => {
    const result = rightsizingData?.response?.[item]?.[0];
    const { sum_cost = 0, sum_savings = 0, total_monthly_savings = 0, total_monthly_cost = 0 } = result || {};
    return {
      service: item,
      row_id: item,
      row_totals: {
        total_rows: 0,
        daily_savings: integrated ? total_monthly_savings / 30 : sum_savings / 30,
        daily_fee_cost: 0,
        daily_net_savings: integrated ? total_monthly_savings / 30 : sum_savings / 30,
        annualized_net_savings: integrated ? (total_monthly_savings / 30) * 365 : (sum_savings / 30) * 365,
        total_lost_opportunity: 0,
        daily_current_cost: integrated ? total_monthly_cost / 30 : sum_cost / 30,
      },
    };
  });
  return [
    {
      opportunity_name: 'Rightsizing',
      value: data_list,
      id: 'rightsizing',
      totals: {
        daily_savings: rightsizingData.total_daily_savings / 30 || 0,
        daily_fee_cost: 0,
        daily_net_savings: rightsizingData.total_daily_savings / 30 || 0,
        annualized_net_savings: (rightsizingData.total_daily_savings / 30) * 365 || 0,
        total_lost_opportunity: (rightsizingData.total_daily_savings / 30) * 60 || 0,
        daily_current_cost: rightsizingData?.total_daily_cost / 30 || 0,
      },
    },
  ];
};

export const getClusterData = (clusterData, accounts, opportunities) => {
  if (!clusterData?.total_rows || (opportunities.length && !opportunities.includes('EKS'))) {
    return [];
  }

  const data_list = (clusterData?.services || []).map(item => {
    const {
      total_daily_savings = 0,
      sharesave_fee_per_day = 0,
      net_savings_per_day = 0,
      total_daily_cost = 0,
      total_rows = 0,
    } = clusterData?.response?.[item]?.[0] || {};
    return {
      service: item,
      row_id: item,
      row_totals: {
        total_rows: total_rows || 0,
        daily_savings: total_daily_savings || 0,
        daily_fee_cost: sharesave_fee_per_day || 0,
        daily_net_savings: net_savings_per_day || 0,
        annualized_net_savings: net_savings_per_day * 365 || 0,
        total_lost_opportunity: 0,
        daily_current_cost: total_daily_cost || 0,
      },
    };
  });

  return [
    {
      opportunity_name: 'EKS Clusters',
      value: data_list,
      id: 'eksClusters',
      totals: {
        daily_savings: clusterData?.total_daily_savings || 0,
        daily_fee_cost: clusterData?.daily_fee_cost || 0,
        daily_net_savings: clusterData?.daily_net_savings || 0,
        annualized_net_savings: clusterData?.daily_net_savings * 365 || 0,
        total_lost_opportunity: clusterData?.daily_net_savings * 60 || 0,
        daily_current_cost: clusterData?.total_daily_cost || 0,
      },
    },
  ];
};

export const getAsgData = (asgData, accounts, opportunities) => {
  if (!asgData?.total_rows || (opportunities.length && !opportunities.includes('ASG'))) {
    return [];
  }

  const data_list = (asgData?.services || []).map(item => {
    const {
      total_daily_savings = 0,
      sharesave_fee_per_day = 0,
      net_savings_per_day = 0,
      total_daily_cost = 0,
      total_rows = 0,
    } = asgData?.response?.[item]?.[0] || {};
    return {
      service: item,
      row_id: item,
      row_totals: {
        total_rows: total_rows || 0,
        daily_savings: total_daily_savings || 0,
        daily_fee_cost: sharesave_fee_per_day || 0,
        daily_net_savings: net_savings_per_day || 0,
        annualized_net_savings: net_savings_per_day * 365 || 0,
        total_lost_opportunity: 0,
        daily_current_cost: total_daily_cost || 0,
      },
    };
  });

  return [
    {
      opportunity_name: 'Auto Scaling Groups',
      value: data_list,
      id: 'asg',
      totals: {
        daily_savings: asgData?.total_daily_savings || 0,
        daily_fee_cost: asgData?.daily_fee_cost || 0,
        daily_net_savings: asgData?.daily_net_savings || 0,
        annualized_net_savings: asgData?.daily_net_savings * 365 || 0,
        total_lost_opportunity: asgData?.daily_net_savings * 60 || 0,
        daily_current_cost: asgData?.total_daily_cost || 0,
      },
    },
  ];
};

export const getEBSData = (ebsData, accounts, opportunities) => {
  if (!ebsData?.total_rows || (opportunities.length && !opportunities.includes('EBS'))) return [];

  const data_list = (ebsData?.services || []).map(item => {
    const {
      total_daily_savings = 0,
      sharesave_fee_per_day = 0,
      net_savings_per_day = 0,
      total_daily_cost = 0,
      total_rows = 0,
    } = ebsData?.response?.[item]?.[0] || {};
    return {
      service: item,
      row_id: item,
      row_totals: {
        total_rows: total_rows || 0,
        daily_savings: total_daily_savings || 0,
        daily_fee_cost: sharesave_fee_per_day || 0,
        daily_net_savings: net_savings_per_day || 0,
        annualized_net_savings: net_savings_per_day * 365 || 0,
        total_lost_opportunity: 0,
        daily_current_cost: total_daily_cost || 0,
      },
    };
  });

  return [
    {
      opportunity_name: 'Storage',
      value: data_list,
      id: 'ebsVolume',
      totals: {
        daily_savings: ebsData?.total_daily_savings || 0,
        daily_fee_cost: ebsData?.daily_fee_cost || 0,
        daily_net_savings: ebsData?.daily_net_savings || 0,
        annualized_net_savings: ebsData?.daily_net_savings * 365 || 0,
        total_lost_opportunity: ebsData?.daily_net_savings * 60 || 0,
        daily_current_cost: ebsData?.total_daily_cost || 0,
      },
    },
  ];
};

export const getGravitonData = (data, accounts, opportunities) => {
  if (!data?.length || (opportunities.length && !opportunities.includes('Graviton'))) return [];
  let data_clone = [...data];
  if (accounts?.length) {
    data_clone = data_clone.filter(item => accounts.includes(+item.project_id));
  }
  if (!data_clone?.length) {
    return [];
  }
  data_clone = data_clone.map(item => ({
    ...item,
    opportunity_name: 'Graviton',
    project_id: +item.billing_project_id,
    daily_savings: item?.normalized_daily_savings_cost || 0,
    daily_fee_cost: item?.sharesave_fee_daily || 0,
    daily_net_savings: item?.est_daily_net_savings || 0,
    annualized_net_savings: item?.annuallized_net_savings || 0,
    total_lost_opportunity: item?.est_lost_opportunity || 0,
    daily_current_cost: item?.normalized_daily_unblended_cost || 0,
  }));
  const data_list = [
    {
      service: 'ec2',
      row_id: 'Graviton-ec2',
      data: data_clone,
      row_totals: getTotals(data_clone),
    },
  ];
  return [
    {
      opportunity_name: 'Graviton',
      value: data_list,
      id: 'Graviton',
      totals: getTotals(data_clone),
    },
  ];
};

const sortDays = (a, b) => {
  a = DAYS.indexOf(a);
  b = DAYS.indexOf(b);
  return a < b ? -1 : 1;
};

export const getWeekDays = (days = '') => {
  const totalDays = days.split('|');
  return totalDays.length === 7
    ? 'Every day'
    : totalDays
        .sort(sortDays)
        .map(day => day.slice(0, 3))
        .join(', ');
};

export const getSliceData = (data, service, activeTab, currentPage) => {
  const gravitonInfo = orderBy(data || [], [detail => detail?.[activeTab.key] || 0], 'desc');
  const total = data?.length || [];
  const totalPage = Math.ceil(total / 10);
  const indexOfLastData = (currentPage?.[service] || 1) * 10;
  const indexOfFirstData = indexOfLastData - 10;
  return {
    data: gravitonInfo.slice(indexOfFirstData, indexOfLastData),
    totalPage,
  };
};

export const getPaginationComponent = (totalPage, service, currentPage, setCurrentPage) => {
  return (
    <tr className="np-sharesave-pagination">
      <td colSpan={9}>
        {totalPage !== 1 && (
          <div className="np-paginationT bottom">
            <div className="pages">
              Page {currentPage?.[service] || 1} of {totalPage}
            </div>
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              breakLinkClassName={'asd'}
              pageCount={totalPage}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              forcePage={(currentPage?.[service] || 1) - 1}
              onPageChange={data => {
                setCurrentPage({
                  ...currentPage,
                  [service]: data.selected + 1,
                });
              }}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

const getValues = (value, key) => {
  switch (key) {
    case 'RecommendedNormalizedUnitsToPurchase':
    case 'MinimumNumberOfInstancesUsedPerHour':
    case 'MaximumNumberOfInstancesUsedPerHour':
    case 'AverageNumberOfInstancesUsedPerHour':
      return numberWithCommasAndDecimal(value);
    case 'AverageUtilization':
    case 'daily_net_savings_percentage':
      return numberWithCommasAndDecimal(value) + '%';
    case 'EstimatedMonthlyOnDemandCost':
      return currencyFormat(value);
    case 'max_size':
    case 'min_size':
    case 'desired_capacity':
      // case 'one_time_config_value':
      return `${value} Instance${value || 0 > 1 ? 's' : ''}`;
    case 'tags': {
      const list = [];
      value.forEach((item, i) => {
        list.push(
          <div key={`${key}-${i}`} className="data tags">
            <ul>
              <li>
                <div className="np-dropdownClick">
                  <button className="np-tag grey np-dropdownLink">
                    <i className="icon-tag">
                      <TagIcon />
                    </i>
                    key: {item.Key || item.key}
                    <br />
                    value: {item.Value || item.value}
                  </button>
                </div>
              </li>
            </ul>
          </div>,
        );
      });
      return list;
    }
    case 'created_time':
      return moment(value).format('DD, MMM YYYY');
    case 'instances':
      return (value || []).join(', ');
    case 'encrypted_ebs':
      return value ? 'Yes' : 'No';
    case 'hibernation_options':
      return value ? 'Enabled' : 'Disabled';
    default:
      return value;
  }
};

export const getElems = (title, fields, record) => {
  const list = [];
  forIn(fields, (value, key) => {
    ((key === 'tags' && record[key].length) || key !== 'tags') &&
      list.push(
        <tr className="searchResDetailRow" key={key}>
          <td>
            <div className="data">
              <b>{value}</b>
            </div>
          </td>

          <td>
            <div className="data">{getValues(record[key] || '', key)}</div>
          </td>
        </tr>,
      );
  });
  return (
    <div className="np-row">
      <div className="npxs-12">
        <div className="np-table2">
          <div className="title">
            <div className="np-titleIcon blue wh-15 risk-free-recomm">
              <NpMultipleCubes color="white" />
            </div>{' '}
            {title}
          </div>

          <div className="npTable autoscaling-metadata">
            <table>
              <tbody>{list}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
