import React from 'react';
import { UsageReportLoader } from 'components/ContentLoader/MonthlyUsageLoaders';
import { CustomTable } from 'components/CustomTable';
import LogImg from 'assets/images/news-feed-icons/log.svg';
import NoLogImg from 'assets/images/news-feed-icons/no-log.svg';

const COL_RULES = [
  {
    key: 'from_port',
    name: 'Port (from)',
  },
  {
    key: 'to_port',
    name: 'Port (to)',
  },
  {
    key: 'ip_protocol',
    name: 'Ip protocol',
  },
  {
    key: 'source',
    name: 'Source',
  },
];

export const getRuleTypeRows = ({ data }) => {
  if (!data) return [];
  if (!data.length) return [];

  return data.map(record => {
    const rowRec = { ...record };
    const { grants } = record;

    rowRec.source = (grants || []).map((item, index) => (
      <div className="source-item" key={index}>
        <span>{item.value}</span>
      </div>
    ));

    return rowRec;
  });
};

export const RulesTable = ({ loading, data, ruleType }) => {
  if (!ruleType) return null;
  return (
    <CustomTable
      defaultRow={20}
      contentLoading={loading}
      contentLoader={UsageReportLoader(2)}
      wrapper_class="rulesTable"
      main={{
        customTitle: (
          <div className="title colored-title tableTitle">
            <div className="np-titleIcon colored">
              <img src={LogImg} alt="event-log-img" />
            </div>
            <b>{`${ruleType} Rules`}</b>
          </div>
        ),
      }}
      columnField={COL_RULES}
      tableData={getRuleTypeRows({ data: data?._source?.rules || [] })}
      noDataChild={
        <div className="np-noData">
          <img src={NoLogImg} alt="No Data" />
          <p>No rules to show.</p>
        </div>
      }
    />
  );
};
