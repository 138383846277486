import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { usePrevious } from 'utils/hooks';
import { useIntercom } from 'react-use-intercom';

const ScrollToTop = ({ location }) => {
  const { update } = useIntercom();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (!prevLocation) return;
    if (location.pathname !== prevLocation.pathname) {
      window.scrollTo(0, 0);
      update();
    }
  }, [location.pathname]); //eslint-disable-line

  return <Fragment />;
};

export default withRouter(ScrollToTop);
