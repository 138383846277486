import axiosObj from './config';

export const getResourceSummary = id => {
  return axiosObj({
    url: `/c/project-resources/${id}/summary/`,
    method: 'GET',
  });
};

export const getResourceTags = id => {
  return axiosObj({
    url: `/c/project-resources/${id}/tag_keys/`,
    method: 'GET',
  });
};

export const getResourceList = ({ params }) => {
  return axiosObj({
    url: `/c/nswitch_resources/`,
    method: 'GET',
    params: params,
  });
};
