import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const PercentageCell = value => (
  <span
    className={cx({
      red: value > 0,
      green: value < 0,
    })}
  >
    {value > 0 && '+'}
    {`${value}%`}
  </span>
);

PercentageCell.propTypes = {
  value: PropTypes.number,
};

PercentageCell.defaultProps = {
  value: 0,
};

export default PercentageCell;
