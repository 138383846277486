import styled from 'styled-components';

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  height: 100vh;
  display: flex;
  position: center;
`;

export default Wrapper;
