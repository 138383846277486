import React from 'react';
import {
  CostPillarIcon,
  OperationPillarIcon,
  PerformancePillarIcon,
  ReliabilityPillarIcon,
  SecurityPillarIcon,
  SustainabilityPillarIcon,
} from 'assets/images/pillars';
import { RuleIcon } from 'assets/images/nOpsRule';
import { RULES_PILLAR } from 'containers/nOpsRules/utils';

export const getIcon = tab => {
  switch (tab) {
    case RULES_PILLAR.SECURITY:
      return <SecurityPillarIcon />;
    case RULES_PILLAR.COST:
      return <CostPillarIcon />;
    case RULES_PILLAR.OPERATIONS:
      return <OperationPillarIcon />;
    case RULES_PILLAR.PERFORMANCE:
      return <PerformancePillarIcon />;
    case RULES_PILLAR.RELIABILITY:
      return <ReliabilityPillarIcon />;
    case RULES_PILLAR.SUSTAINABILITY:
      return <SustainabilityPillarIcon />;
    default:
      return <RuleIcon />;
  }
};
