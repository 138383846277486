import { numberFix, numberWithCommasAndDecimal } from 'utils/helper';

export const getLineOptions = (unit, yLabel, xLabel = '', columns) => {
  return {
    plugins: {
      legend: { display: true, position: 'bottom', fullSize: true },
      tooltips: {
        mode: 'point',
      },
    },
    responsive: true,
    animation: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        display: true,
        labels: columns,
        title: {
          display: true,
          text: xLabel || '',
        },
      },
      y: {
        grid: {
          display: false,
        },
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y',
        title: {
          display: true,
          text: yLabel || '',
        },
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 7,
          callback: value => {
            if (unit === '$') {
              return `$${numberFix(value)}`;
            } else if (unit === '%') {
              return `${numberFix(value)}%`;
            } else if (unit) {
              return unit + numberWithCommasAndDecimal(value);
            } else {
              return numberFix(value);
            }
          },
        },
        labels: {
          show: true,
        },
      },
      y1: {
        grid: {
          display: false,
        },
        type: 'linear',
        display: false,
        position: 'right',
        id: 'y2',
        ticks: {
          maxTicksLimit: 6,
          callback: (value, index, values) => {
            if (parseInt(value) >= 1000) {
              return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            } else {
              return '$' + value.toFixed(2);
            }
          },
        },
        labels: {
          show: true,
        },
      },
    },
  };
};
