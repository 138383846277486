import { createSelector } from 'reselect';

export const selectPartner = state => state.partner;

export const makeSelectPartnerProfileLoading = () =>
  createSelector(selectPartner, state => state?.userLoading || false);

export const makeSelectAllPartnerClients = () => createSelector(selectPartner, state => state?.partnerClients || []);

export const makePartnerLoading = () => createSelector(selectPartner, state => state?.loading || false);

export const makePartnerStatusLoading = () => createSelector(selectPartner, state => state?.loadingStatus || false);

export const makePartnerPLoading = () => createSelector(selectPartner, state => state?.pLoading || false);

export const makeSelectPartnerDetail = () => createSelector(selectPartner, state => state?.partnerDetail || {});

export const makeSelectPartnerId = () => createSelector(selectPartner, state => state?.partnerDetail?.id || 0);

export const makeSelectClientIdForPartner = () =>
  createSelector(selectPartner, state => state?.partnerDetail?.client_id || 0);

export const makeSelectPartnerProjects = () =>
  createSelector(selectPartner, state => state?.partnerDetail?.primary_projects || []);

export const makeSelectPartnerProfile = () => createSelector(selectPartner, state => state?.partnerProfile || {});

export const makeSelectPartnerUserId = () => createSelector(selectPartner, state => state?.partnerProfile?.id || 0);

export const makeConfigCatAzure = () => createSelector(selectPartner, state => state?.configCatAzure);
