import React from 'react';
import { RuleIcon } from 'assets/images/nOpsRule';
import { Anchor } from 'components/Custom';

const SlackSection = ({ inputData: { notify_slack }, change, allow = false, redirect }) => {
  return (
    <>
      {/* <label className="filtersLabel">NOTIFY ON SLACK</label> */}
      <div className="np-input-group">
        <label className="np-label">Notify on Slack Channel When Exceeds Budget (optional)</label>
        {allow ? (
          <input
            type="text"
            name="notify_slack"
            className="np-input"
            placeholder="Choose Slack Channel"
            value={notify_slack}
            onChange={change}
          />
        ) : (
          <div className="np-note np-note-chargeback yellow">
            <i className="note-icon">
              <RuleIcon />
            </i>
            <p>
              {`You haven't done the Slack Integration yet. Please do that in the `}
              <Anchor
                style={{ textDecoration: 'underline', color: '#1c2b39' }}
                id="slack_integrations"
                onClick={() => redirect('/v3/settings?tab=Integrations')}
              >
                organization settings.
              </Anchor>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default SlackSection;
