import { all, call, put, takeLatest } from 'redux-saga/effects';
import orderBy from 'lodash/orderBy';
import { LOAD_USER_DATA_REQUEST } from './constants';
import { loadUserDataSuccess, loadUserDataError } from './actions';
import { allUsers, allUserInvites } from 'api/team-member';
import { api_condition_error, api_condition_success, globalShowNotification } from 'containers/App/saga';
import { showToastAction } from 'containers/App/actions';

export function* getUSerData() {
  try {
    const [summary, invites] = yield all([call(allUsers), call(allUserInvites)]);
    const computedData = orderBy([...invites.data, ...summary.data], val => val.email.toLowerCase());
    if (api_condition_success(summary.status) && api_condition_success(invites.status)) {
      yield put(loadUserDataSuccess(computedData));
    } else if (api_condition_error(summary.status) || api_condition_error(invites.status)) {
      yield call(globalShowNotification, summary);
      yield put(loadUserDataError());
    }
  } catch (err) {
    yield put(loadUserDataError());
    yield put(
      showToastAction({
        message: 'Failed to load user data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export default function* appUpdate() {
  yield takeLatest(LOAD_USER_DATA_REQUEST, getUSerData);
}
