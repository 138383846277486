import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const selectFilter = state => state.globalFilter;

export const makeTagResourcesFilter = () => createDraftSafeSelector(selectFilter, state => state?.tagResources || []);

export const makeTagResourcesCloudFilter = () =>
  createDraftSafeSelector(selectFilter, state => state?.tagResourcesCloud || []);

export const makeCloudInformationTagsData = () =>
  createDraftSafeSelector(selectFilter, state => state?.cloudInformationTags || []);

export const makeAutoScalingData = () => createDraftSafeSelector(selectFilter, state => state?.allAutoScaling || []);

export const makeVPCNames = () => createDraftSafeSelector(selectFilter, state => state?.vpcNames || []);

export const makeVPCCloudNames = () => createDraftSafeSelector(selectFilter, state => state?.vpcNamesCloud || []);

export const makeSelectRuleFilter = () => createDraftSafeSelector(selectFilter, state => state?.defaultFilter || {});

export const makeSelectRuleCloudFilter = () =>
  createDraftSafeSelector(selectFilter, state => state?.defaultFilterCloud || {});

export const makeSelectGlobalFilterLoading = () =>
  createDraftSafeSelector(selectFilter, state => state?.loading || false);

export const makeSelectGlobalFilterAPICalled = () =>
  createDraftSafeSelector(selectFilter, state => state?.isCalled || false);

export const makeSelectGlobalAWSLoaded = () => createDraftSafeSelector(selectFilter, state => state?.loadedAWSOnlyData);

export const makeSelectGlobalCloudLoaded = () => createDraftSafeSelector(selectFilter, state => state?.loadedCloudData);

export const makeSelectGlobalCloudPlatform = () => createDraftSafeSelector(selectFilter, state => state?.cloudPlatform);
