import React, { useMemo, useEffect, useState } from 'react';
import cx from 'classnames';
import orderBy from 'lodash/orderBy';
import SearchSelectDropDown from 'components/Dropdown/SearchSelectDropDown';
import { SelectableGroup, createSelectable } from 'react-selectable';
import { DAYS } from 'containers/ShareSave/Opportunities/helper';
import { WEEK_NUMBER } from 'components/Schedules/constants';
import { TIMEZONES, getTimeZoneDiff, getActionStoppedPositions } from 'components/ScheduleGrid/helpers';
import { CalendarIcon } from 'assets/images';
import { SmallRefreshIcon } from 'assets/images/RINewIcons/index.js';

const PlaygroundCell = ({ index, running, stopped, onClickCell, readOnly }) => {
  return (
    <div
      className={cx('schedule-playground--cell', {
        green: running.indexOf(index) > -1,
        red: stopped.indexOf(index) > -1,
        'read-only': readOnly,
      })}
      onClick={() => onClickCell(index)}
    ></div>
  );
};
const SelectablePlaygroundCell = createSelectable(PlaygroundCell);

const HOURS = Array.from(Array(24).keys());
const RUNNING_ALL = Array.from(Array(168).keys());
export const ScheduleGrid = ({
  actions = [],
  setGridPayload,
  setGridHoursDown,
  setGridStopped,
  errors = {},
  readOnly = false,
}) => {
  const defaultTimeZone = useMemo(() => {
    const tz = localStorage.getItem('nSwitch:timezone') || '';
    return tz ? TIMEZONES.find(item => item.value === tz) || TIMEZONES[0] : TIMEZONES[0];
  }, []);
  const [timeZone, setTimeZone] = useState(defaultTimeZone);
  const [running, setRunning] = useState(RUNNING_ALL);
  const [stopped, setStopped] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [tempGridPayload, setTempGridPayload] = useState([]);

  const makeScheduleGrid = (tempActions = []) => {
    const cond =
      !(tempActions || []).length || (tempActions.length === 1 && (!stopped.length || stopped.length === 168));
    if (cond) return;

    const stoppedPositions = getActionStoppedPositions(tempActions, timeZone);
    setStopped(stoppedPositions || []);
  };

  const resetGrid = () => {
    makeScheduleGrid(actions);
  };

  useEffect(() => {
    if (!(actions || []).length) return;
    setTempGridPayload(actions);
    makeScheduleGrid(actions);
  }, [actions]); //eslint-disable-line

  useEffect(() => {
    const tz = localStorage.getItem('nSwitch:timezone') || '';
    if (tz !== timeZone.value) {
      localStorage.setItem('nSwitch:timezone', timeZone.value);
    }
    if (!(tempGridPayload || []).length) return;
    makeScheduleGrid(tempGridPayload);
  }, [timeZone.value]); //eslint-disable-line

  useEffect(() => {
    const timeDiff = getTimeZoneDiff(timeZone.value);
    const convertedRunning = running.map(item => {
      let position = item - timeDiff;
      if (position < 0) {
        position = 168 + position;
      } else if (position > 167) {
        position = position - 168;
      }
      return position;
    });
    let tempPayload = RUNNING_ALL.reduce((acc, key) => {
      const prevAction = convertedRunning.includes(key - 1) ? 'start' : 'stop';
      const currAction = convertedRunning.includes(key) ? 'start' : 'stop';
      if (!key) {
        return [
          {
            action: currAction,
            action_type: 'selected_day_of_week',
            day: WEEK_NUMBER[key],
            day_of_week: [WEEK_NUMBER[key]],
            hour: key,
            minute: 0,
            position: key,
          },
        ];
      }
      if (prevAction !== currAction) {
        return [
          ...acc,
          {
            action: currAction,
            action_type: 'selected_day_of_week',
            day: WEEK_NUMBER[Math.floor(key / 24)],
            day_of_week: [WEEK_NUMBER[Math.floor(key / 24)]],
            hour: key % 24,
            minute: 0,
            position: key,
          },
        ];
      }
      return acc;
    }, []);
    tempPayload = orderBy([...tempPayload], ['position', 'action'], 'asc');
    if (
      tempPayload.length > 1 &&
      tempPayload[0].action === 'stop' &&
      tempPayload[tempPayload.length - 1].action === 'stop'
    ) {
      tempPayload.shift();
      setGridPayload(tempPayload);
      setTempGridPayload(tempPayload);
      return;
    }
    if (
      tempPayload.length > 1 &&
      tempPayload[0].action === 'start' &&
      tempPayload[tempPayload.length - 1].action === 'start'
    ) {
      tempPayload.shift();
      setGridPayload(tempPayload);
      setTempGridPayload(tempPayload);
      return;
    }
    setGridPayload(tempPayload);
    setTempGridPayload(tempPayload);
  }, [running, stopped]); //eslint-disable-line

  useEffect(() => {
    setRunning(RUNNING_ALL.filter(r => !stopped.includes(r)));
    setGridHoursDown && setGridHoursDown(stopped.length);
    setGridStopped && setGridStopped(stopped);
  }, [stopped]); //eslint-disable-line

  const handleSelection = selectedKeys => {
    setSelectedKeys(selectedKeys);
  };

  const handleEndSelection = selectedKeys => {
    if (readOnly) return;
    //select GREEN cell
    if (running.includes(selectedKeys[0])) {
      setStopped([...new Set([...stopped, ...selectedKeys])]);
    }
    //select RED cell
    if (stopped.includes(selectedKeys[0])) {
      setStopped(stopped.filter(s => !selectedKeys.includes(s)));
      setRunning([...new Set([...running, ...selectedKeys])]);
    }
  };

  const onClickCell = clickedKey => {
    if (readOnly) return;
    //click GREEN cell
    if (running.includes(clickedKey)) {
      setStopped([...new Set([...stopped, clickedKey])]);
    }
    //click RED cell
    if (stopped.includes(clickedKey)) {
      setStopped(stopped.filter(s => s !== clickedKey));
      setRunning([...new Set([...running, clickedKey])]);
    }
  };

  const onClickHourBtn = hour => {
    if (readOnly) return;
    const hourColKeys = Array.from(Array(7).keys()).map(key => key * 24 + hour);
    const hasStopped = stopped.some(s => hourColKeys.includes(s));
    if (hasStopped) {
      setStopped(stopped.filter(s => !hourColKeys.includes(s)));
      setRunning([...new Set([...running, ...hourColKeys])]);
      return;
    }
    setStopped([...new Set([...stopped, ...hourColKeys])]);
  };

  const onClickDayBtn = day => {
    if (readOnly) return;
    const dayRowKeys = Array.from(Array(24).keys()).map(key => day * 24 + key);
    const hasStopped = stopped.some(s => dayRowKeys.includes(s));
    if (hasStopped) {
      setStopped(stopped.filter(s => !dayRowKeys.includes(s)));
      setRunning([...new Set([...running, ...dayRowKeys])]);
      return;
    }
    setStopped([...new Set([...stopped, ...dayRowKeys])]);
  };

  return (
    <div className="np-row">
      <div className="npxs-12">
        <div className="np-card np-table2">
          <div className="title">
            <div className="np-cardIcon blue">
              <CalendarIcon />
            </div>
            Schedule Grid
          </div>
          <div className="np-table2Actions">
            <div className="np-pageHead-actions">
              <button className="np-button np-action-button light" onClick={() => resetGrid()}>
                <SmallRefreshIcon className="np-refresh-icon" />
                Reset
              </button>
            </div>
            <SearchSelectDropDown
              className="np-pageHead-actions"
              btnClassName="np-dropdownLink"
              color="white"
              list={[...TIMEZONES]}
              title={`View by Timezone: ${timeZone.name}`}
              onItemClick={(value, name) => setTimeZone({ value, name })}
              selectedItem={timeZone}
            />
          </div>
          <div className="schedule-grid">
            <section className="schedule-grid-info">
              <div className="schedule-grid-info--hours">
                <div className="schedule-grid-info--hours-up">
                  <span>Hours Up:</span>
                  {running.length}
                </div>
                <div className="schedule-grid-info--hours-down">
                  <span>Hours Down:</span>
                  {stopped.length}
                </div>
              </div>
              <div className="schedule-grid-info--legend">
                <div className="running">
                  <div className="legend--cell green"></div>
                  Running
                </div>
                <div className="running">
                  <div className="legend--cell red"></div>
                  Stopped
                </div>
              </div>
            </section>
            <section className="schedule-grid-container">
              <div className="schedule-grid-hours">
                {HOURS.map(hour => (
                  <div
                    key={hour}
                    className={cx('schedule-grid-hours--tile', {
                      'read-only': readOnly,
                    })}
                    onClick={() => onClickHourBtn(hour)}
                  >
                    {hour < 10 ? `0${hour}` : hour.toString()}
                  </div>
                ))}
              </div>
              <div className="d-flex" style={{ marginTop: '6px' }}>
                <div className="schedule-grid-days">
                  {DAYS.map((day, index) => (
                    <div
                      key={day}
                      className={cx('schedule-grid-days--tile', {
                        'read-only': readOnly,
                      })}
                      onClick={() => onClickDayBtn(index)}
                    >
                      {day.slice(0, 3).toLocaleUpperCase()}
                    </div>
                  ))}
                </div>
                <div className="schedule-playground">
                  <SelectableGroup
                    onSelection={handleSelection}
                    onEndSelection={handleEndSelection}
                    className="np-selected-area"
                    tolerance={0}
                  >
                    {DAYS.map((day, dayIndex) => (
                      <div key={day} className="schedule-playground--row">
                        {HOURS.map((hour, hourIndex) => (
                          <SelectablePlaygroundCell
                            key={dayIndex * 24 + hourIndex}
                            index={dayIndex * 24 + hourIndex}
                            selectableKey={dayIndex * 24 + hourIndex}
                            selectedKeys={selectedKeys}
                            running={running}
                            stopped={stopped}
                            onClickCell={onClickCell}
                            readOnly={readOnly}
                          />
                        ))}
                      </div>
                    ))}
                  </SelectableGroup>
                </div>
              </div>
            </section>
            <section className="d-flex flex-jcc" style={{ marginTop: '12px' }}>
              {errors?.grid ? (
                <span className="red">
                  <b>{errors.grid}</b>
                </span>
              ) : null}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
