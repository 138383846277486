import moment from 'moment';
import { TWO_WEEKS_AGO, WEEK_AGO, YESTERDAY, LAST_30_DAYS } from 'utils/filter';

export const isSameDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return a.date() === b.date() && a.month() === b.month() && a.year() === b.year();
};

export const isSameMonth = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return a.month() === b.month() && a.year() === b.year();
};

export const presets = [
  {
    text: '1W',
    end: YESTERDAY,
    start: WEEK_AGO,
  },
  {
    text: '2W',
    end: YESTERDAY,
    start: TWO_WEEKS_AGO,
  },
  {
    text: 'MTD',
    end: isSameMonth(moment().utc(), moment().utc().subtract(1, 'days'))
      ? moment().utc().subtract(1, 'days')
      : moment().utc().set('date', 1),
    start: moment().utc().set('date', 1),
  },
  {
    text: '30D',
    end: YESTERDAY,
    start: LAST_30_DAYS,
  },
];

export const presets_month = [
  {
    start: moment().utc().subtract(3, 'M').startOf('M'),
    end: moment().utc().subtract(1, 'M').endOf('M'),
    text: '3M',
  },
  {
    start: moment().utc().subtract(6, 'M').startOf('M'),
    end: moment().utc().subtract(1, 'M').endOf('M'),
    text: '6M',
  },
];

export const dropDownList = () => {
  const options = [...presets_month];
  let i = 1;

  do {
    options.push({
      start: moment().utc().subtract(i, 'M').startOf('M'),
      end: moment().utc().subtract(i, 'M').endOf('M'),
      text: moment().subtract(i, 'M').utc().format('MMM YYYY'),
    });
    i++;
  } while (i <= 6);
  return options;
};
