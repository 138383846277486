import { createSelector } from 'reselect';

export const selectRule = state => state['RULES'];

export const makeSelectRuleViolations = () => createSelector(selectRule, state => state?.ruleViolations || {});

export const makeFilterStore = () => createSelector(selectRule, state => state?.filterStore || {});

export const makeChartDataStore = () => createSelector(selectRule, state => state?.chartData || {});

export const makeSelectRuleTimeStamp = () => createSelector(selectRule, state => state?.ruleTimeStamp || '');

export const makeSelectRuleLoading = () => createSelector(selectRule, state => state?.loading || false);

export const makeSelectRuleFilterCount = () => createSelector(selectRule, state => state?.counts || {});

export const makeSelectRuleFilterCountCloud = () => createSelector(selectRule, state => state?.countsCloud || {});

export const makeSelectResult = () => createSelector(selectRule, state => state?.resultData || {});

export const makeSelectRuleDetail = () => createSelector(selectRule, state => state?.ruleDetail || []);

export const makeSelectRuleDetailCount = () => createSelector(selectRule, state => state?.ruleDetailCounts || {});

export const makeSelectChangeManagement = () => createSelector(selectRule, state => state?.changeData || {});

export const makeSelectChangeManagementDetail = () =>
  createSelector(selectRule, state => state?.changeDataDetail || {});

export const makeSelectRulesResources = () => createSelector(selectRule, state => state?.resources || []);

export const makeSelectRulesCMLoading = () => createSelector(selectRule, state => state?.cmLoading || false);

export const makeSelectRulesCMLoadingViolation = () =>
  createSelector(selectRule, state => state?.cmLoadingViolation || false);

export const makeSelectRulesFilterLoading = () => createSelector(selectRule, state => state?.filterLoading || false);
