import React, { Fragment } from 'react';
import classNames from 'classnames';
import { RuleIcon } from 'assets/images/nOpsRule';
import { WellArchitectedIcon } from 'assets/images/war';
import moment from 'moment';
import { GraterThenArrowIcon } from 'assets/images';
import ReactDOM from 'react-dom';
import { breakStyle, Table, PILLARS_COLUMNS } from '../LandingPages';
import { RULE_DETAIL_PATHS } from '../constants';

export const getViolationIndex = (violations = [], key) => {
  return violations.findIndex(violation => violation?.violation_type === key);
};

export const getViolationIndexForFirstSeenData = (violations = [], key) => {
  return violations.findIndex(violation => violation?.violation_type === key);
};

export const getProjectName = (index, projects) => {
  const projectId = index.split(':')[0].split('_')[1];
  const projectName = projects.find(x => x.id === +projectId);
  return { name: (projectName && projectName.name) || '', id: projectId };
};

export const getRuleData = (bucket = [], service = '') => {
  const ruleData = [];

  bucket.forEach(item => {
    item.resource_of_violation.hits.hits.forEach(hit => {
      if (service === 'all') {
        [
          'ebs',
          'elb',
          'nat',
          'eip',
          'disk_storage',
          'network_interfaces',
          'public_ip_address',
          'virtual_machine',
          'virtual_network_nat',
        ].forEach(serviceKey => {
          if (hit._source.type === serviceKey) {
            ruleData.push(hit);
          }
        });
      } else if (hit._source.type === service) {
        ruleData.push(hit);
      }
    });
  });
  return ruleData;
};

export const renderTextWithIcon = (flag, trueText, falseText) => {
  const iconClass = classNames({
    'icon-spam': true,
    red: flag,
    green: !flag,
  });
  return (
    <>
      {flag ? (
        <i className={iconClass}>
          <RuleIcon /> {trueText}
        </i>
      ) : (
        <i className={iconClass}>
          <WellArchitectedIcon /> {falseText}
        </i>
      )}
    </>
  );
};

export const renderAccountElement = ({ project, status, lastSeen = '' }) => {
  return (
    <Fragment>
      {project || '---'}
      {status && (
        <small className="overriddenText">
          Overridden&nbsp;
          <RuleIcon />
        </small>
      )}
      {lastSeen && (
        <>
          <br />
          <small>
            <span className="np-tableTag yellow terminated">Last Seen: {moment(lastSeen).format('DD, MMM YYYY')}</span>
          </small>
        </>
      )}
    </Fragment>
  );
};

export const getDetailForMultipleService = (payload, key, counts) => {
  const detail = payload[key];
  const service_detail = detail[detail.service[0]];
  const link = detail?.rule_link ? detail.rule_link : RULE_DETAIL_PATHS + '?' + service_detail.path;
  let text = detail.name,
    description = detail.description;
  if (detail.service.length === 1) {
    if (counts > 1) {
      text = service_detail.text_for_grater;
      description = service_detail.description_for_grater;
    } else {
      text = service_detail.text;
      description = service_detail.description;
    }
  }
  return {
    link,
    text,
    description,
  };
};

export const rulesRendererForMultipleService = (text, link, description, rule) => {
  return (
    <div id={rule} style={{ lineHeight: '50px' }}>
      <b style={{ ...breakStyle, display: 'block', lineHeight: '30px' }}>
        {text} <GraterThenArrowIcon />
      </b>
      <a
        href={link}
        target={'_blank'}
        rel="noopener noreferrer"
        onClick={e => e.preventDefault()}
        className="rule-description"
      >
        <p
          style={{
            ...breakStyle,
            lineHeight: '20px',
          }}
        >
          {description}
        </p>
      </a>
    </div>
  );
};

export const elementForLastMonthViolationWrapper = (icon, count, className, showText = true, rule_key, service) =>
  elementForLastMonthViolation(icon, count, className, showText, rule_key, service);

export const elementForLastMonthViolation = (icon, count, className, showText = true, rule_key, service) => (
  <>
    <i key={'lastmviol-' + rule_key + '-' + service} className={`${className}-fill`}>
      {icon}
      &nbsp;
    </i>
    <span className={className}>{count}</span>
    {showText ? ' than last month' : ''}
  </>
);

export const fieldRenderer = params => {
  const data = params.data;
  const element = document.createElement('span');
  element.className = 'np-field-container';
  const issueElement = data[params.colDef.field + 'Element'];
  ReactDOM.render(issueElement, element);
  return element;
};

export const renderElement = (count, data, handleViewRule, handleShowData) => {
  const viewRule = params => {
    typeof handleViewRule === 'function' &&
      params.count > 0 &&
      handleViewRule(params.link ? { link: params.link } : { path: params.path });
    typeof handleShowData === 'function' && handleShowData(params);
  };

  return <Table columns={PILLARS_COLUMNS} tab={''} count={count} lastCount={0} data={[...data]} onClicked={viewRule} />;
};
