import { createSelector } from 'reselect';

const selectSearch = state => state['SEARCH_PAGE'];

export const makeSelectSearchData = () => {
  return createSelector(selectSearch, state => state?.searchData || {});
};

export const makeSelectSearchFilters = () => {
  return createSelector(selectSearch, state => state?.filters || {});
};

export const makeSelectSearchCM = () => {
  return createSelector(selectSearch, state => state?.changes || []);
};

export const makeSelectSearchCMLoading = () => {
  return createSelector(selectSearch, state => state?.cmLoading || false);
};

export const makeSelectSearchCMFilters = () => {
  return createSelector(selectSearch, state => state?.cmFilters || {});
};

export const makeSelectSearchResourceData = () => {
  return createSelector(selectSearch, state => state?.resourcesDetailData || {});
};

export const makeSelectSearchLoading = () => createSelector(selectSearch, state => state?.loading || false);

export const makeSelectSearchRLoading = () => createSelector(selectSearch, state => state?.rLoading || false);

export const makeSelectSearchSummaryData = () => createSelector(selectSearch, state => state?.warSummary || {});

export const makeSelectSearchCounters = () => createSelector(selectSearch, state => state?.searchCounters || {});

export const makeSelectSearchCloudCounters = () =>
  createSelector(selectSearch, state => state?.searchCountersCloud || {});

export const makeSelectDefaultSearchCounters = () =>
  createSelector(selectSearch, state => state?.defaultSearchCounters || {});

export const makeSelectDefaultSearchCountersCloud = () =>
  createSelector(selectSearch, state => state?.defaultSearchCountersCloud || {});
