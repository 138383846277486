/* istanbul ignore file */

import React from 'react';
import { Route } from 'react-router-dom';
import { isLogin } from './PrivateRoute';
import { getEnv, DEV_ENV } from '../utils/env';

const PublicRoute = ({ component: Component, restricted, defaultPath, ...rest }) => {
  const redirect = () => {
    if (getEnv() === DEV_ENV) {
      window.location = '/v3/';
    } else {
      window.location = '/landing';
    }
  };

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route {...rest} render={props => (isLogin() && restricted ? redirect() : <Component {...props} />)} />
  );
};

export default PublicRoute;
