/* istanbul ignore file */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCookieByName } from '../utils/getCookie';

export const isLogin = () => {
  return !!getCookieByName('nops_user_id');
};

const PrivateRoute = ({ component: Component, basePageProps, defaultPath, ...rest }) => {
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /signin page
  return (
    <Route
      {...rest}
      render={props =>
        isLogin() ? (
          <Component {...basePageProps} {...props} />
        ) : (
          <Redirect to={`/accounts/signin/?next=${rest.location.pathname}`} />
        )
      }
    />
  );
};

export default PrivateRoute;
