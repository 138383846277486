import { RULES_PILLAR } from './utils';

export const LOAD_RULE_VIOLATION_DATA = 'RULE/LOAD_RULE_VIOLATION_REQUEST';
export const LOAD_RULE_VIOLATION_DATA_SUCCESS = 'RULE/LOAD_RULE_VIOLATION_SUCCESS';
export const LOAD_RULE_VIOLATION_DATA_ERROR = 'RULE/LOAD_RULE_VIOLATION_ERROR';

export const LOAD_RULE_FILTER_DATA = 'RULE/LOAD_RULE_FILTER_DATA';
export const LOAD_RULE_FILTER_DATA_SUCCESS = 'RULE/LOAD_RULE_FILTER_DATA_SUCCESS';
export const LOAD_RULE_FILTER_DATA_ERROR = 'RULE/LOAD_RULE_FILTER_DATA_ERROR';

export const LOAD_CM_RULE_VIOLATION_DATA_REQUEST = 'RULE/LOAD_CM_RULE_VIOLATION_DATA_REQUEST';
export const LOAD_CM_RULE_VIOLATION_DATA_SUCCESS = 'RULE/LOAD_CM_RULE_VIOLATION_SUCCESS';
export const LOAD_CM_RULE_VIOLATION_DATA_ERROR = 'RULE/LOAD_CM_RULE_VIOLATION_ERROR';

export const LOAD_RULE_DETAIL_VIOLATION_DATA = 'RULE/LOAD_RULE_DETAIL_VIOLATION_REQUEST';
export const LOAD_RULE_DETAIL_VIOLATION_DATA_SUCCESS = 'RULE/LOAD_RULE_DETAIL_VIOLATION_SUCCESS';
export const LOAD_RULE_DETAIL_VIOLATION_DATA_ERROR = 'RULE/LOAD_RULE_DETAIL_VIOLATION_ERROR';

export const LOAD_RULE_DETAIL_FILTER_DATA = 'RULE/LOAD_RULE_DETAIL_FILTER_DATA';
export const LOAD_RULE_DETAIL_FILTER_DATA_SUCCESS = 'RULE/LOAD_RULE_DETAIL_FILTER_DATA_SUCCESS';
export const LOAD_RULE_DETAIL_FILTER_DATA_ERROR = 'RULE/LOAD_RULE_DETAIL_FILTER_DATA_ERROR';

export const LOAD_CM_RULE_DETAIL_VIOLATION_DATA_REQUEST = 'RULE/LOAD_CM_RULE_DETAIL_VIOLATION_DATA_REQUEST';
export const LOAD_CM_RULE_DETAIL_VIOLATION_DATA_SUCCESS = 'RULE/LOAD_CM_RULE_DETAIL_VIOLATION_SUCCESS';
export const LOAD_CM_RULE_DETAIL_VIOLATION_DATA_ERROR = 'RULE/LOAD_CM_RULE_DETAIL_VIOLATION_ERROR';

export const unused_rules = [
  { key: 'type', label: 'Type' },
  { key: 'project', label: 'AWS Account' },
  { key: 'region', label: 'Region' },
  { key: 'name', label: 'Resource Name / ID' },
  { key: 'ip', label: 'IP Address' },
  { key: 'cost', label: 'Cost ($)' },
];

export const IS_SHOW = ['searchString', 'account', 'cloudformation', 'resource', 'autoScaling', 'region', 'vpc'];
export const filterProps = {
  account: {
    paramKey: 'violations_counts_per_project',
    isCost: false,
  },
  region: {
    paramKey: 'violations_counts_per_region',
    isCost: false,
  },
  vpc: {
    paramKey: 'violations_counts_per_vpc',
    isCost: false,
  },
};

export const rules_pillars_array = [
  RULES_PILLAR.SECURITY,
  RULES_PILLAR.COST,
  RULES_PILLAR.OPERATIONS,
  RULES_PILLAR.RELIABILITY,
  RULES_PILLAR.PERFORMANCE,
];

export const RULE_DETAIL_PATHS = '/v3/rule-details/';
