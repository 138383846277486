import * as Sentry from '@sentry/browser';
import React from 'react';
import PropTypes from 'prop-types';

const ErrorComponent = ({ sentryEventId }) => {
  return (
    <div className="np-row">
      <div className="npxs-12">
        <div className="np-wrapper">
          <div className="np-contentMain full">
            <div className="np-noData" style={{ padding: 12 }}>
              <p>Oh snap! Something went wrong, we are unable to load data.</p>
              <button
                id={'errorBoundary'}
                className="np-button light"
                onClick={() => Sentry.showReportDialog({ eventId: sentryEventId })}
              >
                Report feedback
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorComponent.propTypes = {
  sentryEventId: PropTypes.string,
};

export default ErrorComponent;
