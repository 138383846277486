import React from 'react';
import { createCustomRule, updateCustomRule } from 'api/settings';
import { getErrorMessage } from 'utils/helper';
import { CustomModal } from 'components/CustomModal';
import SingleSelectDropDown from 'components/Dropdown/SingleSelectDropDown';
import { RULES_PILLAR } from 'containers/nOpsRules/utils';
import QuerySearchSuggestion from 'containers/Search/QuerySearchSuggestion';
import { api_condition_error, api_condition_success } from 'containers/App/saga';
import { rules_pillars_array } from 'containers/nOpsRules/constants';

const pillars = (rules_pillars_array || []).map(rule => ({
  value: rule,
  label: rule,
}));

class SaveRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleName: props?.data?.name || '',
      query: props?.data?.query || '',
      label: props?.data?.label || RULES_PILLAR.SECURITY,
      selectedLabel: {
        label: props?.data?.label || RULES_PILLAR.SECURITY,
        value: props?.data?.label || RULES_PILLAR.SECURITY,
      },
      error: '',
      queryError: '',
      loader: false,
    };
  }

  handleQueryChange = (query, error) => {
    this.setState({
      query,
      error: error ? 'Query is not valid' : '',
    });
  };

  handleNameChange = e => {
    this.setState({
      ruleName: e.target.value,
      error: '',
    });
  };

  handleSaveRule = async () => {
    const { ruleName, label, query } = this.state;
    const { saveNewCustomRule, data = {}, client } = this.props;
    let response = '';
    const payload = {
      query,
      name: ruleName,
      label,
      client,
    };
    this.setState(
      {
        loader: true,
      },
      () => {
        const fun = data?.id ? updateCustomRule(data.id, payload) : createCustomRule(payload);
        fun
          .then(res => {
            if (api_condition_error(res.status)) {
              response = getErrorMessage(res);
              if (response) {
                this.setState({ error: response, loader: false });
              }
            } else if (api_condition_success(res.status)) {
              saveNewCustomRule();
            }
          })
          .catch(e => {
            saveNewCustomRule('Failed to add new custom rule');
          });
      },
    );
  };

  onPillarChange = data => {
    this.setState({
      label: data.value,
      selectedLabel: data,
      error: '',
    });
  };

  clearFiltration = () => {
    this.setState({
      query: '',
      error: '',
    });
  };

  updateError = error => {
    this.setState({
      queryError: error,
    });
  };

  render() {
    const { handleCloseRuleModal } = this.props;
    const { query, ruleName, selectedLabel, error, loader, queryError } = this.state;
    return (
      <CustomModal
        showModal={true}
        title={'Custom Rule'}
        mainClass={'querySearchModal'}
        loading={loader}
        child={
          <>
            <div className="np-input-group">
              <label className="np-label">
                Defined Query <span className="red">*</span>
              </label>
              <div className="np-select-group">
                <QuerySearchSuggestion
                  updateError={this.updateError}
                  onClick={this.handleQueryChange}
                  clearFiltration={this.clearFiltration}
                  showButtons={false}
                  isSearch={false}
                  name={query}
                />
              </div>
            </div>
            <div className="np-input-group">
              <label className="np-label">
                Rule Name <span className="red">*</span>
              </label>
              <input id={'ruleName'} className="np-input" value={ruleName} onChange={this.handleNameChange} />
              <p className="naming-hint">Enter a name or an abbreviation for your query, to use it anytime later</p>
            </div>
            <div className="np-input-group">
              <label className="np-label">Rule Label</label>
              <div className="np-select-group">
                <SingleSelectDropDown
                  isClearable={false}
                  data={pillars}
                  type={'pillars'}
                  placeHolder={'Select label'}
                  onChange={this.onPillarChange}
                  defaultData={
                    selectedLabel || {
                      value: RULES_PILLAR.SECURITY,
                      label: RULES_PILLAR.SECURITY,
                    }
                  }
                />
              </div>
            </div>
            {error || queryError ? (
              <div className="np-input-group">
                <span className={'red'}>{queryError || error}</span>
              </div>
            ) : null}
          </>
        }
        footerData={{
          type: 'custom',
          custom: [
            {
              text: 'Save',
              className: `blue ${!ruleName || !query || !!error || !!queryError || loader ? 'disabled' : ''}`,
              onClick: this.handleSaveRule,
              disabled: !ruleName || !query || !!error || !!queryError || loader,
            },
            {
              text: 'Cancel',
              className: `light`,
              onClick: handleCloseRuleModal,
            },
          ],
        }}
        handleToggleModal={handleCloseRuleModal}
      />
    );
  }
}

export default SaveRule;
