import {
  LOAD_SEARCH_REQUEST,
  LOAD_SEARCH_SUCCESS,
  LOAD_SEARCH_ERROR,
  LOAD_SEARCH_CM_DATA,
  LOAD_SEARCH_CM_DATA_SUCCESS,
  LOAD_SEARCH_CM_DATA_ERROR,
  LOAD_SEARCH_FILTER_REQUEST,
  LOAD_SEARCH_FILTER_SUCCESS,
  LOAD_SEARCH_FILTER_ERROR,
  LOAD_SEARCH_RESOURCE_DETAIL_REQUEST,
  LOAD_SEARCH_RESOURCE_DETAIL_SUCCESS,
  LOAD_SEARCH_RESOURCE_DETAIL_ERROR,
} from './constants';

export function loadData(payload) {
  return {
    type: LOAD_SEARCH_REQUEST,
    payload,
  };
}

export function loadDataSuccess(response) {
  return {
    type: LOAD_SEARCH_SUCCESS,
    response,
  };
}

export function loadDataError() {
  return {
    type: LOAD_SEARCH_ERROR,
  };
}

export function loadCMData(payload) {
  return {
    type: LOAD_SEARCH_CM_DATA,
    payload,
  };
}

export function loadCMDataSuccess(response) {
  return {
    type: LOAD_SEARCH_CM_DATA_SUCCESS,
    response,
  };
}

export function loadCMDataError() {
  return {
    type: LOAD_SEARCH_CM_DATA_ERROR,
  };
}

export function loadSearchFilterData(payload) {
  return {
    type: LOAD_SEARCH_FILTER_REQUEST,
    payload,
  };
}

export function loadSearchFilterDataSuccess(response) {
  return {
    type: LOAD_SEARCH_FILTER_SUCCESS,
    response,
  };
}

export function loadSearchFilterDataError() {
  return {
    type: LOAD_SEARCH_FILTER_ERROR,
  };
}

export function loadResourceData(
  payload,
  project_id,
  partner_scope_id = 0,
  isSharesaveScheduler,
  resource_type = null,
) {
  return {
    type: LOAD_SEARCH_RESOURCE_DETAIL_REQUEST,
    payload,
    project_id,
    partner_scope_id,
    isSharesaveScheduler,
    resource_type,
  };
}

export function loadResourceDataSuccess(response) {
  return {
    type: LOAD_SEARCH_RESOURCE_DETAIL_SUCCESS,
    response,
  };
}

export function loadResourceDataError() {
  return {
    type: LOAD_SEARCH_RESOURCE_DETAIL_ERROR,
  };
}
