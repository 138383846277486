import React, { useEffect, useRef, useState } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import moment from 'moment';
import { colors } from '../Color';
import cloneDeep from 'lodash/cloneDeep';
import { COST_TYPE } from 'utils/constants';
import { allowedFormat } from '../constant';

const CLOUD_NAMES = {
  aws: 'AWS',
  azure: 'Azure',
};

const CustomLegend = ({ data, onClick, isDemoMode }) => {
  const _data = cloneDeep(data);
  const platforms = _data.reduce((obj, elem, index) => {
    const [, ...rest] = elem.label.split('_');
    elem.label = rest.join('_');
    if (elem.label.length > 18) {
      elem.label = elem.label.slice(0, 15) + '...';
    }

    !elem.cloudPlatform && (elem.cloudPlatform = 'aws');
    if (!obj[elem.cloudPlatform]) obj[elem.cloudPlatform] = [];
    obj[elem.cloudPlatform].push({ ...elem, index });
    return obj;
  }, {});

  const platformsArray = Object.keys(platforms) || [];
  return (
    <div className="np-legendBlock">
      {platformsArray.map(platform => (
        <ul className="np-customLegend" key={platform}>
          <li className="legendTitle">{CLOUD_NAMES[platform]}:</li>
          {platforms[platform].map((item, index) => {
            item.label = `${index + 1}. ${item.label}`;
            return (
              <li
                key={item.label + index}
                data-cloud={platform}
                data-index={item.index}
                onClick={() => onClick(item.index)}
              >
                <span className="legendColor" style={{ backgroundColor: item.backgroundColor }} />
                <span className={`legendLabel ${item.hidden ? 'strike' : ''} ${isDemoMode ? 'demo-mode-black' : ''}`}>
                  {item.label}
                </span>
              </li>
            );
          })}
        </ul>
      ))}
    </div>
  );
};

const BarChartCustomLegend = ({ dataSet, columns, unit, handleClick, type }) => {
  const chartRef = useRef();
  const [chartData, setData] = useState({
    labels: [],
    datasets: [],
  });

  const onClick = event => {
    const eventDetail = getElementAtEvent(chartRef.current, event);
    if (!eventDetail.length || typeof handleClick !== 'function') return null;
    handleClick(eventDetail);
  };

  useEffect(() => {
    addStyle();
  }, [dataSet]); //eslint-disable-line

  const addStyle = () => {
    const dateFormat = setDateFormat();

    const data = {
      labels: (columns || []).map(row => moment(row, allowedFormat, true).format(dateFormat)),
    };

    data.datasets = (dataSet || []).map((row, i) => ({
      label: `${i + 1}_${row.label}`,
      fill: false,
      lineTension: 0.1,
      backgroundColor: colors[i % colors.length].backgroundColor,
      borderColor: colors[i % colors.length].borderColor,
      hoverBackgroundColor: colors[i % colors.length].hoverBackgroundColor,
      hoverBorderColor: colors[i % colors.length].hoverBorderColor,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#22C7E3',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#22C7E3',
      pointHoverBorderColor: '#22C7E3',
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      borderWidth: 1.7,
      pointHitRadius: 40,
      stack: '1',
      data: row.data,
      hidden: false,
      cloudPlatform: row.cloudPlatform,
    }));

    setData(data);
  };

  const setDateFormat = () => {
    let dateFormat = 'MMM-D-YYYY';
    if (columns?.length) {
      const lastDate = moment(columns[columns.length - 1], 'YYYY-MM-DD');
      const startDate = moment(columns[0], 'YYYY-MM-DD');
      const diff = lastDate.diff(startDate, 'M');
      if ([2, 5].includes(diff)) {
        dateFormat = 'YYYY-MM';
      }
    }
    return dateFormat;
  };

  const handleLegendClick = index => {
    const chart = chartRef.current._metasets;
    chart[index].hidden = chart[index].hidden === null ? true : !chart[index].hidden;
    chartRef.current.legend.chart.update();
    const data = { ...chartData };
    data.datasets[index].hidden = !data.datasets[index].hidden;
    setData(data);
  };

  const isDemoMode =
    sessionStorage.getItem('demo-mode') === 'on' &&
    [COST_TYPE.AWS_ACCOUNTS, COST_TYPE.CLOUD_ACCOUNTS, COST_TYPE.RESOURCES, COST_TYPE.NON_RESOURCES].includes(type);

  return (
    <>
      <div className="chart_js" data-testid="bar-chart-custom-legend" style={{ height: '350px' }}>
        <Bar
          ref={chartRef}
          data={chartData}
          options={{
            plugins: {
              tooltips: {
                enabled: true,
                mode: 'label',
                position: 'average',
                intersect: false,
                callbacks: {
                  label: function (tooltipItem, data) {
                    let label = !isDemoMode ? data?.datasets?.[tooltipItem?.datasetIndex]?.label || '' : '********';
                    label += ':' + Math.round(tooltipItem.yLabel * 100) / 100;
                    return label;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                axis: 'y',
                type: 'linear',
                stacked: true,
                grid: {
                  color: '#e2e7ea',
                  display: true,
                  drawBorder: true,
                },
                ticks: {
                  maxTicksLimit: 6,
                  callback: (value, index, values) => {
                    const tmpUnit = unit === undefined ? '$' : unit;
                    return [
                      tmpUnit,
                      value.toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                      }),
                    ].join('');
                  },
                },
              },
              x: {
                stacked: true,
                grid: {
                  color: '#e2e7ea',
                  display: true,
                  drawBorder: true,
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
          type="bar"
          onClick={onClick}
        />
      </div>
      <CustomLegend isDemoMode={isDemoMode} data={chartData.datasets} onClick={handleLegendClick} />
    </>
  );
};

export default BarChartCustomLegend;
