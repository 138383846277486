import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getErrorMessage, IsJsonValid, IsURIValid } from 'utils/helper';
import { getParamFromQueryString } from 'utils/queryString';
import { overwriteRule } from 'api/rule';
import { CustomModal } from 'components/CustomModal';
import { api_condition_success } from 'containers/App/saga';
import { loadOverrideDataRequest } from '../reducer';

export const ActionModal = ({ ...props }) => {
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);
  const [reason, setReason] = useState('');
  const {
    data = {},
    closeModal,
    rule,
    loading = false,
    getViolationData,
    isResource,
    resourceOverridePayload = {},
    getSearchResourceData,
    isPartner,
  } = props;
  const { overridePayload = {} } = useSelector(state => state?.['RULE_DETAILS'] || {});
  const location = useLocation();
  const dispatch = useDispatch();
  const { mainId, projectId, status, overrideInfo } = data;
  const isOverridden = overrideInfo?.is_compliant || (isEmpty(overrideInfo) && status);

  const overwriteTheRule = async () => {
    setLoader(true);

    const projectResource = [{ project: +projectId, resources: [mainId] }];

    const getViolations = () => {
      setLoader(false);
      closeModal();

      let data = getParamFromQueryString(location.search, 'data') || '';
      data = IsJsonValid(IsURIValid(data));

      if (isFunction(getViolationData)) getViolationData(data);
      if (isResource) getSearchResourceData();
    };

    overwriteRule(
      {
        compliant: Boolean(!isOverridden),
        project_resources: projectResource,
        rule_name: rule,
        reason,
      },
      isPartner?.partner_scope_id,
    )
      .then(res => {
        if (api_condition_success(res.status)) {
          if (!res.data.ok) {
            setLoader(false);
            setError(getErrorMessage(res));
          } else {
            setTimeout(() => {
              dispatch(
                loadOverrideDataRequest({
                  payload: isResource ? resourceOverridePayload : overridePayload,
                  callback: getViolations,
                  partner_scope_id: isPartner?.partner_scope_id,
                }),
              );
            }, 1000);
          }
        } else {
          setLoader(false);
          setError(getErrorMessage(res));
        }
      })
      .catch(() => setLoader(false));
  };

  return (
    <CustomModal
      showModal={true}
      handleToggleModal={!loader ? closeModal : () => {}}
      child={
        <div data-testid="ActionModal">
          <div className="np-input-group">
            <div className="np-label">Action</div>
            <p>
              {isOverridden
                ? 'Revert and mark as a violation'
                : 'Overriding this violation will exclude it from overall violations count.'}
            </p>
          </div>

          <div className="np-input-group">
            <div className="np-label">{isOverridden ? 'Revert Details' : 'Override Details'} (Optional)</div>
            <textarea
              id="modal-note"
              className="np-input"
              name="note"
              onChange={e => setReason(e.target.value)}
              value={reason}
              placeholder={
                isOverridden
                  ? 'Add your notes on why are you reverting override...'
                  : 'Add your notes on why are you overriding this violation...'
              }
              required
            />
          </div>
          {!error ? null : (
            <div className="np-input-group">
              <span className="color-red">{error}</span>
            </div>
          )}
          {loader && <span className="modal-loading-message">This action may take some time..</span>}
        </div>
      }
      title={isOverridden ? 'Revert and Mark as Violation' : 'Override Violation'}
      footerData={{
        type: 'custom',
        custom: [
          {
            text: isOverridden ? 'Revert' : 'Override',
            onClick: !error && !loader && !loading ? overwriteTheRule : () => {},
            className: `blue ${error || loader || loading ? 'disabled' : ''}`,
          },
          {
            text: 'Cancel',
            onClick: !loader ? closeModal : () => {},
          },
        ],
      }}
      loading={loader || loading}
    />
  );
};
