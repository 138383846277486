import React from 'react';

export const NpMultipleCubes = ({ color = '' }) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M61.0945 33.8435V10.2067L40.0002 0L18.9055 10.2067V33.8435L0 43.2301V69.4486L21.2545 80L40.0002 70.6166L58.7458 80L80 69.4486V43.2301L61.0945 33.8435ZM18.9055 73.5958L4.688 66.5366V48.4682L18.9071 55.5274L18.9055 73.5958ZM21.2449 51.449L7.61922 44.6861L21.2449 37.9216L34.7585 44.6861L21.2449 51.449ZM37.656 66.5414L23.5936 73.5814V55.521L37.656 48.481V66.5414ZM37.656 40.8867L23.5936 33.8467V15.4752L37.656 22.5152V40.8867ZM26.5601 11.7078L40.0002 5.21235L53.4402 11.7078L40.0002 18.4355L26.5601 11.7078ZM56.4064 15.4752V33.8499L42.3441 40.8899V22.5152L56.4064 15.4752ZM56.4064 73.5814L42.3441 66.5414V48.4826L56.4064 55.5226V73.5814ZM45.2415 44.6845L58.7554 37.92L72.3808 44.6845L58.7538 51.449L45.2415 44.6845ZM75.3122 66.5382L61.0929 73.5974V55.5274L75.3122 48.4682V66.5382Z"
      fill={color}
    />
  </svg>
);
