import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getLineOptions } from './constants';

const LineChart = ({ data: propsData, columns, label, unit, yLabel }) => {
  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    setInitialState();
  }, [propsData, columns]); //eslint-disable-line

  const setInitialState = () => {
    const detail = {
      datasets: [
        {
          fill: true,
          label: label,
          type: 'line',
          borderDash: [],
          pointRadius: 3,
          data: propsData || [0],
          lineTension: 0.1,
          pointHitRadius: 10,
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          borderDashOffset: 0.0,
          borderCapStyle: 'butt',
          borderColor: '#1e71ea',
          borderJoinStyle: 'miter',
          pointHoverBorderWidth: 2,
          backgroundColor: '#bfd8ff',
          pointBorderColor: '#1e71ea',
          pointBackgroundColor: '#1e71ea',
          pointHoverBorderColor: '#1e71ea',
          pointHoverBackgroundColor: '#1e71ea',
          yAxisID: 'y',
        },
      ],
    };
    setData(detail);
  };

  const options = getLineOptions(unit, yLabel, '', columns);
  return (
    <div data-testid="line-chart">
      <Line type="line" data={data} options={options} height={50} />
    </div>
  );
};

export default LineChart;
