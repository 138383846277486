import React, { PureComponent } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { DateRangePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay, SingleDatePicker } from 'react-dates';
import Tippy from '@tippyjs/react';
import cx from 'classnames';

import { CalendarIcon, InfoIcon, PlusIcon, SearchIcon } from 'assets/images';
import { COST_TYPE, datePickerProps, singlePickerProps } from 'utils/constants';
import { numberFix, keyToCloudObj } from 'utils/helper';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { LAST_30_DAYS, TWO_WEEKS_AGO, YESTERDAY } from 'utils/filter';

import { RadioFilter, SelectFilter } from 'components/Filter';
import { Anchor } from 'components/Custom';
import { showToastAction } from 'containers/App/actions';
import reducer from 'containers/App/reducer';
import saga from 'containers/App/saga';
import {
  makeAccountFilter,
  makeAzureBillingCurrency,
  makeIsMultipleCurrency,
  makeUserData,
} from 'containers/App/selectors';
import { loadOperationDataRequest, loadUsageTypeDataRequest } from 'containers/Cost/actions';
import { makeSelectOperationData, makeSelectUsageTypeData } from 'containers/Cost/selectors';
import arpReducer from 'containers/Cost/reducer';
import arpSaga from 'containers/Cost/saga';
import { makeRulesDataLoading, makeSelectRulesData } from 'containers/Settings/CustomRules/selectors';
import { loadCustomRulesRequest } from 'containers/Settings/CustomRules/actions';
import rulesReducer from 'containers/Settings/CustomRules/reducer';
import rulesSaga from 'containers/Settings/CustomRules/saga';
import SaveRule from 'containers/Settings/CustomRules/saveRule';
import { makeSelectPartnerProjects } from 'containers/Partner/BasePage/selectors';
import partnerReducer from 'containers/Partner/BasePage/reducer';
import partnerSaga from 'containers/Partner/BasePage/saga';

import filterReducer, { loadInitial } from './reducer';
import {
  makeVPCNames,
  makeVPCCloudNames,
  makeAutoScalingData,
  makeCloudInformationTagsData,
  makeSelectGlobalFilterAPICalled,
  makeSelectGlobalFilterLoading,
  makeTagResourcesFilter,
  makeTagResourcesCloudFilter,
  makeSelectRuleFilter,
  makeSelectRuleCloudFilter,
  makeSelectGlobalAWSLoaded,
  makeSelectGlobalCloudLoaded,
  makeSelectGlobalCloudPlatform,
  selectFilter,
} from './selectors';

import { dropDownList, isSameDay, presets } from './constants';
import TagFiltersComponent from './TagFilters';
import { convertStringDateToMoment } from '../Cost/AWSResource';

const key = 'global';

const utilizationOption = [
  { value: 'All', key: '0', type: 'isRUOpen' },
  { value: '< 10% utilized', key: '1', type: 'isRUOpen' },
  { value: '< 20% utilized', key: '2', type: 'isRUOpen' },
  { value: '< 30% utilized', key: '3', type: 'isRUOpen' },
];
const resourceOption = [
  { value: 'All', key: '0', type: 'resource' },
  { value: 'Active', key: '1', type: 'resource' },
  { value: 'Inactive', key: '2', type: 'resource' },
];

const getInitialSearchString = value => {
  if (!value?.searchString) return '';
  if (value?.searchString === '*') return '';
  return value?.searchString;
};

export const getInitialState = (filters = undefined) => {
  return {
    isClear: false,
    seFocusedInput: null,
    launchSFocusedInput: null,
    launchEFocusedInput: null,
    qSearch: filters?.qSearch || -1,
    showSaveRuleModal: false,
    isASData: filters?.isASData || [{ id: '0', value: 'Part of Autoscaling', checked: false }],
    isRUData: filters?.isRUData || [{ id: '0', value: 'Reserved Instance', checked: false }],
    launchEnd: filters?.launchEnd || undefined,
    launchStart: filters?.launchStart || undefined,
    include_credit: filters?.include_credit || false,
    resourceStatus: filters?.resourceStatus || '1',
    selectedResourceUtilization: filters?.selectedResourceUtilization || '0',
    searchString: getInitialSearchString(filters),
    dslSearchString: getInitialSearchString(filters),
    tagData: filters?.tagData || {},
    eksDateRange: filters?.eksDateRange || '30d',
    cit: filters?.cit || [],
    opState: filters?.opState || [],
    utState: filters?.utState || [],
    vpcState: filters?.vpcState || [],
    cloudPlatformState: filters?.cloudPlatformState || [],
    cloudPlatformState_values: filters?.cloudPlatformState_values || [],
    vpcState_values: filters?.vpcState_values || [],
    userState: filters?.userState || [],
    eventState: filters?.eventState || [],
    regionState: filters?.regionState || [],
    regionState_values: filters?.regionState_values || [],
    accountState: filters?.accountState || [],
    accountState_values: filters?.accountState_values || [],
    productState: filters?.productState || [],
    operationState: filters?.operationState || [],
    selectedAutoScaling: filters?.selectedAutoScaling || [],
    instanceTypeState: filters?.instanceTypeState || [],
  };
};

class Filters extends PureComponent {
  constructor(props) {
    super(props);
    const filters = { ...props?.filters };
    const initialState = getInitialState(filters);
    this.state = {
      ...initialState,
      endDate: filters?.endDate || YESTERDAY,
      startDate: filters?.startDate || LAST_30_DAYS,
    };

    this.searchOperationRecord = debounce(
      () =>
        (this.opS === '' || this.opS.trim()) &&
        this.props.loadOperationDataRequest(this.opS.trim(), this.props.configCatAzure),
      600,
    );

    this.searchUsageTypeRecord = debounce(
      () =>
        (this.utS === '' || this.utS.trim()) &&
        this.props.loadUsageTypeDataRequest(this.utS.trim(), this.props.configCatAzure),
      600,
    );
  }

  componentDidMount = () => {
    const {
      operationData: { data: opData = [], loading: opLoading = false },
      usageTypeData: { data: utData = [], loading: utLoading = false },
      isShow = [],
      rulesDataLoading = false,
      rulesData,
      filterLoading,
      filterAPICalled,
      configCatAzure,
      hideAzure,
      loadedAWSOnlyData,
      loadedCloudData,
      filters,
      accountFilter,
    } = this.props;

    // delay operations and usage type loading until config cat is resolved
    if (configCatAzure !== null) {
      if (isShow.includes('op') && !opData.length && !opLoading) {
        this.props.loadOperationDataRequest('', configCatAzure);
      }
      if (isShow.includes('ut') && !utData.length && !utLoading) {
        this.props.loadUsageTypeDataRequest('', configCatAzure);
      }
    }
    if (!rulesDataLoading && !rulesData) {
      this.props.loadCustomRulesRequest();
    }

    // select cloud platform if we have regions or account selected in filter
    if (this.props.configCatAzure) {
      if ((filters?.cloudPlatformState || []).length === 0) {
        if ((filters?.regionState_values || []).length > 0) {
          const cloud_platform = filters?.regionState_values[0].cloudPlatform;
          if (cloud_platform) {
            this.updateState({ ...keyToCloudObj(cloud_platform), checked: true }, 'cloudPlatformState');
          }
        }
        if ((filters?.accountState || []).length > 0) {
          const accID = filters?.accountState[0];
          const rec = (accountFilter || []).find(n => (n?.id || '').toString() === accID.toString());
          const cloud_platform = rec?.cloudPlatform;
          if (cloud_platform) {
            this.updateState({ ...keyToCloudObj(cloud_platform), checked: true }, 'cloudPlatformState');
          }
        }
      }
    }
    const useCloudData = !configCatAzure ? false : hideAzure ? false : true;

    // reload if proper aggregations (aws or cloud) are not yet loaded to fix filter bug during page transitions between aws only pages and azure enabled pages
    if (
      (!filterLoading && !filterAPICalled) ||
      (configCatAzure && ((useCloudData && loadedAWSOnlyData) || (!useCloudData && loadedCloudData)))
    ) {
      this.props.loadInitial({
        isPartner: this.props?.isPartner || false,
        configCatAzure: this.props.configCatAzure,
        hideAzure: this.props.hideAzure,
      });
    }
  };

  componentDidUpdate = prevProps => {
    const { filters, accountFilter } = this.props;

    if (!isEqual(filters, prevProps.filters)) {
      let cloudPlatformStateVal = {};
      if (filters?.operationDataSearch) {
        this.onOperationSearchChange(filters.operationDataSearch);
      }
      if (filters?.usageTypeDataSearch) {
        this.onUsageTypeSearchChange(filters.usageTypeDataSearch);
      }
      if ((filters?.cloudPlatformState || []).length === 0) {
        if ((filters?.accountState || []).length > 0) {
          const accID = filters?.accountState[0];
          const rec = (accountFilter || []).find(n => (n?.id || '').toString() === accID.toString());
          const cloud_platform = rec?.cloudPlatform;
          if (cloud_platform) {
            cloudPlatformStateVal = this.getUpdateStateValue(
              { ...keyToCloudObj(cloud_platform), checked: true },
              'cloudPlatformState',
            );
          }
        }

        if ((filters?.regionState_values || []).length > 0) {
          const cloud_platform = filters?.regionState_values[0].cloudPlatform;
          if (cloud_platform) {
            cloudPlatformStateVal = this.getUpdateStateValue(
              { ...keyToCloudObj(cloud_platform), checked: true },
              'cloudPlatformState',
            );
          }
        }

        this.setState(
          {
            ...filters,
            ...cloudPlatformStateVal,
            seFocusedInput: null,
            launchSFocusedInput: null,
            launchEFocusedInput: null,
          },
          () => {
            if (!isEmpty(cloudPlatformStateVal)) this.onUpdateRequest();
          },
        );
      } else {
        this.setState({
          ...filters,
          seFocusedInput: null,
          launchSFocusedInput: null,
          launchEFocusedInput: null,
        });
      }
    }
  };

  onUpdateRequest = () => {
    let newState = { ...this.state };
    newState = {
      ...newState,
      startDate: moment(newState.startDate).format('YYYY-MM-DD'),
      endDate: moment(newState.endDate).format('YYYY-MM-DD'),
    };
    if (this.props.updateFilter) this.props.updateFilter(newState);
  };

  getCloudPlatformStateValue = key => {
    const { configCatAzure = false } = this.props;
    if (!configCatAzure) return false;
    if (!this.state.cloudPlatformState.includes(key)) {
      return keyToCloudObj(key);
    }
    return false;
  };

  updateStateAndCloudType = (data, key) => {
    const { configCatAzure = false } = this.props;
    const stateData = { ...data };

    // keep region data in values, we need cloud type
    if (configCatAzure && key === 'regionState') {
      stateData.value = {
        id: data.id,
        value: data.value,
        cloudPlatform: data.cloudPlatform,
      };
    }
    const stateVal = this.getUpdateStateValue(stateData, key);

    // update cloud platform filter if resource with cloud platform value is selected
    if (configCatAzure && data.checked && data?.cloudPlatform && ['aws', 'azure'].includes(data?.cloudPlatform)) {
      const cloudObj = this.getCloudPlatformStateValue(data?.cloudPlatform);
      if (cloudObj) {
        const cloudStateVal = this.getUpdateStateValue(cloudObj, 'cloudPlatformState');
        this.setState({ ...stateVal, ...cloudStateVal }, () => this.onUpdateRequest());
        return;
      }
    }

    this.setState(stateVal, () => this.onUpdateRequest());
  };

  getUpdateStateValue = (data, key) => {
    const { configCatAzure = false } = this.props;
    // regions have a bit different structure
    const checkCloudType = configCatAzure && ['regionState', 'cloudPlatformState'].includes(key);

    const newState = cloneDeep(this.state[key]);
    const newStateValues = cloneDeep(this.state[`${key}_values`]) || [];
    const rec = newState.findIndex(r => r === data.id);
    const recValues = newStateValues.findIndex(r => (checkCloudType ? r.id === data.id : r === data.value));

    rec === -1 ? newState.push(data.id) : newState.splice(rec, 1);

    if (data.value) {
      const val = key === 'cloudPlatformState' ? data : data.value;
      recValues === -1 ? newStateValues.push(val) : newStateValues.splice(recValues, 1);
    }
    return data.value
      ? {
          [key]: newState,
          [`${key}_values`]: newStateValues,
        }
      : {
          [key]: newState,
        };
  };

  updateState = (data, key) => {
    const stateVal = this.getUpdateStateValue(data, key);
    this.setState(stateVal, () => this.onUpdateRequest());
  };

  onChangeAccount = data => this.updateStateAndCloudType(data, 'accountState');

  onChangeCloudPlatform = data => {
    if (data.checked) {
      this.updateState(data, 'cloudPlatformState');
    } else {
      // cleanup filter state when unselecting cloud type
      this.setState(
        {
          accountState: [],
          accountState_values: [],
          cloudPlatformState: [],
          cloudPlatformState_values: [],
          operationState: [],
          productState: [],
          regionState: [],
          regionState_values: [],
          vpcState: [],
          vpcState_values: [],
          userState: [],
          utState: [],
          opState: [],
          instanceTypeState: [],
          include_credit: false,
          cit: [],
          tagData: {},
        },
        () => {
          // apply changes to filter state
          this.onUpdateRequest();
          if (this.opS) this.props.loadOperationDataRequest('', this.props.configCatAzure);
          if (this.utS) this.props.loadUsageTypeDataRequest('', this.props.configCatAzure);
        },
      );
    }
  };

  onChangeCloudPlatformForMultiCurrency = data => {
    this.setState(
      {
        cloudPlatformState: [data.id],
        cloudPlatformState_value: data,
      },
      () => {
        this.onUpdateRequest();
      },
    );
  };

  onChangeRegion = data => this.updateStateAndCloudType(data, 'regionState');

  onChangeInstanceType = data => this.updateStateAndCloudType(data, 'instanceTypeState');

  onChangeVpc = data => this.updateStateAndCloudType(data, 'vpcState');

  onChangeProduct = data => this.updateStateAndCloudType(data, 'productState');

  onChangeOperation = data => this.updateStateAndCloudType(data, 'operationState');

  onChangeEvent = data => this.updateState(data, 'eventState');

  onChangeUser = data => this.updateState(data, 'userState');

  onResourceStatusChange = data => {
    this.setState(
      {
        resourceStatus: data.data.key,
      },
      () => this.onUpdateRequest(),
    );
  };

  onRURadioChange = data => {
    this.setState(
      {
        selectedResourceUtilization: data.data.key,
      },
      () => this.onUpdateRequest(),
    );
  };

  onRURadioChecked = data => {
    const temp = cloneDeep(this.state.isRUData);
    temp[0].checked = data.checked;
    this.setState(
      {
        isRUData: temp,
      },
      () => this.onUpdateRequest(),
    );
  };

  onAURadioChecked = data => {
    const temp = cloneDeep(this.state.isASData);
    temp[0].checked = data.checked;
    this.setState(
      {
        isASData: temp,
      },
      () => this.onUpdateRequest(),
    );
  };

  onCITChange = data => {
    data.cloudPlatform = 'aws';
    this.updateStateAndCloudType(data, 'cit');
  };

  onAutoScalingChange = data => this.updateStateAndCloudType(data, 'selectedAutoScaling');

  onOPChange = data => this.updateStateAndCloudType(data, 'opState');

  onUTChange = data => this.updateStateAndCloudType(data, 'utState');

  handleClearAll = () => {
    const { isCostControl = false, isMultipleCurrency = false } = this.props;
    const initialState = getInitialState(undefined);
    this.setState(
      {
        ...initialState,
        searchString: '',
        dslSearchString: '',
        startDate: this.props?.isType === 'CT' ? TWO_WEEKS_AGO : LAST_30_DAYS,
        endDate: YESTERDAY,
        cloudPlatformState: isMultipleCurrency && isCostControl ? ['aws'] : [],
        cloudPlatformState_values:
          isMultipleCurrency && isCostControl ? [{ id: 'aws', value: 'AWS', key: 'aws', cloudPlatform: 'aws' }] : [],
      },
      () => {
        this.props.clearFiltration();
        if (this.opS) this.props.loadOperationDataRequest('', this.props.configCatAzure);
        if (this.utS) this.props.loadUsageTypeDataRequest('', this.props.configCatAzure);
      },
    );
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.onUpdateRequest();
    }
  };

  handleChange = e => {
    this.setState({
      searchString: e.target.value,
    });
  };

  handleReset = () => {
    this.setState({ isClear: false });
  };

  onDateChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  onFilterButtonClick = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate }, () => {
      this.onUpdateRequest();
    });
  };

  onDateFocusChange = focusedInput => {
    this.setState({ seFocusedInput: focusedInput });
  };

  renderDatePresets = () => {
    const { startDate, endDate } = this.state;
    const data = dropDownList();
    const presetsData = [...presets, ...data];
    return (
      <div>
        {presetsData.map(({ text, start, end }) => {
          if (this.props?.isType === 'CT' && ['3M', '6M'].includes(text)) return null;
          const isSelected = isSameDay(start, startDate, text) && isSameDay(end, endDate, text);
          return (
            <button
              key={text}
              className={isSelected ? 'selected' : ''}
              type="button"
              onClick={() => {
                this.onFilterButtonClick({ startDate: start, endDate: end });
                this.onDateFocusChange(null);
              }}
            >
              {text}
            </button>
          );
        })}
        <div className="footer-button">
          <button
            className={cx('apply', {
              disabled: !startDate || !endDate,
            })}
            type="button"
            onClick={this.applyDateChange}
            disabled={!startDate || !endDate}
          >
            Apply
          </button>
          <button className="cancel" type="button" onClick={() => this.onDateFocusChange(null)}>
            Cancel
          </button>
        </div>
      </div>
    );
  };

  applyDateChange = () => {
    this.onDateFocusChange(null);
    this.onUpdateRequest();
  };

  onCreditChange = data => {
    this.setState(
      {
        include_credit: data.checked,
      },
      () => {
        if (data.checked) {
          const cloudObj = this.getCloudPlatformStateValue('aws');
          if (cloudObj) {
            const cloudStateVal = this.getUpdateStateValue(cloudObj, 'cloudPlatformState');
            this.setState(cloudStateVal, () => this.onUpdateRequest());
          } else {
            this.onUpdateRequest();
          }
        } else {
          this.onUpdateRequest();
        }
      },
    );
  };

  onLaunchStartChange = date => {
    this.setState({ launchStart: date }, () => {
      this.onUpdateRequest();
    });
  };

  onLaunchEndChange = date => {
    this.setState({ launchEnd: date }, () => {
      this.onUpdateRequest();
    });
  };

  onChangeTag = (data, cloudPlatform) => {
    this.setState(
      {
        tagData: data,
      },
      () => {
        const hasData = data?.must?.length || false || data?.must_not?.length || false;
        if (cloudPlatform && hasData) {
          const cloudObj = this.getCloudPlatformStateValue(cloudPlatform);
          if (cloudObj) {
            const cloudStateVal = this.getUpdateStateValue(cloudObj, 'cloudPlatformState');
            this.setState(cloudStateVal, () => this.onUpdateRequest());
          } else {
            this.onUpdateRequest();
          }
        } else {
          this.onUpdateRequest();
        }
      },
    );
  };

  onDateRangeChange = type => {
    this.setState(
      {
        eksDateRange: type,
      },
      () => {
        this.onUpdateRequest();
      },
    );
  };

  onOperationSearchChange = key => {
    const {
      operationData: { search: opS = '' },
    } = this.props;
    if (key !== opS) {
      this.opS = key;
      this.searchOperationRecord();
    }
  };

  onUsageTypeSearchChange = key => {
    const {
      usageTypeData: { search: utS = '' },
    } = this.props;
    if (key !== utS) {
      this.utS = key;
      this.searchUsageTypeRecord();
    }
  };

  querySearch = data => {
    const initialState = getInitialState(undefined);
    this.setState(
      {
        ...initialState,
        startDate: this.props?.isType === 'CT' ? TWO_WEEKS_AGO : LAST_30_DAYS,
        endDate: YESTERDAY,
        searchString: data.data.query,
        qSearch: data.data.key,
      },
      () => {
        // this.props.clearFiltration();
        if (this.opS) this.props.loadOperationDataRequest('', this.props.configCatAzure);
        if (this.utS) this.props.loadUsageTypeDataRequest('', this.props.configCatAzure);
        this.onUpdateRequest();
      },
    );
  };

  getFilterData = (filterData, summaryParam, filterState, type) => {
    const {
      defaultFilters = {},
      defaultFiltersCloud = {},
      isCost = [],
      dataFilters = {},
      dataFiltersCloud = {},
      loading = false,
      vpcNames,
      vpcNamesCloud,
      defaultRulesFilter = {},
      defaultRulesCloudFilter = {},
      configCatAzure = false,
      hideAzure = false,
      cloudPlatformFilter = [],
    } = this.props;

    if (
      loading ||
      (isEmpty(defaultFilters) &&
        isEmpty(defaultFiltersCloud) &&
        isEmpty(defaultRulesFilter) &&
        isEmpty(defaultRulesCloudFilter)) ||
      (isEmpty(dataFilters) && isEmpty(dataFiltersCloud))
    )
      return [];

    const checkCloudType = configCatAzure && ['region'].includes(type);
    const useCloudData = !configCatAzure ? false : !hideAzure;
    const _defaultFilters = useCloudData ? defaultFiltersCloud : defaultFilters;
    const _dataFilters = useCloudData ? dataFiltersCloud : dataFilters;
    const _vpcNames = useCloudData ? vpcNamesCloud : vpcNames;
    const _defaultRulesFilter = useCloudData ? defaultRulesCloudFilter : defaultRulesFilter;

    // filter out aws data if Azure is disabled
    const _filterData =
      type === 'account' && (hideAzure || !configCatAzure)
        ? (filterData || []).filter(data => data.cloudPlatform !== 'azure')
        : filterData;

    const showCost = isCost.includes(type);
    let param = summaryParam;
    if (param === 'violations_counts_per_project') {
      param = 'violations_counts_per_project_data';
    }
    // determine if new type of aggregation (by cloud platform)

    const allData = [
      'arp_summary_projects',
      'violations_counts_per_project',
      'projects',
      'ct_counters_projects',
    ].includes(summaryParam)
      ? _filterData
      : _defaultFilters?.[summaryParam]?.buckets
        ? _defaultFilters[summaryParam].buckets
        : _defaultRulesFilter?.[summaryParam]
          ? _defaultRulesFilter[summaryParam]
          : ['cloudPlatform'].includes(type)
            ? cloudPlatformFilter
            : [];

    const apiData = _dataFilters[summaryParam]?.buckets || [];

    let data = [];
    data = allData.map(row => {
      // filter by key and cloud type (for new aggregations)
      const rec = useCloudData
        ? apiData.find(
            n =>
              (n?.key || '').toString() === (row?.key || row?.id || '').toString() &&
              (!n?.cloudPlatform || n?.cloudPlatform === row?.cloudPlatform),
          )
        : apiData.find(n => (n?.key || '').toString() === (row?.key || row?.id || '').toString());

      const filterRow = _filterData
        ? _filterData.find(f => (f?.id || '').toString() === (row?.key || row?.id || '').toString())
        : undefined;
      let cost = 0,
        count = 0,
        bucket = [];
      if (rec) {
        if (['products', 'regions', 'projects'].includes(param)) {
          count = rec?.doc_count || 0;
        } else if (['ct_counters_projects', 'ct_counters_regions'].includes(param)) {
          count = rec?.ct_counters_data?.counts?.event_count.value || 0;
        } else if (['ct_counters_users', 'ct_counters_operation_types', 'ct_counters_event_names'].includes(param)) {
          count = rec?.event_count?.value || 0;
        } else {
          count = rec[param]?.violations_details?.violations_counts_sum?.value || 0;
        }
        bucket = rec[param]?.violations_details?.violation_type?.buckets || [];
        cost = rec?.arp_summary_tag_values_cost
          ? numberFix(rec.arp_summary_tag_values_cost.summary.summary.value)
          : showCost
            ? bucket.length
              ? bucket[0]?.cost_data?.total_current_month_cost?.total_current_month_cost?.summary?.value
              : 0
            : 0;
      }

      let name = '';
      if (summaryParam === 'violations_counts_per_vpc') {
        const vpcName = _vpcNames && _vpcNames.find(d => d.key === row.key);
        name = vpcName ? vpcName.name : row.key;
      } else {
        name = filterRow?.value || filterRow?.name || row?.key;
      }

      const checked = Boolean(
        checkCloudType
          ? filterState.find(d => d.id === row.key && d.cloudPlatform === (row.cloudPlatform || 'aws'))
          : filterState.includes((row?.key || row?.id || '').toString()),
      );

      // get doc_count for each cloud platform to disable it if no resources are found
      if (type === 'cloudPlatform') {
        const bucket = (_dataFilters?.cloud_data || []).find(d => d.key === row.cloudPlatform);
        count = bucket?.doc_count || 0;
      }

      const retData = {
        id: row.key || row.id,
        value: name,
        cost,
        count,
        checked,
        fixPermission:
          ['arp_summary_projects', 'violations_counts_per_project', 'projects', 'ct_counters_projects'].includes(
            summaryParam,
          ) && filterRow?.status === 3,
      };
      if (configCatAzure) {
        const cloudPlatform = row.cloudPlatform || row?.cloud_type?.hits?.hits[0]?._source?.cloud_type || 'aws';
        retData['cloudPlatform'] = cloudPlatform;
      }
      return retData;
    });
    data = orderBy(data, 'cost', 'asc');
    return data;
  };

  customRuleClick = () => {
    this.setState({ showSaveRuleModal: !this.state.showSaveRuleModal });
  };

  saveCustomRule = error => {
    this.handleCloseRuleModal();
    if (!error) {
      this.props.showToastAction({
        message: 'New custom rule added!',
        type: 'success',
        show: true,
      });
      this.props.loadCustomRulesRequest();
    } else {
      this.props.showToastAction({
        message: 'Failed to add new custom rule',
        type: 'error',
        show: true,
      });
    }
  };

  handleCloseRuleModal = () => {
    this.setState({ showSaveRuleModal: false });
  };

  render() {
    const {
      accountFilter = [],
      cloudPlatformFilter = [],
      configCatAzure = false,
      partnerProjects = [],
      type = '',
      allAutoScaling = [],
      cloudInformationTags = [],
      isShow = [],
      isShowForecast,
      filterProps = {},
      operationData: { data: op = [], search: opS = '', loading: opLoading = false },
      usageTypeData: { data: ut = [], search: utS = '', loading: utLoading = false },
      rulesData = [],
      hideAzure = false,
      instanceTypeData = [],
      isMultipleCurrency = false,
      isAWSSelected = true,
      azure_billing_currency = 'USD',
      isCostControl = false,
    } = this.props;

    const {
      searchString,
      isClear,
      startDate,
      endDate,
      accountState,
      regionState,
      regionState_values,
      productState,
      vpcState,
      cloudPlatformState,
      opState,
      utState,
      cit,
      include_credit,
      tagData,
      eksDateRange,
      selectedAutoScaling,
      launchEFocusedInput,
      launchSFocusedInput,
      seFocusedInput,
      launchEnd,
      launchStart,
      selectedResourceUtilization,
      isRUData,
      isASData,
      resourceStatus,
      qSearch,
      showSaveRuleModal,
      instanceTypeState,
    } = this.state; // currently applied filters

    const tab = [
      COST_TYPE.AWS_ACCOUNTS,
      COST_TYPE.AWS_PRODUCTS,
      COST_TYPE.REGIONS,
      COST_TYPE.RESOURCES,
      COST_TYPE.NON_RESOURCES,
      COST_TYPE.USAGE_TYPES,
      COST_TYPE.OPERATIONS,
    ];

    const cloudPlatformData = this.getFilterData(
      cloudPlatformFilter,
      'cloudPlatform',
      cloudPlatformState,
      'cloudPlatform',
    );
    const azurePlatformData = cloudPlatformData.find(d => d.id === 'azure');
    const awsPlatformData = cloudPlatformData.find(d => d.id === 'aws');

    const getDisableAwsValue = () => {
      if (!configCatAzure) return false;
      if (hideAzure) return false;
      if (!cloudPlatformState.includes('azure') && awsPlatformData && awsPlatformData?.count !== 0) return false;
      return true;
    };

    const disableAWSFilters = getDisableAwsValue();

    const disableAzureFilters = !!(
      cloudPlatformState.includes('aws') ||
      (azurePlatformData && azurePlatformData?.count === 0)
    );

    const disableAllFilters = qSearch >= 0;

    const checkDisabled = cloudType =>
      cloudType === 'azure' && disableAzureFilters ? 0 : cloudType === 'aws' && disableAWSFilters ? 0 : 1;

    const projectFilters =
      accountFilter?.length > 0 ? accountFilter : partnerProjects?.length > 0 ? partnerProjects : [];

    return (
      <>
        <div className="np-filtersHead title" data-testid="Filters">
          <span>Filters</span>
          <Anchor id="clearAll" title="Clear" onClick={this.handleClearAll}>
            Clear All
          </Anchor>
        </div>

        {isShow.includes('searchString') ? (
          <div className="np-filtersBlock">
            <div className="np-input-icon">
              <input
                id="searchString"
                type="text"
                name="searchString"
                value={searchString}
                placeholder="Search for anything .."
                className="np-input search-input-filter"
                onChange={this.handleChange}
                onKeyDown={this.handleKeyPress}
              />
              <SearchIcon />
            </div>
          </div>
        ) : null}

        {isShow.includes('mtdPicker') && (
          <div className="np-filtersBlock">
            <div className="np-filtersHead">Show Data For</div>

            <div className="np-filterTab">
              <button
                className={cx('np-button color-grey light', {
                  active: eksDateRange === '30d',
                  inactive: eksDateRange !== '30d',
                })}
                onClick={() => this.onDateRangeChange('30d')}
              >
                Last 30 Days
              </button>
              <button
                className={cx('np-button color-grey light', {
                  active: eksDateRange === 'mtd',
                  inactive: eksDateRange !== 'mtd',
                })}
                onClick={() => this.onDateRangeChange('mtd')}
              >
                Month to Date
              </button>
            </div>
          </div>
        )}

        {isShow.includes('searchString') && rulesData?.length ? (
          <div className="np-filtersBlock np-filterCQuery">
            <RadioFilter
              onChange={this.querySearch}
              data={orderBy(rulesData, 'id', 'desc').map(r => ({
                value: r.name,
                key: r.id,
                type: 'qSearch',
                query: r.query,
                extraValue: (
                  <Tippy content={`Query: ${r.query}`} placement="right">
                    <i className="np-svgIcon">
                      <InfoIcon />
                    </i>
                  </Tippy>
                ),
              }))}
              title={
                <>
                  Custom Rules
                  <span
                    id="add-custom-rule"
                    onClick={this.customRuleClick}
                    className="button"
                    title="Create new custom rule"
                  >
                    <PlusIcon />
                  </span>
                </>
              }
              placeHolder="Custom rules"
              type="cr"
              isClear={isClear}
              checkedData={qSearch}
              isOpen={true}
            />
          </div>
        ) : null}

        {isShow.includes('startEndPicker') && type !== COST_TYPE.CM && !isShowForecast ? (
          <div className="np-filtersBlock">
            <div className="np-input-icon">
              <CalendarIcon />
              <DateRangePicker
                {...datePickerProps}
                small={true}
                renderCalendarInfo={this.renderDatePresets}
                startDate={moment(startDate)}
                endDate={moment(endDate)}
                onDatesChange={this.onDateChange}
                startDateId="unique_1"
                endDateId="unique_2"
                focusedInput={seFocusedInput}
                onFocusChange={this.onDateFocusChange}
                keepOpenOnDateSelect={true}
                disabled={disableAllFilters}
                isOutsideRange={day => {
                  return (
                    isInclusivelyAfterDay(
                      moment().utc().startOf('month').subtract(6, 'month').subtract(1, 'day'),
                      day,
                    ) || isInclusivelyBeforeDay(moment().utc(), day)
                  );
                }}
              />
            </div>
          </div>
        ) : null}

        {isShow.includes('account') && !hideAzure && configCatAzure ? (
          !isCostControl || (isCostControl && !isMultipleCurrency) ? (
            <SelectFilter
              onChange={this.onChangeCloudPlatform}
              data={this.getFilterData(cloudPlatformFilter, 'cloudPlatform', cloudPlatformState, 'cloudPlatform')}
              configCatAzure={configCatAzure}
              title="Cloud platform"
              type="cloudPlatform"
              noCount={true}
              handleReset={this.handleReset}
              isCost={false}
              disabled={disableAllFilters}
            />
          ) : (
            <RadioFilter
              onChange={({ data }) => {
                this.onChangeCloudPlatformForMultiCurrency(data);
              }}
              data={[
                {
                  value: 'aws',
                  key: 'aws',
                  id: 'aws',
                  type: 'cloudPlatform',
                },
                {
                  value: 'azure',
                  id: 'azure',
                  key: 'azure',
                  type: 'azure',
                },
              ]}
              title="Cloud platform"
              type="cloudPlatform"
              isClear={isClear}
              checkedData={cloudPlatformState[0]}
              isOpen={true}
              isSearch={false}
            />
          )
        ) : null}

        {isShow.includes('include_credit') && tab.includes(type) ? (
          <SelectFilter
            onChange={this.onCreditChange}
            data={[
              {
                id: 'include_credit',
                value: 'Exclude AWS Credits',
                checked: include_credit,
                cloudPlatform: 'aws',
              },
            ]}
            title="AWS Credits"
            type="aws_credits"
            isOpen={true}
            isClear={isClear}
            isCost={false}
            showCost={false}
            handleReset={this.handleReset}
            disabled={disableAWSFilters || disableAllFilters}
            configCatAzure={configCatAzure}
          />
        ) : null}

        {isShow.includes('launchStartEnd') && type !== COST_TYPE.CM && !isShowForecast ? (
          <>
            <div className="np-filtersBlock">
              <div className="np-filtersHead">Resources launched after</div>
              <div className="np-input-icon">
                <CalendarIcon />
                <SingleDatePicker
                  {...singlePickerProps}
                  date={convertStringDateToMoment(launchStart, undefined)}
                  onDateChange={this.onLaunchStartChange}
                  focused={launchSFocusedInput}
                  placeholder="Resources launched after"
                  onFocusChange={({ focused }) => this.setState({ launchSFocusedInput: focused })}
                  id="launchStart"
                  disabled={disableAllFilters}
                  isOutsideRange={day => {
                    return (
                      isInclusivelyAfterDay(
                        moment().startOf('month').subtract(6, 'month').subtract(1, 'day').utc(),
                        day,
                      ) || isInclusivelyBeforeDay(moment().utc(), day)
                    );
                  }}
                />
              </div>
            </div>
            <div className="np-filtersBlock">
              <div className="np-filtersHead">Resources launched before</div>
              <div className="np-input-icon">
                <CalendarIcon />
                <SingleDatePicker
                  {...singlePickerProps}
                  date={convertStringDateToMoment(launchEnd, undefined)}
                  onDateChange={this.onLaunchEndChange}
                  focused={launchEFocusedInput}
                  placeholder="Resources launched before"
                  onFocusChange={({ focused }) => this.setState({ launchEFocusedInput: focused })}
                  id="launchEnd"
                  disabled={disableAllFilters}
                  isOutsideRange={day => {
                    return (
                      isInclusivelyAfterDay(
                        moment().startOf('month').subtract(6, 'month').subtract(1, 'day').utc(),
                        day,
                      ) || isInclusivelyBeforeDay(moment().utc(), day)
                    );
                  }}
                />
              </div>
            </div>
          </>
        ) : null}

        {isShow.includes('account') ? (
          <SelectFilter
            onChange={this.onChangeAccount}
            data={this.getFilterData(projectFilters, filterProps['account']['paramKey'], accountState, 'account')}
            title="Cloud Account"
            type="account"
            isClear={isClear}
            isCost={filterProps['account']['isCost']}
            handleReset={this.handleReset}
            allowPermission={true}
            isSensitiveInfo={true}
            configCatAzure={configCatAzure}
            disabled={disableAllFilters}
            isCostControl={isCostControl}
            billing_currency={isAWSSelected ? 'USD' : azure_billing_currency}
            isMultipleCurrency={isMultipleCurrency}
          />
        ) : null}

        {isShow.includes('ru') ? (
          <RadioFilter
            onChange={this.onRURadioChange}
            data={utilizationOption}
            title="Resource utilization"
            type="ru"
            isClear={isClear}
            checkedData={selectedResourceUtilization}
            isOpen={true}
            disabled={disableAllFilters}
          />
        ) : null}

        {isShow.includes('ri') ? (
          <SelectFilter
            onChange={this.onRURadioChecked}
            data={isRUData}
            title="RESOURCE IS A"
            type="ri"
            isClear={isClear}
            handleReset={this.handleReset}
            isOpen={true}
            isSearch={false}
            isCost={false}
            showCost={false}
            disabled={disableAllFilters}
          />
        ) : null}

        {isShow.includes('as') ? (
          <SelectFilter
            onChange={this.onAURadioChecked}
            data={isASData}
            title="RESOURCE IS"
            type="as"
            isClear={isClear}
            handleReset={this.handleReset}
            isOpen={true}
            isSearch={false}
            isCost={false}
            showCost={false}
            disabled={disableAllFilters}
          />
        ) : null}
        {isShow.includes('cloudformation') && cloudInformationTags.length ? (
          <SelectFilter
            onChange={this.onCITChange}
            data={cloudInformationTags.map(m => ({
              id: m.key,
              value: m.key,
              checked: cit.includes(m.key),
              count: m?.doc_count || 0,
              cloudPlatform: 'aws',
            }))}
            title="cloudformation:stack-name"
            type="cloudformation"
            isClear={isClear}
            isOpen={true}
            showCost={false}
            handleReset={this.handleReset}
            disabled={disableAWSFilters || disableAllFilters}
            configCatAzure={configCatAzure}
            hideAzure={this.props.hideAzure}
          />
        ) : null}

        {isShow.includes('resource') && type !== 'Tags' ? (
          <TagFiltersComponent
            {...this.props}
            applyFilter={this.onChangeTag}
            tagData={tagData || {}}
            configCatAzure={configCatAzure}
            disableAWSFilters={disableAWSFilters}
            disableAzureFilters={disableAzureFilters}
            hideAzure={this.props.hideAzure}
            disabled={disableAllFilters}
          />
        ) : null}

        {isShow.includes('autoScaling') ? (
          <SelectFilter
            onChange={this.onAutoScalingChange}
            data={allAutoScaling.map(m => ({
              id: m.key,
              value: m.key,
              checked: selectedAutoScaling.includes(m.key),
              count: m?.doc_count || 0,
              cloudPlatform: m?.cloudPlatform,
            }))}
            title="Autoscaling group"
            type="Autoscaling"
            isClear={isClear}
            isOpen={true}
            showCost={false}
            handleReset={this.handleReset}
            configCatAzure={configCatAzure}
            hideAzure={this.props.hideAzure}
            disabled={disableAWSFilters || disableAllFilters}
          />
        ) : null}

        {isShow.includes('region') ? (
          <SelectFilter
            onChange={this.onChangeRegion}
            data={this.getFilterData(
              null,
              filterProps['region']['paramKey'],
              configCatAzure ? regionState_values : regionState,
              'region',
            )}
            title="Regions"
            type="region"
            isClear={isClear}
            isCost={filterProps['region']['isCost']}
            handleReset={this.handleReset}
            configCatAzure={configCatAzure}
            disabled={disableAllFilters}
            isCostControl={isCostControl}
            billing_currency={isAWSSelected ? 'USD' : azure_billing_currency}
            isMultipleCurrency={isMultipleCurrency}
          />
        ) : null}

        {isShow.includes('instanceType') ? (
          <SelectFilter
            onChange={this.onChangeInstanceType}
            data={instanceTypeData.map(m => ({
              ...m,
              checked: instanceTypeState.includes(m.id),
            }))}
            title="Instance Type"
            type="instanceType"
            isClear={isClear}
            isCost={filterProps['region']['isCost']}
            handleReset={this.handleReset}
            configCatAzure={configCatAzure}
            disabled={disableAllFilters}
            isCostControl={isCostControl}
            billing_currency={isAWSSelected ? 'USD' : azure_billing_currency}
            isMultipleCurrency={isMultipleCurrency}
          />
        ) : null}

        {isShow.includes('product') ? (
          <SelectFilter
            onChange={this.onChangeProduct}
            isCost={filterProps['product']['isCost']}
            data={this.getFilterData(null, filterProps['product']['paramKey'], productState, 'product')}
            title="Cloud Managed Services"
            type="product"
            isClear={isClear}
            handleReset={this.handleReset}
            configCatAzure={configCatAzure}
            disabled={disableAllFilters}
            isCostControl={isCostControl}
            billing_currency={isAWSSelected ? 'USD' : azure_billing_currency}
            isMultipleCurrency={isMultipleCurrency}
          />
        ) : null}

        {isShow.includes('op') ? (
          <SelectFilter
            onChange={this.onOPChange}
            data={op.map(m => ({
              id: configCatAzure ? m.ac_result : m,
              value: configCatAzure ? m.ac_result : m,
              cloudPlatform: configCatAzure ? m?.cloud_type || 'aws' : 'aws',
              checked: configCatAzure ? opState.includes(m.ac_result) : opState.includes(m),
              count: configCatAzure ? checkDisabled(m?.cloud_type || 'aws') : 1,
            }))}
            title="Operations"
            type="Operations"
            isClear={isClear}
            isOpen={true}
            showCost={false}
            handleReset={this.handleReset}
            SearchFunction={this.onOperationSearchChange}
            searchKeyWord={opS}
            loading={opLoading}
            configCatAzure={configCatAzure}
            disabled={disableAllFilters}
          />
        ) : null}

        {isShow.includes('ut') ? (
          <SelectFilter
            onChange={this.onUTChange}
            data={ut.map(m => ({
              id: configCatAzure ? m.ac_result : m,
              value: configCatAzure ? m.ac_result : m,
              cloudPlatform: configCatAzure ? m?.cloud_type || 'aws' : 'aws',
              checked: configCatAzure ? utState.includes(m.ac_result) : utState.includes(m),
              count: configCatAzure ? checkDisabled(m?.cloud_type || 'aws') : 1,
            }))}
            title="Usage Type"
            type="Usage Type"
            isClear={isClear}
            isOpen={true}
            showCost={false}
            handleReset={this.handleReset}
            SearchFunction={this.onUsageTypeSearchChange}
            searchKeyWord={utS}
            loading={utLoading}
            configCatAzure={configCatAzure}
            disabled={disableAllFilters}
          />
        ) : null}

        {isShow.includes('vpc') ? (
          <SelectFilter
            onChange={this.onChangeVpc}
            isCost={filterProps['vpc']['isCost']}
            data={this.getFilterData(null, filterProps['vpc']['paramKey'], vpcState, 'VPC')}
            title="VPC"
            type="vpc"
            isClear={isClear}
            handleReset={this.handleReset}
            configCatAzure={configCatAzure}
            disabled={disableAllFilters}
            isCostControl={isCostControl}
            billing_currency={isAWSSelected ? 'USD' : azure_billing_currency}
            isMultipleCurrency={isMultipleCurrency}
          />
        ) : null}

        {isShow.includes('rs') ? (
          <RadioFilter
            onChange={this.onResourceStatusChange}
            data={resourceOption}
            title="Resource Status"
            type="rs"
            isClear={isClear}
            checkedData={resourceStatus}
            isOpen={true}
            disabled={disableAllFilters}
          />
        ) : null}

        {showSaveRuleModal ? (
          <SaveRule
            data={{}}
            handleCloseRuleModal={this.handleCloseRuleModal}
            saveNewCustomRule={this.saveCustomRule}
            client={this.props?.userData?.client || 0}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  accountFilter: makeAccountFilter(),
  partnerProjects: makeSelectPartnerProjects(),
  cloudInformationTags: makeCloudInformationTagsData(),
  allAutoScaling: makeAutoScalingData(),
  tagResources: makeTagResourcesFilter(),
  loadedAWSOnlyData: makeSelectGlobalAWSLoaded(),
  loadedCloudData: makeSelectGlobalCloudLoaded(),
  tagResourcesCloud: makeTagResourcesCloudFilter(),
  vpcNames: makeVPCNames(),
  cloudPlatformFilter: makeSelectGlobalCloudPlatform(),
  vpcNamesCloud: makeVPCCloudNames(),
  userData: makeUserData(),
  operationData: makeSelectOperationData(),
  usageTypeData: makeSelectUsageTypeData(),
  rulesData: makeSelectRulesData(),
  defaultRulesFilter: makeSelectRuleFilter(),
  defaultRulesCloudFilter: makeSelectRuleCloudFilter(),
  rulesDataLoading: makeRulesDataLoading(),
  filterLoading: makeSelectGlobalFilterLoading(),
  filterAPICalled: makeSelectGlobalFilterAPICalled(),
  globalFilter: selectFilter,
  azure_billing_currency: makeAzureBillingCurrency(),
  isMultipleCurrency: makeIsMultipleCurrency(),
});

const withConnect = connect(mapStateToProps, {
  loadOperationDataRequest,
  loadUsageTypeDataRequest,
  loadCustomRulesRequest,
  showToastAction,
  loadInitial,
});

const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });

const withFilterReducer = injectReducer({
  key: 'globalFilter',
  reducer: filterReducer,
});

const withARPReducer = injectReducer({ key: 'COST', reducer: arpReducer });
const withARPSaga = injectSaga({ key: 'COST', saga: arpSaga });

const withPartnerReducer = injectReducer({
  key: 'partner',
  reducer: partnerReducer,
});
const withPartnerSaga = injectSaga({ key: 'partner', saga: partnerSaga });

const withRulesReducer = injectReducer({
  key: 'rulesData',
  reducer: rulesReducer,
});
const withRulesSaga = injectSaga({ key: 'rulesData', saga: rulesSaga });

export default compose(
  withReducer,
  withSaga,
  withARPReducer,
  withARPSaga,
  withRulesReducer,
  withRulesSaga,
  withFilterReducer,
  withPartnerReducer,
  withPartnerSaga,
  withConnect,
)(Filters);
