import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';

import { checkIsEqual, IsJsonValid, IsURIValid, setDefaultFilterData, getClassNameBasedOnCloud } from 'utils/helper';
import injectReducer from 'utils/injectReducer';

import { getFilterParams, getFilterQuery, YESTERDAY } from 'utils/filter';
import { getParamFromQueryString, removeQueryString } from 'utils/queryString';
import { ROUTES_PATH, COST_TYPE } from 'utils/constants';
import { getNewQueryString } from 'utils/queryString';
import { FILTER_DATE_FORMAT, FILTER_META_DATA } from 'utils/filter';
import injectSaga from 'utils/injectSaga';

import { DropDownArrowIcon, CMRuleIcon } from 'assets/images/nOpsRule';
import { CheckedIcon, SmallWarningIcon } from 'assets/images/common';
import { GraterThenArrowIcon, BottomArrowIcon, SettingNotificationIcon } from 'assets/images';
import NoDetectionIcon from 'assets/images/no-detection.svg';

import saga from '../saga';
import reducer from '../reducer';
import { makeSelectCMData, makeSelectCMLoading } from '../selectors';
import { loadCostCMRequest } from '../actions';
import { getTextToShow, setInitialDailyData, setInitialMonthlyData, setInitialWeeklyData } from './helper';
import SimpleDropdown from 'components/Dropdown/SimpleDropdown';
import Loader from 'components/LoadingIndicator/Loader';
import { Anchor } from 'components/Custom';
import { makeGlobalLoading } from 'containers/App/selectors';
import { makeUserData } from 'containers/App/selectors';
import globalReducer from 'containers/App/reducer';
import CompareCost from './compareCost';

const STORE_GLOBAL_KEY = 'global';
const STORE_COST_KEY = 'COST';
const DEFAULT_QUERY = {
  q: '*',
  scope: 'client',
  show_active: null,
};

let loader = false;
const PAGES = [10, 25, 50, 75, 100];
const DEFAULT_ROWS = 10;
const dates = {
  daily_start_date: moment().utc().subtract(30, 'days').format(FILTER_DATE_FORMAT),
  weekly_start_date: moment().utc().subtract(2, 'months').startOf('months').format().split('T')[0],
  monthly_start_date: moment().utc().subtract(5, 'months').startOf('months').format().split('T')[0],
  mtd_start_date: moment().utc().subtract(2, 'months').format().split('T')[0],
};
class ChangeManagement extends React.Component {
  constructor(props) {
    super(props);
    const selectedTab = getParamFromQueryString(props.location.search, 'cmTab') || 'Daily';
    this.state = {
      dataToDisplay: [],
      collapse: [],
      selectedTab,
      showCompareCost: false,
      data: {},
      sortingOrder: 'desc',
      sortingField: 'costDiff',
      propFilters: {},
    };
  }

  componentDidMount() {
    const {
      loading,
      data: { weekly = {}, monthly = {}, daily = {} } = {},
      loadCostCMRequest,
      location,
      isMultipleCurrency,
      isAWSSelected,
    } = this.props;
    let filters = {
      cost_to: YESTERDAY.format(FILTER_DATE_FORMAT),
    };
    let data = getParamFromQueryString(location.search, 'data') || '';
    data = IsJsonValid(IsURIValid(data));
    if (!isEmpty(data)) {
      const { payload } = data;
      const searchString = getFilterQuery(payload) || '*';
      const filter = getFilterParams(payload) || {};
      const filterParam = setDefaultFilterData({
        filters: filter,
        tagData: payload.tagData,
      }).filters;
      if (!isEmpty(filterParam)) {
        filters = {
          ...filters,
          filters: { ...filterParam },
          q: searchString,
        };
      }
    } else if (isMultipleCurrency) {
      filters = {
        ...filters,
        filters: {
          must: [{ 'cloud_type.keyword': [isAWSSelected ? 'aws' : 'azure'] }],
          must_not: [],
          _multiple_conditions: true,
        },
      };
    }
    this.setState(
      {
        propFilters: filters,
      },
      () => {
        const temp = { Weekly: weekly, Monthly: monthly, Daily: daily };
        if (
          !loading &&
          (checkIsEqual(false, filters, temp[this.state.selectedTab]?.filters || {}) ||
            isEmpty(temp[this.state.selectedTab]))
        ) {
          loader = true;
          loadCostCMRequest({
            param: {
              parameters: {
                ...DEFAULT_QUERY,
                ...this.state.propFilters,
              },
            },
            dates,
            tab: this.state.selectedTab,
            filters: this.state.propFilters,
            configCatAzure: this.props.configCatAzure,
          });
        } else {
          loader = true;
          this.handleInitialData();
        }
      },
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      userData: { projects = [] },
      data: { weekly = {}, monthly = {}, daily = {} } = {},
    } = this.props;
    loader = false;

    if (
      checkIsEqual(false, weekly, prevProps.data.weekly) ||
      checkIsEqual(false, monthly, prevProps.data.monthly) ||
      checkIsEqual(false, daily, prevProps.data.daily) ||
      (projects.length && checkIsEqual(false, projects, prevProps.userData.projects))
    ) {
      this.handleInitialData();
    }
    if (
      checkIsEqual(true, weekly, {} && checkIsEqual(false, prevProps.data.weekly, {})) &&
      checkIsEqual(true, monthly, {} && checkIsEqual(false, prevProps.data.monthly, {})) &&
      checkIsEqual(true, daily, {} && checkIsEqual(false, prevProps.data.daily, {}))
    ) {
      this.setState({
        dataToDisplay: [],
        collapse: [],
      });
    }
  }

  resetPages = () => {
    const temp = cloneDeep(this.state.dataToDisplay);
    temp.forEach((x, index) => {
      temp[index].page = 1;
    });
    this.setState({
      dataToDisplay: temp,
    });
  };

  handleSortingOrder = value => {
    const order = value === 'all' ? 'all' : value.split('_')[1];
    const field = value === 'all' ? 'all' : value.split('_')[0];
    this.setState(
      {
        sortingField: field,
        sortingOrder: order,
      },
      () => {
        this.resetPages();
      },
    );
  };

  toggleCompareCost = data => () => {
    this.setState({
      showCompareCost: !this.state.showCompareCost,
      data: {
        ...data,
        filters: this.state.propFilters.filters,
        searchString: this.state.propFilters.searchString,
      },
    });
  };

  closeCompareCost = () => {
    this.setState({ showCompareCost: false, data: {} });
  };

  handleNoData = () => {
    loader = false;
    this.setState({
      dataToDisplay: [],
      collapse: [],
    });
  };

  handleInitialData = () => {
    const {
      data = {},
      userData: { projects = [] },
    } = this.props;
    const { weekly = {}, monthly = {}, daily = {} } = data;
    const { selectedTab } = this.state;
    let date = '';
    loader = true;
    if (!projects.length) {
      loader = false;
      return null;
    }
    let newData = {};
    const endDate = moment().utc().subtract(1, 'day');
    switch (selectedTab) {
      case 'Daily':
        if (isEmpty(daily)) {
          this.handleNoData();
          loader = false;
          return null;
        }
        date = dates.daily_start_date;
        newData = setInitialDailyData(projects, daily, date, endDate);
        break;
      case 'Weekly':
        if (isEmpty(weekly)) {
          this.handleNoData();
          loader = false;
          return null;
        }
        date = dates.weekly_start_date;
        newData = setInitialWeeklyData(projects, weekly, endDate);
        break;
      case 'Monthly':
        if (isEmpty(monthly?.monthly) && isEmpty(monthly?.mtd)) {
          this.handleNoData();
          loader = false;
          return null;
        }
        date = dates.monthly_start_date;
        newData = setInitialMonthlyData(projects, monthly, date, endDate);
        break;
      default:
        break;
    }
    loader = false;
    this.setState(
      {
        dataToDisplay: newData.filterDates || [],
        collapse: newData.collapse || [],
      },
      () => {
        this.resetPages();
      },
    );
  };

  handleViewMore = date => () => {
    const { collapse } = this.state;
    const test = cloneDeep(collapse);
    const cIndex = (collapse || []).findIndex(x => x === date);
    if (cIndex > -1) {
      test.splice(cIndex, 1);
    } else {
      test.push(date);
    }
    this.setState({
      collapse: [...test],
    });
  };

  handlePageChange = (selected, key) => {
    const data = cloneDeep(this.state.dataToDisplay);
    const rec = (data || []).findIndex(x => x.key === key);
    if (rec < 0) return null;
    data[rec].page = selected.selected + 1;
    this.setState({ dataToDisplay: data });
  };

  handleRecordChange = key => e => {
    const data = cloneDeep(this.state.dataToDisplay);
    const rec = (data || []).findIndex(x => x.key === key);
    if (rec < 0) return null;
    data[rec].records = Number(e.target.value);
    data[rec].page = 1;
    this.setState({ dataToDisplay: data });
  };

  onChangeTab = key => () => {
    const { data } = this.props;
    const { propFilters } = this.state;
    const { weekly = {}, monthly = {}, daily = {} } = data;
    this.setState(
      {
        selectedTab: key,
      },
      () => {
        const search = removeQueryString(this.props.location.search, 'cmTab');
        this.props.history.push({
          pathname: ROUTES_PATH.RESOURCES,
          search: `${search}&cmTab=${key}`,
        });
        let flag = false;
        switch (key) {
          case 'Daily':
            if (isEmpty(daily) || checkIsEqual(false, propFilters, daily.filters || {})) flag = true;
            break;
          case 'Monthly':
            if (isEmpty(monthly) || checkIsEqual(false, propFilters, monthly.filters || {})) flag = true;
            break;
          case 'Weekly':
            if (isEmpty(weekly) || checkIsEqual(false, propFilters, weekly.filters || {})) flag = true;
            break;
          default:
            break;
        }
        if (flag) {
          this.props.loadCostCMRequest({
            param: {
              parameters: {
                ...DEFAULT_QUERY,
                ...propFilters,
                cloud_type: this.props.configCatAzure ? 'all' : 'aws',
              },
            },
            dates,
            tab: key,
            filters: propFilters,
            configCatAzure: this.props.configCatAzure,
          });
        } else {
          this.handleInitialData();
        }
      },
    );
  };

  renderTab = () => {
    const { selectedTab } = this.state;
    const tabs = ['Daily', 'Weekly', 'Monthly'];

    return (
      <div className={'np-tableFiltersHead'}>
        <ul className="np-tableTabs">
          {(tabs || []).map((x, index) => {
            return (
              <li key={index} onClick={this.onChangeTab(x)}>
                <Anchor
                  className={classNames('np-button color-grey light', {
                    active: selectedTab === x,
                  })}
                >
                  {x}
                </Anchor>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  onSeeDetailClick = tabData => () => {
    const { configCatAzure } = this.props;
    let search = removeQueryString(this.props.location.search, 'cmTab') || '';
    const data = getParamFromQueryString(search, 'data');
    let rec = {};
    const newData = IsJsonValid(IsURIValid(data));
    if (!isEmpty(newData)) {
      rec = newData.payload;
    }
    const { id, type, name, cloudPlatform } = tabData;
    const addValuesToState =
      configCatAzure && ['regionState', 'accountState', 'cloudPlatformState'].includes(FILTER_META_DATA[type]);
    const isValueObject = ['regionState', 'cloudPlatformState'].includes(FILTER_META_DATA[type]);
    const value = addValuesToState && isValueObject ? { id: id, value: name, cloudPlatform: cloudPlatform } : name;

    const filterData =
      isEmpty(rec) || !rec[FILTER_META_DATA[type]]
        ? [id.toString()]
        : [...(rec[FILTER_META_DATA[type]] || []), id.toString()];
    const filterDataValues =
      isEmpty(rec) || !rec[`${FILTER_META_DATA[type]}_values`]
        ? [value]
        : [...(rec[`${FILTER_META_DATA[type]}_values`] || []), value];

    const existingFilter = (rec[FILTER_META_DATA[type]] || []).includes(id.toString());

    // don't add to filter state if value is already there
    if (!existingFilter) {
      rec = {
        ...rec,
        [FILTER_META_DATA[type]]: filterData,
      };
      if (addValuesToState) {
        rec = {
          ...rec,
          [`${FILTER_META_DATA[type]}_values`]: filterDataValues,
        };
      }
    }

    // if row has cloud platform, select that cloud platform in filter
    if (configCatAzure && cloudPlatform) {
      const cloudName = cloudPlatform === 'azure' ? 'Azure' : 'AWS';
      rec = {
        ...rec,
        cloudPlatformState: [cloudPlatform],
        cloudPlatformState_values: [
          {
            id: cloudPlatform,
            value: cloudName,
            cloudPlatform: cloudPlatform,
          },
        ],
      };
    }
    const state = {
      ...rec,
      startDate: tabData.startDate_props,
      endDate: tabData.endDate_props,
    };
    const stateString = JSON.stringify({ payload: { ...state } });
    const newEncodedURI = encodeURIComponent(stateString);
    search = getNewQueryString(search, { tab: type });
    search = removeQueryString(search, 'data');
    search = `${search}&data=${newEncodedURI}`;
    this.props.history.push({
      pathname: ROUTES_PATH.RESOURCES,
      search: search,
    });
  };

  getFilterData = data => {
    const { sortingField, sortingOrder } = this.state;
    if (sortingOrder === 'all') {
      return data || [];
    } else if (sortingField === 'costDiff') {
      const newData = (data || []).filter(x => (sortingOrder === 'asc' ? x.costDiff < 0 : x.costDiff > 0));
      return orderBy(newData, ['costDiff'], sortingOrder === 'asc' ? 'asc' : 'desc');
    } else if (sortingField === 'percent') {
      const newData = (data || []).filter(x => (sortingOrder === 'asc' ? x.percent < 0 : x.percent > 0));
      return orderBy(newData, ['percent'], 'desc');
    }
  };

  getData = () => {
    const dataToDisplay = cloneDeep(this.state.dataToDisplay);
    return dataToDisplay.map((data, index) => {
      const collapseClass = (this.state.collapse.length > 0 && this.state.collapse.includes(data.key)) || '';
      const indexOfLastData = data.page * data.records;
      const indexOfFirstData = indexOfLastData - data.records;
      const filterData = this.getFilterData(data.data) || [];
      const totalPage = Math.ceil(filterData.length / data.records);
      const currentData = filterData.slice(indexOfFirstData, indexOfLastData);
      const collapseClassName = classNames('np-paginationT bottom', {
        collapse: !collapseClass,
      });
      return {
        ...data,
        filterData,
        totalPage,
        currentData,
        collapseClassName,
        collapseClass,
      };
    });
  };

  renderNoData = rec => {
    return (
      <div className="noData">
        <div className="icon">
          <img src={NoDetectionIcon} alt={''} />
        </div>
        <p>
          {!rec
            ? 'No changes detected to show'
            : 'All good! No billing anomalies found in your Cloud accounts with selected filters.'}
        </p>
      </div>
    );
  };

  render() {
    const { dataToDisplay, showCompareCost, data, sortingField, sortingOrder, selectedTab } = this.state;

    const { loading, parentLoading, configCatAzure, azure_billing_currency, isMultipleCurrency, isAWSSelected } =
      this.props;

    const cl = classNames({
      'npxs-12 arpCM': true,
      'np-nops-loading': !parentLoading && (loading || loader),
    });
    const orderType = [
      { value: 'all', name: 'All' },
      { value: 'costDiff_asc', name: 'Cost Decrease' },
      { value: 'costDiff_desc', name: 'Cost Increase' },
      { value: 'percent_asc', name: 'Percent Decrease' },
      { value: 'percent_desc', name: 'Percent Increase' },
    ];
    const rec =
      sortingOrder === 'all'
        ? orderType[0]
        : (orderType || []).find(x => x.value === sortingField + '_' + sortingOrder);
    const display_data = this.getData();
    const isFilterData = display_data.filter(dataByDate => dataByDate.filterData.length);

    return (
      <>
        <div className={cl}>
          <Loader />
          <div className="np-table2">
            <div className="np-table2Actions">
              {dataToDisplay.length > 0 && (
                <SimpleDropdown
                  className="np-pageHead-actions"
                  list={orderType}
                  title={`Sort by: ${rec.name}`}
                  onItemClick={this.handleSortingOrder}
                />
              )}
              <Anchor
                id={'subscribe'}
                className="np-button blue light"
                onClick={() => this.props.history.push('/v3/settings?tab=Notifications%20Center')}
              >
                <i className="np-svgIcon">
                  <SettingNotificationIcon />
                  &nbsp;
                </i>
                Subscribe
              </Anchor>
            </div>

            <div className="title">
              <div className="np-titleIcon blue">
                <CMRuleIcon />
              </div>
              &nbsp;Change Management
            </div>

            <div className="np-table2-wrapper">
              {this.renderTab()}
              {!dataToDisplay.length || !isFilterData?.length ? (
                this.renderNoData(!isFilterData.length ? rec : undefined)
              ) : (
                <Fragment>
                  {display_data.map((data, index) => {
                    if (!data.filterData.length) return null;
                    return (
                      <Fragment key={index}>
                        <div className={`date ${!data.collapseClass ? 'collapse' : ''}`}>
                          {data.date || moment(data.key).format('MMM, YYYY')}
                          <Tippy content={data.collapseClass ? 'Collapse' : 'Expand'} placement={'left'}>
                            <button
                              id={'collapse'}
                              type="button"
                              className="np-button grey"
                              onClick={this.handleViewMore(data.key)}
                            >
                              {!data.collapseClass ? <GraterThenArrowIcon /> : <BottomArrowIcon />}
                            </button>
                          </Tippy>
                        </div>
                        {data.currentData.length
                          ? data.collapseClass && (
                              <table className={!data.collapseClass ? 'collapse' : ''}>
                                <tbody>
                                  {(data.currentData || []).map((ruleData, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div
                                          className={`data detection ${getClassNameBasedOnCloud(
                                            configCatAzure,
                                            ruleData.cloudPlatform,
                                          )}`}
                                        >
                                          {' '}
                                          {ruleData.percent >= 0 ? (
                                            <i className={'np-tableIcon red'}>
                                              <SmallWarningIcon />
                                            </i>
                                          ) : (
                                            <i className={'np-tableIcon green'}>
                                              <CheckedIcon />
                                            </i>
                                          )}
                                          {getTextToShow(
                                            ruleData,
                                            selectedTab,
                                            azure_billing_currency,
                                            isMultipleCurrency,
                                            isAWSSelected,
                                          )}
                                          <ul className="cmActions">
                                            {ruleData.type !== COST_TYPE.USAGE_TYPES &&
                                              ruleData.type !== COST_TYPE.OPERATIONS && (
                                                <li>
                                                  <Anchor
                                                    id={'seeDetail'}
                                                    className="np-button grey"
                                                    onClick={this.onSeeDetailClick(ruleData)}
                                                  >
                                                    See Details
                                                  </Anchor>
                                                </li>
                                              )}
                                            {!['Dollar', 'AWS Premium Business Support'].includes(ruleData.name) ? (
                                              <li>
                                                <Anchor
                                                  id={'compareCost'}
                                                  className="np-button grey"
                                                  onClick={this.toggleCompareCost(ruleData)}
                                                >
                                                  Compare costs
                                                </Anchor>
                                              </li>
                                            ) : null}
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )
                          : null}
                        {data.filterData.length > DEFAULT_ROWS && (
                          <div className={data.collapseClassName}>
                            <div className="pages">
                              Page {data.page} of {data.totalPage}
                            </div>
                            <ReactPaginate
                              previousLabel={'previous'}
                              nextLabel={'next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              breakLinkClassName={'breakLinkClass'}
                              pageCount={data.totalPage}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={2}
                              forcePage={data.page - 1}
                              onPageChange={selected => this.handlePageChange(selected, data.key)}
                              containerClassName={'pagination'}
                              subContainerClassName={'pages pagination'}
                              activeClassName={'active'}
                            />
                            <div className="np-select-group">
                              <select
                                id="checkbox-Check"
                                value={data.records}
                                className="np-input"
                                onChange={this.handleRecordChange(data.key)}
                              >
                                {(PAGES || []).map((page, index) => (
                                  <option key={index} value={page}>
                                    {page}
                                  </option>
                                ))}
                              </select>

                              <div className="np-caret">
                                <DropDownArrowIcon />
                              </div>
                            </div>
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </Fragment>
              )}
            </div>
          </div>
        </div>

        {showCompareCost && (
          <CompareCost
            {...this.props}
            data={data}
            closeCompareCost={this.closeCompareCost}
            configCatAzure={this.props.configCatAzure}
            azure_billing_currency={azure_billing_currency}
            isMultipleCurrency={isMultipleCurrency}
            isAWSSelected={isAWSSelected}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectCMLoading(),
  globalLoading: makeGlobalLoading(),
  data: makeSelectCMData(),
  userData: makeUserData(),
});

const withConnect = connect(mapStateToProps, { loadCostCMRequest });

const withGlobalReducer = injectReducer({
  key: STORE_GLOBAL_KEY,
  reducer: globalReducer,
});
const withReducer = injectReducer({ key: STORE_COST_KEY, reducer });
const withSaga = injectSaga({ key: STORE_COST_KEY, saga });

export default compose(withGlobalReducer, withReducer, withSaga, withConnect)(ChangeManagement);
