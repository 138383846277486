import styled from 'styled-components';

const NestedSettingsWrapper = styled.div`
  width: calc(100% - 253px);
  height: 90vh;
  float: right;
  display: flex;
`;

export default NestedSettingsWrapper;
