import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { LOAD_CUSTOM_RULE_REQUEST, LOAD_CUSTOM_RULE_SUCCESS, LOAD_CUSTOM_RULE_ERROR } from './constants';

export const initialState = {
  loading: false,
  rulesData: null,
};

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_CUSTOM_RULE_REQUEST:
        draft.loading = true;
        break;
      case LOAD_CUSTOM_RULE_SUCCESS: {
        draft.loading = false;
        const data = cloneDeep(action?.response || []);
        draft.rulesData = data.reverse();
        break;
      }
      case LOAD_CUSTOM_RULE_ERROR:
        draft.loading = false;
        break;
      default:
        break;
    }
  });

export default appReducer;
