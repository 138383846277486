import React, { useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDeepCompareEffect, usePrevious } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { makeSelectTheme } from 'containers/App/selectors';
import { getTheme, getSortedOptions } from './helper';
import { SearchIcon } from 'assets/images';
import Select, { components } from 'react-select';

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      {props.menuIsOpen ? (
        <i className="single-select-search-icon">
          <SearchIcon />
        </i>
      ) : null}
      {children}
    </components.Control>
  );
};

const SingleSelectDropdown = ({
  refs,
  type = '',
  placeHolder = '',
  className = '',
  isLoading = false,
  isDisabled = false,
  clearOption = false,
  isClearable = true,
  isSorted = true,
  data,
  defaultData = {},
  styles = {},
  menuPortalTarget = null,
  menuPlacement = 'auto',
  closeMenuOnScroll = () => false,
  onChange,
  extraProps = null,
  ...rest
}) => {
  const prevDefaultData = usePrevious(defaultData);
  const [selectedValues, setSelectedValues] = useState(defaultData);
  const theme = useSelector(makeSelectTheme());
  const isDark = useMemo(() => theme === 'dark', [theme]);
  const sortedOptions = useMemo(() => getSortedOptions(data, isSorted), [data, isSorted]);

  useDeepCompareEffect(() => {
    if (!isEmpty(defaultData)) {
      setSelectedValues(defaultData);
      return;
    }

    if (!isEmpty(prevDefaultData)) {
      setSelectedValues({});
    }
  }, [defaultData]); //eslint-disable-line

  const handleOnChange = selected => {
    setSelectedValues(!clearOption ? selected : null);
    if (typeof onChange === 'function') {
      onChange(selected, type, extraProps);
    }
  };

  return (
    <Select
      ref={refs}
      id={type}
      theme={getTheme(isDark)}
      placeholder={placeHolder}
      classNamePrefix="select"
      className={`basic-single ${className}`}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isClearable={isClearable}
      options={sortedOptions}
      value={!isEmpty(selectedValues) ? selectedValues : null}
      styles={styles}
      openMenuOnFocus={true}
      closeMenuOnSelect={true}
      menuPortalTarget={menuPortalTarget}
      menuPlacement={menuPlacement}
      closeMenuOnScroll={closeMenuOnScroll}
      onChange={handleOnChange}
      components={{ Control }}
      {...rest}
    />
  );
};

export default React.memo(SingleSelectDropdown);
