import ReactDOM from 'react-dom';
import React from 'react';
import NumberFormatter from 'components/Common/NumberFormatter';
import numeral from 'numeral';
import { SeeDetailLinkIcon } from 'assets/images/cost';
import { numberWithCommas, numberWithCommasAndDecimal } from 'utils/helper';
import { currencyFormat } from 'utils/number';

export const actionRenderer = params => {
  const element = document.createElement('div');
  if (params.node?.group || params.node?.data?.tagName === 'map-migrated') return element;
  const detailElement = (
    <div className="np-costDashCard ur">
      <div className="seeDetails">
        <i className="np-tableIcon grey">
          <SeeDetailLinkIcon />
        </i>
      </div>
    </div>
  );
  ReactDOM.render(detailElement, element);
  return element;
};

export const costRenderer = params => {
  const element = document.createElement('div');
  const costElement = <NumberFormatter>{params.value}</NumberFormatter>;
  ReactDOM.render(costElement, element);
  return element;
};

export const issueRenderer = params => {
  const element = document.createElement('div');
  const issueStr = ` ${params.value} issues`;
  const issueElement = (
    <div className="red">
      {/*<i className="np-svgIcon red">*/}
      {/*  <InfoGreyIcon />*/}
      {/*</i>*/}
      {numberWithCommas(issueStr)}
    </div>
  );
  ReactDOM.render(issueElement, element);
  return element;
};

export const textEllipsisRenderer = params => {
  const element = document.createElement('div');
  const issueElement = (
    <div className="tag-explorer-title-ellipsis" data-toggle="tooltip" title={params.value}>
      {params.value}
    </div>
  );
  ReactDOM.render(issueElement, element);
  return element;
};

export const serviceNameRenderer = ({ data, value }, handleChange) => {
  const element = document.createElement('div');
  const issueElement = (
    <div className="tag-explorer-title-ellipsis d-flex flex-aic" data-toggle="tooltip" title={value}>
      {value}
    </div>
  );
  ReactDOM.render(issueElement, element);
  return element;
};

export const numberValueFormatter = params => numberWithCommas(+params.value);

export const decimalValueFormatter = params => numberWithCommasAndDecimal(+params.value);

export const currencyValueFormatter = params => currencyFormat(+params.value);

export const currencyValueFormatterWithNoValue = params => {
  const value = +params.value;
  if (!value) return '-';
  return value < 0.01 ? '< $0.01' : currencyFormat(value);
};

export const getTotalCost = costList => {
  if (!costList.length) {
    return 0;
  } else {
    return (costList || [])
      .map(function (e) {
        const isNum = numeral(e.summary.value).format(0, 0.0) !== 'NaN';
        return isNum ? e.summary.value : 0;
      })
      .reduce(function (a, b) {
        return a + b;
      }, 0);
  }
};

export const getUntaggedTotalCost = costList => {
  if (!costList.length) {
    return 0;
  } else {
    return (costList || [])
      .map(function (e) {
        const isNum = numeral(e.cost).format(0, 0.0) !== 'NaN';
        return isNum ? e.cost : 0;
      })
      .reduce(function (a, b) {
        return a + b;
      }, 0);
  }
};

export const getColorElement = color => {
  return (
    <div
      style={{
        backgroundColor: color,
        width: '12px',
        height: '12px',
        display: 'inline-block',
        marginRight: '10px',
      }}
    />
  );
};
