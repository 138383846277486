import { getResourceType } from 'utils/helper';

export const getLink = pData => {
  if (!pData?._source) return undefined;
  const awsLink = 'https://console.aws.amazon.com/ec2/v2/home?region=';
  const data = pData?._source;
  let id = data?.item_id;
  const type = data?.type ? data.type : id ? (id.startsWith('arn') ? getResourceType(data.item_id) : id) : null;
  switch (type) {
    case 's3': {
      if (data?.region && data?.name) return `https://s3.console.aws.amazon.com/s3/buckets/${data.name}`;
      else return undefined;
    }
    case 'security_group':
      if (data?.region && id) {
        return `https://console.aws.amazon.com/ec2/v2/home?region=${data.region}#SecurityGroup:groupId=${id}`;
      } else {
        return undefined;
      }
    case 'redshift':
      if (data?.region && data?.name) {
        return `https://console.aws.amazon.com/redshiftv2/home?region=${data.region}#cluster-details?cluster=${data.name}`;
      } else {
        return undefined;
      }
    case 'eks': {
      let newID = id;
      newID = newID.split('/').pop();
      if (data?.region && (data?.name || newID)) {
        return `https://console.aws.amazon.com/eks/home?region=${data.region}#/clusters/${data?.name || newID}`;
      } else {
        return undefined;
      }
    }
    case 'loggroup': {
      if (data?.region && data?.name) {
        let newName = data.name.split(' ');
        newName = newName[newName.length - 1];
        newName = newName.split(':').pop();
        newName = newName.split('/').join('$252F');
        return `https://console.aws.amazon.com/cloudwatch/home?region=${data.region}#logsV2:log-groups/log-group/${newName}`;
      } else {
        return undefined;
      }
    }
    case 'client-vpn':
      if (data?.region && id) {
        let newID = id;
        newID = newID.split('/').pop();
        return `https://console.aws.amazon.com/vpc/home?region=${data.region}#ClientVPNEndpoints:clientVpnEndpointId=${newID};sort=clientVpnEndpointId`;
      } else {
        return undefined;
      }
    case 'elb':
      if (data?.region && data?.name) {
        return `https://console.aws.amazon.com/ec2/v2/home?region=${data.region}#LoadBalancers:search=${data.name};sort=loadBalancerName`;
      } else {
        return undefined;
      }
    case 'es': {
      if (data?.region && id) {
        let newID = id;
        newID = newID.split('/').pop();
        return `https://console.aws.amazon.com/es/home?region=${data.region}#domain:resource=${newID};action=dashboard`;
      } else {
        return undefined;
      }
    }
    case 'ds': {
      if (data?.region && id) {
        let newID = id;
        newID = newID.split('/').pop();
        return `https://console.aws.amazon.com/directoryservicev2/home?region=${data.region}#!/directories/${newID}`;
      } else {
        return undefined;
      }
    }
    case 'cloudfront':
      if (data?.region && id) {
        let newID = id;
        newID = newID.split('/').pop();
        return `https://console.aws.amazon.com/cloudfront/home?region=${data.region}#distribution-settings:${newID}`;
      } else {
        return undefined;
      }
    case 'kms':
      if (data?.region && id) {
        let newID = id;
        newID = newID.split('/').pop();
        return `https://console.aws.amazon.com/kms/home?region=${data.region}#/kms/keys/${newID}`;
      } else {
        return undefined;
      }
    case 'dynamodb':
      if (data?.region && data?.name) {
        return `https://console.aws.amazon.com/dynamodb/home?region=${data.region}#tables:selected=${data.name};tab=overview`;
      } else {
        return undefined;
      }
    case 'lambda':
      if (data?.region && id) {
        let newName = id;
        newName = newName.split(':').pop();
        return `https://console.aws.amazon.com/lambda/home?region=${data.region}#/functions/${newName}?tab=configuration`;
      } else {
        return undefined;
      }
    case 'iam_role':
      if (data?.name) {
        return `https://console.aws.amazon.com/iam/home#/roles/${data.name}`;
      } else {
        return undefined;
      }
    case 'iam': {
      if (data?.user_name) return `https://console.aws.amazon.com/iam/home#/users/${data.user_name}`;
      else return undefined;
    }
    case 'ebs_snapshot': {
      if (data?.region && id) {
        let ebsId = id;
        ebsId = ebsId.split('/').pop();
        return `https://console.aws.amazon.com/ec2/v2/home?Snapshots:visibility=owned-by-me;snapshotId=${ebsId};sort=snapshotId`;
      } else return undefined;
    }
    case 'ecs': {
      if (data?.region && data?.name)
        return `https://console.aws.amazon.com/ecs/home?region=${data.region}#/clusters/${data.name}/services`;
      else return undefined;
    }
    case 'ct_trail': {
      if (data?.region && data?.arn)
        return `https://console.aws.amazon.com/cloudtrail/home?region=${data.region}#/configuration/${data.arn}`;
      else return undefined;
    }
    case 'rds':
      if (data?.region && data?.name) {
        const is_cluster = Boolean(data?.db_cluster_identifier);
        return `https://console.aws.amazon.com/rds/home?region=${data.region}#database:id=${data?.name};is-cluster=${is_cluster}`;
      } else {
        return undefined;
      }
    /*
    is cluster not available
    data not available to test
     case 'subnet': {
      if(data?.region && id)
        return `https://console.aws.amazon.com/vpc/home?region=${data.region}#subnets:search=${id};sort=SubnetId`
      else
        return undefined
    }
      */

    case 'ebs': {
      if (data?.region && id) return `${awsLink + data.region}#Volumes:volumeId=${id}`;
      else return undefined;
    }
    case 'eip':
      if (data?.region && data?.public_ip) return `${awsLink + data.region}#Addresses:PublicIp=${data.public_ip}`;
      else return undefined;
    case 'nat': {
      id = id.split('/')[1];
      if (data?.region && id)
        return `https://${data.region}.console.aws.amazon.com/vpc/home?region=${data.region}#NatGateways:filter=${id};sort=natGatewayId`;
      else return undefined;
    }
    case 'ec2': {
      if (data?.region && id)
        return `https://${data.region}.console.aws.amazon.com/ec2/v2/home?region=${data.region}#Instances:search=${id};sort=instanceId`;
      else return undefined;
    }
    default:
      return undefined;
  }
};
