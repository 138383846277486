import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { hotjar } from 'react-hotjar';
import Cookies from 'js-cookie';
import {
  LOAD_INITIAL_REQUEST,
  LOGIN_REQUEST,
  LOAD_AUTO_SUGGESTION_DATA,
  CLEAR_SUGGESTION_DATA,
  SET_OVERRIDE_TIMESTAMP,
  UPDATE_POLICY_PER_PROJECT_REQUEST,
  UPDATE_POLICY_ALL_PROJECT_REQUEST,
  GET_JIRA_CREDENTIALS_REQUEST,
  GET_JIRA_ISSUES_REQUEST,
  LOAD_JIRA_OAUTH2_DATA_REQUEST,
} from './constants';
import {
  loadInitialSuccess,
  loadFirstBunchSuccess,
  loadSecondBunchSuccess,
  loadInitialError,
  loginError,
  loadAutoSuggestionDataSuccess,
  loadAutoSuggestionDataError,
  clearSuggestionData,
  setTimeStampSuccess,
  showToastAction,
  loadPolicySuccess,
  loadPolicyError,
  updatePolicyForAllProjectError,
  updatePolicyPerProjectError,
  getJiraCredSuccess,
  getJiraCredError,
  getJiraIssuesSuccess,
  getJiraIssuesError,
  // loadConfigCatValueSuccess,
  loadJIRAOAuth2Success,
  loadJIRAOAuth2Error,
} from './actions';
import {
  loadStatus,
  loadBillingInfo,
  loadProfile,
  login,
  loadAutoSuggestion,
  getClientDetail,
  getNewPolicies,
  updatePolicyForAllProject,
  updatePolicyForProject,
  // getConfigCatValue,
  getUserSegment,
} from 'api/global';
import { getJiraCredentialsData, getJiraBoard, jiraOAuth2Integration } from 'api/jira';
import { getErrorMessage } from 'utils/helper';
import { checkNopsEmail, noSpendPendo } from 'utils/common';
import { currencyFormat } from 'utils/number';
import { logMyErrors } from 'utils/request';
import { PRODUCTION_ENV, getEnv } from 'utils/env';
import { getSummaryData } from 'api/cloud-resource-cost';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { FILTER_DATE_FORMAT, LAST_30_DAYS, YESTERDAY } from 'utils/filter';
import { loadAllProjectsList } from 'api/global';

export const api_condition_success = (status = 0) => status >= 200 && status < 400;
export const api_condition_error = (status = 0) => status >= 400;
const env = getEnv();

export function* globalShowNotification(res) {
  try {
    yield put(
      showToastAction({
        type: 'error',
        show: true,
        message: getErrorMessage(res),
      }),
    );
  } catch (e) {
    logMyErrors('Global', e);
  }
}

export function* loadInitialData() {
  try {
    yield call(getInitialData);
  } catch (e) {
    logMyErrors('Initial load', e);
  }
}

export function* getInitialData() {
  try {
    let firstBunchResponse = {};
    let secondBunchResponse = {};
    let thirdBunchResponse = {};

    const profileRes = yield call(loadProfile);
    const pendoConfig = {};
    if (api_condition_success(profileRes.status)) {
      const { id, first_name, last_name, has_project, is_admin, email, username, is_partner_user, partner_user_role } =
        profileRes.data;
      const full_name = `${first_name || '(First name)'} ${last_name || '(Last name)'}`;
      window.user = { has_aws_account: has_project, is_partner_user };

      // ******* Sentry Config ********
      Sentry.configureScope(scope => {
        scope.setTag('email', email);
        scope.setUser({ id, email, username, full_name, has_project });
      });

      // ******* Pendo Config ********
      pendoConfig['visitor'] = {
        id,
        email,
        full_name,
        role: is_admin ? 'Admin' : 'Member',
        has_aws_account: has_project,
        is_partner_user,
        partner_user_role,
      };

      firstBunchResponse = {
        isAdmin: is_admin,
        profile: profileRes.data,
        isNopsAccount: checkNopsEmail(email),
      };
      yield put(loadFirstBunchSuccess(firstBunchResponse));
    } else {
      if (api_condition_error(profileRes.status)) {
        yield call(globalShowNotification, profileRes);
      }
    }

    const statusRes = yield call(loadStatus);
    const projectRes = yield call(loadAllProjectsList);
    if (api_condition_success(statusRes.status)) {
      const { project, client_id, share_save_eligibility = {}, projects = [] } = statusRes.data;
      const clientRes = yield call(getClientDetail, client_id);
      const currentClient = clientRes?.data || {};
      const sharesave_allowed = !!currentClient?.sharesave_allowed || currentClient?.partner === 485;

      pendoConfig['account'] = {
        id: client_id,
        partner_id: currentClient?.partner || undefined,
        name: currentClient.name,
        has_marketplace_subscription: !!profileRes?.data?.has_marketplace_subscription,
      };
      secondBunchResponse = {
        share_save_eligibility,
        project,
        client: client_id,
        currentClient,
        projects: projectRes?.data || [],
        projectStatus: keyBy(projects, 'id'),
        sharesave_allowed,
        azure_billing_currency: currentClient?.azure_billing_currency || 'USD',
        isMultipleCurrency: currentClient?.azure_billing_currency && currentClient?.azure_billing_currency !== 'USD',
      };
      const sharesaveAllowed = localStorage.getItem('sharesaveAllowed') || '';

      if ((sharesaveAllowed.toLowerCase() === 'true') !== secondBunchResponse.sharesave_allowed) {
        localStorage.setItem('sharesaveAllowed', secondBunchResponse.sharesave_allowed);
      }
      yield put(loadSecondBunchSuccess(secondBunchResponse));
      if (projectRes?.data?.length) yield call(getAllPolicy);
    } else {
      if (api_condition_error(statusRes.status)) {
        yield call(globalShowNotification, statusRes);
      }
    }

    const billingRes = yield call(loadBillingInfo);
    if (api_condition_success(billingRes.status)) {
      thirdBunchResponse = {
        payment: billingRes.data,
      };
      pendoConfig['visitor'] = {
        ...pendoConfig['visitor'],
        start_subscription_date: billingRes?.data?.start_subscription_date
          ? moment(billingRes?.data?.start_subscription_date).utc().format('MMMM, DD YYYY')
          : 'none',
        trial: billingRes?.data?.payment_method === 'trail',
        product: billingRes?.data?.product_name,
      };
      yield put(loadInitialSuccess(thirdBunchResponse));
    } else {
      if (api_condition_error(billingRes.status)) {
        yield call(globalShowNotification, billingRes);
      }
    }

    const { has_project } = profileRes.data;
    if (has_project) {
      const mtdData = JSON.parse(Cookies.get('client_mtd_data') || '{}');
      if (!isEmpty(mtdData)) {
        pendoConfig['account'] = {
          ...pendoConfig['account'],
          ...mtdData,
        };
      } else {
        const spendRes = yield call(getSummaryData, {
          date_start: LAST_30_DAYS.format(FILTER_DATE_FORMAT),
          date_end: YESTERDAY.format(FILTER_DATE_FORMAT),
          cost_type: 'line_item_unblended_cost',
        });
        if (api_condition_success(spendRes.status)) {
          const { mtd_spend = 0, mtd_delta_spend = 0 } = spendRes?.data || {};
          if (!isEmpty(spendRes?.data)) {
            Cookies.set(
              'client_mtd_data',
              JSON.stringify({
                aws_mtd_spend: currencyFormat(mtd_spend),
                aws_last_month_spend: currencyFormat(mtd_delta_spend),
              }),
              { expires: 1, secure: true },
            );
            pendoConfig['account'] = {
              ...pendoConfig['account'],
              aws_mtd_spend: currencyFormat(mtd_spend),
              aws_last_month_spend: currencyFormat(mtd_delta_spend),
            };
          } else {
            pendoConfig['account'] = {
              ...pendoConfig['account'],
              ...noSpendPendo,
            };
          }
        } else {
          pendoConfig['account'] = {
            ...pendoConfig['account'],
            ...noSpendPendo,
          };
        }
      }
    }

    if (env === PRODUCTION_ENV) {
      window.pendo.initialize(pendoConfig);
      const hotjarConfig = {
        ...pendoConfig['visitor'],
        ...pendoConfig['account'],
        account_id: pendoConfig['account'].id,
      };
      delete hotjarConfig.id;
      hotjar.identify(pendoConfig['visitor'].id, hotjarConfig);
    }

    const userSegmentRes = yield call(getUserSegment);
    if (api_condition_success(userSegmentRes.status)) {
      window.nopsData = { userSegment: userSegmentRes.data };
    } else {
      if (api_condition_error(userSegmentRes.status)) {
        window.nopsData = { userSegment: {} };
      }
    }
  } catch (err) {
    yield put(loadInitialError());
    yield put(
      showToastAction({
        message: 'Failed to load initial data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* loginSaga(action) {
  const { payload } = action;
  try {
    const res = yield call(login, payload);
    if (res.data.key) {
      yield put(push('/v3/usage-report/dashboard/'));
    }
  } catch (err) {
    yield put(loginError());
  }
}

export function* getSuggestionData(action) {
  const { query } = action;
  try {
    const response = yield call(loadAutoSuggestion, query);
    if (api_condition_success(response.status)) {
      yield put(loadAutoSuggestionDataSuccess(response.data));
    } else if (api_condition_error(response.status)) {
      // yield call(globalShowNotification, response)
      yield put(loadAutoSuggestionDataError());
    }
  } catch (err) {
    yield put(loadAutoSuggestionDataError());
    yield put(
      showToastAction({
        message: 'Failed to load searched data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* clearAutoSuggestionData() {
  return yield call(clearSuggestionData);
}

export function* setTimeStampData(action) {
  return yield put(setTimeStampSuccess(action.payload));
}

export function* getAllPolicy() {
  try {
    const response = yield call(getNewPolicies);
    if (api_condition_success(response.status)) {
      yield put(loadPolicySuccess(response?.data || {}));
    } else if (api_condition_error(response.status)) {
      yield call(globalShowNotification, response);
      yield put(loadPolicyError());
    }
  } catch (err) {
    yield put(loadPolicyError());
  }
}

export function* updatePolicyPerProject(action) {
  try {
    const response = yield call(updatePolicyForProject, action.payload);
    if (api_condition_success(response.status)) {
      yield call(getAllPolicy);
    } else if (api_condition_error(response.status)) {
      yield call(globalShowNotification, response);
      yield put(updatePolicyPerProjectError());
    }
  } catch (err) {
    yield put(updatePolicyPerProjectError());
    yield put(
      showToastAction({
        message: 'Failed to update policy data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* updatePolicyAllProject(action) {
  try {
    const response = yield call(updatePolicyForAllProject, action.payload);
    if (api_condition_success(response.status)) {
      yield call(getAllPolicy);
    } else if (api_condition_error(response.status)) {
      yield call(globalShowNotification, response);
      yield put(updatePolicyForAllProjectError());
    }
  } catch (err) {
    yield put(updatePolicyForAllProjectError());
    yield put(
      showToastAction({
        message: 'Failed to update policy data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getJiraCred() {
  try {
    const Workload = yield call(getJiraCredentialsData);
    yield put(getJiraCredSuccess(Workload.status));
    if (api_condition_error(Workload.status)) {
      yield put(getJiraCredError(getErrorMessage(Workload)));
    }
  } catch (err) {
    yield put(getJiraCredError(''));
  }
}

export function* getJiraIssues() {
  try {
    const issues = yield call(getJiraBoard);
    if (api_condition_success(issues.status)) {
      yield put(getJiraIssuesSuccess(issues.data));
    } else if (api_condition_error(issues.status)) {
      yield put(getJiraIssuesError());
    }
  } catch (err) {
    yield put(getJiraIssuesError());
  }
}

export function* loadJIRAOAuth2Data(action) {
  try {
    const jiraOAuth2Data = yield call(jiraOAuth2Integration, action.payload);
    if (api_condition_success(jiraOAuth2Data.status)) {
      yield put(loadJIRAOAuth2Success(jiraOAuth2Data.data));
    } else if (api_condition_error(jiraOAuth2Data.status)) {
      yield put(loadJIRAOAuth2Error());
      // yield call(globalShowNotification, jiraOAuth2Data);
    }
  } catch (err) {
    yield put(loadJIRAOAuth2Error());
    yield put(
      showToastAction({
        message: 'Failed to load Jira OAuth2 data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export default function* appUpdate() {
  yield takeLatest(LOAD_INITIAL_REQUEST, loadInitialData);
  yield takeEvery(LOGIN_REQUEST, loginSaga);
  yield takeEvery(LOAD_AUTO_SUGGESTION_DATA, getSuggestionData);
  yield takeEvery(CLEAR_SUGGESTION_DATA, clearAutoSuggestionData);
  yield takeEvery(SET_OVERRIDE_TIMESTAMP, setTimeStampData);
  yield takeEvery(UPDATE_POLICY_PER_PROJECT_REQUEST, updatePolicyPerProject);
  yield takeEvery(UPDATE_POLICY_ALL_PROJECT_REQUEST, updatePolicyAllProject);
  yield takeEvery(GET_JIRA_CREDENTIALS_REQUEST, getJiraCred);
  yield takeEvery(GET_JIRA_ISSUES_REQUEST, getJiraIssues);
  yield takeLatest(LOAD_JIRA_OAUTH2_DATA_REQUEST, loadJIRAOAuth2Data);
}
