import { call, put, takeLatest } from 'redux-saga/effects';
import { hotjar } from 'react-hotjar';
import * as Sentry from '@sentry/browser';
import {
  LOAD_PARTNER_INITIAL_REQUEST,
  LOAD_PARTNER_PROFILE_REQUEST,
  LOAD_PARTNER_CLIENT_REQUEST,
  LOAD_PARTNER_DETAIL_REQUEST,
  LOAD_PARTNER_STATUS_REQUEST,
} from './constants';
import {
  loadPartnerProfileSuccess,
  loadPartnerProfileError,
  loadPartnerClientSuccess,
  loadPartnerClientError,
  loadPartnerDetailSuccess,
  loadPartnerDetailError,
  loadPartnerStatusSuccess,
  loadPartnerStatusError,
} from './actions';
import { loadProfile, loadStatus } from 'api/global';
import { allClients, getPartner } from 'api/partner';
import { showToastAction } from 'containers/App/actions';
import { api_condition_error, api_condition_success, globalShowNotification } from 'containers/App/saga';
import { PRODUCTION_ENV, getEnv } from 'utils/env';

export function* loadPartnerInitialData() {
  try {
    const profile = yield call(loadProfile);
    const pendoConfig = {};
    if (api_condition_success(profile.status)) {
      const { id, first_name, last_name, has_project, is_admin, email, username, is_partner_user, partner_user_role } =
        profile.data;

      const full_name = `${first_name || '(First name)'} ${last_name || '(Last name)'}`;

      // ******* Sentry Config ********
      Sentry.configureScope(scope => {
        scope.setTag('email', email);
        scope.setUser({ id, email, username, full_name, has_project });
      });

      // ******* Pendo Config ********
      pendoConfig['visitor'] = {
        id,
        email,
        full_name,
        role: is_admin ? 'Admin' : 'Member',
        has_aws_account: has_project,
        is_partner_user,
        partner_user_role,
      };
      window.user = {
        has_aws_account: profile.data.has_project,
        is_partner_user: profile.data.is_partner_user,
      };
      yield put(loadPartnerProfileSuccess(profile.data));
    } else if (api_condition_error(profile.status)) {
      yield call(globalShowNotification, profile);
      yield put(loadPartnerProfileError());
    }

    const partner = yield call(getPartner);
    if (api_condition_success(partner.status)) {
      const { id, name, primary_client_id } = partner.data[0];
      pendoConfig['account'] = {
        id,
        partner_id: id,
        name,
        has_marketplace_subscription: !!profile?.data?.has_marketplace_subscription,
      };
      yield put(
        loadPartnerDetailSuccess({
          ...partner.data?.[0],
          client_id: primary_client_id,
        }),
      );
    } else if (api_condition_error(partner.status)) {
      yield call(globalShowNotification, partner);
      yield put(loadPartnerDetailError());
    }

    const env = getEnv();
    if (env === PRODUCTION_ENV) {
      window.pendo.initialize(pendoConfig);
      hotjar.initialize(2824059, 6);
      const hotjarConfig = {
        ...pendoConfig['visitor'],
        ...pendoConfig['account'],
        account_id: pendoConfig['account'].id,
      };
      delete hotjarConfig.id;
      hotjar.identify(pendoConfig['visitor'].id, hotjarConfig);
    }

    const clients = yield call(allClients);
    if (api_condition_success(clients.status)) {
      yield put(loadPartnerClientSuccess(clients.data));
    } else if (api_condition_error(clients.status)) {
      yield call(globalShowNotification, clients);
      yield put(loadPartnerClientError());
    }
  } catch (err) {
    yield put(loadPartnerProfileError());
    yield put(loadPartnerClientError());
    yield put(loadPartnerDetailError());
    yield put(
      showToastAction({
        message: 'Failed to load partner initial data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getPartnerProfileData() {
  try {
    const profile = yield call(loadProfile);
    if (api_condition_success(profile.status)) {
      yield put(loadPartnerProfileSuccess(profile.data));
    } else if (api_condition_error(profile.status)) {
      yield call(globalShowNotification, profile);
      yield put(loadPartnerProfileError());
    }
  } catch (err) {
    yield put(loadPartnerProfileError());
    yield put(
      showToastAction({
        message: 'Failed to load partner profile data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getPartnerClientData() {
  try {
    const clients = yield call(allClients);
    if (api_condition_success(clients.status)) {
      yield put(loadPartnerClientSuccess(clients.data));
    } else if (api_condition_error(clients.status)) {
      yield call(globalShowNotification, clients);
      yield put(loadPartnerClientError());
    }
  } catch (err) {
    yield put(loadPartnerClientError());
    yield put(
      showToastAction({
        message: 'Failed to load client data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getPartnerDetailData() {
  try {
    const partner = yield call(getPartner);
    if (api_condition_success(partner.status)) {
      const status = yield call(loadStatus, partner?.data?.[0]?.id);
      yield put(
        loadPartnerDetailSuccess({
          ...partner.data?.[0],
          client_id: status?.data?.client_id,
          partnerProjects: status?.data?.projects || [],
        }),
      );
    } else if (api_condition_error(partner.status)) {
      yield call(globalShowNotification, partner);
      yield put(loadPartnerDetailError());
    }
  } catch (err) {
    yield put(loadPartnerDetailError());
    yield put(
      showToastAction({
        message: 'Failed to load partner detail data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getPartnerStatusData(action) {
  try {
    const statusRsp = yield call(loadStatus, action.partnerId);
    if (api_condition_success(statusRsp.status)) {
      yield put(loadPartnerStatusSuccess(statusRsp?.data?.projects || []));
    } else if (api_condition_error(statusRsp.status)) {
      yield call(globalShowNotification, statusRsp);
      yield put(loadPartnerStatusError());
    }
  } catch (err) {
    yield put(loadPartnerStatusError());
    yield put(
      showToastAction({
        message: 'Failed to load partner status data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export default function* appUpdate() {
  yield takeLatest(LOAD_PARTNER_INITIAL_REQUEST, loadPartnerInitialData);
  yield takeLatest(LOAD_PARTNER_PROFILE_REQUEST, getPartnerProfileData);
  yield takeLatest(LOAD_PARTNER_DETAIL_REQUEST, getPartnerDetailData);
  yield takeLatest(LOAD_PARTNER_CLIENT_REQUEST, getPartnerClientData);
  yield takeLatest(LOAD_PARTNER_STATUS_REQUEST, getPartnerStatusData);
}
