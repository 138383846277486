import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { currencyFormat } from 'utils/number';
import { DecIncIcon } from 'assets/images/common';
import { InfoTooltipIcon } from 'assets/images/common';
import { SummaryCostLoader } from 'components/ContentLoader/CostControlLoaders';
import LoadingWrapper from 'components/ContentLoader/LoadingWrapper';
import Tippy from '@tippyjs/react';
import { EmptySvg } from 'assets/images';
import { currencyFormatterByCode } from 'utils/currencySymbol';

const CLOUD_PLATFORMS = {
  aws: 'AWS',
  azure: 'Azure',
};

const CostCard = ({
  loading,
  customClass,
  title,
  description,
  value,
  prevValue,
  percentage,
  clouds = {},
  configCatAzure,
  nonResourcesValue = 0,
  granularCredits = 0,
  displayCredits,
  handleExcludeCredits,
  includeCredit,
  showTooltip = false,
  isMultipleCurrency = false,
  azure_billing_currency = 'USD',
  isAWSSelected,
}) => {
  const cardClass = cx('np-cardInner', customClass, {
    decrease: percentage <= 0,
    increase: percentage > 0,
  });

  const costDetail = amount => {
    return currencyFormatterByCode(isMultipleCurrency, isAWSSelected, amount, azure_billing_currency);
  };

  const show = !isMultipleCurrency || (isMultipleCurrency && isAWSSelected);
  const cloudPlatforms = Object.keys(clouds);
  const nonResourcesAndCreditsDisplay = (
    <div className="nonResourcesTooltip">
      <div>
        <p>Resource cost</p>
        <p className="nonResourcesTooltipCost">{`${
          includeCredit
            ? costDetail(value - nonResourcesValue + granularCredits)
            : costDetail(value - nonResourcesValue - granularCredits)
        }`}</p>
      </div>
      <div>
        <p>Non-resource cost</p>
        <p className="nonResourcesTooltipCost">{`${costDetail(nonResourcesValue)}`}</p>
      </div>
      {show && (
        <div>
          <p>AWS Credits</p>
          <p className="nonResourcesTooltipCost">{`${currencyFormat(granularCredits)}`}</p>
        </div>
      )}
    </div>
  );

  return (
    <div className={cardClass} data-testid="cost-card">
      <LoadingWrapper isLoading={loading} customLoader={SummaryCostLoader}>
        <div className="descTitle">{title}</div>

        <div className="stat" id={title}>
          <div className="np-cardInner-icon-wrapper">
            {`${costDetail(value)} `}
            {percentage !== 0 && <DecIncIcon />}
            {showTooltip && !displayCredits ? (
              <span className="nonResources">
                <Tippy content={nonResourcesAndCreditsDisplay} placement="top">
                  <InfoTooltipIcon />
                </Tippy>
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
        {configCatAzure && clouds && cloudPlatforms.length > 1 && (
          <div className="desc">
            {cloudPlatforms.map(
              (cl, index) =>
                clouds[cl] > 0 && (
                  <span key={cl}>
                    {index > 0 ? '| ' : ''}
                    {CLOUD_PLATFORMS[cl]}: {`${costDetail(clouds[cl])} `}
                  </span>
                ),
            )}
          </div>
        )}
        <div className="desc">
          {percentage !== 0 && (
            <>
              <span className="green">{percentage > 0 ? `+${percentage}` : percentage}%</span>
              {` ${description} ${prevValue > 0 ? '(' + costDetail(prevValue) + ')' : ''} `}
            </>
          )}

          {displayCredits ? (
            <div>
              <div className="np-check">
                <input id={'keyCredits'} type="checkbox" checked={includeCredit} onChange={handleExcludeCredits} />
                <label className="np-check-label" htmlFor={'keyCredits'}>
                  <EmptySvg />
                </label>
              </div>
              <label className="np-filterLabel" htmlFor="keyCredits">
                Exclude Credits
              </label>
            </div>
          ) : null}
        </div>
      </LoadingWrapper>
    </div>
  );
};

CostCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.number,
  prevValue: PropTypes.number,
  percentage: PropTypes.number,
  clouds: PropTypes.object,
  configCatAzure: PropTypes.bool,
  displayCredits: PropTypes.bool,
};

CostCard.defaultProps = {
  value: 0,
  prevValue: 0,
  percentage: 0,
  displayCredits: false,
};

export default CostCard;
