import React from 'react';

export const NpTags = ({ color }) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M64.8315 44.6197L70.8044 38.6432C72.0239 37.4231 72.0239 35.4455 70.8044 34.2255L37.5159 0.916137C36.9293 0.32959 36.1346 0 35.3052 0H11.25C9.52393 0 8.125 1.39893 8.125 3.125V43.5657C8.125 44.3939 8.45398 45.1892 9.03992 45.7751L42.3279 79.0845C43.548 80.3052 45.5286 80.3052 46.7487 79.0845L70.8038 55.014C72.0233 53.7939 72.0233 51.8164 70.8038 50.5957L64.8315 44.6197ZM44.5386 72.4548L14.375 42.2717V34.743L42.3279 62.7136C43.5474 63.9343 45.5286 63.9349 46.7487 62.7136L60.4138 49.0405L64.1754 52.8052L44.5386 72.4548ZM44.5386 56.084L14.375 25.9015V6.25H34.01L64.176 36.4343L44.5386 56.084Z"
      fill={color}
    />
  </svg>
);
