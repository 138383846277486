import React, { useEffect, useState, Fragment } from 'react';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
import Tippy from '@tippyjs/react';
import LoadingWrapper from 'components/ContentLoader/LoadingWrapper';
import Loader from 'components/LoadingIndicator/Loader';

import { DropDownArrowIcon, LinkArrowIcon } from 'assets/images/nOpsRule';
import './style.css';

export const CustomTable = ({ ...props }) => {
  const [viewAll, setViewAll] = useState(!(props?.expand || false));
  const [currentPage, setCurrentPage] = useState(props?.currentPage + 1 || 1);
  const [records, setTotalRecord] = useState(props.defaultRow || 25);
  const [recordsToShow, setRecordsToShow] = useState([]);
  const {
    main = {},
    columnField = [],
    tableData = [],
    isLink = false,
    onClick,
    headerChild,
    newRow = [],
    handleSorting,
    name,
    tableChild,
    defaultRow,
    showClickableTD = false,
    noDataChild,
    trClass = '',
    wrapper_class = '',
    loading = false,
    contentLoading = false,
    contentLoader = '',
    defaultTotal = 0,
    updateSorting = false,
    configCatAzure,
    disableRibbons,
    expand = false,
  } = props;
  const [colClicks, setColClicks] = useState({});
  const { title, customTitle, icon, description, tabs } = main;
  const total = defaultTotal ? defaultTotal : tableData.length;
  const totalPage = Math.ceil(total / records);
  const isWA = window.location.pathname.startsWith('/v3/workload-assessment/');

  useEffect(() => {
    const tempColClicks = {};
    columnField.forEach(item => {
      if (item.order === 'asc') tempColClicks[item.key] = 2;
      if (item.order === 'desc') tempColClicks[item.key] = 1;
    });
    setColClicks(tempColClicks);
  }, [columnField]);

  useEffect(() => {
    if (!viewAll) {
      setCurrentPage(1);
      setRecordsToShow([...tableData.slice(0, 5)]);
      return;
    }

    const indexOfLastData = currentPage * records;
    const indexOfFirstData = indexOfLastData - records;
    let currentData = tableData.slice(indexOfFirstData, indexOfLastData);
    if (props.currentPage) {
      currentData = tableData.slice(0, 49);
    }
    setRecordsToShow([...currentData]);
    // eslint-disable-next-line
  }, [currentPage, records, tableData, viewAll]);

  useEffect(() => {
    setCurrentPage(props.currentPage + 1 || 1);
    setTotalRecord(defaultRow || 25);
    // eslint-disable-next-line
  }, [tableData.length, defaultRow]);

  const onRowClick = (data, index) => e => {
    if ((isLink || showClickableTD) && typeof onClick === 'function') onClick(data, index);
  };

  const onSelectChange = e => {
    if (records === Number(e.target.value)) return null;
    setCurrentPage(1);
    setTotalRecord(Number(e.target.value));
  };

  const handleSortClickUpdated = (col, index) => () => {
    const { key, isSortable, order } = col;
    if (!isSortable) return;
    let newOrder = '';

    if (!order) {
      newOrder = 'desc';
    } else {
      newOrder = order === 'asc' ? 'desc' : 'asc';
    }

    const colClickVal = colClicks[key] || 0;
    const reset = colClickVal + 1 === 3;

    const tempColClicks = { ...colClicks };
    Object.keys(tempColClicks || []).forEach(item => {
      if (item !== key) tempColClicks[item] = 0;
    });
    setColClicks({ ...tempColClicks, [key]: reset ? 0 : colClickVal + 1 });
    setCurrentPage(1);
    setTotalRecord(defaultRow || 25);
    typeof handleSorting === 'function' && handleSorting(col.key, reset ? '' : newOrder, col.type, index);
  };

  const handleSortClick = (col, index) => () => {
    if (col.isSortable) {
      const newOrder = col.order === 'asc' ? 'desc' : 'asc';
      typeof handleSorting === 'function' && handleSorting(col.key, newOrder, col.type, index);
      setCurrentPage(1);
      setTotalRecord(defaultRow || 25);
    }
  };

  const handlePageClick = data => {
    if (data.selected === currentPage - 1) return null;
    setCurrentPage(data.selected + 1);
    typeof props.handlePageChange === 'function' && props.handlePageChange(data.selected);
  };

  const renderSortingClass = value => {
    if (!value) return '';
    if (value === 'asc') return 'up-icon';
    return 'down-icon';
  };
  const tableClassName = classNames({
    'np-table2-wrapper': true,
    clickableRows: isLink || showClickableTD,
    'np-nops-loading': loading,
    [wrapper_class]: true,
  });
  const pages = [25, 50, 75, 100];
  if (defaultRow && !pages.includes(defaultRow)) {
    pages.unshift(defaultRow);
  }
  return (
    <div className={'np-table2 ' + name}>
      {(icon || title) && (
        <div className="title">
          {icon}
          {title}
        </div>
      )}

      {customTitle && customTitle}

      {description && <p id={'description'}>{description}</p>}

      {tabs && tabs}

      {headerChild && (
        <div id={'headerChild'} className="np-table2Actions">
          {headerChild}
        </div>
      )}

      {tableChild && <div id={'tableChild'}>{tableChild}</div>}

      <div className={tableClassName} id="np-table2-wrapper">
        <Loader />
        <LoadingWrapper isLoading={contentLoading} customLoader={contentLoader}>
          <table>
            <tbody>
              <tr>
                {recordsToShow.length > 0 &&
                  (columnField || []).map((col, index) => (
                    <th
                      key={col.key + index}
                      id={`${col.key}Sorting`}
                      className={`${col.customClassForCol || ''} ${configCatAzure ? 'util-padding' : ''}`}
                      onClick={updateSorting ? handleSortClickUpdated(col, index) : handleSortClick(col, index)}
                      colSpan={col.colSpan ? col.colSpan : null}
                    >
                      {col?.child ? (
                        col.child
                      ) : (
                        <div className={`data ${col.isSortable ? 'sorting' : ''}`}>
                          {col.name}
                          {col.icon && (
                            <Tippy content={col.info} placement={'bottom'}>
                              {col.icon}
                            </Tippy>
                          )}
                          {col.isSortable && (
                            <span className={updateSorting ? renderSortingClass(col.order) : 'up-down-icon'} />
                          )}
                          <small>{col.small ? col.small : ''}</small>
                        </div>
                      )}
                    </th>
                  ))}
              </tr>
              {recordsToShow.length > 0 ? (
                <>
                  {(recordsToShow || []).map((data, index) => {
                    const idWiseData = (newRow || []).filter(n => n && n.id === data.id);
                    const isAzure = (data?.cloudPlatform || '').toLowerCase() === 'azure';
                    const con = newRow.length > 0 && idWiseData.length > 0;
                    return (
                      <Fragment key={`Fragment-clickableRow-${index}`}>
                        <tr
                          key={`clickableRow-${index}`}
                          id={`clickableRow-${index}`}
                          data-testid={`clickableRow-${index}`}
                          className={`${trClass || ''} ${
                            data.customTrClass || ''
                          } ${isWA && configCatAzure ? 'amazonRibbon' : ''}`}
                          onClick={onRowClick(data, index)}
                        >
                          {(columnField || []).map((col, index1) => (
                            <td
                              id={col.key + '-' + index}
                              key={col.key + '-' + index + index1}
                              className={`${col.customClassForCol || ''} ${
                                isWA && configCatAzure ? 'util-padding' : ''
                              }`}
                              style={recordsToShow[index][`${col.key}CustomStyle`] || {}}
                            >
                              <div
                                id={col.key + '-div-' + index}
                                className={`data ${col?.tdClass || ''} ${
                                  configCatAzure && !disableRibbons ? (isAzure ? 'azureRibbon' : 'amazonRibbon') : ''
                                }`}
                              >
                                <div>{recordsToShow[index][col.key]}</div>
                                {col.rowSmall ? (
                                  <div className="row-subtitle">{recordsToShow[index][col.rowSmall.key]}</div>
                                ) : null}
                              </div>
                            </td>
                          ))}
                          {isLink && (
                            <td id={'action-' + index} align="right">
                              <div id={'action-div-' + index} className="data action">
                                <i className="np-tableIcon grey">
                                  <LinkArrowIcon />
                                </i>
                              </div>
                            </td>
                          )}
                        </tr>
                        {data.extraCustomRow ? data.extraCustomRow : null}
                        {con
                          ? idWiseData.map((i, index) => (
                              <tr key={i + index}>
                                <td className={`data ${i.class || ''}`} colSpan={columnField.length + (isLink ? 1 : 0)}>
                                  {i.child}
                                </td>
                              </tr>
                            ))
                          : null}
                      </Fragment>
                    );
                  })}
                  {expand && tableData.length > 5 ? (
                    <tr>
                      <td colSpan="100%">
                        <div className="data link" onClick={() => setViewAll(!viewAll)}>
                          {`View ${viewAll ? 'Less' : 'More'}`}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </>
              ) : !loading && !noDataChild ? (
                <tr>
                  <td colSpan={columnField.length}>
                    <div className="data">No data available yet..</div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
          {noDataChild && !recordsToShow.length ? noDataChild : null}
        </LoadingWrapper>
      </div>
      {totalPage !== 1 && recordsToShow.length > 0 && viewAll && (
        <div className="np-paginationT bottom">
          <div className="pages">
            Page {currentPage} of {totalPage}
          </div>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            breakLinkClassName={'asd'}
            pageCount={totalPage}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            forcePage={currentPage - 1}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
          {!defaultRow ? (
            <div className="np-select-group">
              <select id="checkbox-Check" value={records} className="np-input" onChange={onSelectChange}>
                {(pages || []).map((page, index) => (
                  <option key={'page' + index} value={page}>
                    {page}
                  </option>
                ))}
              </select>

              <div className="np-caret">
                <DropDownArrowIcon />
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
