import React from 'react';
import { Charts } from './Charts';
import { Table } from './Table';

export const RightPageContent = ({ ...props }) => {
  return (
    <React.Fragment>
      <Charts {...props} />
      <Table {...props} />
    </React.Fragment>
  );
};
