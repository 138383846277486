import React from 'react';
import ContentLoader from 'react-content-loader';
import { commonColorProps } from './commonProps';

const UsageReportLoader_PROPS = {
  speed: 2,
  width: '100%',
  height: '100%',
};

export const UsageReportLoader = count => (
  <>
    {[...Array(count).keys()].map(item => (
      <div key={`usage-report-loader-${item}`} className="np-usage-Report-loader-item">
        <ContentLoader {...UsageReportLoader_PROPS} {...commonColorProps}>
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
        </ContentLoader>
      </div>
    ))}
  </>
);
