import range from 'lodash/range';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

function getFormatTemplate({ currency, percentage, precision }) {
  if (percentage) {
    return '0%';
  }
  if (currency) {
    return `$0,0.${range(precision).join('')}`;
  }
}

const NumberFormatter = ({ children, ...props }) => {
  const num = numeral(children);
  const formatTmpl = useMemo(() => getFormatTemplate(props), [props]);

  return <span>{num.format(formatTmpl)}</span>;
};

NumberFormatter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currency: PropTypes.bool,
  percentage: PropTypes.bool,
  precision: PropTypes.number,
};

NumberFormatter.defaultProps = {
  children: 0,
  currency: true,
  percentage: false,
  precision: 2,
};

export default NumberFormatter;
