import produce from 'immer';
import {
  LOAD_PARTNER_INITIAL_REQUEST,
  LOAD_PARTNER_PROFILE_REQUEST,
  LOAD_PARTNER_PROFILE_SUCCESS,
  LOAD_PARTNER_PROFILE_ERROR,
  LOAD_PARTNER_CLIENT_REQUEST,
  LOAD_PARTNER_CLIENT_SUCCESS,
  LOAD_PARTNER_CLIENT_ERROR,
  LOAD_PARTNER_DETAIL_REQUEST,
  LOAD_PARTNER_DETAIL_SUCCESS,
  LOAD_PARTNER_DETAIL_ERROR,
  LOAD_PARTNER_STATUS_REQUEST,
  LOAD_PARTNER_STATUS_SUCCESS,
  LOAD_PARTNER_STATUS_ERROR,
  LOAD_CONFIGCAT_SUCCESS,
} from './constants';
import orderBy from 'lodash/orderBy';

export const initialState = {
  loading: false,
  pLoading: false,
  userLoading: false,
  loadingStatus: false,
  partnerProfile: {},
  partnerClients: [],
  partnerDetail: {},
};

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_PARTNER_INITIAL_REQUEST:
        draft.loading = true;
        draft.pLoading = true;
        draft.userLoading = true;
        draft.partnerProfile = {};
        draft.partnerClients = [];
        draft.partnerDetail = {};
        break;
      case LOAD_PARTNER_PROFILE_REQUEST:
        draft.userLoading = true;
        break;
      case LOAD_PARTNER_PROFILE_SUCCESS:
        draft.userLoading = false;
        draft.partnerProfile = action.response;
        break;
      case LOAD_PARTNER_PROFILE_ERROR:
        draft.userLoading = false;
        break;
      case LOAD_PARTNER_CLIENT_REQUEST:
        draft.loading = true;
        break;
      case LOAD_PARTNER_CLIENT_SUCCESS:
        draft.loading = false;
        draft.partnerClients = orderBy(action.response || [], ['id'], ['desc']);
        break;
      case LOAD_PARTNER_CLIENT_ERROR:
        draft.loading = false;
        break;
      case LOAD_PARTNER_DETAIL_REQUEST:
        draft.pLoading = true;
        break;
      case LOAD_PARTNER_DETAIL_SUCCESS:
        draft.pLoading = false;
        draft.partnerDetail = action.response;
        break;
      case LOAD_PARTNER_DETAIL_ERROR:
        draft.pLoading = false;
        break;
      case LOAD_PARTNER_STATUS_REQUEST:
        draft.loadingStatus = true;
        break;
      case LOAD_PARTNER_STATUS_SUCCESS:
        draft.loadingStatus = false;
        draft.partnerDetail.partnerProjects = action.response;
        break;
      case LOAD_PARTNER_STATUS_ERROR:
        draft.loadingStatus = false;
        break;
      case LOAD_CONFIGCAT_SUCCESS: {
        draft.configCatAzure = action.response['value'];
        break;
      }
      default:
        break;
    }
  });

export default appReducer;
