import * as configcat from 'configcat-js';
import React from 'react';
import { getEnv, PRODUCTION_ENV } from 'utils/env';

const CC_UAT_KEY = 'ySnZCGuW90-CIrk3UtogNw/EcsMInxY-0Go-nzTGi7X-A';
const CC_PRODUCTION_KEY = 'ySnZCGuW90-CIrk3UtogNw/bkxhzAqwNUqqvAvFsOricg';
const CC_KEY = getEnv() === PRODUCTION_ENV ? CC_PRODUCTION_KEY : CC_UAT_KEY;

export const configClient = configcat.createClientWithLazyLoad(CC_KEY, {
  cacheTimeToLiveSeconds: 10,
});

const FeatureFlagsContext = React.createContext(configClient);

export const FeatureFlagsProvider = FeatureFlagsContext.Provider;
export const FeatureFlagsConsumer = FeatureFlagsContext.Consumer;
export default FeatureFlagsContext;
