import reject from 'lodash/reject';
import {
  currencyValueFormatter,
  decimalValueFormatter,
  numberValueFormatter,
} from 'containers/Cost/TagExplorer/helper';
import moment from 'moment';
import { getClassNameBasedOnCloud } from 'utils/helper';

export const customComparator = (valueA, valueB) => {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

export const dateCustomComparator = (valueA, valueB) => {
  return moment(valueA, 'DD, MMM YYYY') - moment(valueB, 'DD, MMM YYYY');
};

export const dateCustomFormatter = params => {
  if (!params.value) return 'no date';
  return moment(params.value, 'YYYY-MM-DD').format('MMM D YYYY');
};

const number_props = {
  filter: 'agNumberColumnFilter',
  valueFormatter: numberValueFormatter,
};

export const decimal_props = {
  filter: 'agNumberColumnFilter',
  valueFormatter: decimalValueFormatter,
};

export const currency_props = {
  filter: 'agNumberColumnFilter',
  valueFormatter: currencyValueFormatter,
};

export const sorting_props = {
  comparator: customComparator,
};

export const AWS_ACCOUNT_FIELD = {
  field: 'project',
  headerName: 'AWS Account',
  cellRenderer: 'awsAccountRenderer',
  minWidth: 300,
  flex: 1,
  ...sorting_props,
};

export const AZURE_ACCOUNT_FIELD = {
  field: 'project',
  headerName: 'Azure Account',
  cellRenderer: 'awsAccountRenderer',
  minWidth: 300,
  flex: 1,
  ...sorting_props,
};

export const AZURE_NAME_FIELD = {
  field: 'name',
  headerName: 'Name',
  minWidth: 160,
  flex: 1,
  ...sorting_props,
};

const REGION_FIELD = {
  field: 'region',
  headerName: 'Region',
  minWidth: 160,
  ...sorting_props,
};

const RESOURCE_FIELD = {
  field: 'name',
  headerName: 'Resource Name / ID',
  cellRenderer: 'resourceRenderer',
  minWidth: 350,
  wrapText: true,
  autoHeight: true,
  ...sorting_props,
};

const COST_FIELD = {
  field: 'cost',
  headerName: 'Cost',
  ...currency_props,
};

const USER_FIELD = {
  field: 'name',
  headerName: 'User Name / ID',
  cellRenderer: 'resourceRenderer',
  minWidth: 350,
  ...sorting_props,
};

const TYPE_FIELD = {
  field: 'type',
  headerName: 'Type',
  ...sorting_props,
};

const DETECTED_DATE_FIELD = {
  field: 'detectedDate',
  headerName: 'Detected date',
  minWidth: 150,
  comparator: dateCustomComparator,
};

const cellClass = params => {
  return getClassNameBasedOnCloud(params?.context?.configCatAzure, params?.context?.cloudPlatform);
};

const EXPAND_COLLAPSE_FIELD = {
  field: 'expand',
  headerName: '',
  cellRenderer: 'agGroupCellRenderer',
  sortable: false,
  filter: false,
  maxWidth: 50,
  cellClass,
};

const ACTION_FIELD = {
  field: 'action',
  headerName: 'Action',
  cellRenderer: 'actionButtonRenderer',
  sortable: false,
  filter: false,
  resizable: false,
  pinned: 'right',
  lockPinned: true,
  minWidth: 250,
};

const COMMON_FIELD_1 = [EXPAND_COLLAPSE_FIELD, AWS_ACCOUNT_FIELD, REGION_FIELD];
const COMMON_FIELD = [...COMMON_FIELD_1, RESOURCE_FIELD, DETECTED_DATE_FIELD];

const COMMON_USER_FIELD = [...COMMON_FIELD_1, USER_FIELD, DETECTED_DATE_FIELD, { ...ACTION_FIELD }];

const AZURE_FIELD = [EXPAND_COLLAPSE_FIELD, AZURE_NAME_FIELD];

const COMMON_COST_FIELD = [...COMMON_FIELD, COST_FIELD, { ...ACTION_FIELD }];

export const ec2_RIGHTSIZING_COLUMNS = [
  ...COMMON_FIELD_1,
  {
    field: 'name',
    headerName: 'Resource Name / ID',
    cellRenderer: 'riAndASRenderer',
    minWidth: 350,
    ...sorting_props,
  },
  DETECTED_DATE_FIELD,
  {
    field: 'currentConfig',
    headerName: 'Current Config',
    ...sorting_props,
  },
  {
    field: 'suggestedConfig',
    headerName: 'Suggested Config',
    ...sorting_props,
  },
  {
    field: 'idle',
    headerName: 'Unused CPU(%)',
    ...decimal_props,
  },
  {
    field: 'currentCost',
    headerName: 'Current Cost Monthly',
    ...currency_props,
  },
  {
    field: 'newCost',
    headerName: 'New Cost Monthly',
    ...currency_props,
  },
  {
    field: 'saving',
    headerName: 'Savings Monthly',
    ...currency_props,
  },
  { ...ACTION_FIELD },
];

export const RIGHTSIZING_COLUMNS = reject(ec2_RIGHTSIZING_COLUMNS, data => data.field === 'idle');

const PUBLIC_SUBNET_COLUMNS = [
  ...COMMON_FIELD,
  DETECTED_DATE_FIELD,
  {
    field: 'port',
    headerName: 'Port',
  },
  {
    field: 'subnet',
    headerName: 'Subnet',
    ...sorting_props,
  },
  TYPE_FIELD,
  { ...ACTION_FIELD },
];

export const COLUMNS_FOR_RULES = {
  check_resource_encryption: {
    rds: [
      ...COMMON_FIELD_1,
      {
        field: 'name',
        headerName: 'DB Instance Identifier',
        cellRenderer: 'resourceRenderer',
        minWidth: 350,
        comparator: customComparator,
      },
      DETECTED_DATE_FIELD,
      { ...ACTION_FIELD },
    ],
    ebs: [
      ...COMMON_FIELD_1,
      {
        field: 'name',
        headerName: 'Volume Name / ID',
        cellRenderer: 'resourceRenderer',
        minWidth: 350,
        ...sorting_props,
      },
      DETECTED_DATE_FIELD,
      { ...ACTION_FIELD },
    ],
    s3: [
      ...COMMON_FIELD_1,
      {
        field: 'name',
        headerName: 'Bucket Name',
        cellRenderer: 'resourceRenderer',
        minWidth: 340,
        flex: 1,
        wrapText: true,
        autoHeight: true,
        ...sorting_props,
      },
      DETECTED_DATE_FIELD,
      { ...ACTION_FIELD },
    ],
  },
  policy_attached_users: {
    iam: [
      ...COMMON_FIELD_1,
      USER_FIELD,
      DETECTED_DATE_FIELD,
      {
        field: 'createDate',
        headerName: 'Created Date',
        ...sorting_props,
      },
      {
        field: 'policyName',
        headerName: 'Policy Name',
        ...sorting_props,
      },
      {
        field: 'policyArn',
        headerName: 'Policy Arn',
        ...sorting_props,
      },
      {
        field: 'policyType',
        headerName: 'Policy Type',
        ...sorting_props,
      },
      {
        field: 'attachedPolicy',
        headerName: 'Directly Attached Policy',
        ...sorting_props,
      },
      {
        field: 'inlinePolicy',
        headerName: 'Inline Policy',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  no_mfa_users: {
    iam: COMMON_USER_FIELD,
  },
  public_subnet: {
    rds: PUBLIC_SUBNET_COLUMNS,
    ec2: PUBLIC_SUBNET_COLUMNS,
  },
  s3_bucket_security: {
    s3: [
      ...COMMON_FIELD_1,
      {
        field: 'name',
        headerName: 'Bucket Name',
        cellRenderer: 'resourceRenderer',
        minWidth: 340,
        flex: 1,
        wrapText: true,
        autoHeight: true,
        ...sorting_props,
      },
      DETECTED_DATE_FIELD,
      {
        field: 'publicWriteAccess',
        headerName: 'Public write Access',
        ...sorting_props,
      },
      {
        field: 'publicReadAccess',
        headerName: 'Public read Access',
        ...sorting_props,
      },
      {
        field: 'loggingEnabled',
        headerName: 'Logging Enabled',
        ...sorting_props,
      },
      {
        field: 'serverSideEncryption',
        headerName: 'Server side Encryption',
        ...sorting_props,
      },
      {
        field: 'versioningEnabled',
        headerName: 'Versioning Enabled',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  check_aws_config: {
    regions: [
      ...COMMON_FIELD_1,
      DETECTED_DATE_FIELD,
      {
        field: 'channelsPresent',
        headerName: 'Config channels present',
        ...sorting_props,
      },
      {
        field: 'recordsPresent',
        headerName: 'Config records present',
        ...sorting_props,
      },
      {
        field: 'recordsRunning',
        headerName: 'Config records running',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  cloudtrail_log_validation_enabled: {
    ct_trail: [
      ...COMMON_FIELD_1,
      DETECTED_DATE_FIELD,
      {
        field: 'compliance',
        headerName: 'Compliance',
        ...sorting_props,
      },
      ACTION_FIELD,
    ],
  },
  check_root_user_mfa: {
    iam: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  root_account_usage: {
    iam: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  inactive_keys: {
    iam: [
      ...COMMON_FIELD_1,
      USER_FIELD,
      {
        field: 'accessKey',
        headerName: 'Access Key',
        ...sorting_props,
      },
      {
        field: 'lastUsed',
        headerName: 'Last Used',
      },
      {
        field: 'created',
        headerName: 'Created',
      },
      ACTION_FIELD,
    ],
  },
  check_aws_enterprise_support: {
    iam: COMMON_USER_FIELD,
  },
  check_waf_used: {
    iam: COMMON_USER_FIELD,
  },
  check_aws_inspector: {
    iam: COMMON_USER_FIELD,
  },
  check_guardduty_enabled: {
    iam: COMMON_USER_FIELD,
  },
  admin_users: {
    iam: COMMON_USER_FIELD,
  },
  classic_vpc: {
    ec2: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  iops_performance: {
    ebs: [
      ...COMMON_FIELD,
      {
        field: 'actual_iops',
        headerName: 'Actual IOPS',
        ...number_props,
        ...sorting_props,
      },
      {
        field: 'capacity',
        headerName: 'Capacity (GB)',
        ...number_props,
      },
      {
        field: 'uStatus',
        headerName: 'Status',
        ...sorting_props,
      },
      {
        field: 'provisionIOPS',
        headerName: 'Provision IOPS',
        ...number_props,
      },
      {
        field: 'iopsLastDay',
        headerName: 'IOPS Last Day',
        ...number_props,
      },
      {
        field: 'iopsLastWeek',
        headerName: 'IOPS (Last Week)',
        ...number_props,
      },
      COST_FIELD,
      {
        field: 'estimatedMonthlySaving',
        headerName: 'Estimated Monthly saving',
        ...number_props,
      },
      { ...ACTION_FIELD },
    ],
    rds: [
      ...COMMON_FIELD,
      {
        field: 'actual_iops',
        headerName: 'Actual IOPS',
        ...number_props,
      },
      {
        field: 'capacity',
        headerName: 'Capacity (GB)',
        ...number_props,
      },
      {
        field: 'uStatus',
        headerName: 'Status',
        ...sorting_props,
      },
      {
        field: 'provisionIOPS',
        headerName: 'Provision IOPS',
        ...number_props,
      },
      {
        field: 'useDays',
        headerName: 'IOPS (Use Days)',
        ...number_props,
      },
      {
        field: 'useWeeks',
        headerName: 'IOPS (Use Week)',
        ...number_props,
      },
      COST_FIELD,
      {
        field: 'estimatedMonthlySaving',
        headerName: 'Estimated (Monthly saving)',
        ...number_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  triggerless_lambda: {
    lambda: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  public_lambda: {
    lambda: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  unencrypted_lambda_variables: {
    lambda: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  dynamodb_limit: {
    dynamodb: [
      ...COMMON_FIELD_1,
      {
        field: 'name',
        headerName: 'Table Name',
        cellRenderer: 'resourceRenderer',
        minWidth: 350,
        ...sorting_props,
      },
      DETECTED_DATE_FIELD,
      {
        field: 'type',
        headerName: 'Type',
        ...sorting_props,
      },
      {
        field: 'maxUsedReadRequest',
        headerName: 'Max Used (Read Request)',
        ...decimal_props,
      },
      {
        field: 'maxUsedAvgReadRequestPerSecond',
        headerName: 'Max Used Avg (Read Request /sec)',
        ...decimal_props,
      },
      {
        field: 'maxUsedWriteRequest',
        headerName: 'Max Used (Write Request)',
        ...decimal_props,
      },
      {
        field: 'maxUsedAvgWriteRequestPerSecond',
        headerName: 'Max Used Avg (Write Request /sec)',
        ...decimal_props,
      },
      {
        field: 'readCapacity',
        headerName: 'Read Capacity',
        ...number_props,
      },
      {
        field: 'writeCapacity',
        headerName: 'Write Capacity',
        ...number_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  unused_resources: {
    elb: COMMON_COST_FIELD,
    nat: COMMON_COST_FIELD,
    ebs: COMMON_COST_FIELD,
    eip: [...COMMON_FIELD, { field: 'public_ip', headerName: 'IP Address' }, { ...ACTION_FIELD }],
  },
  unused_roles: {
    iam_role: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  ec2_low_network_utilization: {
    ec2: [
      ...COMMON_FIELD_1,
      {
        field: 'name',
        headerName: 'Resource Name / ID',
        cellRenderer: 'riAndASRenderer',
        minWidth: 350,
        ...sorting_props,
      },
      DETECTED_DATE_FIELD,
      {
        field: 'ipAddress',
        headerName: 'IP Address',
      },
      {
        field: 'uStatus',
        headerName: 'Status',
        cellRenderer: 'statusRenderer',
        ...sorting_props,
      },
      {
        field: 'timestamp',
        headerName: 'Timestamp',
        filter: false,
        cellRenderer: 'timestampRenderer',
      },
      TYPE_FIELD,
      COST_FIELD,
      { ...ACTION_FIELD },
    ],
  },
  ebs_snapshot: {
    ebs: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  dynamodb_continuos_backup: {
    dynamodb: [
      ...COMMON_FIELD_1,
      {
        field: 'name',
        headerName: 'Table Name',
        cellRenderer: 'resourceRenderer',
        minWidth: 350,
        ...sorting_props,
      },
      DETECTED_DATE_FIELD,
      {
        field: 'pointStatus',
        headerName: 'Point In Time Recovery Status',
        comparator: customComparator,
      },
      {
        field: 'backUpStatus',
        headerName: 'Continuous Backups Status',
        comparator: customComparator,
      },
      { ...ACTION_FIELD },
    ],
  },
  rds_backup_policy: {
    rds: [
      ...COMMON_FIELD,
      TYPE_FIELD,
      {
        field: 'size',
        headerName: 'Size (GiB)',
        ...number_props,
      },
      {
        field: 'multiAZDBStatus',
        headerName: 'MultiAZ DB Status',
        comparator: customComparator,
      },
      { ...ACTION_FIELD },
    ],
  },
  rds_instances_without_multiaz: {
    rds: [
      ...COMMON_FIELD,
      TYPE_FIELD,
      {
        field: 'engine',
        headerName: 'Engine',
        ...sorting_props,
      },
      {
        field: 'size',
        headerName: 'Size (GiB)',
        ...number_props,
      },
      {
        field: 'multiAZDBStatus',
        headerName: 'MultiAZ DB Status',
        comparator: customComparator,
      },
      COST_FIELD,
      { ...ACTION_FIELD },
    ],
  },
  check_empty_tags: {
    ec2: [
      ...COMMON_FIELD,
      {
        field: 'ipAddress',
        headerName: 'IP Address',
      },
      COST_FIELD,
      {
        field: 'instanceType',
        headerName: 'InstanceType',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
    ebs: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  check_ec2_autoscaling: {
    ec2: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  public_ebs_snapshot: {
    ebs_snapshot: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  scheduled_events: {
    ec2: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  vpc_cidr: {
    vpc: [
      ...COMMON_FIELD_1,
      {
        field: 'name',
        headerName: 'VPC Name',
        cellRenderer: 'resourceRenderer',
        minWidth: 350,
        ...sorting_props,
      },
      DETECTED_DATE_FIELD,
      {
        field: 'vpcCIDR',
        headerName: 'VPC CIDR',
      },
      {
        field: 'overlaps',
        headerName: 'Overlaps',
      },
      { ...ACTION_FIELD },
    ],
  },
  underutilized_resources: {
    ec2: [
      ...COMMON_FIELD_1,
      {
        field: 'name',
        headerName: 'Resource Name / ID',
        cellRenderer: 'riAndASRenderer',
        minWidth: 350,
        ...sorting_props,
      },
      DETECTED_DATE_FIELD,
      {
        field: 'ipAddress',
        headerName: 'IP Address',
      },
      {
        field: 'uStatus',
        headerName: 'Status',
        cellRenderer: 'statusRenderer',
        ...sorting_props,
      },
      {
        field: 'timestamp',
        headerName: 'Timestamp',
        filter: false,
        cellRenderer: 'timestampRenderer',
      },
      TYPE_FIELD,
      {
        field: 'cpu',
        headerName: 'CPU (%)',
        ...decimal_props,
      },
      COST_FIELD,
      {
        field: 'sStatus',
        headerName: 'Subnet Status',
        cellRenderer: 'subnetStatusRenderer',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  s3_underutilized: {
    s3: [
      ...COMMON_FIELD,
      COST_FIELD,
      {
        field: 'requests_count',
        headerName: 'Requests Count',
        ...number_props,
      },
      {
        field: 'network_mbytes',
        headerName: 'Network mbytes',
        ...number_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  efs_low_utilization: {
    efs: [
      ...COMMON_FIELD,
      COST_FIELD,
      {
        field: 'total_io',
        headerName: 'TotalIOBytes',
        sortable: false,
      },
      {
        field: 'performance_mode',
        headerName: 'Performance Mode',
        ...sorting_props,
      },
      {
        field: 'read_iops',
        headerName: 'Read IOPS Bytes',
        sortable: false,
      },
      {
        field: 'write_iops',
        headerName: 'Write IOPS Bytes',
        sortable: false,
      },
      {
        field: 'ioUsagePercents',
        headerName: 'IO Usage 2 weeks (%)',
        ...number_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  ecs_low_utilization: {
    ecs: [
      ...COMMON_FIELD,
      {
        field: 'cpu_utilization',
        headerName: 'CPU Utilization',
        ...decimal_props,
      },
      {
        field: 'memory_utilization',
        headerName: 'Memory Utilization',
        ...number_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  dynamodb_low_utilization: {
    dynamodb: [
      ...COMMON_FIELD,
      COST_FIELD,
      {
        field: 'readUsagePercents',
        headerName: 'Read Usage (%)',
        ...number_props,
      },
      {
        field: 'writeUsagePercents',
        headerName: 'Write Usage (%)',
        ...number_props,
      },
      {
        field: 'tabSizeMBytes',
        headerName: 'Tab Size (MBytes)',
        ...number_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  log_group_underutilized: {
    loggroup: [
      ...COMMON_FIELD,
      COST_FIELD,
      {
        field: 'log_events_bandwidth_kbytes',
        headerName: 'Data Processing Bytes',
        ...number_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  instance_recommendation_rightsizing: {
    ec2: ec2_RIGHTSIZING_COLUMNS,
  },
  instance_recommendation: {
    rds: RIGHTSIZING_COLUMNS,
  },
  redshift_low_node_utilization: {
    redshift: [
      ...COMMON_FIELD,
      {
        field: 'cpuUtilization',
        headerName: 'CPU Utilization',
        ...decimal_props,
      },
      {
        field: 'diskUtilization',
        headerName: 'Disk Utilization',
        ...number_props,
      },
      {
        field: 'nodeType',
        headerName: 'Node Type',
        ...sorting_props,
      },
      {
        field: 'readIOPS',
        headerName: 'Read IOPS Utilization',
        sortable: false,
      },
      {
        field: 'writeIOPS',
        headerName: 'Write IOPS Utilization',
        sortable: false,
      },
      COST_FIELD,
      { ...ACTION_FIELD },
    ],
  },
  root_access_keys: {
    iam: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  strong_password_policy: {
    iam: [
      ...COMMON_FIELD,
      {
        field: 'reasons',
        headerName: 'Reasons',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  cloudtrail_s3_protection: {
    s3: [
      ...COMMON_FIELD,
      COST_FIELD,
      {
        field: 'mfa_delete',
        headerName: 'MFA delete',
        ...sorting_props,
      },
      {
        field: 'versioning',
        headerName: 'Versioning',
        ...sorting_props,
      },
      ACTION_FIELD,
    ],
  },
  rds_instances_idle: {
    rds: [...COMMON_FIELD, COST_FIELD, ACTION_FIELD],
  },
  unattached_workspace_directories: {
    workspace_directory: [...COMMON_FIELD, COST_FIELD, ACTION_FIELD],
  },
  ami_expired: {
    ami: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  elasticache_disabled_multizone: {
    elasticache: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },
  s3_bucket_versioning_disabled: {
    s3: [...COMMON_FIELD, { ...ACTION_FIELD }],
  },

  // SECURITY
  azure_resource_encryption: {
    disk_storage: [
      ...AZURE_FIELD,
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      {
        field: 'disk_size_gb',
        headerName: 'Disk Size (GB)',
        ...sorting_props,
      },
      {
        field: 'managed_by',
        headerName: 'Virtual Machine',
        ...sorting_props,
      },
      {
        field: 'disk_state',
        headerName: 'Disk State',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
    storage_account: [
      ...AZURE_FIELD,
      {
        field: 'blob_encryption',
        headerName: 'Blob Encryption',
        ...sorting_props,
      },
      {
        field: 'file_encryption',
        headerName: 'File Encryption',
        ...sorting_props,
      },
      {
        field: 'require_infrastructure_encryption',
        headerName: 'Infrastructure Encryption',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  azure_storage_account_security: {
    storage_account: [
      ...AZURE_FIELD,
      {
        field: 'allow_blob_public_access',
        headerName: 'Allow Blob Public Acces',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  azure_strong_password_policy: {
    rbac_users: [
      ...AZURE_FIELD,
      {
        field: 'number_of_issues',
        headerName: 'Number of Issues',
        ...sorting_props,
      },
      {
        field: 'reason',
        headerName: 'Reason',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  azure_public_subnet: {
    sql_database: [
      ...AZURE_FIELD,
      REGION_FIELD,
      // {
      //   field: 'public_network_access',
      //   headerName: 'Public Network Access',
      //   ...sorting_props,
      // },
      {
        field: 'zone_redundant',
        headerName: 'Zone Redundant',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
    virtual_machine: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      {
        field: 'vm_id',
        headerName: 'VM ID',
        ...sorting_props,
      },
      // {
      //   field: 'ip_configurations',
      //   headerName: 'IP Configurations',
      //   ...sorting_props,
      // },
      {
        field: 'virtual_machine_scale_set',
        headerName: 'Virtual Machine Scale Set',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  azure_no_mfa_users: {
    rbac_users: [...AZURE_FIELD, { ...ACTION_FIELD }],
  },
  check_azure_security_center: {
    rbac_users: [
      ...AZURE_FIELD,
      {
        field: 'MCAS',
        headerName: 'MCAS',
        ...sorting_props,
      },
      {
        field: 'WDATP',
        headerName: 'WDATP',
        ...sorting_props,
      },
      {
        field: 'Sentinel',
        headerName: 'Sentinel',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  check_azure_policy: {
    rbac_users: [
      ...AZURE_FIELD,
      {
        field: 'non_compliant_policies',
        headerName: 'Non Compliant Policies',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  ami_public: {
    ami: [
      ...COMMON_FIELD_1,
      {
        field: 'image_name',
        headerName: 'Image Name',
        ...sorting_props,
      },
      DETECTED_DATE_FIELD,
      ACTION_FIELD,
    ],
  },
  vpc_flow_logging_disabled: {
    vpc: [
      ...COMMON_FIELD_1,
      {
        field: 'item_id',
        headerName: 'VPC ID',
        ...sorting_props,
      },
      DETECTED_DATE_FIELD,
      ACTION_FIELD,
    ],
  },
  rds_public_snapshots: {
    rds: [
      ...COMMON_FIELD_1,
      {
        field: 'snapshot_id',
        headerName: 'Snapshot ID',
        ...sorting_props,
      },
      DETECTED_DATE_FIELD,
      ACTION_FIELD,
    ],
  },

  // COST
  file_service_underutilized: {
    file_storage_services: [
      ...AZURE_FIELD,
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      REGION_FIELD,
      {
        field: 'read_iops',
        headerName: 'Read IOPS Bytes',
        ...sorting_props,
      },
      {
        field: 'write_iops',
        headerName: 'Write IOPS Bytes',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  storage_account_underutilized: {
    storage_account: [
      ...AZURE_FIELD,
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      {
        field: 'total_io',
        headerName: 'Total (IO)',
        ...sorting_props,
      },
      {
        field: 'read_iops',
        headerName: 'Read IOPS Bytes',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  unused_azure_resources: {
    disk_storage: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'managed_by',
        headerName: 'Managed by',
        ...sorting_props,
      },
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      {
        field: 'disk_size_gb',
        headerName: 'Disk Size (GB)',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
    network_interfaces: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'virtual_machine',
        headerName: 'Virtual Machine',
        ...sorting_props,
      },
      // {
      //   field: 'network_security_group',
      //   headerName: 'Network Security Group',
      //   ...sorting_props,
      // },
      { ...ACTION_FIELD },
    ],
    public_ip_address: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'ip_configuration',
        headerName: 'IP Configuration',
        ...sorting_props,
      },
      {
        field: 'ip_address',
        headerName: 'IP Address',
        ...sorting_props,
      },
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
    virtual_machine: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      {
        field: 'vm_id',
        headerName: 'VM ID',
        ...sorting_props,
      },
      {
        field: 'statuses',
        headerName: 'Statuses',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
    virtual_network_nat: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  underutilized_azure_resources: {
    virtual_machine: [
      ...AZURE_FIELD,
      REGION_FIELD,
      // {
      //   field: 'product',
      //   headerName: 'Product',
      //   ...sorting_props,
      // },
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      {
        field: 'vm_id',
        headerName: 'VM ID',
        ...sorting_props,
      },
      {
        field: 'cpu_604800',
        headerName: 'CPU',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  azure_instance_recommendation: {
    servers: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'product',
        headerName: 'Product',
        ...sorting_props,
      },
      {
        field: 'impacted_value',
        headerName: 'Impacted Value',
        ...sorting_props,
      },
      {
        field: 'solution',
        headerName: 'Solution',
        ...sorting_props,
      },
      {
        field: 'type',
        headerName: 'Type',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
    virtual_machine: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'impact',
        headerName: 'Impact',
        ...sorting_props,
      },
      {
        field: 'impacted_value',
        headerName: 'Impacted Value',
        ...sorting_props,
      },
      {
        field: 'result',
        headerName: 'result',
        ...sorting_props,
      },
      {
        field: 'type',
        headerName: 'Type',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
    maria_db: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'current_30d_cost',
        headerName: 'Current 30d Cost',
        ...sorting_props,
      },
      {
        field: 'old_type',
        headerName: 'Old Type',
        ...sorting_props,
      },
      {
        field: 'new_type',
        headerName: 'New Type',
        ...sorting_props,
      },
      {
        field: 'type',
        headerName: 'Type',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
    postgresql_db: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'current_30d_cost',
        headerName: 'Current 30d Cost',
        ...sorting_props,
      },
      {
        field: 'old_type',
        headerName: 'Old Type',
        ...sorting_props,
      },
      {
        field: 'new_type',
        headerName: 'New Type',
        ...sorting_props,
      },
      {
        field: 'type',
        headerName: 'Type',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
    mysql_db: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'current_30d_cost',
        headerName: 'Current 30d Cost',
        ...sorting_props,
      },
      {
        field: 'old_type',
        headerName: 'Old Type',
        ...sorting_props,
      },
      {
        field: 'new_type',
        headerName: 'New Type',
        ...sorting_props,
      },
      {
        field: 'type',
        headerName: 'Type',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  vm_low_network_utilization: {
    virtual_machine: [
      ...AZURE_FIELD,
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      {
        field: 'vm_id',
        headerName: 'VM ID',
        ...sorting_props,
      },
      {
        field: 'network_in',
        headerName: 'Network In',
        ...sorting_props,
      },
      {
        field: 'network_out',
        headerName: 'Network Out',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  azure_iops_performance: {
    disk_storage: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'managed_by',
        headerName: 'Managed by',
        ...sorting_props,
      },
      {
        field: 'estimated_saving',
        headerName: 'Estimated Saving',
        ...sorting_props,
      },
      {
        field: 'read_iops',
        headerName: 'Read IOPS Bytes',
        ...sorting_props,
      },
      {
        field: 'write_iops',
        headerName: 'Write IOPS Bytes',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  cosmosdb_limit: {
    cosmos_db: [
      ...AZURE_FIELD,
      {
        field: 'kind',
        headerName: 'Kind',
        ...sorting_props,
      },
      {
        field: 'violation_subtype',
        headerName: 'Violation Subtype',
        ...sorting_props,
      },
      {
        field: 'total_request',
        headerName: 'Total Request',
        ...sorting_props,
      },
      {
        field: 'violation_date',
        headerName: 'Violation Date',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  cosmosdb_low_utilization: {
    cosmos_db: [
      ...AZURE_FIELD,
      {
        field: 'kind',
        headerName: 'Kind',
        ...sorting_props,
      },
      {
        field: 'total_request_units',
        headerName: 'Total Request Units',
        ...sorting_props,
      },
      {
        field: 'provisioned_throughput',
        headerName: 'Provisioned Throughput',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },

  // RELIABILITY
  disks_snapshots: {
    disk_storage: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'managed_by',
        headerName: 'Managed by',
        ...sorting_props,
      },
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      {
        field: 'disk_size_gb',
        headerName: 'Disk Size (GB)',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  azure_db_enable_multiaz: {
    sql_database: [
      ...AZURE_FIELD,
      {
        field: 'zone_redundant',
        headerName: 'Zone Redundant',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  azure_vm_autoscaling: {
    virtual_machine: [
      ...AZURE_FIELD,
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      {
        field: 'vm_id',
        headerName: 'VM ID',
        ...sorting_props,
      },
      {
        field: 'virtual_machine_scale_set',
        headerName: 'VM Machine Scale Set',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  container_instance_underutilized: {
    container_instance: [
      ...AZURE_FIELD,
      {
        field: 'containers',
        headerName: 'Containers',
        minWidth: 250,
        ...sorting_props,
      },
      REGION_FIELD,
      {
        field: 'container_cpu_millicores_10_percent',
        headerName: 'Container CPU Milicores (10%)',
        ...sorting_props,
      },
      {
        field: 'container_memory_10_percent',
        headerName: 'Container Memory (10%)',
        minWidth: 250,
        ...sorting_props,
      },
      {
        field: 'utilization_millicores',
        headerName: 'VM Machine Scale Set',
        minWidth: 220,
        ...sorting_props,
      },
      {
        field: 'utilization_bytes',
        headerName: 'Utilizations (B)',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },

  // OPERATIONS
  azure_empty_tags: {
    virtual_machine: [
      ...AZURE_FIELD,
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      {
        field: 'vm_id',
        headerName: 'VM ID',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  azure_scheduled_events: {
    virtual_machine: [
      ...AZURE_FIELD,
      {
        field: 'sku_name',
        headerName: 'SKU',
        ...sorting_props,
      },
      {
        field: 'vm_id',
        headerName: 'VM ID',
        ...sorting_props,
      },
      {
        field: 'data',
        headerName: 'Data',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
  azure_sql_pool_low_utilization: {
    synapse_sql_pool: [
      ...AZURE_FIELD,
      REGION_FIELD,
      {
        field: 'period',
        headerName: 'Period',
        ...sorting_props,
      },
      {
        field: 'cpu_utilization',
        headerName: 'CPU Utilization',
        ...sorting_props,
      },
      {
        field: 'disk_utilization',
        headerName: 'Disk Utilization',
        ...sorting_props,
      },
      {
        field: 'read_write_iops_utilization',
        headerName: 'Read/Write Iops Utilization',
        ...sorting_props,
      },
      { ...ACTION_FIELD },
    ],
  },
};
