export const SHARE_SAVE_ROUTE = '/v3/cost/sharesave/';

export const RISK_FREE_PARAMS = {
  view: 'risk-free-commitment',
  config: 'configure-risk-free-commitment',
  trigger: 'risk-free-trigger',
};

export const SummaryCards = [
  {
    value: 'net_savings',
    label: 'Estimated 30 Days Net Savings',
    key: 'daily_net_savings',
    desc: 'What you Save after paying nOps.',
  },
  {
    value: 'annualized_net_savings',
    label: 'Estimated Annualized Net Savings',
    key: 'annualized_net_savings',
    desc: 'What you will save in a year with ShareSave (Estimated savings vs on-demand with ShareSave).',
  },
];
