import moment from 'moment';

export const getMonth = (quarter, year) => {
  switch (quarter) {
    case 1:
      return moment().set({ date: 1, month: 0, year });
    case 2:
      return moment().set({ date: 1, month: 3, year });
    case 3:
      return moment().set({ date: 1, month: 6, year });
    case 4:
      return moment().set({ date: 1, month: 9, year });
    default:
      return moment();
  }
};
