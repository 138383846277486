import React from 'react';
import ContentLoader from 'react-content-loader';
import { commonColorProps } from './commonProps';

const CostControlLoader_PROPS = {
  speed: 2,
  width: '100%',
  height: '100',
};

const CostControlLoader_TABLE_PROPS = {
  speed: 2,
  width: '100%',
  height: 550,
};

const CostControlLoader_CHART_PROPS = {
  speed: 2,
  width: '100%',
  height: 200,
};

const tableSection = (
  <ContentLoader {...CostControlLoader_TABLE_PROPS} {...commonColorProps}>
    <rect x="0" y="45" rx="3" ry="3" width="100%" height="17"></rect>
    <circle cx="90%" cy="123" r="11"></circle>
    <rect x="10%" y="115" rx="3" ry="3" width="10%" height="15"></rect>
    <rect x="30%" y="114" rx="3" ry="3" width="20%" height="15"></rect>
    <rect x="60%" y="114" rx="3" ry="3" width="21%" height="15"></rect>
    <rect x="0" y="155" rx="3" ry="3" width="100%" height="2"></rect>
    <circle cx="90%" cy="184" r="11"></circle>
    <rect x="10%" y="176" rx="3" ry="3" width="10%" height="15"></rect>
    <rect x="30%" y="175" rx="3" ry="3" width="20%" height="15"></rect>
    <rect x="60%" y="175" rx="3" ry="3" width="21%" height="15"></rect>
    <rect x="0" y="216" rx="3" ry="3" width="100%" height="2"></rect>
    <circle cx="90%" cy="242" r="11"></circle>
    <rect x="10%" y="234" rx="3" ry="3" width="10%" height="15"></rect>
    <rect x="30%" y="233" rx="3" ry="3" width="20%" height="15"></rect>
    <rect x="60%" y="233" rx="3" ry="3" width="21%" height="15"></rect>
    <rect x="0" y="274" rx="3" ry="3" width="100%" height="2"></rect>
    <circle cx="90%" cy="303" r="11"></circle>
    <rect x="10%" y="295" rx="3" ry="3" width="10%" height="15"></rect>
    <rect x="30%" y="294" rx="3" ry="3" width="20%" height="15"></rect>
    <rect x="60%" y="294" rx="3" ry="3" width="21%" height="15"></rect>
    <rect x="0" y="335" rx="3" ry="3" width="100%" height="2"></rect>
    <circle cx="90%" cy="363" r="11"></circle>
    <rect x="10%" y="355" rx="3" ry="3" width="10%" height="15"></rect>
    <rect x="30%" y="354" rx="3" ry="3" width="20%" height="15"></rect>
    <rect x="60%" y="354" rx="3" ry="3" width="21%" height="15"></rect>
    <rect x="0" y="395" rx="3" ry="3" width="100%" height="2"></rect>
    <circle cx="90%" cy="424" r="11"></circle>
    <rect x="10%" y="416" rx="3" ry="3" width="10%" height="15"></rect>
    <rect x="30%" y="415" rx="3" ry="3" width="20%" height="15"></rect>
    <rect x="60%" y="415" rx="3" ry="3" width="21%" height="15"></rect>
    <rect x="0" y="453" rx="3" ry="3" width="100%" height="2"></rect>
    <rect x="0" y="49" rx="3" ry="3" width="2" height="465"></rect>
    <rect x="99.8%" y="49" rx="3" ry="3" width="2" height="465"></rect>
    <circle cx="90%" cy="484" r="11"></circle>
    <rect x="10%" y="476" rx="3" ry="3" width="10%" height="15"></rect>
    <rect x="30%" y="475" rx="3" ry="3" width="20%" height="15"></rect>
    <rect x="60%" y="475" rx="3" ry="3" width="21%" height="15"></rect>
    <rect x="0" y="513" rx="3" ry="3" width="100%" height="2"></rect>
    <rect x="0" y="80" rx="3" ry="3" width="100%" height="17"></rect>
    <rect x="0" y="57" rx="3" ry="3" width="10%" height="33"></rect>
    <rect x="20%" y="54" rx="3" ry="3" width="10%" height="33"></rect>
    <rect x="50%" y="55" rx="3" ry="3" width="10%" height="33"></rect>
    <rect x="81%" y="56" rx="3" ry="3" width="7%" height="33"></rect>
    <rect x="92%" y="54" rx="3" ry="3" width="10%" height="33"></rect>
  </ContentLoader>
);

export const chartSection = (
  <ContentLoader {...CostControlLoader_CHART_PROPS} {...commonColorProps}>
    <rect x="0" y="160" rx="0" ry="0" width="25" height="40" />
    <rect x="30" y="145" rx="0" ry="0" width="25" height="55" />
    <rect x="60" y="126" rx="0" ry="0" width="25" height="74" />
    <rect x="90" y="80" rx="0" ry="0" width="25" height="120" />
    <rect x="120" y="60" rx="0" ry="0" width="25" height="140" />
    <rect x="150" y="110" rx="0" ry="0" width="25" height="90" />
    <rect x="180" y="120" rx="0" ry="0" width="25" height="80" />
    <rect x="210" y="140" rx="0" ry="0" width="25" height="60" />
    <rect x="240" y="140" rx="0" ry="0" width="25" height="60" />
    <rect x="270" y="140" rx="0" ry="0" width="25" height="60" />
    <rect x="300" y="140" rx="0" ry="0" width="25" height="60" />
    <rect x="330" y="140" rx="0" ry="0" width="25" height="60" />
    <rect x="360" y="155" rx="0" ry="0" width="25" height="45" />
    <rect x="390" y="170" rx="0" ry="0" width="25" height="30" />
    <rect x="420" y="170" rx="0" ry="0" width="25" height="30" />
  </ContentLoader>
);

export const CostControlLoader = (
  <>
    <ContentLoader {...CostControlLoader_PROPS} {...commonColorProps}>
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
    </ContentLoader>
    {chartSection}
    {tableSection}
  </>
);

export const TagsLoader = <>{tableSection}</>;

const SummaryCostLoader_PROPS = {
  speed: 2,
  width: '76%',
  height: '132',
};

export const SummaryCostLoader = (
  <ContentLoader {...SummaryCostLoader_PROPS} {...commonColorProps}>
    <rect x="0" y="8" rx="3" ry="3" width="100%" height="100%" />
  </ContentLoader>
);

const podRecomm_PROPS = {
  speed: 2,
  width: '100%',
  height: '60',
};

export const PodRecommendationLoader = (
  <ContentLoader {...podRecomm_PROPS} {...commonColorProps}>
    <rect x="0" y="5" rx="0" ry="0" width="100%" height="100%" />
  </ContentLoader>
);

const summaryOpportunities_PROPS = {
  speed: 3,
  width: '100%',
  height: '130',
};

export const SummaryOpportunitiesLoader = (
  <ContentLoader {...summaryOpportunities_PROPS} {...commonColorProps}>
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
  </ContentLoader>
);
