import { LOAD_USER_DATA_REQUEST, LOAD_USER_DATA_SUCCESS, LOAD_USER_DATA_ERROR } from './constants';

export function loadUserDataData() {
  return {
    type: LOAD_USER_DATA_REQUEST,
  };
}

export function loadUserDataSuccess(response) {
  return {
    type: LOAD_USER_DATA_SUCCESS,
    response,
  };
}

export function loadUserDataError() {
  return {
    type: LOAD_USER_DATA_ERROR,
  };
}
