import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { makeSelectTheme } from 'containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTheme, getSortedOptions } from './helper';
import { SearchIcon } from 'assets/images';

const { Control, Option, MultiValueLabel } = components;

const SearchControl = ({ children, ...props }) => {
  return (
    <Control {...props}>
      {props.menuIsOpen ? (
        <i className="single-select-search-icon">
          <SearchIcon />
        </i>
      ) : null}
      {children}
    </Control>
  );
};

const NestedOption = props => {
  const { label } = props.data;
  const hasColon = label.includes(':');

  if (hasColon) {
    const [firstPart, secondPart] = label.split(':');
    return (
      <Option {...props}>
        {firstPart}:<b>{secondPart}</b>
      </Option>
    );
  }

  return <Option {...props}>{label}</Option>;
};

const NestedMultiValueLabel = props => {
  const { label } = props.data;
  const hasColon = label?.includes(':');
  if (hasColon) {
    const [firstPart, secondPart] = label?.split(':');
    return (
      <MultiValueLabel {...props}>
        {firstPart}:<b>{secondPart}</b>
      </MultiValueLabel>
    );
  }

  return <MultiValueLabel {...props}>{label}</MultiValueLabel>;
};

const MultiSelectDropdown = ({
  defaultData,
  onChange,
  type = '',
  placeHolder,
  data,
  isSorted = true,
  isMulti = true,
  disabled = false,
  isClearable = true,
  closeOnSelect = false,
  styles = {},
  onMenuOpen = () => {},
  onMenuClose = () => {},
  menuIsOpen = undefined,
  menuPortalTarget = null,
  menuPlacement = 'auto',
  wrapperClass = '',
  theme,
}) => {
  const [selectedValues, setSelectedValues] = useState(defaultData);

  useEffect(() => {
    setSelectedValues(defaultData);
  }, [defaultData]); //eslint-disable-line

  const handleOnChange = (selected, { action, removedValue }) => {
    if (['remove-value', 'pop-value'].includes(action)) {
      if (removedValue?.isFixed) {
        return;
      }
    }
    setSelectedValues(selected || []);
    onChange(selected || [], type);
  };

  return (
    <Select
      id={type}
      menuPortalTarget={menuPortalTarget}
      styles={{ ...styles }}
      closeMenuOnSelect={closeOnSelect || !isMulti}
      value={selectedValues}
      isMulti={isMulti}
      options={getSortedOptions(data, isSorted)}
      placeholder={placeHolder}
      className={`basic-multi-select ${wrapperClass}`}
      classNamePrefix="select"
      onChange={handleOnChange}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      isClearable={isClearable}
      isDisabled={disabled}
      menuPlacement={menuPlacement}
      menuIsOpen={menuIsOpen}
      components={{
        Option: NestedOption,
        MultiValueLabel: NestedMultiValueLabel,
        Control: SearchControl,
      }}
      theme={getTheme(theme === 'dark')}
    />
  );
};

const mapStateToProps = createStructuredSelector({ theme: makeSelectTheme() });
const withConnect = connect(mapStateToProps, {});
export default compose(withConnect)(MultiSelectDropdown);
