import React from 'react';
import SingleSelectDropDown from 'components/Dropdown/SingleSelectDropDown';
import { CHARGEBACK_TYPE_VALUES } from '../constants';

const TypeSection = ({
  inputData: { type, type_value_predefined, type_value_custom },
  inputChange,
  radioChange,
  selectChange,
  errors,
}) => {
  const getDefaultPredefinedType = () => {
    const typeItem = CHARGEBACK_TYPE_VALUES.find(item => item.key === type_value_predefined);
    if (!typeItem) return {};
    return { value: typeItem.key, label: typeItem.label };
  };

  const radioTypes = [
    {
      key: 'predefined',
      label: 'Predefined:',
      extra: (
        <SingleSelectDropDown
          data={CHARGEBACK_TYPE_VALUES.map(type => ({
            value: type.key,
            label: type.label,
          }))}
          type="type_value_predefined"
          placeHolder="Please select chargeback type"
          onChange={selectChange}
          isClearable={false}
          isDisabled={type !== 'predefined'}
          defaultData={getDefaultPredefinedType()}
        />
      ),
    },
    {
      key: 'custom',
      label: 'Custom:',
      extra: (
        <input
          type="text"
          name="type_value_custom"
          className="np-input"
          placeholder="Please enter chargeback type"
          value={type_value_custom}
          onChange={inputChange}
          disabled={type !== 'custom'}
          maxLength={200}
        />
      ),
    },
  ];

  return (
    <>
      <div className="np-input-group">
        <label className="np-label">
          Chargeback Type <span className="red">*</span>
        </label>
        {radioTypes.map(item => (
          <div id={item.key} key={item.key} className="np-type-container">
            <div className="np-radio-container">
              <div className="np-radio">
                <input
                  id={`np-radio-${item.key}`}
                  name="type"
                  type="radio"
                  value={item.key}
                  checked={item.key === type}
                  onChange={radioChange}
                />
                <label className="np-radio-label" htmlFor={`np-radio-${item.key}`} />
              </div>
              <label className="np-filterLabel" htmlFor={`np-radio-${item.key}`}>
                {item.label}
              </label>
            </div>
            <div className="np-input-container">{item.extra}</div>
          </div>
        ))}
        {errors.type_value_custom && <span className="red">{errors.type_value_custom}</span>}
      </div>
    </>
  );
};

export default TypeSection;
