import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { DecreaseIcon, IncreaseIcon } from 'assets/images/common';

const PercentageCellWithIcon = value => (
  <span
    className={cx({
      red: value > 0,
      green: value < 0,
    })}
  >
    {value === 0 ? (
      '0%'
    ) : value > 0 ? (
      <>
        {`+${value}%`}
        <i className="red-fill">
          &nbsp;
          <IncreaseIcon />
          &nbsp;
        </i>
      </>
    ) : (
      <>
        {`${value}%`}
        <i className="green-fill">
          &nbsp;
          <DecreaseIcon />
          &nbsp;
        </i>
      </>
    )}
  </span>
);

PercentageCellWithIcon.propTypes = {
  value: PropTypes.number,
};

PercentageCellWithIcon.defaultProps = {
  value: 0,
};

export default PercentageCellWithIcon;
