import uniq from 'lodash/uniq';
import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { CommentIcon } from 'assets/images';
import { RESOURCE_DETAIL_SLICE } from '../reducer';
import CheckLogo from 'assets/images/log-check.png';
import CrossLogo from 'assets/images/log-cross.png';
import LoadingWrapper from 'components/ContentLoader/LoadingWrapper';
import { RowLoader } from 'components/ContentLoader/TableLoader';
import { RULES_DESCRIPTION_TEXT } from 'utils/RuleDetail';

export const ActivityLog = ({ data, isPartner }) => {
  const { overrideHistoryLoading, overrideHistoryData } = useSelector(state => state[RESOURCE_DETAIL_SLICE]);

  const rule_name = uniq((data?._source?.violations || []).map(violation => violation.violation_type));

  if (!rule_name.length) return null;
  return (
    <div
      className={cx('np-table2 np-activityLogWrap', {
        'np-activity-min-table': overrideHistoryData?.length > 2,
      })}
    >
      <div className="title">
        <div className="np-titleIcon teal">
          <CommentIcon />
        </div>
        Activity Log
      </div>

      <div className="np-row">
        <div className="npxs-12">
          <LoadingWrapper
            isLoading={overrideHistoryLoading}
            customLoader={RowLoader}
            customLoadingClassName="np-resource-details-loading"
          >
            <div className="np-activityLog">
              {!overrideHistoryData.length && !overrideHistoryLoading && <div className="data">No activity yet</div>}
              {overrideHistoryData.map(record => {
                const { reason, modified } = record;
                const modified_date = new Date(modified).toUTCString();
                let name = '';
                if (record?.user?.first_name) name = record.user.first_name + ' ' + record.user.last_name;
                else if (record?.user?.username) name = record.user.username;
                return (
                  <div key={modified_date} className="np-logItem">
                    <div className="gravatar">
                      <img src={record.became_compliant ? CheckLogo : CrossLogo} alt={'no-logo'} />
                    </div>
                    <b>{name}</b>
                    <p>Reason: {reason || 'No reason added'}</p>
                    <p>
                      {record.became_compliant ? 'Override 1 ' : 'Reverted 1 '}{' '}
                      {RULES_DESCRIPTION_TEXT[record.rule_name]?.name}
                    </p>
                    <small>On {modified_date}</small>
                  </div>
                );
              })}
            </div>
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};
