import React from 'react';
import Page from 'images/404-page-illustration.svg';
import { getEnv, PRODUCTION_ENV } from 'utils/env';

export const DefaultRoute = () => {
  const url = getEnv() === PRODUCTION_ENV ? 'https://app.nops.io/v3/' : 'https://uat2.nops.io/v3/';

  return (
    <div className="np-container">
      <div className="np-row">
        <div className="npxs-10 npxs-offset-1">
          <div className="np-error-404">
            <div className="np-err-inr">
              <h2>404</h2>
              <h4>Sorry, looks like you are lost!</h4>
              <p>
                This page doesn't seem to exist. You might have followed a bad link or mistyped the address, feel free
                to try again. Alternatively, you can <a href={url}>return to nOps dashboard</a> or, if you need help,{' '}
                <a href="mailto:support@nops.io" target="_blank" rel="noopener noreferrer">
                  just let us know
                </a>
                .
              </p>
              <div className="error-page-graphic">
                <img src={Page} alt={'404'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
