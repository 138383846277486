import React from 'react';
import ContentLoader from 'react-content-loader';
import { commonColorProps } from './commonProps';

const LandingPageLoader_PROPS = {
  speed: 2,
  width: '100%',
  height: '100',
};

const LandingPageLoader_TABLE_PROPS = {
  speed: 2,
  width: '100%',
  height: 550,
};

export const LandingPageLoader = (
  <>
    <ContentLoader {...LandingPageLoader_PROPS} {...commonColorProps}>
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
    </ContentLoader>
    <ContentLoader {...LandingPageLoader_TABLE_PROPS} {...commonColorProps}>
      <rect x="0" y="45" rx="3" ry="3" width="100%" height="17"></rect>
      <circle cx="90%" cy="123" r="11"></circle>
      <rect x="10%" y="115" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="114" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="114" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="155" rx="3" ry="3" width="100%" height="2"></rect>
      <circle cx="90%" cy="184" r="11"></circle>
      <rect x="10%" y="176" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="175" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="175" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="216" rx="3" ry="3" width="100%" height="2"></rect>
      <circle cx="90%" cy="242" r="11"></circle>
      <rect x="10%" y="234" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="233" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="233" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="274" rx="3" ry="3" width="100%" height="2"></rect>
      <circle cx="90%" cy="303" r="11"></circle>
      <rect x="10%" y="295" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="294" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="294" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="335" rx="3" ry="3" width="100%" height="2"></rect>
      <circle cx="90%" cy="363" r="11"></circle>
      <rect x="10%" y="355" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="354" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="354" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="395" rx="3" ry="3" width="100%" height="2"></rect>
      <circle cx="90%" cy="424" r="11"></circle>
      <rect x="10%" y="416" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="415" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="415" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="453" rx="3" ry="3" width="100%" height="2"></rect>
      <rect x="0" y="49" rx="3" ry="3" width="2" height="465"></rect>
      <rect x="99.8%" y="49" rx="3" ry="3" width="2" height="465"></rect>
      <circle cx="90%" cy="484" r="11"></circle>
      <rect x="10%" y="476" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="475" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="475" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="513" rx="3" ry="3" width="100%" height="2"></rect>
      <rect x="0" y="80" rx="3" ry="3" width="100%" height="17"></rect>
      <rect x="0" y="57" rx="3" ry="3" width="10%" height="33"></rect>
      <rect x="20%" y="54" rx="3" ry="3" width="10%" height="33"></rect>
      <rect x="50%" y="55" rx="3" ry="3" width="10%" height="33"></rect>
      <rect x="81%" y="56" rx="3" ry="3" width="7%" height="33"></rect>
      <rect x="92%" y="54" rx="3" ry="3" width="10%" height="33"></rect>
    </ContentLoader>
  </>
);

const DetailsCMPageLoader_PROPS = {
  speed: 2,
  width: '100%',
  height: '500',
};

export const DetailsCMPageLoader = (
  <ContentLoader {...DetailsCMPageLoader_PROPS} {...commonColorProps}>
    <circle cx="20" cy="20" r="20"></circle>
    <rect x="50" y="7" rx="5" ry="5" width="70%" height="25"></rect>
    <circle cx="20" cy="70" r="20"></circle>
    <rect x="50" y="57" rx="5" ry="5" width="70%" height="25"></rect>
    <circle cx="20" cy="120" r="20"></circle>
    <rect x="50" y="107" rx="5" ry="5" width="70%" height="25"></rect>
    <circle cx="20" cy="170" r="20"></circle>
    <rect x="50" y="157" rx="5" ry="5" width="70%" height="25"></rect>
    <circle cx="20" cy="220" r="20"></circle>
    <rect x="50" y="207" rx="5" ry="5" width="70%" height="25"></rect>
    <circle cx="20" cy="270" r="20"></circle>
    <rect x="50" y="257" rx="5" ry="5" width="70%" height="25"></rect>
    {/* <circle cx="20" cy="320" r="20"></circle>
    <rect x="50" y="307" rx="5" ry="5" width="70%" height="25"></rect>
    <circle cx="20" cy="370" r="20"></circle>
    <rect x="50" y="357" rx="5" ry="5" width="70%" height="25"></rect> */}
  </ContentLoader>
);

const RuleDetailsTitleLoader_PROPS = {
  speed: 2,
  width: '45%',
  height: 40,
};

export const RuleDetailsTitleLoader = (
  <>
    <ContentLoader {...RuleDetailsTitleLoader_PROPS} {...commonColorProps}>
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
    </ContentLoader>
  </>
);

const RuleDetailsBreadcrumbLoader_PROPS = {
  speed: 2,
  width: 180,
  height: 21,
};

export const RuleDetailsBreadcrumbLoader = (
  <>
    <ContentLoader {...RuleDetailsBreadcrumbLoader_PROPS} {...commonColorProps}>
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
    </ContentLoader>
  </>
);

const RuleDetailsTabLoader_PROPS = {
  speed: 2,
  width: 160,
  height: 22,
};

export const RuleDetailsTabLoader = (
  <>
    <ContentLoader {...RuleDetailsTabLoader_PROPS} {...commonColorProps}>
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
    </ContentLoader>
  </>
);
