export const colors = [
  {
    backgroundColor: '#0071EA',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#1A7FEC',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#07ACFC',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#39BDFD',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#22C7E3',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#38CDE6',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#004FA4',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#0066D3',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#F95B5B',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#FA7C7C',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#FFAA5E',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#FFBB7E',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#00D0A4',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#33D9B6',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#5D54E1',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#7D76E7',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#E91E63',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#F06292',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#26A69A',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#B2DFDB',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#607D8B',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#B0BEC5',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#C0CA33',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#D4E157',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#9C27B0',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#BA68C8',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#4DDEBF',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#80E8D2',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#FA7C7C',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#FB9D9D',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#7D76E7',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#9E98ED',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#F06292',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#F8BBD0',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#80CBC4',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#B2DFDB',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#A1887F',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#D7CCC8',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#B0BEC5',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#CFD8DC',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#D4E157',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#E6EE9C',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#BA68C8',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#E1BEE7',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#002246',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#00448C',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#4D9CF0',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#80B8F5',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#7ADDEE',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#A7E9F4',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#FFB36E',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#FFC48E',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#147788',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#188B9F',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#FFCC9E',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#FFDDBF',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#996638',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#CC884B',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#009273',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#00BB94',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#795548',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#A1887F',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#FB9D9D',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#FDBDBD',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#006852',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#009273',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#AEAAF0',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#BEBBF3',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#83D6FE',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#9CDEFE',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#383287',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#544CCB',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#AD1457',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#F06292',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#00796B',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#009688',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#546E7A',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#90A4AE',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#4E342E',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#795548',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#CE93D8',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#F3E5F5',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#9E9D24',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#CDDC39',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#0578B0',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#069BE3',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#880E4F',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#D81B60',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#004D40',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#00796B',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#654C27',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#7F5F31',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#37474F',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#607D8B',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#E6EE9C',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#F0F4C3',
    hoverBorderColor: '#FFFFFF',
  },
  {
    backgroundColor: '#6A1B9A',
    borderColor: '#FFFFFF',
    hoverBackgroundColor: '#9C27B0',
    hoverBorderColor: '#FFFFFF',
  },
];

export const otherDataColors = {
  backgroundColor: '#607D8B',
  borderColor: '#FFFFFF',
  hoverBackgroundColor: '#B0BEC5',
  hoverBorderColor: '#FFFFFF',
};
