export const LOAD_CLUSTER_STATUS_REQUEST = 'COST/LOAD_CLUSTER_STATUS_REQUEST';
export const LOAD_CLUSTER_STATUS_SUCCESS = 'COST/LOAD_CLUSTER_STATUS_SUCCESS';
export const LOAD_CLUSTER_STATUS_ERROR = 'COST/LOAD_CLUSTER_STATUS_ERROR';
export const LOAD_MRC_COST_DATA_REQUEST = 'COST/LOAD_MRC_COST_REQUEST';
export const LOAD_MRC_COST_DATA_SUCCESS = 'COST/LOAD_MRC_COST_SUCCESS';
export const LOAD_MRC_COST_DATA_ERROR = 'COST/LOAD_MRC_COST_ERROR';
export const LOAD_COST_SUMMARY_REQUEST = 'COST/LOAD_COST_SUMMARY_REQUEST';
export const LOAD_COST_SUMMARY_SUCCESS = 'COST/LOAD_COST_SUMMARY_SUCCESS';
export const LOAD_COST_SUMMARY_CREDITS_SUCCESS = 'COST/LOAD_COST_SUMMARY_CREDITS_SUCCESS';
export const LOAD_COST_SUMMARY_ERROR = 'COST/LOAD_COST_SUMMARY_ERROR';
export const LOAD_ALL_COST_DATA_REQUEST = 'COST/LOAD_ALL_COST_REQUEST';
export const LOAD_ALL_COST_DATA_SUCCESS = 'COST/LOAD_ALL_COST_SUCCESS';
export const LOAD_ALL_COST_DATA_ERROR = 'COST/LOAD_ALL_COST_ERROR';
export const LOAD_FILTER_DATA_REQUEST = 'COST/LOAD_FILTER_REQUEST';
export const LOAD_FILTER_DATA_SUCCESS = 'COST/LOAD_FILTER_SUCCESS';
export const LOAD_FILTER_DATA_ERROR = 'COST/LOAD_FILTER_ERROR';
export const LOAD_DEFAULT_FILTER_DATA_REQUEST = 'COST/LOAD_DEFAULT_FILTER_REQUEST';
export const LOAD_DEFAULT_FILTER_DATA_SUCCESS = 'COST/LOAD_DEFAULT_FILTER_SUCCESS';
export const LOAD_DEFAULT_FILTER_DATA_ERROR = 'COST/LOAD_DEFAULT_FILTER_ERROR';
export const LOAD_COST_DETAIL_REQUEST = 'COST/LOAD_COST_DETAIL_REQUEST';
export const LOAD_COST_DETAIL_SUCCESS = 'COST/LOAD_COST_DETAIL_SUCCESS';
export const LOAD_COST_DETAIL_ERROR = 'COST/LOAD_COST_DETAIL_ERROR';
export const LOAD_COST_TAGS_REQUEST = 'COST/LOAD_COST_TAGS_REQUEST';
export const LOAD_COST_TAGS_SUCCESS = 'COST/LOAD_COST_TAGS_SUCCESS';
export const LOAD_COST_TAGS_ERROR = 'COST/LOAD_COST_TAGS_ERROR';

export const LOAD_COST_CHARGEBACK_ERROR = 'COST/LOAD_COST_CHARGEBACK_ERROR';
export const LOAD_COST_CHARGEBACK_SUCCESS = 'COST/LOAD_COST_CHARGEBACK_SUCCESS';
export const LOAD_COST_CHARGEBACK_REQUEST = 'COST/LOAD_COST_CHARGEBACK_REQUEST';
export const CREATE_COST_CHARGEBACK_ERROR = 'COST/CREATE_COST_CHARGEBACK_ERROR';
export const CREATE_COST_CHARGEBACK_SUCCESS = 'COST/CREATE_COST_CHARGEBACK_SUCCESS';
export const CREATE_COST_CHARGEBACK_REQUEST = 'COST/CREATE_COST_CHARGEBACK_REQUEST';
export const UPDATE_MONTHLY_COST_CHARGEBACK = 'COST/UPDATE_MONTHLY_COST_CHARGEBACK';
export const UPDATE_COST_CHARGEBACK_ERROR = 'COST/UPDATE_COST_CHARGEBACK_ERROR';
export const UPDATE_COST_CHARGEBACK_SUCCESS = 'COST/UPDATE_COST_CHARGEBACK_SUCCESS';
export const UPDATE_COST_CHARGEBACK_REQUEST = 'COST/UPDATE_COST_CHARGEBACK_REQUEST';
export const DELETE_COST_CHARGEBACK_ERROR = 'COST/DELETE_COST_CHARGEBACK_ERROR';
export const DELETE_COST_CHARGEBACK_SUCCESS = 'COST/DELETE_COST_CHARGEBACK_SUCCESS';
export const DELETE_COST_CHARGEBACK_REQUEST = 'COST/DELETE_COST_CHARGEBACK_REQUEST';
export const LOAD_COST_CM_REQUEST = 'COST/LOAD_COST_CM_REQUEST';
export const LOAD_COST_CM_SUCCESS = 'COST/LOAD_COST_CM_SUCCESS';
export const LOAD_COST_CM_ERROR = 'COST/LOAD_COST_CM_ERROR';

export const LOAD_OPERATION_REQUEST = 'COST/LOAD_OPERATION_SAVINGS_REQUEST';
export const LOAD_OPERATION_SUCCESS = 'COST/LOAD_OPERATION_SAVINGS_SUCCESS';
export const LOAD_OPERATION_ERROR = 'COST/LOAD_OPERATION_SAVINGS_ERROR';

export const LOAD_USAGE_TYPE_REQUEST = 'COST/LOAD_USAGE_TYPE_SAVINGS_REQUEST';
export const LOAD_USAGE_TYPE_SUCCESS = 'COST/LOAD_USAGE_TYPE_SAVINGS_SUCCESS';
export const LOAD_USAGE_TYPE_ERROR = 'COST/LOAD_USAGE_TYPE_SAVINGS_ERROR';
