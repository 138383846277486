import React, { useEffect, useState, useRef } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { colors } from '../Color';

const HorizontalChart = ({ dataSet, handleClick, getClickedElem, labels, showXAxes = true, showYAxes = true }) => {
  const [chartHeight, setChartHeight] = useState('100px');
  const chartRef = useRef();

  useEffect(() => {
    const data_split = Math.round(dataSet.length / 6);
    setChartHeight(`${100 + data_split * 34}px`);
  }, [dataSet]);

  const onClick = e => {
    const eventDetail = getElementAtEvent(chartRef.current, e);
    if (!eventDetail.length) return null;
    if (typeof handleClick === 'function') {
      handleClick(dataSet[eventDetail[0].datasetIndex].label);
    }
    if (typeof getClickedElem === 'function') {
      getClickedElem(dataSet[eventDetail[0].datasetIndex].label);
    }
  };

  const newDS = [];

  dataSet.forEach((row, i) => {
    const color = colors[i % colors.length];
    newDS.push({
      axis: 'y',
      data: row.data,
      label: row.label,
      fill: true,
      lineTension: 0.1,
      backgroundColor: color.backgroundColor,
      borderColor: color.borderColor,
      hoverBackgroundColor: color.hoverBackgroundColor,
      hoverBorderColor: color.hoverBorderColor,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#22C7E3',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#22C7E3',
      pointHoverBorderColor: '#22C7E3',
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      borderWidth: 1,
      pointHitRadius: 40,
      stack: '1',
    });
  });

  return (
    <div className="chart_js" data-testid="HorizontalChart" style={{ height: chartHeight }}>
      <Bar
        ref={chartRef}
        data={{
          labels: [labels],
          datasets: newDS,
        }}
        options={{
          indexAxis: 'y',
          plugins: {
            legend: {
              fullSize: true,
              position: 'bottom',
            },
            tooltips: {
              enabled: true,
              mode: 'label',
              position: 'average',
              intersect: true,
              axis: 'y',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              display: showYAxes,
              stacked: true,
            },
            x: {
              stacked: true,
              display: showXAxes,
              beginAtZero: true,
              ticks: {
                precision: 0,
                callback: function (value, index, values) {
                  if (Number.isNaN(value)) return;
                  return value;
                },
              },
            },
          },
        }}
        // height={50}
        // width={width || 50}
        onClick={onClick}
        type="bar"
      />
    </div>
  );
};

export default HorizontalChart;
