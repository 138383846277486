import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { Anchor } from 'components/Custom';
import { DollarGreenIcon } from 'assets/images/cost';
import { PlusIcon } from 'assets/images';
import ErrorBoundary from 'components/Common/ErrorBoundary';
import SummaryCost from './SummaryCost';

const SpendSummary = React.memo(
  ({
    loading,
    data,
    onCreateChargeback,
    isShowForecast,
    estimatedSpend,
    startDate,
    endDate,
    configCatAzure,
    selectedTab,
    handleExcludeCredits,
    includeCredit,
    summaryCreditsData,
    isMultipleCurrency = false,
    azure_billing_currency = 'USD',
    isAWSSelected,
  }) => {
    return (
      <div className="nplg-12 npmd-12 npsm-12 npxs-12">
        <div className="np-card">
          <div className="np-cardActions">
            <Anchor id="createBudget" onClick={() => onCreateChargeback({})} className="np-button blue">
              <PlusIcon /> Create New Chargeback
            </Anchor>
          </div>
          <div className="title">
            <div className="np-cardIcon blue">
              <DollarGreenIcon />
            </div>
            Spend Summary
          </div>
          <ErrorBoundary>
            <SummaryCost
              loading={loading}
              data={data}
              estimatedSpend={estimatedSpend}
              isShowForecast={isShowForecast}
              startDate={startDate}
              endDate={endDate}
              configCatAzure={configCatAzure}
              selectedTab={selectedTab}
              handleExcludeCredits={handleExcludeCredits}
              includeCredit={includeCredit}
              summaryCreditsData={summaryCreditsData}
              isMultipleCurrency={isMultipleCurrency}
              isAWSSelected={isAWSSelected}
              azure_billing_currency={azure_billing_currency}
            />
          </ErrorBoundary>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.data, nextProps.data) &&
      prevProps.estimatedSpend === nextProps.estimatedSpend &&
      prevProps.isShowForecast === nextProps.isShowForecast &&
      prevProps.startDate === nextProps.startDate &&
      prevProps.endDate === nextProps.endDate
    );
  },
);

SpendSummary.propTypes = {
  data: PropTypes.shape(),
  onCreateChargeback: PropTypes.func,
};

SpendSummary.defaultProps = {
  data: {},
  onCreateChargeback: undefined,
};

export default SpendSummary;
