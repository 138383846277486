import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import { AgGridReact } from 'ag-grid-react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { compose } from 'redux';
import { InfoNewIcon } from 'assets/images/workload-images';
import { CostChargeback } from 'assets/images/cost';
import reducer, { loadSummaryDetailData, loadResourceDetailData, RESOURCE_DETAIL_COST_PAGE } from './reducer';
import { CustomSideModal } from 'components/CustomModal';
import ResourceDetails from 'containers/Search/Detail';
import { numberWithCommas, setDefaultFilterData } from 'utils/helper';
import { FILTER_DATE_FORMAT } from 'utils/filter';
import { AG_GRID_DEFAULT_CONFIG } from 'utils/constants';
import injectReducer from 'utils/injectReducer';
import { COST_TYPE } from 'utils/constants';
import LoadingWrapper from 'components/ContentLoader/LoadingWrapper';
import { RowLoader, TableLoader } from 'components/ContentLoader/TableLoader';
import { Anchor } from 'components/Custom';
import { DownloadIcon } from 'assets/images';
import { DropDownArrowIcon } from 'assets/images/nOpsRule';
import { ReactComponent as ResourceIcon } from './resourcesTealIcon.svg';
import { detailRenderer, nameRenderer, issueRenderer, getResourceData, getNonResourceData } from './helper';
// import { currencyValueFormatter } from '../../TagExplorer/helper';
import ScrollControls from 'components/ScrollControls';
import { getNewQueryString } from 'utils/queryString';
import { useLocation } from 'react-router-dom';
import { currencyByCodeFormatter, currencyFormatterByCode } from 'utils/currencySymbol';
import { RULES_DESCRIPTION_TEXT } from 'utils/RuleDetail';

const resource_columns = currencyCode => [
  {
    field: 'name',
    headerName: 'Resource ID',
    cellRenderer: 'nameRenderer',
    width: 300,
  },
  {
    field: 'details',
    headerName: 'Details',
    cellRenderer: 'detailRenderer',
    width: 300,
  },
  {
    field: 'issues',
    headerName: 'Issues',
    cellRenderer: 'issueRenderer',
  },
  {
    field: 'cost',
    headerName: 'Cost',
    filter: 'agNumberColumnFilter',
    // valueFormatter: currencyValueFormatter,
    valueFormatter: params => currencyByCodeFormatter(params, currencyCode),
  },
];

const non_resource_columns = currencyCode => [
  {
    field: 'name',
    headerName: 'Name',
    width: 500,
  },
  {
    field: 'type',
    headerName: 'Type',
  },
  {
    field: 'cost',
    headerName: 'Cost',
    filter: 'agNumberColumnFilter',
    // valueFormatter: currencyValueFormatter,
    valueFormatter: params => currencyByCodeFormatter(params, currencyCode),
  },
];

const TABS = ['Resources', 'Non-Resources'];
const ResourceDetailDialog = ({
  startDate,
  endDate,
  isMonth,
  isDaily,
  title,
  totalEndDate,
  row,
  type,
  aggName,
  filters,
  launchEnd,
  tagData,
  searchString = '*',
  launchStart,
  configCatAzure,
  onClose,
  isNonResourceTab,
  isMultipleCurrency = false,
  isAWSSelected = true,
  azure_billing_currency = 'USD',
}) => {
  const [showModal, setShowModal] = useState({});
  const [payload, setPayload] = useState({});
  const [selectedFilter, setSelectedFilter] = useState('operations');
  const [activeTab, setActiveTab] = useState(!isNonResourceTab ? TABS[0] : TABS[1]);
  const isDemoMode =
    sessionStorage.getItem('demo-mode') === 'on' &&
    [COST_TYPE.AWS_ACCOUNTS, COST_TYPE.CLOUD_ACCOUNTS, COST_TYPE.RESOURCES, COST_TYPE.NON_RESOURCES].includes(type);

  const dispatch = useDispatch();
  const location = useLocation();
  const {
    resourceLoading = false,
    summaryData = {},
    counter = {},
    summaryLoading = false,
    resourcesData = [],
    nonResourcesData = [],
  } = useSelector(state => state[RESOURCE_DETAIL_COST_PAGE]);

  useEffect(() => {
    let filerParam = setDefaultFilterData({
      filters: { ...filters.filters },
      tagData,
    }).filters;
    const moreParams = {};
    if (launchStart || launchEnd) {
      moreParams.range = {
        'cost.dates_available': {
          gte: launchStart && launchStart.format(FILTER_DATE_FORMAT),
          lte: launchEnd && launchEnd.format(FILTER_DATE_FORMAT),
          relation: 'within',
        },
      };
    }

    let newParam = [];
    switch (type) {
      // case 'AWS Accounts':
      case COST_TYPE.AWS_ACCOUNTS:
        newParam = [{ 'project.id': [row.id] }];
        break;
      case COST_TYPE.REGIONS:
        newParam = [{ 'region.keyword': [row.id] }];
        break;
      // case 'AWS Managed Services':
      case COST_TYPE.CLOUD_PRODUCTS:
        newParam = [{ 'product.keyword': [row.id] }];
        break;
      case COST_TYPE.RESOURCES:
        newParam = [{ 'cost.meta.resource.keyword': [row.id] }];
        break;
      case COST_TYPE.NON_RESOURCES:
        newParam = [{ 'product.keyword': [row.id] }];
        break;
      case COST_TYPE.USAGE_TYPES:
        newParam = [
          {
            'nested:cost.usagetype:cost.usagetype.item_type': [row.id],
          },
        ];
        // aggregations.push('arp_resources_usagetype');
        break;
      case COST_TYPE.OPERATIONS:
        newParam = [
          {
            'nested:cost.operation:cost.operation.item_type': [row.id],
          },
        ];
        // aggregations.push('arp_resources_operation');
        break;
      case COST_TYPE.TAGS:
        newParam = [{ [aggName]: [row.id] }];
        break;
      default:
        break;
    }
    filerParam = {
      ...filerParam,
      must: [...filerParam['must'], ...newParam],
      _multiple_conditions: true,
    };
    const newEndDate = totalEndDate
      ? totalEndDate
      : isMonth
        ? moment(startDate).endOf('month').format(FILTER_DATE_FORMAT)
        : type === 'Tags'
          ? endDate
          : startDate;

    const payload = {
      q: searchString || '*',
      filters: { ...filerParam },
      scope: 'client',
      aggregations:
        'arp_resources_v2:arp_billing_type_non_resources_usage_types:arp_billing_type_non_resources_operations',
      show_active: null,
      cost_from: startDate,
      cost_to: newEndDate,
      ...moreParams,
    };

    configCatAzure && (payload.cloud_type = 'all');

    if (isMonth) {
      payload.cost_interval = 'month';
    } else {
      payload.cost_sort = {
        type: 'desc',
        range: { from: startDate, to: newEndDate },
      };
    }
    dispatch(
      loadResourceDetailData({
        parameters: payload,
        type: 'query',
        configCatAzure,
      }),
    );
    setPayload(payload);
    const summaryParams = cloneDeep({
      parameters: payload,
      type: 'query',
      configCatAzure,
    });
    summaryParams['parameters']['aggregations'] = configCatAzure ? 'arp_resources_v2_summary' : 'arp_spend_summary';
    if (configCatAzure) {
      summaryParams['parameters']['cloud_type'] = 'all';
    }
    dispatch(loadSummaryDetailData(summaryParams));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!nonResourcesData?.operations?.length && nonResourcesData?.usageTypes?.length) {
      setSelectedFilter('usageTypes');
    }
  }, [nonResourcesData]);

  const costValue = value => currencyFormatterByCode(isMultipleCurrency, isAWSSelected, value, azure_billing_currency);

  const renderHeader = () => {
    return (
      <div className="cost-details-header">
        <div className="np-card">
          <div className="title">
            <div className="np-cardIcon blue">
              <CostChargeback />
            </div>
            {!isNonResourceTab ? 'Resources' : ''} Summary
          </div>

          <LoadingWrapper isLoading={summaryLoading} customLoader={RowLoader}>
            <>
              {!isNonResourceTab ? (
                <>
                  <div className="nplg-4 npmd-6 npsm-12 npxs-12">
                    <div>
                      <div className="np-cardInner decrease ">
                        <div className="descTitle cost-details-cardInner-wrapper">
                          Total cost
                          <div>
                            <Tippy
                              content="The overall total spending of this AWS account."
                              placement="right"
                              delay="400"
                            >
                              <InfoNewIcon />
                            </Tippy>
                          </div>
                        </div>
                        <div className="stat">{costValue(summaryData?.totalCost || 0)}</div>
                        <div className="desc">
                          for {numberWithCommas(counter?.totalCount || 0)} {isNonResourceTab ? 'non-' : ''}resources
                          cost
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nplg-3 npmd-6 npsm-12 npxs-12">
                    <div>
                      <div className="np-cardInner decrease">
                        <div className="descTitle cost-details-cardInner-wrapper">
                          Total Resource Cost
                          <div>
                            <Tippy
                              content="The total cost of AWS services and their resources, such as S3, NAT, etc."
                              placement="right"
                              delay="400"
                            >
                              <InfoNewIcon />
                            </Tippy>
                          </div>
                        </div>
                        <div className="stat">{costValue(summaryData?.resourceCost || 0)}</div>
                        <div className="desc" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className="nplg-3 npmd-6 npsm-12 npxs-12">
                <div>
                  <div className="np-cardInner decrease">
                    <div className="descTitle cost-details-cardInner-wrapper">
                      Total Non-Resource Cost
                      <div>
                        <Tippy
                          content="The total cost of operation in this AWS account, i.e., retrieving/sending information between AWS services."
                          placement="right"
                          delay="400"
                        >
                          <InfoNewIcon />
                        </Tippy>
                      </div>
                    </div>
                    <div className="stat">{costValue(summaryData?.nonResourceCost || 0)}</div>
                    <div className="desc" />
                  </div>
                </div>
              </div>

              <div className="nplg-2 npmd-6 npsm-12 npxs-12">
                <div>
                  <span className="np-cardInner saveMoney">
                    <div className="descTitle cost-details-cardInner-wrapper">
                      Credits
                      <div>
                        <Tippy content="AWS Credits." placement="right" delay="400">
                          <InfoNewIcon />
                        </Tippy>
                      </div>
                    </div>
                    <div className="stat">
                      {summaryData?.credits < 0
                        ? `-${costValue(Math.abs(summaryData.credits))}`
                        : `${costValue(summaryData?.credits || 0)}`}
                    </div>
                    <div className="desc" />
                  </span>
                </div>
              </div>
            </>
          </LoadingWrapper>
        </div>
      </div>
    );
  };

  const handleDownloadClick = () => {
    const search = getNewQueryString(location.search, {
      parameters: JSON.stringify({
        ...payload,
        aggregations:
          activeTab === TABS[0]
            ? 'arp_resources_csv'
            : selectedFilter === 'operations'
              ? 'arp_billing_type_non_resources_operations_csv'
              : 'arp_billing_type_non_resources_usage_types_csv',
      }),
    });
    window.open(`/c/ac/query/csv?${search}`, '_blank');
  };

  const total =
    activeTab === TABS[0]
      ? resourcesData?.length
      : selectedFilter === 'operations'
        ? nonResourcesData?.operations?.length || 0
        : nonResourcesData?.usageTypes?.length || 0;
  const renderTable = () => {
    return (
      <div className="np-row">
        <div className="npxs-12">
          <div className="np-table2 cost-detail np-cost-detail-wrapper np-tableIcons np-v-top">
            <div className="title">
              <div className="np-cost-details-action">
                <div className="np-cardActions">
                  {TABS.map(tab => {
                    if (isNonResourceTab && tab === 'Resources') return null;
                    return (
                      <Anchor
                        key={tab}
                        className={cx('np-button color-grey light', {
                          active: tab === activeTab,
                        })}
                        onClick={() => setActiveTab(tab)}
                      >
                        {tab}
                      </Anchor>
                    );
                  })}
                </div>
              </div>
            </div>
            {resourceLoading ? null : (
              <div className=" np-table2Actions">
                <Anchor className="np-button round blue light" onClick={() => handleDownloadClick()}>
                  <DownloadIcon /> <b>Download CSV</b>
                  {/* {total} Resources*/}
                </Anchor>
              </div>
            )}
            <div className="np-table2-wrapper " id="np-table2-wrapper">
              {resourceLoading ? null : (
                <div className="np-cost-table-header">
                  <div className="np-cost-table-header-left">
                    <span className="d-flex flex-aic">
                      <ResourceIcon />
                      &nbsp;
                      {total} of{' '}
                      {activeTab === TABS[0]
                        ? numberWithCommas(counter?.resourceCount || 0)
                        : numberWithCommas(counter?.nonResourceCount || 0)}{' '}
                      {activeTab} Displayed
                    </span>
                  </div>

                  {activeTab === TABS[0] ? null : (
                    <div className="np-select-group">
                      <select
                        className="np-input"
                        value={selectedFilter}
                        onChange={({ target: { value = '' } = {} }) => setSelectedFilter(value)}
                      >
                        <option value="operations">Operations</option>
                        <option value="usageTypes">Usage Types</option>
                      </select>

                      <div className="np-caret">
                        <DropDownArrowIcon />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <LoadingWrapper isLoading={resourceLoading} customLoader={TableLoader}>
                <div className="np-table2-wrapper">
                  <ScrollControls />
                  <div className="ag-theme-alpine w-full">
                    <AgGridReact
                      suppressContextMenu={true}
                      onGridReady={params => params.api.sizeColumnsToFit()}
                      columnDefs={
                        activeTab === TABS[0]
                          ? resource_columns(isMultipleCurrency && !isAWSSelected ? azure_billing_currency : 'USD')
                          : non_resource_columns(isMultipleCurrency && !isAWSSelected ? azure_billing_currency : 'USD')
                      }
                      defaultColDef={{
                        ...AG_GRID_DEFAULT_CONFIG,
                        autoHeight: activeTab === TABS[0],
                      }}
                      components={{
                        nameRenderer: nameRenderer(
                          configCatAzure,
                          record =>
                            setShowModal({
                              id: record.id,
                              projectId: record.projectId,
                            }),
                          isDemoMode,
                        ),
                        issueRenderer,
                        detailRenderer,
                      }}
                      rowData={
                        activeTab === TABS[0]
                          ? getResourceData(resourcesData, isDaily, RULES_DESCRIPTION_TEXT)
                          : getNonResourceData(nonResourcesData, selectedFilter)
                      }
                      domLayout={'autoHeight'}
                      pagination
                      paginationPageSize={25}
                      rowClassRules={{
                        blueCola: params => params?.data?.cloud_type === 'azure',
                        orangePeel: params => params?.data?.cloud_type === 'aws',
                      }}
                    />
                  </div>
                </div>
              </LoadingWrapper>
            </div>
          </div>
        </div>
        <div className="np-input-group">
          <hr />
          <button className="np-button light close" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );
  };
  const formattedDate = totalEndDate
    ? moment(startDate).isSame(moment(totalEndDate))
      ? moment(startDate).format(isMonth ? 'MMM, YYYY' : 'MMM DD, YYYY')
      : moment(startDate).format('MMM DD, YYYY') + ' to ' + moment(totalEndDate).format('MMM DD, YYYY')
    : moment(startDate).format(isMonth ? 'MMM, YYYY' : 'MMM DD, YYYY');

  return (
    <CustomSideModal
      wrapperClass="np-resourceDetail np-non-resourceDetail"
      title={title ? title : `Cost details of ${isDemoMode ? '********' : row?.name} for "${formattedDate}"`}
      onClose={onClose}
    >
      <div className="body">
        {renderHeader()}
        {renderTable()}
      </div>
      {!isEmpty(showModal) ? (
        <ResourceDetails {...showModal} isCurrencyVisible={true} onClose={() => setShowModal({})} />
      ) : null}
    </CustomSideModal>
  );
};

const withReducer = injectReducer({ key: RESOURCE_DETAIL_COST_PAGE, reducer });

export default compose(withReducer)(ResourceDetailDialog);
