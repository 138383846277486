import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { withRouter } from 'react-router';
import ReactPaginate from 'react-paginate';
import Tippy from '@tippyjs/react';
import { CSVLink } from 'react-csv';
import { InfoGreyIcon, LinkIcon } from 'assets/images/common';
import { DollarGreenIcon } from 'assets/images/cost';
import { SmallCloseIcon } from 'assets/images/modal';
import { DownloadIcon, SearchIcon } from 'assets/images';
import NoDetectionIcon from 'assets/images/no-detection.svg';
import { COST_TYPE, ROUTES_PATH } from 'utils/constants';
import { checkIsEqual, IsJsonValid, IsURIValid, getClassNameBasedOnCloud, getIconBasedOnCloud } from 'utils/helper';
import { currencyFormat } from 'utils/number';
import { getNewQueryString, getParamFromQueryString, removeQueryString } from 'utils/queryString';
import { Anchor } from 'components/Custom';
import ResourceDetailDialog from './CostDetail/ResourceDetailDialog';
import { selectedIsMonth } from './TableChart';
import { changeDateToStringFormat, FILTER_DATE_FORMAT, FILTER_META_DATA } from 'utils/filter';
import ResourceDetails from 'containers/Search/Detail';
import { currencyFormatterByCode } from 'utils/currencySymbol';

const typesToLink = [COST_TYPE.AWS_ACCOUNTS, COST_TYPE.REGIONS, COST_TYPE.AWS_PRODUCTS, COST_TYPE.RESOURCES];

const typesToSort = [COST_TYPE.RESOURCES, COST_TYPE.OPERATIONS, COST_TYPE.USAGE_TYPES];

class BaseTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showDetail: false,
      selectedRec: {},
      menu: {
        isOpenDownloadMenu: false,
        isSearchMenuOpen: false,
      },
      searchString: '',
      displayData: [],
      reverseColumn: props.columns.reverse(),
      totalSpend: 0,
      totalSpendData: [],
      totalEndDate: '',
      totalMRC: 0,
      showModal: false,
      record: {},
    };
  }

  componentDidMount() {
    const data = this.setDisplayData();
    this.setState({
      displayData: data.dataToDisplay,
      totalSpend: data.totalSpend,
      totalSpendData: data.spendData,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { menu } = this.state;
    const { type, data, columns } = this.props;
    if (!isEqual(type, prevProps.type) || !isEqual(data, prevProps.data)) {
      const data = this.setDisplayData();
      this.setState({
        displayData: data.dataToDisplay,
        totalSpend: data.totalSpend,
        totalSpendData: data.spendData,
        totalMRC: data.totalMRC,
      });
    }

    if (columns.length && checkIsEqual(false, columns, prevProps.columns)) {
      this.setState(
        {
          reverseColumn: this.props.columns.reverse(),
        },
        () => {
          const data = this.setDisplayData();
          this.setState({
            displayData: data.dataToDisplay,
            totalSpend: data.totalSpend,
            totalSpendData: data.spendData,
            totalMRC: data.totalMRC,
          });
        },
      );
    }

    setTimeout(() => {
      if (menu.isOpenDownloadMenu || menu.isSearchMenuOpen) {
        window.addEventListener('click', this.closeMenu);
      } else {
        window.removeEventListener('click', this.closeMenu);
      }
    }, 0);
  }

  openMenu = name => () => {
    const temp = cloneDeep(this.state.menu);
    temp[name] = !this.state.menu[name];
    this.setState({
      menu: temp,
      searchString: this.props.showKeyword ? this.state.searchString : '',
    });
  };

  closeMenu = e => {
    if (e.target.closest('.dynamic-search')) return;
    const temp = cloneDeep(this.state.menu);
    temp['isSearchMenuOpen'] = false;
    temp['isOpenDownloadMenu'] = false;
    this.setState({ menu: temp });
  };

  setDisplayData = () => {
    let totalSpend = 0,
      totalMRC = 0;
    const dataToDisplay = [];
    const spendData = {};
    const { data } = this.props;
    const { reverseColumn } = this.state;

    data.length > 0 &&
      data.forEach((x, index) => {
        reverseColumn.forEach(c => {
          dataToDisplay[index] = {
            ...dataToDisplay[index],
            [c]: (x.data && x.data[c]) || 0,
          };
        });
        dataToDisplay[index] = {
          ...dataToDisplay[index],
          ...x,
          name: x.name,
          total: x.total,
          mrc: x.mrc,
          id: x.id,
          cloudPlatform: x.cloudPlatform,
        };
        totalSpend += x.total;
        totalMRC += x.mrc;
      });

    dataToDisplay.forEach(d => {
      reverseColumn.forEach(r => {
        if (spendData[r]) {
          spendData[r] += d[r];
        } else {
          spendData[r] = d[r];
        }
      });
    });
    return {
      dataToDisplay,
      totalSpend,
      spendData,
      totalMRC,
    };
  };

  renderTableActions() {
    const { type } = this.props;
    const { displayData = [], reverseColumn } = this.state;

    const searchOpenClass = classNames({
      'np-dropdownContent sort right': true,
      open: this.state.menu.isSearchMenuOpen,
      'dynamic-search': true,
    });
    const header = [
      { key: 'name', label: 'Project Name' },
      { key: 'total', label: 'Total' },
    ];
    if (typesToLink.includes(type) && type !== COST_TYPE.RESOURCES) {
      header.push({ key: 'mrc', label: 'NET 3MRC GROWTH' });
    }
    const detail = this.getDateFormat();
    reverseColumn.forEach(x => {
      header.push({
        key: x,
        label: moment(x).format(detail.dateFormat),
      });
    });
    return (
      <div className="npxs-6">
        <div className="actions">
          <div className="actionItem np-dropdownClick">
            <div id={'searchIcon'} className="actionLink np-dropdownLink" onClick={this.openMenu('isSearchMenuOpen')}>
              <div className="np-cardIcon">
                <SearchIcon />
              </div>
              Search
            </div>

            <div className={searchOpenClass}>
              <div className="np-input-icon">
                <input
                  id="search"
                  type="text"
                  name="searchString"
                  value={this.state.searchString}
                  placeholder="Search for type.."
                  className="np-input"
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyPress}
                  autoFocus
                />
                <SearchIcon />
              </div>
            </div>
          </div>

          {displayData.length > 0 && (
            <div className="actionItem np-dropdownClick">
              <div className="actionLink np-dropdownLink">
                <CSVLink className="np-cardIcon" data={displayData} headers={header} filename={`export_${type}.csv`}>
                  <div className="np-cardIcon">
                    <DownloadIcon />
                  </div>
                  &nbsp;Download CSV
                </CSVLink>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  onSelectDetail = (row, dateRow, endDate) => () => {
    this.startDate = dateRow;
    this.setState({
      record: { id: row.linkIds.resourceID, projectId: row.linkIds.projectId },
      showDetail: true,
      selectedRec: row,
      totalEndDate: endDate ? endDate : '',
    });
  };

  closeDetail = () => {
    this.setState({ showDetail: false });
  };

  typeToClass(type) {
    switch (type) {
      case COST_TYPE.AWS_ACCOUNTS:
        return 'arp-accounts';
      case COST_TYPE.REGIONS:
        return 'arp-regions';
      case COST_TYPE.AWS_PRODUCTS:
        return 'arp-products';
      case COST_TYPE.RESOURCES:
        return 'arp-resources';
      case COST_TYPE.NON_RESOURCES:
        return 'arp-non-resources';
      case COST_TYPE.USAGE_TYPES:
        return 'arp-usagetypes';
      case COST_TYPE.OPERATIONS:
        return 'arp-operations';
      case COST_TYPE.TAGS:
        return 'arg-tags';
      default:
        break;
    }
    return '';
  }

  handleUpdateFilter(nextTab, prevTab, data) {
    const { location, history } = this.props;
    let search = removeQueryString(location.search, 'tab');
    search = removeQueryString(search, 'data');
    search = getNewQueryString(search, { tab: nextTab });
    history.push({
      pathname: ROUTES_PATH.RESOURCES,
      search: search + `&data=${data}`,
    });
  }

  handleRowClick(row, rowIndex) {
    const { startDate, endDate, type, location, configCatAzure } = this.props;
    const data = getParamFromQueryString(location.search, 'data') || '';
    let rec = {};
    const newData = IsJsonValid(IsURIValid(data));
    if (!isEmpty(newData)) {
      rec = newData.payload;
    }

    // in some cases _values (e.g. regionState_values) is required and used by filters in order
    // to determine correct resource
    const addValuesToState =
      (configCatAzure && ['regionState', 'accountState', 'cloudPlatformState'].includes(FILTER_META_DATA[type])) ||
      ['regionState'].includes(FILTER_META_DATA[type]);
    const isValueObject = configCatAzure && ['regionState', 'cloudPlatformState'].includes(FILTER_META_DATA[type]);

    // get value to be used in filters _values state object
    const value =
      addValuesToState && isValueObject ? { id: row.id, value: row.name, cloudPlatform: row.cloudPlatform } : row.name;
    const filterData =
      isEmpty(rec) || !rec[FILTER_META_DATA[type]] ? [row.id] : [...(rec[FILTER_META_DATA[type]] || []), row.id];
    const filterDataValues =
      isEmpty(rec) || !rec[`${FILTER_META_DATA[type]}_values`]
        ? [value]
        : [...(rec[`${FILTER_META_DATA[type]}_values`] || []), value];

    // check if value exist in filter
    const existingFilter = (rec[FILTER_META_DATA[type]] || []).includes(row.id);

    // don't add to filter state if value is already there
    if (!existingFilter) {
      rec = {
        ...rec,
        [FILTER_META_DATA[type]]: filterData,
      };
      if (addValuesToState) {
        rec = {
          ...rec,
          [`${FILTER_META_DATA[type]}_values`]: filterDataValues,
        };
      }
    }

    // if row has cloud platform, select that cloud platform in filter
    if (configCatAzure && row.cloudPlatform) {
      const cloudName = row.cloudPlatform === 'azure' ? 'Azure' : 'AWS';
      rec = {
        ...rec,
        cloudPlatformState: [row.cloudPlatform],
        cloudPlatformState_values: [
          {
            id: row.cloudPlatform,
            value: cloudName,
            cloudPlatform: row.cloudPlatform,
          },
        ],
      };
    }

    if (startDate) {
      rec = {
        ...rec,
        startDate: startDate.format('YYYY-MM-DD'),
      };
    }
    if (endDate) {
      rec = {
        ...rec,
        endDate: endDate.format('YYYY-MM-DD'),
      };
    }

    const stateString = JSON.stringify({ payload: rec });
    const newEncodedURI = encodeURIComponent(stateString);
    switch (type) {
      case COST_TYPE.AWS_ACCOUNTS:
        this.handleUpdateFilter(COST_TYPE.REGIONS, type, newEncodedURI);
        break;

      case COST_TYPE.REGIONS:
        this.handleUpdateFilter(COST_TYPE.AWS_PRODUCTS, type, newEncodedURI);
        break;

      case COST_TYPE.AWS_PRODUCTS:
        this.handleUpdateFilter(COST_TYPE.RESOURCES, type, newEncodedURI);
        break;

      default:
        return;
    }
  }

  getDateFormat = () => {
    const { reverseColumn } = this.state;
    if (
      reverseColumn?.length > 0 &&
      selectedIsMonth(moment(reverseColumn[reverseColumn.length - 1]).utc(), moment(reverseColumn[0]).utc())
    ) {
      return {
        dateFormat: 'YYYY-MM',
        isMonth: true,
      };
    } else {
      return {
        dateFormat: 'MMM-D',
        isMonth: false,
      };
    }
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.openMenu('isSearchMenuOpen')();
      this.props.handleTotal();
      this.props.updateFilter({
        searchString: (this.state?.searchString || '').toLowerCase().trim(),
        startDate: changeDateToStringFormat(this.props.startDate),
        endDate: changeDateToStringFormat(this.props.endDate),
        showKeyword: true,
      });
    }
  };

  handleChange = e => {
    this.setState({
      searchString: e.target.value,
    });
    if (e.target.value === '') {
      this.handleClear();
    }
  };

  handleClear = () => {
    this.props.handleTotal();
    this.props.updateFilter({
      searchString: '*',
      startDate: changeDateToStringFormat(this.props.startDate),
      endDate: changeDateToStringFormat(this.props.endDate),
      showKeyword: false,
    });
  };

  handleSorting = (action, startDate, endDate) => {
    this.props.handleTotal();
    action(startDate, endDate);
  };

  handleRecord = row => () => {
    this.setState({
      showModal: true,
      record: { id: row.linkIds.resourceID, projectId: row.linkIds.projectId },
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      record: {},
    });
  };

  chooseIcon = (tab, keyName, cloudPlatform) => {
    const cloudIcon = getIconBasedOnCloud(cloudPlatform);
    const id = `${cloudIcon}_${keyName}`;
    let class_name = `brandIcon ${cloudIcon}`;
    if (tab === 'arp-products') {
      class_name = `${cloudPlatform}Icon ${keyName}`;
    }

    return (
      <div className="brandIcon-container">
        <span id={id} className={class_name} />
      </div>
    );
  };

  render() {
    const query = {};
    const {
      pageData,
      type,
      title,
      onSorting,
      onServerSorting,
      showKeyword,
      startDate,
      endDate,
      filters,
      filtersCloud,
      mrcSorting,
      tagData,
      columns,
      configCatAzure,
      isMultipleCurrency = false,
      isAWSSelected = true,
      azure_billing_currency = 'USD',
    } = this.props;
    const { reverseColumn, displayData, totalSpend, totalSpendData, totalEndDate, totalMRC } = this.state;
    const data = cloneDeep(displayData).slice(pageData.limit * (pageData.page - 1), pageData.limit * pageData.page);
    const containerClass = {
      'np-table3': true,
    };
    const tmp = this.typeToClass(type);
    const totalNonResources = filtersCloud?.buckets?.reduce((prevValue, currentValue) => {
      return prevValue + currentValue.non_resources.arp_summary_tag_values_cost.non_resources_summary.summary.value;
    }, 0);

    containerClass[tmp] = true;
    const detail = this.getDateFormat();

    const con = totalMRC && typesToLink.includes(type) && type !== COST_TYPE.RESOURCES;

    const isDemoMode =
      sessionStorage.getItem('demo-mode') === 'on' &&
      [COST_TYPE.CLOUD_ACCOUNTS, COST_TYPE.AWS_ACCOUNTS, COST_TYPE.RESOURCES, COST_TYPE.NON_RESOURCES].includes(type);

    const costValue = value =>
      currencyFormatterByCode(isMultipleCurrency, isAWSSelected, value, azure_billing_currency);
    return (
      <div className="npxs-12">
        <div className={classNames(containerClass)}>
          <div className="title">
            <div className="npxs-6">
              <h4>
                <div className="np-titleIcon teal">
                  <DollarGreenIcon />
                </div>
                {title}
                {showKeyword && (
                  <p>
                    {`Search results for "${this.props.searchString}" `}
                    <Tippy content={'Clear search'} placement={'right'}>
                      <Anchor onClick={this.handleClear} id={'closeIcon'}>
                        <i className="np-svgIcon">
                          <SmallCloseIcon />
                        </i>
                      </Anchor>
                    </Tippy>
                  </p>
                )}
              </h4>
            </div>
            {data?.length && columns?.length ? this.renderTableActions() : null}
          </div>

          <div className="np-table3Pos">
            <div
              className={classNames('np-table3-wrapper', {
                nonResourcesStyles: filtersCloud?.buckets?.length > 0,
              })}
            >
              <div className="freezeCol">
                {!data?.length || !columns?.length ? (
                  <div className="np-noData">
                    <img src={NoDetectionIcon} alt={''} />
                    {this.state.searchString ? (
                      <p>
                        The search <b>{this.state.searchString} </b>
                        did not match any results
                      </p>
                    ) : (
                      <p>Nothing to show</p>
                    )}
                  </div>
                ) : (
                  <table>
                    <tbody>
                      <tr className="headRow">
                        <th>
                          <div className="data">Name</div>
                        </th>

                        <th
                          id={'total'}
                          onClick={() => {
                            if (typesToSort.includes(type)) {
                              this.handleSorting(
                                onServerSorting,
                                moment(startDate).utc().format(FILTER_DATE_FORMAT),
                                moment(endDate).utc().format(FILTER_DATE_FORMAT),
                              );
                            } else {
                              this.handleSorting(onSorting, 'total');
                            }
                          }}
                        >
                          <div className="data sorting">
                            Total
                            <span className="up-down-icon" />
                          </div>
                        </th>

                        {con ? (
                          <th>
                            <div className="data sorting" onClick={mrcSorting}>
                              NET 3MRC GROWTH
                              <Tippy content={'3-month recurring charges'} placement={'right'}>
                                <Anchor className="ng-binding">
                                  <i className={'icon-link'}>
                                    <InfoGreyIcon />
                                  </i>
                                </Anchor>
                              </Tippy>
                              <span className="up-down-icon" />
                            </div>
                          </th>
                        ) : null}

                        {(reverseColumn || []).map(row => (
                          <th
                            id={`sorting-${row}`}
                            key={row}
                            onClick={() => {
                              if (typesToSort.includes(type)) {
                                detail.isMonth
                                  ? this.handleSorting(
                                      onServerSorting,
                                      moment(row).startOf('M').utc().format(FILTER_DATE_FORMAT),
                                      moment(row).endOf('M').utc().format(FILTER_DATE_FORMAT),
                                    )
                                  : this.handleSorting(onServerSorting, row);
                              } else {
                                this.handleSorting(onSorting, row);
                              }
                            }}
                          >
                            <div className="data sorting">
                              {moment(row).format(detail.dateFormat)}
                              <span className="up-down-icon" />
                            </div>
                          </th>
                        ))}
                      </tr>
                      <tr className="totalRow">
                        <td>
                          <div className="data">Total Spend</div>
                        </td>

                        <td>
                          <div className="data">
                            <div>{costValue(totalSpend)}</div>
                            {totalNonResources ? (
                              <div className="non-resources-table">
                                <span>{!filtersCloud ? '' : costValue(totalNonResources)}</span>
                                <span> Non-resource cost</span>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </td>

                        {con ? (
                          <td>
                            <div className="data">{'---'}</div>
                          </td>
                        ) : null}

                        {(reverseColumn || []).map(row => (
                          <td key={row}>
                            <div className="data">{costValue(totalSpendData[row] || 0)}</div>
                          </td>
                        ))}
                      </tr>
                      {(data || []).map((row, i) => {
                        let keyName = row.name;
                        if (type === COST_TYPE.RESOURCES) keyName = row.productName;
                        keyName = keyName.replace(/ /g, '_').split(',')[0];
                        keyName = keyName.replace(/\)/g, '');
                        keyName = keyName.replace(/\(/g, '');

                        const cloudIcon = getIconBasedOnCloud(row.cloudPlatform);
                        return (
                          <tr key={i}>
                            <td
                              className={`${getClassNameBasedOnCloud(configCatAzure, row.cloudPlatform)}`}
                              style={row?.nameCustomStyle || {}}
                            >
                              <div className="data name">
                                {typesToLink.includes(type) ? (
                                  type === COST_TYPE.RESOURCES ? (
                                    !isEmpty(row.linkIds) ? (
                                      <Tippy content={row.productName}>
                                        <Anchor id={row.name} onClick={!isDemoMode && this.handleRecord(row)}>
                                          <span id={`${cloudIcon}_${keyName}`} className={`${cloudIcon} ${keyName}`} />
                                          <span className={isDemoMode ? 'demo-mode-black' : ''}>{row.name}</span>
                                          &nbsp;
                                          {!isDemoMode && <LinkIcon />}
                                        </Anchor>
                                      </Tippy>
                                    ) : (
                                      <Tippy content={row.productName}>
                                        <Anchor className={'noDec'}>
                                          <span id={`${cloudIcon}_${keyName}`} className={`${cloudIcon} ${keyName}`} />
                                          <span className={isDemoMode ? 'demo-mode-black' : ''}>{row.name}</span>
                                        </Anchor>
                                      </Tippy>
                                    )
                                  ) : (
                                    <Anchor
                                      id={row.name}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                      onClick={() => this.handleRowClick(row, i)}
                                    >
                                      {configCatAzure ? (
                                        this.chooseIcon(tmp, keyName, row.cloudPlatform)
                                      ) : (
                                        <span id={'amazonIcon_' + keyName} className={'amazonIcon ' + keyName} />
                                      )}
                                      <span className={isDemoMode ? 'demo-mode-black' : ''}>{row.name}</span>
                                    </Anchor>
                                  )
                                ) : (
                                  <Fragment>
                                    {configCatAzure && this.chooseIcon(tmp, keyName, row.cloudPlatform)}
                                    <span className={isDemoMode ? 'demo-mode-black' : ''}>{row.name}</span>
                                  </Fragment>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="data">
                                <Tippy content="See Details" placement={'left'}>
                                  <Anchor
                                    id={row.name + '-seeDetail'}
                                    data-row={row}
                                    onClick={this.onSelectDetail(
                                      row,
                                      changeDateToStringFormat(startDate),
                                      changeDateToStringFormat(endDate),
                                    )}
                                  >
                                    {costValue(row.total)}
                                    <i className="np-svgIcon">
                                      <InfoGreyIcon />
                                    </i>
                                  </Anchor>
                                </Tippy>
                                {row.nonResourcesTotal ? (
                                  <div className="non-resources-table">
                                    <span>{!row.nonResourcesTotal ? '' : currencyFormat(row.nonResourcesTotal)}</span>
                                    <span> Non-resource cost</span>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </td>
                            {con ? (
                              <td>
                                <div
                                  className={classNames('data', {
                                    red: row.mrc > 0,
                                    green: row.mrc < 0,
                                  })}
                                >
                                  {row.mrc > 0 && '+'}
                                  {row.mrc}%
                                </div>
                              </td>
                            ) : null}

                            {(reverseColumn || []).map(dateRow => (
                              <td key={dateRow}>
                                <div className="data">
                                  <Tippy content="See Details" placement={'left'}>
                                    <Anchor
                                      id={dateRow}
                                      data-row={row}
                                      data-date={dateRow}
                                      onClick={this.onSelectDetail(row, dateRow)}
                                    >
                                      {costValue(row.data[dateRow])}
                                      <i className="np-svgIcon">
                                        <InfoGreyIcon />
                                      </i>
                                    </Anchor>
                                  </Tippy>
                                </div>
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          {pageData.total !== 1 && data.length > 0 && columns?.length > 0 && (
            <div id={'np-paginationT'} className="np-paginationT bottom">
              <div className="pages">
                Page {pageData.page} of {pageData.total}
              </div>

              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                breakLinkClassName={'breakLinkClass'}
                pageCount={pageData.total}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                forcePage={pageData.page - 1}
                onPageChange={this.props.handleChangePage}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
          )}
        </div>
        {this.state.showDetail && (
          <ResourceDetailDialog
            {...this.props}
            title=""
            rec={this.state.record}
            isDaily={true}
            startDate={this.startDate}
            endDate={this.endDate}
            type={this.props.type}
            query={query}
            onClose={this.closeDetail}
            row={this.state.selectedRec}
            isMonth={detail.isMonth}
            filters={filters}
            tagData={tagData}
            totalEndDate={totalEndDate}
            searchString={this.props.searchString}
            launchStart={this.props.launchStart}
            launchEnd={this.props.launchEnd}
            configCatAzure={configCatAzure}
            isNonResourceTab={this.props.type === COST_TYPE.NON_RESOURCES}
            isMultipleCurrency={isMultipleCurrency}
            isAWSSelected={isAWSSelected}
            azure_billing_currency={azure_billing_currency}
          />
        )}
        {this.state.showModal ? (
          <ResourceDetails {...this.state.record} isCurrencyVisible={true} onClose={this.closeModal} />
        ) : null}
      </div>
    );
  }
}

BaseTable.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
};

BaseTable.defaultProps = {
  data: [],
};

export default withRouter(BaseTable);
