import {
  LOAD_CLUSTER_STATUS_REQUEST,
  LOAD_CLUSTER_STATUS_SUCCESS,
  LOAD_CLUSTER_STATUS_ERROR,
  LOAD_COST_SUMMARY_REQUEST,
  LOAD_COST_SUMMARY_SUCCESS,
  LOAD_COST_SUMMARY_CREDITS_SUCCESS,
  LOAD_COST_SUMMARY_ERROR,
  LOAD_FILTER_DATA_REQUEST,
  LOAD_FILTER_DATA_SUCCESS,
  LOAD_FILTER_DATA_ERROR,
  LOAD_DEFAULT_FILTER_DATA_REQUEST,
  LOAD_DEFAULT_FILTER_DATA_SUCCESS,
  LOAD_DEFAULT_FILTER_DATA_ERROR,
  LOAD_COST_DETAIL_REQUEST,
  LOAD_COST_DETAIL_SUCCESS,
  LOAD_COST_DETAIL_ERROR,
  LOAD_COST_TAGS_REQUEST,
  LOAD_COST_TAGS_SUCCESS,
  LOAD_COST_TAGS_ERROR,
  LOAD_COST_CHARGEBACK_REQUEST,
  LOAD_COST_CHARGEBACK_SUCCESS,
  LOAD_COST_CHARGEBACK_ERROR,
  CREATE_COST_CHARGEBACK_ERROR,
  CREATE_COST_CHARGEBACK_REQUEST,
  CREATE_COST_CHARGEBACK_SUCCESS,
  DELETE_COST_CHARGEBACK_ERROR,
  DELETE_COST_CHARGEBACK_REQUEST,
  DELETE_COST_CHARGEBACK_SUCCESS,
  UPDATE_MONTHLY_COST_CHARGEBACK,
  UPDATE_COST_CHARGEBACK_ERROR,
  UPDATE_COST_CHARGEBACK_REQUEST,
  UPDATE_COST_CHARGEBACK_SUCCESS,
  LOAD_COST_CM_REQUEST,
  LOAD_COST_CM_SUCCESS,
  LOAD_COST_CM_ERROR,
  LOAD_ALL_COST_DATA_ERROR,
  LOAD_ALL_COST_DATA_SUCCESS,
  LOAD_ALL_COST_DATA_REQUEST,
  LOAD_MRC_COST_DATA_REQUEST,
  LOAD_MRC_COST_DATA_SUCCESS,
  LOAD_MRC_COST_DATA_ERROR,
  LOAD_USAGE_TYPE_REQUEST,
  LOAD_USAGE_TYPE_SUCCESS,
  LOAD_USAGE_TYPE_ERROR,
  LOAD_OPERATION_REQUEST,
  LOAD_OPERATION_SUCCESS,
  LOAD_OPERATION_ERROR,
} from './constants';
import orderBy from 'lodash/orderBy';

export const mergeBillingTypeAgg = response => {
  const aggs = response.data?.data?.aggregations || response.res?.data?.aggregations || response.data?.aggregations;

  if (aggs?.arp_billing_type_projects) {
    const buckets = (aggs.arp_billing_type_projects?.buckets || []).reduce((accu, curr) => {
      (curr.projects?.buckets || []).forEach(project => {
        const item = {
          ...project,
          cloudPlatform: curr.key,
          arp_projects: project.resources,
        };
        delete item.resources;
        accu.push(item);
      });
      return accu;
    }, []);

    if (aggs.arp_billing_type_projects?.buckets[0]?.projects) {
      aggs.arp_billing_type_projects.buckets[0].projects.buckets = buckets;
      aggs.arp_projects = aggs.arp_billing_type_projects.buckets[0].projects;
    } else if (!aggs.arp_billing_type_projects?.buckets?.length) {
      aggs.arp_projects = aggs.arp_billing_type_projects;
    }
  }

  if (aggs?.arp_billing_type_regions) {
    let doc_count_error_upper_bound = 0;
    let sum_other_doc_count = 0;

    const buckets = (aggs.arp_billing_type_regions?.buckets || []).reduce((accu, curr) => {
      doc_count_error_upper_bound += curr.regions?.doc_count_error_upper_bound;
      sum_other_doc_count += curr.regions?.sum_other_doc_count;

      (curr.regions?.buckets || []).forEach(region => {
        const item = {
          ...region,
          cloudPlatform: curr.key,
          arp_regions: region.resources,
        };

        delete item.resources;
        accu.push(item);
      });
      return accu;
    }, []);

    delete aggs.arp_billing_type_regions;

    aggs.arp_regions = {
      buckets,
      doc_count_error_upper_bound,
      sum_other_doc_count,
    };
  }

  if (aggs?.arp_billing_type_products) {
    let doc_count_error_upper_bound = 0;
    let sum_other_doc_count = 0;

    const buckets = (aggs.arp_billing_type_products?.buckets || []).reduce((accu, curr) => {
      doc_count_error_upper_bound += curr.products?.doc_count_error_upper_bound;
      sum_other_doc_count += curr.products?.sum_other_doc_count;

      (curr.products?.buckets || []).forEach(product => {
        const item = {
          ...product,
          cloudPlatform: curr.key,
        };
        accu.push(item);
      });
      return accu;
    }, []);

    delete aggs.arp_billing_type_products;

    aggs.arp_products = {
      buckets,
      doc_count_error_upper_bound,
      sum_other_doc_count,
    };
  }

  if (aggs?.arp_billing_type_tags_kv) {
    const buckets = (aggs.arp_billing_type_tags_kv?.buckets || []).reduce((accu, curr) => {
      (curr.arp_tags_kv?.arp_tags_keys?.buckets || []).forEach(tag => {
        const item = {
          ...tag,
          cloudPlatform: curr.key,
          _sum: tag.arp_tags_keys_reverse?.arp_tags_keys_cost_nested?.arp_tags_keys_timeframe_cost?.summary?.value || 0,
        };
        accu.push(item);
      });
      return accu;
    }, []);

    const sorted = orderBy(buckets, ['_sum'], ['desc']);

    if (aggs.arp_billing_type_tags_kv.buckets[0]?.arp_tags_kv?.arp_tags_keys) {
      aggs.arp_billing_type_tags_kv.buckets[0].arp_tags_kv.arp_tags_keys.buckets = sorted;
      aggs.arp_tags_kv = aggs.arp_billing_type_tags_kv.buckets[0].arp_tags_kv;
    }

    delete aggs.arp_billing_type_tags_kv;
  }

  if (aggs?.arp_billing_type_operations) {
    let doc_count = 0;
    let doc_count_error_upper_bound = 0;
    let sum_other_doc_count = 0;

    const buckets = (aggs.arp_billing_type_operations?.buckets || []).reduce((accu, curr) => {
      doc_count += curr.arp_operations_nested?.doc_count;
      doc_count_error_upper_bound += curr.arp_operations_nested?.arp_operations?.doc_count_error_upper_bound;
      sum_other_doc_count += curr.arp_operations_nested?.arp_operations?.sum_other_doc_count;

      (curr.arp_operations_nested?.arp_operations?.buckets || []).forEach(op => {
        const item = {
          ...op,
          cloudPlatform: curr.key,
        };
        accu.push(item);
      });
      return accu;
    }, []);

    const sorted = orderBy(buckets, ['_sort_values.summary.value', 'key'], ['desc', 'asc']);
    delete aggs.arp_billing_type_operations;

    aggs.arp_operations_nested = {
      arp_operations: {
        buckets: sorted,
        doc_count_error_upper_bound,
        sum_other_doc_count,
      },
      doc_count,
    };
  }

  if (aggs?.arp_billing_type_usage_types) {
    let doc_count = 0;
    let doc_count_error_upper_bound = 0;
    let sum_other_doc_count = 0;

    const buckets = (aggs.arp_billing_type_usage_types?.buckets || []).reduce((accu, curr) => {
      doc_count += curr.arp_usagetypes_nested?.doc_count || 0;
      doc_count_error_upper_bound += curr.arp_usagetypes_nested?.arp_usagetypes?.doc_count_error_upper_bound || 0;
      sum_other_doc_count += curr.arp_usagetypes_nested?.arp_usagetypes?.sum_other_doc_count || 0;

      (curr.arp_usagetypes_nested?.arp_usagetypes?.buckets || []).forEach(type => {
        const item = {
          ...type,
          cloudPlatform: curr.key,
        };
        accu.push(item);
      });
      return accu;
    }, []);

    const sorted = orderBy(buckets, ['_sort_values.summary.value'], ['desc']);
    delete aggs.arp_billing_type_usage_types;

    aggs.arp_usagetypes_nested = {
      arp_usagetypes: {
        buckets: sorted,
        doc_count_error_upper_bound,
        sum_other_doc_count,
      },
      doc_count,
    };
  }

  // Resources BE response was changed, arp_resources is under resources property
  if (aggs.resources) {
    aggs.arp_resources = aggs.resources.arp_resources;
  }

  if (aggs?.arp_resources) {
    (aggs.arp_resources.buckets || []).forEach(resource => {
      resource.cloudPlatform = resource.arp_resource?.hits?.hits[0]?._source?.cloud_type || 'aws';
      resource.project = resource.arp_resource?.hits?.hits[0]?._source?.project?.id || null;
    });
  }

  if (aggs?.arp_resources_operation) {
    (aggs.arp_resources_operation.buckets || []).forEach(res_op => {
      res_op.cloudPlatform = res_op.arp_resource?.hits?.hits[0]?._source?.cloud_type || 'aws';
    });
  }

  if (aggs?.arp_resources_usagetype) {
    (aggs.arp_resources_usagetype.buckets || []).forEach(res_type => {
      res_type.cloudPlatform = res_type.arp_resource?.hits?.hits[0]?._source?.cloud_type || 'aws';
    });
  }

  if (aggs?.arp_spend_summary_billing_type) {
    const { data: arp_spend_summary } = mergeSpendSummaryAggs({
      data: {
        arp_spend_summary_billing_type: aggs.arp_spend_summary_billing_type,
      },
    });
    aggs.arp_spend_summary = arp_spend_summary;
    delete aggs.arp_spend_summary_billing_type;
  }
  return response;
};

export const mergeSpendSummaryAggs = response => {
  if (response.data?.arp_spend_summary_billing_type) {
    const cloudArr = response.data?.arp_spend_summary_billing_type?.buckets || [];
    const aggsArr = [
      'arp_spend_summary_day',
      'arp_spend_summary_day_delta',
      'arp_spend_summary_last_30_days',
      'arp_spend_summary_last_30_days_delta',
      'arp_spend_summary_mtd',
      'arp_spend_summary_mtd_delta',
      'arp_spend_summary_total',
      'arp_spend_summary_week',
      'arp_spend_summary_week_delta',
    ];

    const arp_spend_summary = {
      doc_count: 0,
      credits: 0,
    };

    cloudArr.forEach(cloud => {
      // On resources, the response is different, has the [resources_agg] parent
      cloud.resources_agg?.arp_spend_summary_billing_type_nested &&
        (cloud.arp_spend_summary_billing_type_nested = cloud.resources_agg?.arp_spend_summary_billing_type_nested);
      const buckets = cloud.arp_spend_summary_billing_type_nested || [];
      const nonResources = cloud.non_resources_agg || [];
      const cp = cloud.key;
      const credits = buckets?.credits_agg?.credits_total.summary.value;
      arp_spend_summary.credits += credits;
      arp_spend_summary.doc_count += cloud.doc_count || 0;
      aggsArr.forEach(agg => {
        if (!arp_spend_summary[agg]) {
          arp_spend_summary[agg] = {};
          arp_spend_summary[agg].doc_count = 0;
          arp_spend_summary[agg].summary = {};
          arp_spend_summary[agg].summary.value = 0;
          arp_spend_summary[agg].summary.non_resources_value = 0;
          arp_spend_summary[agg].summary[cp] = 0;
          arp_spend_summary[agg].summary[`non_resources_${cp}`] = 0;
        }
        arp_spend_summary[agg].doc_count += buckets[agg].doc_count || 0;
        arp_spend_summary[agg].summary.value += buckets[agg].summary?.value || 0;
        arp_spend_summary[agg].summary.non_resources_value += nonResources.non_resources_agg[agg].summary?.value || 0;
        arp_spend_summary[agg].summary[cp] = buckets[agg].summary?.value || 0;
        arp_spend_summary[agg].summary[`non_resources_${cp}`] = nonResources.non_resources_agg[agg].summary?.value || 0;
      });
    });

    return {
      data: {
        arp_spend_summary,
      },
    };
  }

  if (
    response.data?.arp_spend_summary_history_billing_type ||
    response.data?.arp_spend_summary_history_month_billing_type
  ) {
    const cloudHistoryArr =
      response.data?.arp_spend_summary_history_billing_type?.buckets ||
      response.data?.arp_spend_summary_history_month_billing_type.buckets ||
      [];

    const prop = response.data?.arp_spend_summary_history_month_billing_type
      ? 'arp_spend_summary_history_month'
      : 'arp_spend_summary_history';

    const helperArr = ['current', 'current_day', 'previous', 'previous_day'];

    let typeOfSummary = 'arp_spend_summary_history';
    if (response.data?.arp_spend_summary_history_month_billing_type) {
      typeOfSummary = 'arp_spend_summary_history_month';
    }

    cloudHistoryArr.forEach(cloud => {
      cloud?.resources_agg?.[typeOfSummary] && (cloud[typeOfSummary] = cloud?.resources_agg?.[typeOfSummary]);
    });

    const tmp = cloudHistoryArr.reduce(
      (accu, curr) => {
        const data = curr.arp_spend_summary_history || curr.arp_spend_summary_history_month || {};
        data.non_resources_agg = curr.non_resources_agg[`${typeOfSummary}_non_resources`] || {};

        accu.doc_count += data.doc_count || 0;
        accu.credits += data.credits_agg?.credits_total?.summary?.value || 0;
        helperArr.forEach(day => {
          if (!accu[day]) {
            accu[day] = {
              doc_count: 0,
              summary: {
                value: 0,
                aws: 0,
                azure: 0,
              },
              non_resources: {
                doc_count: 0,
                summary: {
                  value: 0,
                  aws: 0,
                  azure: 0,
                },
              },
            };
          }
          accu[day].doc_count += data[day].doc_count || 0;
          accu[day].summary.value += data[day].summary?.value || 0;
          accu[day].summary[curr.key] += data[day].summary?.value || 0;

          accu[day].non_resources.doc_count += data.non_resources_agg[day].doc_count || 0;
          accu[day].non_resources.summary.value += data.non_resources_agg[day].summary?.value || 0;
          accu[day].non_resources.summary[curr.key] += data.non_resources_agg[day].summary?.value || 0;
        });

        return accu;
      },
      {
        credits: 0,
      },
    );

    return {
      data: {
        [prop]: tmp,
      },
    };
  }

  return response;
};

export function loadOperationDataRequest(payload, configCatAzure = false) {
  return {
    type: LOAD_OPERATION_REQUEST,
    payload,
    configCatAzure,
  };
}

export function loadOperationDataSuccess(response) {
  return {
    type: LOAD_OPERATION_SUCCESS,
    response,
  };
}

export function loadOperationDataError() {
  return {
    type: LOAD_OPERATION_ERROR,
  };
}

export function loadUsageTypeDataRequest(payload, configCatAzure = false) {
  return {
    type: LOAD_USAGE_TYPE_REQUEST,
    payload,
    configCatAzure,
  };
}

export function loadUsageTypeDataSuccess(response) {
  return {
    type: LOAD_USAGE_TYPE_SUCCESS,
    response,
  };
}

export function loadUsageTypeDataError() {
  return {
    type: LOAD_USAGE_TYPE_ERROR,
  };
}

export function loadMRCCostData(payload) {
  return {
    type: LOAD_MRC_COST_DATA_REQUEST,
    payload,
  };
}

export function loadMRCCostDataSuccess(response) {
  return {
    type: LOAD_MRC_COST_DATA_SUCCESS,
    response,
  };
}

export function loadMRCCostDataError() {
  return {
    type: LOAD_MRC_COST_DATA_ERROR,
  };
}

export function loadCostSummaryRequest(payload) {
  return {
    type: LOAD_COST_SUMMARY_REQUEST,
    payload,
  };
}

export function loadCostSummarySuccess(response) {
  response = mergeSpendSummaryAggs(response);
  return {
    type: LOAD_COST_SUMMARY_SUCCESS,
    response,
  };
}

export function loadCostSummaryCreditsSuccess(response) {
  return {
    type: LOAD_COST_SUMMARY_CREDITS_SUCCESS,
    response,
  };
}

export function loadCostSummaryError() {
  return {
    type: LOAD_COST_SUMMARY_ERROR,
  };
}

export function loadClusterStatusRequest(payload) {
  return {
    type: LOAD_CLUSTER_STATUS_REQUEST,
    payload,
  };
}

export function loadClusterStatusSuccess(response) {
  return {
    type: LOAD_CLUSTER_STATUS_SUCCESS,
    response,
  };
}

export function loadClusterStatusError() {
  return {
    type: LOAD_CLUSTER_STATUS_ERROR,
  };
}

export function loadAllCostDataRequest(payload) {
  return {
    type: LOAD_ALL_COST_DATA_REQUEST,
    payload,
  };
}

export function loadAllCostDataSuccess(response) {
  response = mergeBillingTypeAgg(response);
  return {
    type: LOAD_ALL_COST_DATA_SUCCESS,
    response,
  };
}

export function loadAllCostDataError() {
  return {
    type: LOAD_ALL_COST_DATA_ERROR,
  };
}

export function loadFilterDataRequest(payload) {
  return {
    type: LOAD_FILTER_DATA_REQUEST,
    payload,
  };
}

export function loadFilterDataSuccess(response) {
  return {
    type: LOAD_FILTER_DATA_SUCCESS,
    response,
  };
}

export function loadFilterDataError() {
  return {
    type: LOAD_FILTER_DATA_ERROR,
  };
}

export function loadDefaultFilterDataRequest(payload) {
  return {
    type: LOAD_DEFAULT_FILTER_DATA_REQUEST,
    payload,
  };
}

export function loadDefaultFilterDataSuccess(response) {
  return {
    type: LOAD_DEFAULT_FILTER_DATA_SUCCESS,
    response,
  };
}

export function loadDefaultFilterDataError() {
  return {
    type: LOAD_DEFAULT_FILTER_DATA_ERROR,
  };
}

export function loadCostDetailRequest(payload) {
  return {
    type: LOAD_COST_DETAIL_REQUEST,
    payload,
  };
}

export function loadCostDetailSuccess(response) {
  if (!response.data.aggregations.arp_projects) response = mergeBillingTypeAgg(response);
  return {
    type: LOAD_COST_DETAIL_SUCCESS,
    response,
  };
}

export function loadCostDetailError() {
  return {
    type: LOAD_COST_DETAIL_ERROR,
  };
}

export function loadCostTagsRequest(payload) {
  return {
    type: LOAD_COST_TAGS_REQUEST,
    payload,
  };
}

export function loadCostTagsSuccess(response) {
  response = mergeBillingTypeAgg(response);
  return {
    type: LOAD_COST_TAGS_SUCCESS,
    response,
  };
}

export function loadCostTagsError() {
  return {
    type: LOAD_COST_TAGS_ERROR,
  };
}

export function loadCostChargebackRequest() {
  return {
    type: LOAD_COST_CHARGEBACK_REQUEST,
  };
}

export function updateMonthlyCostChargeback(payload) {
  return {
    type: UPDATE_MONTHLY_COST_CHARGEBACK,
    payload,
  };
}

export function loadCostChargebackSuccess(response) {
  return {
    type: LOAD_COST_CHARGEBACK_SUCCESS,
    response,
  };
}

export function loadCostChargebackError() {
  return {
    type: LOAD_COST_CHARGEBACK_ERROR,
  };
}

export function createCostChargebackRequest(payload) {
  return {
    type: CREATE_COST_CHARGEBACK_REQUEST,
    payload,
  };
}

export function createCostChargebackSuccess(response) {
  return {
    type: CREATE_COST_CHARGEBACK_SUCCESS,
    response,
  };
}

export function createCostChargebackError() {
  return {
    type: CREATE_COST_CHARGEBACK_ERROR,
  };
}

export function updateCostChargebackRequest(payload) {
  return {
    type: UPDATE_COST_CHARGEBACK_REQUEST,
    payload,
  };
}

export function updateCostChargebackSuccess(response) {
  return {
    type: UPDATE_COST_CHARGEBACK_SUCCESS,
    response,
  };
}

export function updateCostChargebackError() {
  return {
    type: UPDATE_COST_CHARGEBACK_ERROR,
  };
}

export function deleteCostChargebackRequest(payload, callback) {
  return {
    type: DELETE_COST_CHARGEBACK_REQUEST,
    payload,
    callback,
  };
}

export function deleteCostChargebackSuccess(response) {
  return {
    type: DELETE_COST_CHARGEBACK_SUCCESS,
    response,
  };
}

export function deleteCostChargebackError() {
  return {
    type: DELETE_COST_CHARGEBACK_ERROR,
  };
}

export function loadCostCMRequest(payload) {
  return {
    type: LOAD_COST_CM_REQUEST,
    payload,
  };
}

export function loadCostCMSuccess(response) {
  return {
    type: LOAD_COST_CM_SUCCESS,
    response,
  };
}

export function loadCostCMError() {
  return {
    type: LOAD_COST_CM_ERROR,
  };
}
