import React from 'react';
import { Anchor } from 'components/Custom';
import { IconBellIcon } from 'assets/images';

export const renderHeader = (isAdmin = false, redirectToNotification) => (
  <div className="np-row">
    <div className="npxs-12">
      <div className="np-pageHead">
        <div className="np-pageTitle">
          <h1>nOps Rules</h1>
        </div>
      </div>
      {isAdmin ? (
        <div className="np-pageHead-actions">
          <ul>
            <li>
              <Anchor id={'redirectToNotification'} onClick={redirectToNotification} className="np-button light blue">
                <IconBellIcon />
                Notifications Settings
              </Anchor>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  </div>
);

export const renderNoViolationElement = (icon, text) => (
  <div className="npxs-12">
    <div className="np-noData green">
      <div className="icon">{icon}</div>
      {text}
    </div>
  </div>
);
