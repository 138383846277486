import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import cx from 'classnames';
import { createStructuredSelector } from 'reselect';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loader from 'components/LoadingIndicator/Loader';
import MultiSelectDropdown from 'components/Dropdown/MultiSelectDropdown';
import TagsSection from './components/tagsSection';
import BudgetSection from './components/budgetSection';
import TypeSection from './components/typeSection';

import { Anchor } from 'components/Custom';
import { CloseIcon } from 'assets/images/modal';
import { showToastAction } from 'containers/App/actions';
import { loadCostData } from 'api/cost';
import { getChargebackFilters } from 'api/global';
import { useQuery, useQueryClient } from 'react-query';
import { handleGlobalNotificationFunction } from 'utils/helper';
import { loadUserDataData } from 'containers/Settings/UserSettings/actions';
import { makeUserData, makeUserProfile } from 'containers/App/selectors';
import { api_condition_error, api_condition_success } from 'containers/App/saga';
import { default_tag, payload, filter_keys, CLOUD_PLATFORMS } from './constants';
import { updateChargebackDetails, mapCloudPlatform } from './helpers';
import {
  createCostChargebackRequest,
  updateCostChargebackRequest,
  updateCostChargebackSuccess,
} from 'containers/Cost/actions';
import { updateBudgetData } from 'api/cost';

import SingleSelectDropDown from 'components/Dropdown/SingleSelectDropDown';
import injectReducer from 'utils/injectReducer';
import reducer from 'containers/Settings/UserSettings/reducer';
import injectSaga from 'utils/injectSaga';
import saga from 'containers/Settings/UserSettings/saga';
import { makeSelectUserData } from 'containers/Settings/UserSettings/selectors';

const queryKey = 'chargebackFilters';
const CreateChargeback = ({
  selectedChargeback,
  userData: { client = '' },
  profile,
  projects,
  detailsView = false,
  onClose,
  chargebackCallback,
  configCatAzure,
  allUserData = [],
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [disableNotify, setDisableNotify] = useState(true);
  const [chargebackDetails, setChargebackDetails] = useState({
    id: 0,
    name: '',
    type: 'predefined',
    type_value_predefined: '',
    type_value_custom: '',
    status: '',
    budget: '',
    budget_type: '',
    notify: false,
    notify_threshold: '',
    notify_slack: '',
    emailList: [],
    projects_ids: [],
    regions: [],
    products: [],
    vpc_ids: [],
    cloud_type: null,
  });
  const [awsData, setAwsData] = useState({});
  const [tags, setTags] = useState([default_tag]);

  const [defaultProjects, setDefaultProjects] = useState([]);
  const [defaultRegions, setDefaultRegions] = useState([]);
  const [defaultProducts, setDefaultProducts] = useState([]);
  const [defaultCloud, setDefaultCloud] = useState();
  const [availableClouds, setAvailableClouds] = useState(CLOUD_PLATFORMS);

  const queryClient = new useQueryClient();
  const cachedCMData = queryClient.getQueryData(queryKey);

  const queryFiltersData = useQuery(
    queryKey,
    async () => {
      return await getChargebackFilters(configCatAzure);
    },
    { enabled: !cachedCMData },
  );

  useEffect(() => {
    const { status, data: response, error } = queryFiltersData;
    if (status === 'loading') {
      setLoading(true);
    } else if (status === 'success') {
      setLoading(false);
      const res = mapCloudPlatform(response.data.aggregations);
      setAwsData(res);
    } else if (status === 'error') {
      setLoading(false);
      dispatch(
        showToastAction({
          message: error.message,
          type: 'error',
          show: true,
        }),
      );
    }
  }, [queryFiltersData, dispatch]);

  useEffect(() => {
    setDisableNotify(!chargebackDetails.budget);
    !chargebackDetails.budget &&
      setChargebackDetails({
        ...chargebackDetails,
        notify: false,
        budget_type: '',
      });
    //eslint-disable-next-line
  }, [chargebackDetails.budget]);

  useEffect(() => {
    if (!isEmpty(selectedChargeback)) {
      const [updatedChargebackDetails, defaultProjects, defaultRegions, defaultProducts, defaultTags] =
        updateChargebackDetails(selectedChargeback, projects);
      setChargebackDetails(prevState => ({
        ...prevState,
        ...updatedChargebackDetails,
        cloud_type: selectedChargeback.cloud_type,
      }));

      setDefaultProjects(defaultProjects);
      setDefaultRegions(defaultRegions);
      setDefaultProducts(defaultProducts);
      setTags(defaultTags);
      setDefaultCloud(updateCloud(selectedChargeback.cloud_type));
    }

    if (!allUserData?.length) {
      dispatch(loadUserDataData());
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (isEmpty(selectedChargeback)) {
      setChargebackDetails({
        ...chargebackDetails,
        notify_threshold: '',
        notify_slack: '',
        emailList: [],
      });
      if (errors.emailList) {
        const tempErrors = { ...errors };
        delete tempErrors.emailList;
        setErrors(tempErrors);
      }
    }
    //eslint-disable-next-line
  }, [chargebackDetails.notify]);

  useEffect(() => {
    const tmp = projects.reduce((reference, item) => {
      reference[item?.cloud_type || 'aws'] = 1;
      return reference;
    }, {});

    const filtered = availableClouds.filter(cloud => {
      return tmp[cloud.key];
    });

    setAvailableClouds(filtered);
    //eslint-disable-next-line
  }, [projects]);

  const updateCloud = cloud => {
    return availableClouds.find(elem => elem.key === cloud);
  };

  const handleCloudChange = selected => {
    setChargebackDetails({
      ...chargebackDetails,
      cloud_type: selected.value,
      products: [],
      projects_ids: [],
      regions: [],
    });
    clearAllTags();
  };

  const handleInputChange = ev => {
    const { name, value, checked } = ev.target;
    if (errors[name]) {
      const tempErrors = { ...errors };
      delete tempErrors[name];
      setErrors(tempErrors);
    }

    setChargebackDetails({
      ...chargebackDetails,
      [name]: name === 'notify' ? checked : value,
    });
  };

  const handleEmailChange = list => {
    setChargebackDetails({
      ...chargebackDetails,
      emailList: list,
    });
  };

  const handleThresholdChange = (target, name) => {
    const { value } = target;
    if (errors[name]) {
      const tempErrors = { ...errors };
      delete tempErrors[name];
      setErrors(tempErrors);
    }

    setChargebackDetails({
      ...chargebackDetails,
      [name]: value,
    });
  };

  const handleRadioChange = ev => {
    const { name, value } = ev.target;
    if (errors[name]) {
      const tempErrors = { ...errors };
      delete tempErrors[name];
      setErrors(tempErrors);
    }

    setChargebackDetails({
      ...chargebackDetails,
      [name]: value,
    });
  };

  const handleSelectChange = (data = null, type, extraProps = undefined) => {
    const setData = data => {
      if (!data) return [];
      if (Array.isArray(data)) {
        return data.map(item => item.value);
      }
      return data.value;
    };

    if (errors[type]) {
      const tempErrors = { ...errors };
      delete tempErrors[type];
      setErrors(tempErrors);
    }

    if (type === 'type_value_predefined' && errors.type_value_custom) {
      const tempErrors = { ...errors };
      delete tempErrors.type_value_custom;
      setErrors(tempErrors);
    }

    setChargebackDetails({
      ...chargebackDetails,
      [type]: setData(data),
    });
  };

  const onChangeTags = (data = null, type, extraProps = undefined) => {
    if (type === 'tagKey') {
      const newTags = [...tags];
      if (extraProps && extraProps.index >= 0) {
        if (!isEqual(newTags[extraProps.index]?.tagKey, data)) {
          const t = newTags.find(n => n?.tagKey?.value === data?.value);
          newTags[extraProps.index] = {
            ...newTags[extraProps.index],
            loading: Boolean(data?.value),
            tagKey: data || {},
            tagValue: {},
            errorKey: '',
            selectedTagsAllValue: data?.value ? newTags[extraProps.index]?.selectedTagsAllValue || [] : [],
          };
          if (t) {
            newTags[extraProps.index].selectedTagsAllValue = t.selectedTagsAllValue;
            newTags[extraProps.index].loading = true;
          }
          setTags(newTags);
          if (!t && data?.value) {
            getValueOfKey(newTags, extraProps);
          }
        }
      }
    }
    if (type === 'tagValue') {
      const newTags = [...tags];
      if (extraProps && extraProps.index >= 0) {
        if (!isEqual(newTags[extraProps.index]?.tagValue, data)) {
          newTags[extraProps.index] = {
            ...newTags[extraProps.index],
            tagValue: data || {},
            errorValue: '',
            loading: false,
          };
          setTags(newTags);
        }
      }
      if (errors.tags) {
        const tempErrors = { ...errors };
        delete tempErrors.tags;
        setErrors(tempErrors);
      }
    }
  };

  const valueData = async (id, cloudPlatform) => {
    let data = [];
    await loadCostData(payload(id, configCatAzure, cloudPlatform))
      .then(res => {
        if (api_condition_error(res.status)) {
          return [];
        } else if (api_condition_success(res.status)) {
          const records = res.data.aggregations.arp_resource_tag_value_list.filter.terms.buckets || [];
          const values = records.length ? records.filter(x => x.key) : [];
          data = (values || []).map(t => ({ value: t.key, label: t.key }));
          return (values || []).map(t => ({ value: t.key, label: t.key }));
        }
      })
      .catch(c => {
        return [];
      });
    return data;
  };

  const getValueOfKey = async (tags, extraProps) => {
    const newTags = [...tags];
    if (extraProps?.index >= 0) {
      const tagKey = newTags[extraProps.index].tagKey;
      if (!isEmpty(tagKey)) {
        let sData = [];
        await valueData(tagKey.value, tagKey.cloudPlatform).then(t => {
          sData = [...t];
        });
        newTags[extraProps.index].selectedTagsAllValue = sData;
        newTags[extraProps.index].loading = false;
        setTags(newTags);
      }
    }
  };

  const addTags = () => {
    const newTags = [...tags];
    newTags.push({
      tagKey: {},
      tagValue: {},
      errorKey: '',
      errorValue: '',
      selectedTagsAllValue: [],
    });
    setTags(newTags);
  };

  const removeTags = index => () => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const clearAllTags = () => {
    setTags([
      {
        tagKey: {},
        tagValue: {},
        errorKey: '',
        errorValue: '',
        selectedTagsAllValue: [],
      },
    ]);
  };

  const getProperPayload = () => {
    const {
      name,
      type,
      type_value_custom,
      type_value_predefined,
      budget,
      notify,
      emailList,
      notify_threshold,
      notify_slack,
      projects_ids,
      regions,
      products,
      cloud_type,
    } = chargebackDetails;

    const filterPayload = [];
    const tagsValues = tags.reduce((acc, item) => {
      if (item.tagKey.value) {
        return [
          ...acc,
          {
            [filter_keys.TAG_KEY]: item.tagKey.value,
            [filter_keys.TAG_VALUE]: item.tagValue.value,
          },
        ];
      }
      return acc;
    }, []);

    projects_ids.length && filterPayload.push({ [filter_keys.PROJECTS]: projects_ids });
    regions.length && filterPayload.push({ [filter_keys.REGIONS]: regions });
    products.length && filterPayload.push({ [filter_keys.PRODUCTS]: products });

    return {
      client: client,
      name: name,
      chargeback_type: type === 'predefined' ? type_value_predefined : type_value_custom,
      range_filters: '{}',
      limit: budget,
      notification_threshold: notify ? notify_threshold : null,
      notification_emails: notify ? uniq(emailList.map(item => item.value)).join(';') : null,
      notification_slack: notify ? notify_slack : null,
      filters:
        tagsValues.length || filterPayload.length
          ? {
              must: [...tagsValues, ...filterPayload],
              _multiple_conditions: true,
            }
          : {},
      cloud_type,
    };
  };

  const createOrEditChargeback = async id => {
    const payload = getProperPayload();
    payload.filters = JSON.stringify(payload.filters);
    !payload.cloud_type && (payload.cloud_type = 'aws');
    if (detailsView) {
      setLoading(true);
      try {
        const res = await updateBudgetData({ params: payload, id });
        if (api_condition_success(res.status)) {
          chargebackCallback(res.data);
          dispatch(updateCostChargebackSuccess({ data: res.data, id }));
          dispatch(
            showToastAction({
              message: 'Chargeback updated successfully',
              type: 'success',
              show: true,
            }),
          );
        } else if (api_condition_error(res.status)) {
          handleGlobalNotificationFunction(res, showToastAction);
          setLoading(false);
        }
      } catch (e) {
        dispatch(
          showToastAction({
            message: 'Failed to update chargeback.',
            type: 'error',
            show: true,
          }),
        );
      }
      return;
    }

    if (id) {
      dispatch(updateCostChargebackRequest({ params: payload, id }));
      chargebackCallback();
      return;
    }
    dispatch(createCostChargebackRequest(payload));
    chargebackCallback();
  };

  const onSave = () => {
    const {
      name,
      budget,
      emailList,
      notify,
      notify_threshold,
      type,
      type_value_custom,
      type_value_predefined,
      cloud_type,
    } = chargebackDetails;
    const errors = {};
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!name) {
      errors.name = 'Chargeback name is required';
    }

    if (!type_value_predefined && type === 'predefined') {
      errors.type_value_custom = 'Chargeback type is required';
    }

    if (!type_value_custom.trim() && type === 'custom') {
      errors.type_value_custom = 'Chargeback type is required';
    }

    if (!budget) {
      errors.budget = 'Budget is required';
    }

    if (budget && Number(budget) <= 0) {
      errors.budget = 'Budget should be positive value';
    }

    if (emailList?.length) {
      let flag = false;
      emailList.forEach(email => {
        if (!flag && !re.test(email.value)) {
          flag = true;
          errors.emailList = 'Please provide a valid email';
        }
      });
    }

    if (notify && !emailList?.length) {
      errors.emailList = 'Email is required';
    }

    if (notify && !notify_threshold) {
      errors.notify_threshold = 'Threshold value is required';
    }

    if (notify && notify_threshold === '0') {
      errors.notify_threshold = 'Threshold value should be positive';
    }

    if (configCatAzure && !cloud_type) {
      errors.cloud_type = 'Please select cloud platform';
    }

    //tags erorrs
    if (tags.length) {
      let hasErrors = false;
      const updatedTags = tags.map(tag => {
        const cond = isEmpty(tag.tagValue) && !isEmpty(tag.tagKey);
        if (cond) hasErrors = true;
        return {
          ...tag,
          errorValue: cond ? "Please select tag value. It's required" : '',
        };
      });
      setTags(updatedTags);
      if (hasErrors) errors.tags = 'has error';
    }

    setErrors(errors);
    if (isEmpty(errors)) {
      createOrEditChargeback(!isEmpty(selectedChargeback) ? selectedChargeback.id : null);
    }
  };

  const redirectToPage = path => {
    history.push(path);
  };

  const typeSectionProps = {
    inputData: chargebackDetails,
    inputChange: handleInputChange,
    radioChange: handleRadioChange,
    selectChange: handleSelectChange,
    errors,
  };

  const budgetSectionProps = {
    inputData: chargebackDetails,
    emailChange: handleEmailChange,
    change: handleInputChange,
    changeThreshold: handleThresholdChange,
    radioChange: handleRadioChange,
    allUserData: allUserData,
    errors,
    disabled: disableNotify,
    allow: profile?.has_slack,
    redirect: redirectToPage,
  };

  const filterTagsByCloud = tags => {
    if (!configCatAzure) return tags;

    return tags.filter(tag => tag.cloudPlatform === chargebackDetails.cloud_type);
  };

  const tagsSectioProps = {
    tagKeys: filterTagsByCloud(awsData?.arp_resource_tag_key_list?.filter?.terms?.buckets || []),
    tags,
    onChange: onChangeTags,
    addTags,
    removeTags,
    // disabled: !isEmpty(selectedChargeback),
    cloudPlatform: chargebackDetails.cloud_type,
  };

  const getMultiSelectData = (array, type, isProjects) => {
    let data = !configCatAzure ? array : array.filter(item => chargebackDetails.cloud_type === item[type]);

    if (isProjects) {
      data = configCatAzure ? data : data.filter(item => item[type] === 'aws');
      return data.map(proj => ({
        value: (proj.id || '').toString(),
        label: proj.name,
        cloudPlatform: proj.cloudPlatform,
      }));
    }

    return data.map(item => ({
      value: item.key,
      label: item.key,
      cloudPlatform: item.cloudPlatform,
    }));
  };

  const renderInputs = () => {
    return (
      <>
        <div id="allAccounts" className="np-input-group">
          <label className="np-label">Cloud accounts</label>
          <MultiSelectDropdown
            data={getMultiSelectData(projects || [], 'billing_type', true)}
            type="projects_ids"
            placeHolder="All accounts"
            onChange={handleSelectChange}
            defaultData={defaultProjects}
            // disabled={!isEmpty(selectedChargeback)}
            cloudPlatform={chargebackDetails.cloud_type}
          />
        </div>
        <div id="allRegions" className="np-input-group">
          <label className="np-label">Regions</label>
          <MultiSelectDropdown
            data={getMultiSelectData(awsData?.arp_summary_regions?.buckets || [], 'cloudPlatform')}
            type="regions"
            placeHolder="All Regions"
            onChange={handleSelectChange}
            defaultData={defaultRegions}
            // disabled={!isEmpty(selectedChargeback)}
            cloudPlatform={chargebackDetails.cloud_type}
          />
        </div>
        <div id="managedServices" className="np-input-group">
          <label className="np-label">Cloud Services</label>
          <MultiSelectDropdown
            data={getMultiSelectData(awsData?.arp_summary_products?.buckets || [], 'cloudPlatform')}
            type="products"
            placeHolder="All Cloud Services"
            onChange={handleSelectChange}
            defaultData={defaultProducts}
            // disabled={!isEmpty(selectedChargeback)}
            cloudPlatform={chargebackDetails.cloud_type}
          />
        </div>
        <TagsSection {...tagsSectioProps} />
        {errors.all ? (
          <div className="np-input-group">
            <span id="all" className="red">
              {errors.all}
            </span>
          </div>
        ) : null}
        <div className="np-input-group d-flex flex-gap-5">
          <button
            id="save"
            className={cx('np-button light blue', {
              disabled: loading,
            })}
            onClick={onSave}
            disabled={loading}
          >
            Save
          </button>
          <button id="onClose" className="np-button light close" onClick={onClose}>
            Close
          </button>
        </div>
      </>
    );
  };

  const showInputs = !configCatAzure || (configCatAzure && chargebackDetails.cloud_type);

  return (
    <div className="np-slidePop np-workloadCreate show">
      <div className="np-slidePopWrap">
        <div className="header">
          <h4>
            <i className="icon-file-text2 icon icon" /> {isEmpty(selectedChargeback) ? 'Create New ' : 'Edit '}
            Chargeback
          </h4>

          <Anchor id="closeIcon" className="close-np-slidePop" title="Close" onClick={onClose}>
            <i className="np-svgIcon">
              <CloseIcon />
            </i>
          </Anchor>
        </div>
        <div
          className={cx('body', {
            'np-nops-loading': loading,
          })}
          style={!loading ? { overflowY: 'auto', height: '100%' } : { height: '100%' }}
        >
          <Loader />
          <div className="np-input-group">
            <label className="np-label">
              Chargeback Name <span className="red">*</span>
            </label>
            <input
              id="chargebackName"
              type="text"
              className="np-input"
              placeholder="e.g business unit name or team name or cost center name"
              name="name"
              value={chargebackDetails.name}
              onChange={handleInputChange}
            />
            {errors.name && <span className="red">{errors.name}</span>}
          </div>
          <TypeSection {...typeSectionProps} />
          <div className="np-input-group">
            <hr />
          </div>
          <BudgetSection {...budgetSectionProps} />
          <div className="np-input-group">
            <hr />
          </div>
          <label className="filtersLabel">
            {'FILTERS '}
            <span>(Select filters to filter out the resources for this chargeback)</span>
          </label>

          {configCatAzure && (
            <div id={'charcgebackCloud'} className="np-input-group">
              <label className="np-label">Cloud Platform</label>
              <SingleSelectDropDown
                data={availableClouds.map(type => ({
                  value: type.key,
                  label: type.label,
                  cloudPlatform: type.cloudPlatform,
                }))}
                type="chargeback_cloud_platform"
                placeHolder="Choose your desired Cloud platform"
                defaultData={defaultCloud}
                onChange={handleCloudChange}
                isClearable={false}
                // isDisabled={!isEmpty(selectedChargeback)}
              />
            </div>
          )}
          {!showInputs ? null : renderInputs()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  userData: makeUserData(),
  profile: makeUserProfile(),
  allUserData: makeSelectUserData(),
});

const withUserReducer = injectReducer({ key: 'userData', reducer });
const withUserSaga = injectSaga({ key: 'userData', saga });

const withConnect = connect(mapStateToProps, {});

export default compose(withUserReducer, withUserSaga, withConnect)(CreateChargeback);
