import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { getParamFromQueryString } from 'utils/queryString';
import { IsJsonValid, IsURIValid } from 'utils/helper';
import isEmpty from 'lodash/isEmpty';
import {
  getFiltersStructure,
  getSelectedFilters,
  removeSelectedFilter,
  removeSelectedApiFilter,
  getHiddenFilters,
} from './helpers';
import { CloseIcon } from 'assets/images/common';
import qs from 'query-string';
import cx from 'classnames';

const SelectedFilters = ({ availableFilters = [], instanceRightSizing = {} }) => {
  const location = useLocation();
  const history = useHistory();
  const [payloadData, setPayloadData] = useState({});
  const [displayFilters, setDisplayFilters] = useState([]);
  const isDemoMode = sessionStorage.getItem('demo-mode') === 'on';

  useEffect(() => {
    let hiddenFilters = [];
    let data = getParamFromQueryString(location.search, 'data') || '';
    data = IsJsonValid(IsURIValid(data));

    if (isEmpty(data)) {
      setDisplayFilters([]);
      return;
    }

    if (!isEmpty(instanceRightSizing)) {
      const {
        violations_cost_delta_per_project: productBuckets = [],
        violations_cost_delta_per_region: regionBuckets = [],
      } = instanceRightSizing;

      hiddenFilters = getHiddenFilters(data.payload, productBuckets, regionBuckets);
    }

    setPayloadData(data);
    const filterStructure = getFiltersStructure(availableFilters, data.payload);
    let selectedFilters = getSelectedFilters(filterStructure);
    if (hiddenFilters.length) {
      selectedFilters = selectedFilters.filter(item => !hiddenFilters.includes(item.value));
    }
    setDisplayFilters(selectedFilters);
    //eslint-disable-next-line
  }, [location.search]);

  const removeFilter = item => {
    // remove all filters when removing cloud platform
    if (item.payloadKey === 'cloudPlatformState') {
      let updatedPayloadData, updatedApiPayloadData;
      let emptyPayloadData = payloadData;
      // remove all filters from payload
      for (const filterItem of displayFilters) {
        updatedPayloadData = removeSelectedFilter(filterItem, {
          ...emptyPayloadData.payload,
        });
        updatedApiPayloadData = removeSelectedApiFilter(
          {
            ...updatedPayloadData,
          },
          emptyPayloadData?.apiPayload,
        );
        emptyPayloadData = updatedPayloadData
          ? {
              ...emptyPayloadData,
              payload: updatedPayloadData,
              apiPayload: updatedApiPayloadData,
            }
          : { ...emptyPayloadData, payload: updatedPayloadData };
      }

      const data = JSON.stringify(emptyPayloadData);
      const queryParams = qs.parse(location.search);
      const newQueries = { ...queryParams, data };
      history.push({ search: qs.stringify(newQueries), hash: location.hash });
    } else {
      const updatedPayloadData = removeSelectedFilter(item, {
        ...payloadData.payload,
      });
      const updatedApiPayloadData = removeSelectedApiFilter(
        {
          ...updatedPayloadData,
        },
        payloadData?.apiPayload,
      );

      const data = JSON.stringify(
        updatedPayloadData
          ? {
              ...payloadData,
              payload: updatedPayloadData,
              apiPayload: updatedApiPayloadData,
            }
          : { ...payloadData, payload: updatedPayloadData },
      );

      const queryParams = qs.parse(location.search);
      const newQueries = { ...queryParams, data };
      history.push({ search: qs.stringify(newQueries), hash: location.hash });
    }
  };

  if (isEmpty(payloadData) || !displayFilters.length) return null;
  return (
    <ul className="np-selected-filters" data-testid="selected-filters">
      {displayFilters.map((item, index) => (
        <li
          key={index}
          className={cx('np-button color-grey light', {
            'demo-mode-black': isDemoMode && item.payloadKey === 'accountState',
          })}
        >
          {item.label || item} <CloseIcon onClick={() => removeFilter(item)} />
        </li>
      ))}
    </ul>
  );
};

export default SelectedFilters;
