import { decimalValueFormatter } from 'containers/Cost/TagExplorer/helper';
import { dateCustomFormatter } from 'utils/rulesColumnDetails';
import { currencyFormat } from 'utils/number';

export const COLUMN = [
  {
    field: 'NetworkInterfaceId',
    headerName: 'Network Interface Id',
  },
  {
    field: 'flow-direction',
    headerName: 'Flow Direction',
  },
  {
    field: 'pkt-srcaddr',
    headerName: 'Source Address',
  },
  {
    field: 'pkt-dstaddr',
    headerName: 'Destination Address',
  },
  {
    field: 'pkt-src-aws-service',
    headerName: 'Source AWS Service',
  },
  {
    field: 'pkt-dst-aws-service',
    headerName: 'Destination AWS Service',
  },
  {
    field: 'date_stamp',
    headerName: 'Date',
    valueFormatter: dateCustomFormatter,
  },
  {
    field: 'gb',
    headerName: 'Bytes (GB)',
    valueFormatter: decimalValueFormatter,
  },
  {
    field: 'data_cost',
    headerName: 'Cost',
    filter: 'agNumberColumnFilter',
    valueFormatter: params => currencyFormat(params),
  },
];
