import * as Sentry from '@sentry/browser';
import React from 'react';
import PropTypes from 'prop-types';
import NoDetectionIcon from 'assets/images/no-detection.svg';

const ErrorPage = ({ onGoBack, sentryEventId }) => {
  return (
    <div className="np-content">
      <div className="np-container-fluid">
        <div className="np-row">
          <div className="npxs-12">
            <div className="np-wrapper">
              <div className="np-contentMain full">
                <div className="page">
                  <div className="np-noData">
                    <img src={NoDetectionIcon} alt={''} />
                    <p>Oh snap! Something went wrong, we are unable to load data.</p>
                    <button id={'goBack'} onClick={onGoBack} className="np-button blue light">
                      Go Back to Previous Page
                    </button>
                    <p>or</p>
                    <button
                      id={'reportFeedBack'}
                      className="np-button light"
                      onClick={() => Sentry.showReportDialog({ eventId: sentryEventId })}
                    >
                      Report feedback
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  sentryEventId: PropTypes.string,
  onGoBack: PropTypes.func.isRequired,
};

export default ErrorPage;
