import * as Sentry from '@sentry/browser';
import React from 'react';
import PropTypes from 'prop-types';
import ErrorComponent from './ErrorComponent';
import ErrorPage from './ErrorPage';

const PAGE = 'page';
const COMPONENT = 'component';

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf([PAGE, COMPONENT]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  };

  static defaultProps = {
    type: COMPONENT,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  handleGoBack = () => {
    this.setState({ hasError: false });
  };

  renderError() {
    switch (this.props.type) {
      case PAGE:
        return <ErrorPage onGoBack={this.handleGoBack} sentryEventId={this.state.eventId} />;

      case COMPONENT:
      default:
        return <ErrorComponent sentryEventId={this.state.eventId} />;
    }
  }

  render() {
    if (this.state.hasError) {
      return this.renderError();
    }

    return this.props.children;
  }
}
