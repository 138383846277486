import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import ReactDOM from 'react-dom';

import { DecreaseIcon, IncreaseIcon } from 'assets/images/common';
import { getIcon } from 'utils/getIcon';
import { AG_GRID_DEFAULT_CONFIG } from 'utils/constants';
import { currencyValueFormatter, numberValueFormatter } from 'containers/Cost/TagExplorer/helper';
import { SeeDetailLinkIcon } from 'assets/images/cost';
import 'styles/ag-grid.css';
import { CheckboxRenderer } from './checkbox';
import { elementForLastMonthViolationWrapper, fieldRenderer } from 'containers/nOpsRules/utils';

export const getTextForIssues = (count, lastCount) =>
  count > lastCount ? `+${count - lastCount}` : `-${lastCount - count}`;

export const actionRenderer = () => {
  const element = document.createElement('div');
  const detailElement = (
    <div>
      <i className="np-tableIcon">
        <SeeDetailLinkIcon />
      </i>
    </div>
  );
  ReactDOM.render(detailElement, element);
  return element;
};

export const Table = ({
  columns,
  tab,
  count,
  lastCount,
  data = [],
  onClicked,
  showDetail = true,
  configCatAzure,
  onCheckBoxChange = () => {},
}) => {
  const text = getTextForIssues(count, lastCount);
  const onTableCellClicked = param => {
    onClicked(param.data);
  };
  return (
    <div className="npxs-12">
      <div className="np-table2 np-tableIcons">
        <div className="title">
          <div className="np-titleIcon red">{getIcon(tab)}</div>
          {showDetail ? `${count} ${tab} Violations` : `${tab} Violations`}
        </div>

        {!showDetail || !lastCount || count === lastCount
          ? ''
          : count > lastCount
            ? elementForLastMonthViolationWrapper(<IncreaseIcon />, text, 'red')
            : elementForLastMonthViolationWrapper(<DecreaseIcon />, text, 'green')}

        <div className="ag-theme-alpine ag-nops-landing-page" style={{ width: '100%' }}>
          <AgGridReact
            suppressContextMenu={true}
            animateRows={true}
            columnDefs={columns}
            defaultColDef={AG_GRID_DEFAULT_CONFIG}
            rowData={[...data]}
            // immutableData={true}
            // getRowNodeId={row => `row-${row.rule}-${row.service}`}
            // animateRows={true}
            components={{
              actionRenderer,
              fieldRenderer,
              numberValueFormatter,
              currencyValueFormatter,
              checkBoxRenderer: CheckboxRenderer(onCheckBoxChange),
            }}
            onCellClicked={onTableCellClicked}
            pagination
            suppressPaginationPanel
            paginationPageSize={50}
            domLayout="autoHeight"
            rowClass="ag-rule-rowIcon"
            rowClassRules={{
              blueCola: params => configCatAzure && params.data.cloudPlatform && params.data.cloudPlatform === 'azure',
              orangePeel: params => configCatAzure && params.data.cloudPlatform && params.data.cloudPlatform === 'aws',
            }}
          />
        </div>
      </div>
    </div>
  );
};
