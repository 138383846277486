import React from 'react';

const Loader = () => {
  return (
    <ul className="np-nops-loader" data-testid="loader">
      <li />
      <li />
      <li />
      <li />
      <li />
    </ul>
  );
};

export default Loader;
