export const keyDisplayName = {
  product: 'Product',
  db_cluster_members_to_arns: 'Cluster Members',
  region: 'Region',
  type: 'Resource Type',
  engine: 'Engine',
  engine_mode: 'Engine Mode',
  engine_version: 'Engine Version',
  attached: 'Attached',
  attached_policies: 'Attached Policies',
  availability_zone: 'Availability Zone',
  availability_zones: 'Availability Zones',
  available_ip_address_count: 'Available IP Address Count',
  canonical_hosted_zone_name: 'Hosted Zone Name',
  cidr_block: 'CIDR Block',
  codesize: 'Code Size',
  create_date: 'Create Date',
  create_time: 'Create Time',
  // created_time: 'Created Time',
  // creation_date: 'Created Time',
  current_month_cost: 'MTD Cost',
  db_instance_class: 'DB Instance Class',
  desc: 'Description',
  dhcp_options_id: 'DHCP Options ID',
  domain: 'Domain',
  encrypted: 'Encrypted',
  endpoint: 'Endpoint',
  first_seen: 'First seen',
  last_seen: 'Last seen',
  desired_capacity: 'Desired Capacity',
  min_size: 'Min Capacity',
  max_size: 'Max Capacity',
  scaling_config: 'Scaling Config',
  groups: 'Groups',
  instance_id: 'Instance ID',
  instance_name: 'Instance Name',
  instance_state: 'Instance Status',
  instance_tenancy: 'Instance Tenancy',
  instance_type: 'Instance Type',
  instances: 'Instances',
  iops: 'IOPS',
  item_count: 'Item Count',
  ip_address: 'IP Address',
  key_name: 'Key Name',
  listeners: 'Listeners',
  memsize: 'Memory Size',
  network_interface_id: 'Network Interface ID',
  network_interface_owner_id: 'Network Interface Owner ID',
  node_type: 'Node Type',
  mfa_enabled: 'MFA Status',
  monitored: 'Monitored',
  multi_az: 'Multi AZ',
  owner_id: 'Owner ID',
  password_last_used: 'Password Last Used',
  path: 'Path',
  policies: 'Policies',
  private_ip: 'Private IP',
  private_ip_address: 'Private IP Address',
  public_ip: 'Public IP',
  rules: 'Rules',
  runtime: 'Runtime',
  security_groups: 'Security Groups',
  scheme: 'Scheme',
  size: 'Size',
  snapshot_id: 'Snapshot ID',
  /*state: 'State',*/
  status: 'Status',
  storage_type: 'Storage Type',
  subnets: 'Subnets',
  subnet_id: 'Subnet ID',
  tab_size_bytes: 'Tab Size Bytes',
  user_id: 'User ID',
  user_name: 'User Name',
  versioning: 'Versioning',
  vpc: 'VPC',
  vpc_id: 'VPC ID',
  zone: 'Zone',
  volume_type: 'Volume Type',
};

export const ec2Metrics = [
  {
    label: 'CPU Utilization',
    name: 'CPUUtilization',
    value: 'Percentage of CPU utilization',
    unit: '%',
    statistics: 'Average',
  },
  {
    label: 'Disk Read Ops',
    name: 'DiskReadOps',
    value: 'Completed read operations from all instance store volumes',
    unit: 'Count',
    statistics: 'Average',
  },
  {
    label: 'Disk Write Ops',
    name: 'DiskWriteOps',
    value: 'Completed write operations to all instance store volumes',
    unit: 'Count',
    statistics: 'Average',
  },
  {
    label: 'Network In',
    name: 'NetworkIn',
    value: 'Number of Mbytes received',
    unit: 'MBytes',
    statistics: 'Average',
  },
  {
    label: 'Network Out',
    name: 'NetworkOut',
    value: 'Number of Mbytes sent out',
    unit: 'MBytes',
    statistics: 'Average',
  },
  {
    label: 'Memory Used',
    name: 'mem_used_percent',
    value: 'Memory',
    unit: '%',
    statistics: 'Average',
  },
];

export const neptuneMetrics = [
  {
    label: 'CPU Utilization',
    name: 'CPUUtilization',
    value: 'Percentage of cluster CPU utilization',
    unit: '%',
    statistics: 'Average',
  },
  {
    label: 'Engine Up Time',
    name: 'EngineUptime',
    value: 'Cluster running time',
    unit: 'Seconds',
    statistics: 'Average',
  },
  {
    label: 'Freeable Memory',
    name: 'FreeableMemory',
    value: 'Available RAM size of cluster',
    unit: 'MBytes',
    statistics: 'Average',
  },
  {
    label: 'Volume Bytes Used',
    name: 'VolumeBytesUsed',
    value: 'Amount of storage used by cluster',
    unit: 'MBytes',
    statistics: 'Average',
  },
  {
    label: 'Volume Read IOPs',
    name: 'VolumeReadIOPs',
    value: 'Average number of cluster read I/O operations',
    unit: 'Count/Second',
    statistics: 'Average',
  },
  {
    label: 'Volume Write IOPs',
    name: 'VolumeWriteIOPs',
    value: 'Average number of cluster write I/O operations',
    unit: 'Count/Second',
    statistics: 'Average',
  },
];

export const s3Metrics = [
  {
    label: 'Number Of Objects',
    name: 'NumberOfObjects',
    value: 'Object count in bucket',
    unit: '# of objects',
    statistics: 'Average',
  },
  {
    label: 'Bucket Size Bytes',
    name: 'BucketSizeBytes',
    value: 'Bucket Size in MBytes',
    unit: 'MBytes',
    statistics: 'Average',
  },
];

export const rdsMetrics = [
  {
    label: 'CPU Utilization',
    name: 'CPUUtilization',
    value: 'Percentage of CPU utilization',
    unit: '%',
    statistics: 'Average',
  },
  {
    label: 'Free Storage Space',
    name: 'FreeStorageSpace',
    value: 'Available storage space',
    unit: 'MBytes',
    statistics: 'Average',
  },
  {
    label: 'Read IOPS',
    name: 'ReadIOPS',
    value: 'Average number of disk read I/O operations',
    unit: 'Count/Second',
    statistics: 'Average',
  },
  {
    label: 'Write IOPS',
    name: 'WriteIOPS',
    value: 'Average number of disk write I/O operations',
    unit: 'Count/Second',
    statistics: 'Average',
  },
];

export const byteMetrics = [
  'FreeStorageSpace',
  'BucketSizeBytes',
  'VolumeBytesUsed',
  'FreeableMemory',
  'NetworkOut',
  'NetworkIn',
];
