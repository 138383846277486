import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import forIn from 'lodash/forIn';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import ReactJson from 'react-json-view';

import { RULES_DESCRIPTION_TEXT } from 'utils/RuleDetail';
import { getResourceType, getSumOfMTDCostFromGranular, getTypeName } from 'utils/helper';
import { usePrevious } from 'utils/hooks';
import { CheckedIcon, LinkIcon } from 'assets/images/common';
import { RuleIcon } from 'assets/images/nOpsRule';

import { Anchor } from 'components/Custom';
import { InfoIcon, SecurityGroupIcon, SubnetIcon, TagIcon } from 'containers/Search/icons';

import { keyDisplayName } from '../constant';
import {
  getDetailForMultipleService,
  rulesRendererForMultipleService,
  RULE_DETAILS_MAPPING,
} from 'containers/nOpsRules/utils';
import { ActionModal } from 'containers/nOpsRules/AllRulesDetailPages/helper/actionModal';
import LoadingWrapper from 'components/ContentLoader/LoadingWrapper';
import { RowLoader, SimpleTableLoader } from 'components/ContentLoader/TableLoader';
import { currencyFormat } from 'utils/number';

export const TableData = React.memo(
  ({ ...props }) => {
    const [violations, setViolations] = useState([]);
    const [showAction, setShowAction] = useState({});
    const [azureLink, setAzureLink] = useState(null);
    const {
      resource_id = '',
      projectId = '',
      overrideRecord = [],
      isDetailPage = false,
      configCatAzure,
      getViolationData,
      _cloudPlatform = '',
      setSelectedInstanceId,
      cost,
    } = props;

    const prevRecord = usePrevious(overrideRecord);
    let client = undefined;
    if (!isEmpty(props?.isPartner) || props?.isPartner?.isPartner) {
      client = props.isPartner.client_id;
    }

    useEffect(() => {
      if (!isEqual(prevRecord, overrideRecord)) {
        setShowAction({});
      }
    }, [prevRecord, overrideRecord]);

    useEffect(() => {
      const data = { ...props.data };
      if (!isEmpty(data)) {
        const vl = cloneDeep(data?._source?.violations || []);
        if (!vl.length) {
          setViolations([]);
        } else {
          const violationsList = uniqBy(vl, 'violation_type');
          const list = [];
          violationsList.forEach(violation => {
            const key = violation.violation_type;
            if (!RULE_DETAILS_MAPPING(RULES_DESCRIPTION_TEXT)?.[key] || !key) return;
            const { text, description, link } = getDetailForMultipleService(RULES_DESCRIPTION_TEXT, key, 0);
            list.push({
              link,
              rule: key,
              desc: rulesRendererForMultipleService(text, link, description, key),
            });
          });
          setViolations(list);
        }
      }

      if (props?.projects && data._source?.item_id) {
        const { id = 0 } = data._source?.project;
        const project = (props.projects || []).find(p => p.id === id);
        project?.tenant_name &&
          setAzureLink(`https://portal.azure.com/#@${project.tenant_name}/resource${data._source.item_id}`);
      }
      //eslint-disable-next-line
    }, [props.data]);

    const getDiv = ({ key, value, name, id, status, cloudPlatform }) => {
      if (
        [
          'db_cluster_members_to_arns',
          'subnet_id',
          'security_groups',
          'region',
          'vpc_id',
          'instance_type',
          'awsAccount',
          'key_name',
          'vpc',
          'endpoint',
          'current_month_cost',
        ].indexOf(key) === -1
      ) {
        if (value === true) return 'true';
        else if (value === false) return 'false';
        else if (typeof value === 'object') {
          return (
            <ReactJson
              src={value}
              iconStyle="circle"
              indentWidth="4"
              name={false}
              collapsed={false}
              displayDataTypes={false}
            />
          );
        } else if (value) {
          return value;
        } else {
          return 'N/A';
        }
      }

      if (name === 'Cloud Account Name') {
        return (
          <>
            {value}
            {status === 3 ? (
              <div className="np-note yellow">
                <i className="note-icon">
                  <RuleIcon />
                </i>
                {_cloudPlatform === 'azure' ? (
                  <p>
                    We detected permission problem with your Azure account.
                    <br />
                    <b>
                      <a href={`/v3/settings?tab=Cloud%20Accounts#${id}`} target="_blank" rel="noopener noreferrer">
                        Click here to fix permission
                      </a>
                    </b>
                  </p>
                ) : (
                  <p>
                    We detected this account in consolidated billing. Give nOps <b>read-only permission</b> to access
                    this account to make you more secure and save costs.
                    <br />
                    <b>
                      <a href={`/v3/settings?tab=Cloud%20Accounts#${id}`} target="_blank" rel="noopener noreferrer">
                        Click here to fix permission
                      </a>
                    </b>
                  </p>
                )}
              </div>
            ) : null}
          </>
        );
      }

      if (['region', 'vpc_id', 'instance_type', 'key_name', 'vpc', 'product', 'billing_type'].indexOf(key) > -1) {
        if (value) {
          let state = {};
          if (key === 'region') {
            state = {
              payload: {
                regionState: [value],
                regionState_values: [{ id: value, value, cloudPlatform }],
              },
            };
          } else if (key === 'product') {
            state = {
              payload: {
                productState: [value],
              },
            };
          } else {
            state = {
              payload: {
                searchString: value,
              },
            };
          }
          const stateString = JSON.stringify(state);
          const newEncodedURI = encodeURIComponent(stateString);
          const url = `/v3/search/?data=${newEncodedURI}`;
          return (
            <Anchor
              className={'np-tag grey'}
              href={client ? `/c/select/?client_id=${client}&next=${url}` : url}
              target="_blank"
            >
              {value || 'N/A'}{' '}
              <i className="icon-link">
                <LinkIcon />
              </i>
            </Anchor>
          );
        } else {
          return value ? '' : 'N/A';
        }
      }

      if (key === 'db_cluster_members_to_arns') {
        return value.map(member => {
          const id = Object.values(member)[0] || '';
          const name = Object.keys(member)[0] || '';
          return (
            <Anchor
              key={id}
              className="np-tag np-cluster-member blue"
              onClick={() => {
                setSelectedInstanceId(id);
              }}
            >
              {name || 'N/A'}{' '}
              <i className="icon-link">
                <LinkIcon />
              </i>
            </Anchor>
          );
        });
      }

      if (key === 'subnet_id') {
        return (
          <div className="np-tag grey">
            <i className="icon-tag">
              <SubnetIcon />
            </i>{' '}
            {value || 'N/A'}
          </div>
        );
      }

      if (key === 'current_month_cost') {
        return currencyFormat(value || 0);
      }

      if (key === 'security_groups') {
        const list = [];
        if (value.length) {
          value.forEach((v, index) => {
            list.push(
              <div key={index} className="np-tag grey">
                <i className={'icon-tag'}>
                  <SecurityGroupIcon />
                </i>
                &nbsp;
                {key === 'endpoint' && <small>{v}</small>}
                {v || 'N/A'}
              </div>,
            );
          });
        }
        return list;
      }

      if (key === 'endpoint') {
        const list = [];
        forIn(value, (v, key) => {
          list.push(
            <div key={key} className="data tags">
              <ul>
                <li>
                  <div className="np-dropdownClick">
                    <button className="np-tag grey np-dropdownLink">
                      <i className="icon-tag">
                        <TagIcon />
                      </i>
                      key: {key}
                      <br />
                      value: {v}
                    </button>
                  </div>
                </li>
              </ul>
            </div>,
          );
        });
        return list;
      }
    };

    const getList = (cList, eList, ec2State, tags) => {
      const rowList = [];
      cList.forEach(data => {
        if (!data?.value) return null;
        rowList.push(
          <tr key={data.name} className="searchResDetailRow">
            <td>
              <div className="data">
                <b>{data.name}</b>
              </div>
            </td>
            <td>
              <div className="data">{getDiv(data)}</div>
            </td>
          </tr>,
        );
      });

      !isEmpty(ec2State) &&
        rowList.push(
          <tr className="searchResDetailRow">
            <td>
              <div className="data">
                <b>Status</b>
              </div>
            </td>

            <td>
              <div className="data">
                <span
                  className={cx('np-tag', {
                    green: ec2State.status === 'Started',
                    red: ec2State.status === 'Stopped',
                  })}
                >
                  {ec2State.status}
                </span>{' '}
                {ec2State.time}
              </div>
            </td>
          </tr>,
        );

      eList.forEach(data => {
        if (!data?.value) return null;
        if (typeof data.value === 'object' && isEmpty(data.value)) return null;
        rowList.push(
          <tr key={data.name} className="searchResDetailRow">
            <td>
              <div className="data">
                <b>{data.name}</b>
              </div>
            </td>
            <td>
              <div className="data">{getDiv(data)}</div>
            </td>
          </tr>,
        );
      });

      tags &&
        tags.length &&
        rowList.push(
          <tr key={'tags-' + tags.length} className="searchResDetailRow">
            <td>
              <div className="data">
                <b>Tags</b>
              </div>
            </td>

            <td>
              {(tags || []).map(t => {
                const state = {
                  payload: {
                    tagData: {
                      must: [
                        {
                          'nested:tags_kv:tags_kv.key': [t.key],
                          'nested:tags_kv:tags_kv.value': [t.value],
                        },
                      ],
                      must_not: [],
                      _multiple_conditions: true,
                    },
                  },
                };
                const stateString = JSON.stringify(state);
                const newEncodedURI = encodeURIComponent(stateString);
                const url = `/v3/search/?data=${newEncodedURI}`;
                return (
                  <div key={t.key} className="data tags">
                    <ul>
                      <li>
                        <div className="np-dropdownClick">
                          <Anchor
                            href={client ? `/c/select/?client_id=${client}&next=${url}` : url}
                            target="_blank"
                            className="np-tag grey np-dropdownLink"
                          >
                            <i className="icon-tag">
                              <TagIcon />
                            </i>
                            <small>Key:</small> {t.key}
                            <br />
                            <small>Tag:</small> {t.value}
                            <i className="icon-link">
                              <LinkIcon />
                            </i>
                          </Anchor>
                        </div>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </td>
          </tr>,
        );

      return rowList;
    };

    const getAllRows = () => {
      let allRuleList = [];
      if (!isEmpty(props.data)) {
        const commonList = [];
        const extraList = [];

        const resourceName = props.data?._source?.name || '';
        if (resourceName) {
          commonList.push({ name: 'Name', value: resourceName });
        }

        const id = props.data?._source?.item_id || 0;
        const arn = props.data?._source?.arn || '';
        const isEksCluster = props.data?._source?.type === 'eks_cluster';
        if (id) {
          commonList.push({ name: 'ID', value: isEksCluster ? arn : id });
        }

        let project = {};
        if (props.projectId) {
          const projectData = props?.projects?.length ? props.projects.find(p => +p.id === +props.projectId) : {};
          if (projectData) {
            project = projectData;
          } else {
            project = props.data?._source?.project || {};
          }
        }
        commonList.push({
          name: 'Cloud Account Name',
          value: project?.name,
          status: project?.status,
          id: project?.id,
          key: 'awsAccount',
        });

        const key = props.data?._source?.type;
        const tags = props.data?._source?.tags_kv;
        const ec2State = {};
        if (key === 'ec2') {
          ec2State.status = props.data?._source?.state === 'running' ? 'Started' : 'Stopped';
          if (props.data?._source?.active) {
            const second = parseInt(props.data?._source?.state_change_time) || 0;
            ec2State.time = '';
            if (second / 3600 / 24 >= 1) ec2State.time = parseInt(second / 3600 / 24) + ' days,  ';
            ec2State.time = ec2State.time + parseInt((second % (3600 * 24)) / 3600) + ' hours ago';
          } else {
            const date = props?.data?._source?.cost?.dates_available?.lte;
            ec2State.time = moment(date).format('MMM D YYYY');
          }
        }

        forIn(keyDisplayName, (value, key) => {
          if (key === 'type') {
            if (props?.data?._source?.type) {
              extraList.push({
                name: keyDisplayName[key],
                value: getTypeName(props.data._source.type),
                key: key,
              });
            } else if (id.startsWith('arn:')) {
              extraList.push({
                name: keyDisplayName[key],
                value: getTypeName(getResourceType(id)),
                key: key,
              });
            }
          } else if (value === 'Created Time')
            extraList.push({
              name: keyDisplayName[key],
              value: moment(props.data?._source?.[key]).format('MMM D YYYY  HH:MM A'),
              key: key,
            });
          else if (key === 'last_seen') {
            const date = props?.data?._source?.cost?.dates_available?.lte;
            if (!date) return null;
            extraList.push({
              name: keyDisplayName[key],
              value: moment(date).format('MMM D YYYY'),
              key: key,
            });
          } else if (key === 'first_seen') {
            const date = props?.data?._source?.first_seen;
            if (!date) return null;
            extraList.push({
              name: keyDisplayName[key],
              value: moment(date).format('MMM D YYYY'),
              key: key,
            });
          } else if (key === 'current_month_cost') {
            const mtd = getSumOfMTDCostFromGranular(cost?.granular || {});
            extraList.push({
              name: keyDisplayName[key],
              value: mtd,
              key: key,
            });
          } else if (key === 'volume_type') {
            extraList.push({
              name: keyDisplayName[key],
              value: props.data._source.volume_type,
              key: key,
            });
          } else {
            const extraElement = {
              name: keyDisplayName[key],
              value: props.data?._source?.[key],
              key: key,
            };
            if (keyDisplayName[key] === 'Region') {
              const cloudPlatform = props.data?._source?.cloud_type || props.data?._source?.cloud_type;
              extraElement.cloudPlatform = cloudPlatform;
            }
            extraList.push(extraElement);
          }
        });

        if (props?.networkInterfaceData?.length) {
          extraList.push({
            name: 'Allocation ID',
            value: props?.networkInterfaceData.map(item => item.AllocationId).join(', '),
            key: 'AllocationId',
          });
          extraList.push({
            name: 'Network Interface ID',
            value: props?.networkInterfaceData.map(item => item.NetworkInterfaceId).join(', '),
            key: 'NetworkInterfaceId',
          });
          extraList.push({
            name: 'Private IP',
            value: props?.networkInterfaceData.map(item => item.PrivateIp).join(', '),
            key: 'PrivateIp',
          });
          extraList.push({
            name: 'Public IP',
            value: props?.networkInterfaceData.map(item => item.PublicIp).join(', '),
            key: 'PublicIp',
          });
        }
        allRuleList = getList(commonList, extraList, ec2State, tags);
      }
      return allRuleList;
    };

    const redirectedToRule = link => () => {
      const data = props?.data?._source;
      const state = {
        payload: {
          searchString: data?.name || data?.item_id,
          accountState: props?.projectId ? [props?.projectId] : [],
          productState: [data?.['product']] || [],
          regionState: [data?.['region']] || [],
        },
      };

      if (configCatAzure) {
        const cloudPlatform = data?.cloud_type || 'aws';
        if (state.payload.regionState.length) {
          const regionState = state.payload.regionState[0];
          state.payload.regionState_values = [{ id: regionState, value: regionState, cloudPlatform }];
        }
      }
      const stateString = JSON.stringify(state);
      const newEncodedURI = encodeURIComponent(stateString);
      const sign = link.includes('?') ? '&' : '?';
      window.open(link + `${sign}data=${newEncodedURI}`, '_blank');
    };

    const closeModal = () => {
      setShowAction({});
    };

    const openModal = payload => e => {
      e.stopPropagation();
      e.preventDefault();
      setShowAction(payload);
    };

    const isLink = () => {
      if (!props?.awsLink && !azureLink) return;
      return (
        <div className="np-table2Actions">
          <a
            className="np-button blue light"
            target={'_blank'}
            rel="noopener noreferrer"
            href={props.awsLink || azureLink}
          >
            <i className="np-svgIcon">
              <LinkIcon />
            </i>{' '}
            {`View resource on ${props?.awsLink ? 'AWS' : 'Azure'} Console`}
          </a>
        </div>
      );
    };

    const filteredData = (violations || []).filter(violation => {
      const record = overrideRecord.find(override => override.rule_name === violation.rule);
      return !!record?.is_compliant;
    });

    return (
      <>
        <div className="np-table2">
          {isLink()}
          <div className="title">
            <div className="np-titleIcon teal">
              <InfoIcon />
            </div>
            Details
          </div>

          <div className="npTable">
            <LoadingWrapper isLoading={props.loading} customLoader={SimpleTableLoader}>
              <table>
                <tbody>{getAllRows()}</tbody>
              </table>
            </LoadingWrapper>
          </div>
        </div>
        {violations?.length > 0 ? (
          <div className="np-table2 resource-nonCompliances hasViolations">
            <div className="title">
              <div className="np-titleIcon red">
                <RuleIcon />
              </div>
              {violations.length - filteredData.length} Non-compliances
            </div>

            <div className="npTable">
              <LoadingWrapper customLoader={RowLoader} isLoading={props.loading}>
                <table>
                  <tbody>
                    {(violations || []).map(violation => {
                      const record = overrideRecord.find(override => override.rule_name === violation.rule);
                      return (
                        <tr
                          key={violation.rule}
                          className={cx('searchResDetailRow', {
                            overriden: !!record?.is_compliant,
                          })}
                          onClick={redirectedToRule(violation.link)}
                        >
                          <td>
                            <div className="violation">
                              {!record?.is_compliant ? (
                                <i className="np-tableIcon red">
                                  <RuleIcon />
                                </i>
                              ) : (
                                <i className="np-tableIcon green">
                                  <CheckedIcon />
                                </i>
                              )}
                              {violation.desc}

                              {!isDetailPage ? (
                                <button
                                  className="np-button"
                                  onClick={openModal({
                                    rule: violation.rule,
                                    status: !!record,
                                    overrideInfo: record,
                                  })}
                                >
                                  {record?.is_compliant ? 'Revert' : 'Override'}
                                </button>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </LoadingWrapper>
            </div>
          </div>
        ) : null}
        {isEmpty(showAction) ? null : (
          <ActionModal
            closeModal={closeModal}
            getSearchResourceData={props.getSearchResourceData}
            isResource={true}
            loading={props.loading}
            data={{
              mainId: resource_id,
              projectId,
              overrideInfo: showAction.overrideInfo,
              status: showAction.status,
            }}
            rule={showAction.rule}
            resourceOverridePayload={{
              project_ids: [+projectId],
              rule_name: violations.map(violation => violation.rule),
            }}
            isPartner={props.isPartner}
            getViolationData={getViolationData}
          />
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    isEqual(prevProps, nextProps);
  },
);
