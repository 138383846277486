import React from 'react';
import { COST_TYPE } from 'utils/constants';

//ICONS
import dollarBagIcon from 'assets/images/figma-imgs/no-data/dollar-bag.svg';
import dollarCirclesIcon from 'assets/images/figma-imgs/no-data/dollar-circles.svg';
import scheduleClockIcon from 'assets/images/figma-imgs/no-data/schedule-clock.svg';
import roundedCloudsIcon from 'assets/images/figma-imgs/no-data/rounded-clouds.svg';
import stackBehindCloudIcon from 'assets/images/figma-imgs/no-data/stack-behind-cloud.svg';
import handoverDollarIcon from 'assets/images/figma-imgs/no-data/handover-dollar.svg';
import squaresIcon from 'assets/images/figma-imgs/no-data/squares.svg';
import labelsIcon from 'assets/images/figma-imgs/no-data/labels.svg';
import rectanglesIcon from 'assets/images/figma-imgs/no-data/rectangles.svg';
import pointingIcon from 'assets/images/figma-imgs/no-data/pointing.svg';
import lockCheckIcon from 'assets/images/figma-imgs/no-data/lock-check.svg';
import loopCheckIcon from 'assets/images/figma-imgs/no-data/loop-check.svg';
import monkeyWrenchIcon from 'assets/images/figma-imgs/no-data/monkey-wrench.svg';
import twoWayArrowsIcon from 'assets/images/figma-imgs/no-data/two-way-arrows.svg';
import NoDetectionIcon from 'assets/images/no-detection.svg';

export const NO_DATA_CONTENT = {
  DASHBOARD: {
    icon: dollarCirclesIcon,
    description:
      'The nOps Dashboard summarizes changes in your cloud costs and allows you to choose what period of time to compare.',
    learnMoreUrl: 'https://help.nops.io/onboarding-aws-with-automatic-setup.html',
  },
  SCHEDULER: {
    icon: scheduleClockIcon,
    description: 'Use the nOps Scheduler to turn resources and databases off when they are not being used.',
    learnMoreUrl: 'https://help.nops.io/eventbridge-integration.html',
  },
  ESSENTIALS_SHARESAVE: {
    icon: scheduleClockIcon,
    description:
      'Use the Essentials packages and take benefits of various features like Rightsizing, Idle Resources, Storage & Scheduler.',
    learnMoreUrl: 'https://help.nops.io/scheduler-eventbridge-setup.html',
  },
  RIGHTSIZING: {
    icon: scheduleClockIcon,
    description: 'Optimizing resources and expenses to align with current needs efficiently.',
    learnMoreUrl: 'https://help.nops.io/ec2-asg-rightsizing-with-nops-using-datadog.html',
  },
  DATA_EXPLORER: {
    icon: stackBehindCloudIcon,
    description: 'Query the resources in your cloud accounts with the help of nOps Data Explorer powered by GraphQL.',
    learnMoreUrl: 'https://help.nops.io/nops-data-explorer.html',
  },
  [COST_TYPE.CLOUD_ACCOUNTS]: {
    icon: roundedCloudsIcon,
    description:
      'Get a breakdown of your spending for each day in a downloadable table with the help of Daily spend by Cloud Accounts table.',
    learnMoreUrl: 'https://help.nops.io/cost-reporting-for-cloud-resources.html',
  },
  [COST_TYPE.AWS_ACCOUNTS]: {
    icon: roundedCloudsIcon,
    description:
      'Get a breakdown of your spending for each day in a downloadable table with the help of Daily spend by Cloud Accounts table.',
    learnMoreUrl: 'https://help.nops.io/cost-reporting-for-cloud-resources.html',
  },
  [COST_TYPE.REGIONS]: {
    icon: roundedCloudsIcon,
    description:
      'Get a breakdown of your spending for each day in a downloadable table with the help of Daily spend by Regions table.',
    learnMoreUrl: 'https://help.nops.io/cost-reporting-for-cloud-resources.html',
  },
  [COST_TYPE.CLOUD_PRODUCTS]: {
    icon: roundedCloudsIcon,
    description:
      'Get a breakdown of your spending for each day in a downloadable table with the help of Daily spend by Cloud Services table.',
    learnMoreUrl: '',
  },
  [COST_TYPE.AWS_PRODUCTS]: {
    icon: roundedCloudsIcon,
    description:
      'Get a breakdown of your spending for each day in a downloadable table with the help of Daily spend by AWS Services table.',
    learnMoreUrl: 'https://help.nops.io/cost-reporting-for-cloud-resources.html',
  },
  [COST_TYPE.RESOURCES]: {
    icon: roundedCloudsIcon,
    description:
      'Get a breakdown of your spending for each day in a downloadable table with the help of Daily spend by Resources table.',
    learnMoreUrl: 'https://help.nops.io/cost-reporting-for-cloud-resources.html',
  },
  [COST_TYPE.NON_RESOURCES]: {
    icon: roundedCloudsIcon,
    description:
      'Get a breakdown of your spending for each day in a downloadable table with the help of Daily spend by Non-resources table.',
    learnMoreUrl: 'https://help.nops.io/cost-reporting-for-cloud-resources.html',
  },
  [COST_TYPE.USAGE_TYPES]: {
    icon: roundedCloudsIcon,
    description:
      'Get a breakdown of your spending for each day in a downloadable table with the help of Daily spend of top 100 Usage Types table.',
    learnMoreUrl: 'https://help.nops.io/cost-reporting-for-cloud-resources.html',
  },
  [COST_TYPE.OPERATIONS]: {
    icon: roundedCloudsIcon,
    description:
      'Get a breakdown of your spending for each day in a downloadable table with the help of Daily spend of top 100 Operations table.',
    learnMoreUrl: 'https://help.nops.io/cost-reporting-for-cloud-resources.html',
  },
  [COST_TYPE.TAGS]: {
    icon: roundedCloudsIcon,
    description:
      'Get a breakdown of your spending for each day in a downloadable table with the help of All Tags (Keys and Resources) table.',
    learnMoreUrl: 'https://help.nops.io/cost-reporting-for-cloud-resources.html',
  },
  'Change Management': {
    icon: dollarCirclesIcon,
    description:
      'Get a breakdown of the increase and decrease of your spending (daily, weekly, monthly) across all cloud accounts and resources.',
    learnMoreUrl: 'https://help.nops.io/cost-reporting-for-cloud-resources.html',
  },
  CHARGEBACKS: {
    icon: handoverDollarIcon,
    description:
      'Enforce accountability for cloud costs by charging them back to teams and projects. Use Chargeback Center to shift-left cost optimization and make your users cost accountable for the cloud they use.',
    learnMoreUrl: 'https://docs.nops.io/en/articles/5201817-how-to-configure-chargebacks-in-chargeback-center',
  },
  TAG_EXPLORER: {
    icon: labelsIcon,
    description:
      'Tags enable you to categorize your resources in different ways and explore your tag competency across all of your cloud accounts to maintain hygiene.',
    learnMoreUrl: 'https://help.nops.io/cost-tag-explorer.html',
  },
  RESOURCE_RIGHTSIZING: {
    icon: rectanglesIcon,
    description:
      'Rightsizing is an ongoing process since resource needs are constantly changing. To achieve cost optimization make rightsizing a regular part of your cloud management process. nOps simplifies both resource analysis and monitoring with a Resource Rightsizing dashboard.',
    learnMoreUrl: 'https://help.nops.io/cost-resource-rightsizing.html',
  },
  COMMITMENT_MANAGEMENT: {
    icon: pointingIcon,
    description:
      'nOps enables you to view usage of AWS Reserved Instances on a near-real-time basis. Reserved instances provide significant cost savings compared to on-demand billing, and instances and usage can be monitored through the nOps Commitment Management dashboard.',
    learnMoreUrl: 'https://help.nops.io/cost-commitment-management-working-with-reserved-instances.html',
  },
  RULES_Security: {
    icon: lockCheckIcon,
    description:
      'See the violations distribution across different cloud accounts and a complete list of security violations and their suggested resolution.',
    learnMoreUrl: 'https://docs.nops.io/en/?q=Rules',
  },
  RULES_Cost: {
    icon: dollarCirclesIcon,
    description:
      'See the violations distribution and cost optimization recommendations across different cloud accounts and a complete list of cost violations and optimizations recommendations.',
    learnMoreUrl: 'https://docs.nops.io/en/?q=Rules',
  },
  RULES_Reliability: {
    icon: loopCheckIcon,
    description:
      'See the violations distribution across different cloud accounts and a complete list of reliability violations and their suggested resolution.',
  },
  RULES_Operations: {
    icon: monkeyWrenchIcon,
    description:
      'See the distribution of the violations across different cloud accounts and a complete list of operations violations and their suggested resolution.',
  },
  RULES_Performance: {
    icon: rectanglesIcon,
    description:
      'See the distribution of the violations across different cloud accounts and a complete list of performance violations and their suggested resolution.',
  },
  RULES_ChangeManagement: {
    icon: twoWayArrowsIcon,
    description:
      'Create and manage nOps rules that automatically track changes across your cloud. The Custom Rule will be added to reports and used as a filter.',
  },
  CLOUD_TRAIL_CHANGES: {
    icon: twoWayArrowsIcon,
    description: 'Surface actionable insights that are hidden within your large and complex AWS CloudTrail files.',
  },
  CLOUD_INVENTORY: {
    icon: NoDetectionIcon,
    description: 'Query, filter and navigate all your cloud accounts and resources in one place.',
  },
  WAFR: {
    icon: loopCheckIcon,
    description:
      'Search, group and customize Well-Architected Framework Review findings into a report you can download and share.',
  },
  SOC2: {
    icon: loopCheckIcon,
    description: 'Check the SOC2 readiness and violations of your AWS resources and download an Excel report.',
  },
  HIPAA: {
    icon: loopCheckIcon,
    description: 'Work towards HIPAA compliance on your AWS accounts and download the report.',
  },
  CIS: {
    icon: loopCheckIcon,
    description:
      'Live dashboard and downloadable report of security industry CIS 7.1 standard applied to your AWS resources.',
  },
  SECURITY: {
    icon: lockCheckIcon,
    description: 'Check the security status of your AWS accounts.',
    learnMoreUrl: 'https://help.nops.io/security-view-violations.html',
  },
  WORKLOADS: {
    icon: squaresIcon,
    description:
      'Create workloads to group resources, get a bird’s eye view of your workload VPCs as diagrams and start assessments or see reports.',
    learnMoreUrl: 'https://help.nops.io/wafr-review.html',
  },
  AWS_MAP_2: {
    icon: twoWayArrowsIcon,
    description:
      'nOps provides an easy automated way for you to tag any untagged resources ensuring that you get the right tags applied to your resources as early as possible and get the maximum credit from the MAP program.',
    learnMoreUrl: 'https://help.nops.io/automated-tagging-for-aws-map.html',
  },
  CUSTOM_DASHBOARD: {
    icon: loopCheckIcon,
    description:
      'Dashboards are a summary of long volume reports and analyses. nOps dashboards are not any different. They make it easier to get insights faster and easier with a sneak peek. There are a lot of metrics in nOps, so having a custom dashboard will be a lifesaver. Here are the steps involved in creating a custom dashboard to put just the metrics you need.',
    learnMoreUrl: 'https://docs.nops.io/en/articles/4785263-create-a-custom-dashboard',
  },
  CUSTOM_REPORT: {
    icon: loopCheckIcon,
    description: 'Use nOps widgets and common document widgets to compose your own report from nOps insights.',
    learnMoreUrl: 'https://docs.nops.io/en/articles/4785307-how-to-create-and-view-custom-reports',
  },
  COMPUTE_COPILOT_SHARESAVE: {
    icon: dollarBagIcon,
    description: 'All the savings Opportunities and Realizations for EKS, ASG and Commitment Management by nOps.',
    youtube: 'https://www.youtube.com/embed/pT5lktRxmv8',
  },
  EKS_CLUSTERS: {
    icon: dollarBagIcon,
    description: 'All the savings opportunities for EKS Clusters by nOps.',
  },
  ASG: {
    icon: dollarBagIcon,
    description: "All the savings opportunities for ASG's by nOps.",
  },
  DATABRICKS: {
    icon: dollarBagIcon,
    description: 'All the savings opportunities for Databricks by nOps.',
  },
  CM: {
    icon: dollarBagIcon,
    description: 'Configure Commitment Manager and reduce your costs on auto-pilot.',
  },
};

const NoData = ({ icon, description, learnMoreUrl, videoSource, youtube }) => {
  const clickLearnMore = () => {
    window.open(learnMoreUrl, '_blank');
  };

  return (
    <section className="no-dataMessage larger">
      <img src={icon} alt="np-nodata-icon" />
      <p>{description}</p>
      {learnMoreUrl ? (
        <div className="content">
          <button className="np-button light" onClick={clickLearnMore}>
            Learn More
          </button>
        </div>
      ) : null}
      {videoSource ? (
        <div className="np-video mT25">
          <video width="640" height="360" controls>
            <source src={videoSource.mp4} type="video/mp4" />
            <source src={videoSource.ogg} type="video/ogg" />
          </video>
        </div>
      ) : null}
      {youtube ? (
        <div className="np-video mT25">
          <iframe
            width="640"
            height="360"
            title="nOps in 1 Min by JT Giri"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            src={youtube}
          ></iframe>
        </div>
      ) : null}
    </section>
  );
};

export default NoData;
