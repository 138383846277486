import React from 'react';

const LoadingWrapper = ({
  isLoading,
  customLoader,
  children = null,
  customClassName = '',
  customLoadingClassName = '',
}) => {
  if (isLoading) {
    // window.scrollTo({ top: 0 });
    return <div className={customLoadingClassName}>{customLoader}</div>;
  }

  return customClassName ? <div className={customClassName}>{children}</div> : children;
};

export default LoadingWrapper;
