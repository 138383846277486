import get from 'lodash/get';
import { COLUMNS_FOR_RULES } from 'utils/rulesColumnDetails';

const RULE_DETAIL = {
  Security: {
    check_resource_encryption: {
      name: 'Monitoring resources for encryption at rest',
      breadcrumbTitle: 'Monitoring resources for encryption at rest',
      description: 'This rule provides Encryption at Rest status for resources (EBS/RDS/S3).',
      service: ['rds', 'ebs', 's3'],
      rule_link: '/v3/rules/?rule=check_resource_encryption',
      rds: {
        title: 'Amazon RDS instances are unencrypted',
        text: 'Unencrypted AWS RDS instances',
        description: 'Encrypt AWS RDS instances to protect data at rest',
        path: 'key=check_resource_encryption&service=rds',
        fileName: 'list-of-unencrypted-rds-bucket',
        column: 'check_resource_encryption.rds',
      },
      ebs: {
        title: 'Amazon EBS volumes are unencrypted',
        text: 'Unencrypted AWS EBS volumes',
        description: 'Encrypt AWS EBS volumes to protect data at rest',
        path: 'key=check_resource_encryption&service=ebs',
        fileName: 'list-of-unencrypted-ebs-bucket',
        column: 'check_resource_encryption.ebs',
      },
      s3: {
        title: 'Amazon S3 buckets are unencrypted',
        text: 'Unencrypted AWS S3 buckets',
        description: 'Encrypt AWS S3 buckets to protect data at rest',
        path: 'key=check_resource_encryption&service=s3',
        fileName: 'list-of-unencrypted-s3-bucket',
        column: 'check_resource_encryption.s3',
      },
    },
    policy_attached_users: {
      name: 'IAM users that are not following best practices for IAM policies',
      breadcrumbTitle: 'Policy check for IAM users',
      description: '',
      service: ['iam'],
      iam: {
        title: 'List of IAM users with inline and direct policies.',
        text: 'Users to review for AWS IAM privilege',
        description: 'Consider revoking unnecessary privilege permissions to protect account access',
        path: 'key=policy_attached_users&service=iam',
        fileName: 'list-of-user-with-policy',
        column: 'policy_attached_users.iam',
      },
    },
    no_mfa_users: {
      name: 'Users without MFA',
      breadcrumbTitle: 'Users without MFA details',
      description:
        'Checks whether the user of your AWS account requires multi-factor authentication for console sign-in.',
      service: ['iam'],
      iam: {
        title: 'AWS IAM users aren’t using MFA sign-in',
        text: 'Users without MFA',
        description: 'Consider enforcing MFA to better protect AWS resources',
        path: 'key=no_mfa_users&service=iam',
        fileName: 'list-of-no-mfa-users',
        column: 'no_mfa_users.iam',
      },
    },
    public_subnet: {
      name: 'Unrestricted public access details',
      breadcrumbTitle: 'Unrestricted public access details',
      description: 'Checks whether security groups that are in use allow unrestricted incoming SSH traffic.',
      service: ['rds', 'ec2'],
      rule_link: '/v3/rules/?rule=public_subnet',
      rds: {
        title: 'public subnet open ports to Amazon RDS',
        text: 'AWS RDS with public subnets with open ports',
        description: 'Consider blocking these RDS ports to secure inbound internet traffic better',
        path: 'key=public_subnet&service=rds',
        fileName: 'list-of-public-subnet-rds-bucket',
        column: 'public_subnet.rds',
      },
      ec2: {
        title: 'public subnet open ports to Amazon EC2',
        text: 'AWS EC2 with public subnets with open ports',
        description: 'Consider blocking these EC2 ports to secure inbound internet traffic better',
        path: 'key=public_subnet&service=ec2',
        fileName: 'list-of-public-subnet-ec2-bucket',
        column: 'public_subnet.ec2',
      },
    },
    s3_bucket_security: {
      name: 'S3 security details',
      breadcrumbTitle: 'S3 Security rule Details',
      description: 'The rule does security checks on S3 buckets.',
      service: ['s3'],
      s3: {
        title: 'Amazon S3 buckets have public read / write access',
        text: 'AWS S3 buckets with public read/write access',
        description: 'Consider restricting public access to protect data of S3 buckets',
        path: 'key=s3_bucket_security&service=s3',
        fileName: 'list-of-s3-bucket-bucket',
        column: 's3_bucket_security.s3',
      },
    },
    check_aws_config: {
      name: 'AWS Config check',
      breadcrumbTitle: 'AWS config check',
      description:
        'AWS Config provides a detailed view of the configuration of AWS resources in your AWS account. This rule checks whether AWS config is enabled in your AWS account.',
      service: ['regions'],
      regions: {
        title: 'AWS Regions don’t have AWS Config enabled',
        text: 'Disabled AWS Config for AWS Regions',
        description: 'Consider enabling AWS Config for better security',
        path: 'key=check_aws_config&service=regions',
        fileName: 'list-of-config-check-bucket',
        column: 'check_aws_config.regions',
      },
    },
    cloudtrail_log_validation_enabled: {
      name: 'CloudTrail check',
      breadcrumbTitle: 'CloudTrail check',
      description: 'Enable CloudTrail log file integrity validation',
      service: ['ct_trail'],
      ct_trail: {
        title: 'AWS Accounts don’t have AWS CloudTrail enabled',
        text: 'AWS CloudTrail event log disabled',
        description: 'Consider enabling AWS CloudTrail event logging to detect potential security issues',
        path: 'key=cloudtrail_log_validation_enabled&service=ct_trail',
        fileName: 'list-of-cloudtrail-log-bucket',
        column: 'cloudtrail_log_validation_enabled.ct_trail',
      },
    },
    check_root_user_mfa: {
      name: 'Root user without MFA details',
      breadcrumbTitle: 'Root user without MFA',
      description: 'Checks if your root account has multi-factor authentication enabled.',
      service: ['iam'],
      iam: {
        title: 'AWS accounts don’t have root account MFA',
        text: 'Root user without MFA',
        description: 'Consider enforcing MFA on the root account to secure account operations',
        path: 'key=check_root_user_mfa&service=iam',
        fileName: 'list-of-root-user-mfa',
        column: 'check_root_user_mfa.iam',
      },
    },
    root_account_usage: {
      name: 'Root account usage details',
      breadcrumbTitle: 'Root account usage',
      description:
        "This rule scans the workload and provides you with details of the root account usage. It's strongly recommended that you do not use the root user for your everyday tasks, even the administrative ones. Instead, adhere to the best practice of using the root user only to create your first IAM user. Root account should be used to perform only a few account and service management tasks.",
      service: ['iam'],
      iam: {
        title: 'AWS root account activities detected',
        text: 'Detected usage of root account',
        description: "Don't use root user for your everyday tasks, even the administrative ones. Use IAM users",
        path: 'key=root_account_usage&service=iam',
        fileName: 'list-of-root-account-usage',
        column: 'root_account_usage.iam',
      },
    },
    inactive_keys: {
      name: 'Inactive keys for last 6 days',
      breadcrumbTitle: 'Inactive key rule details',
      description: 'Checks whether access key of the IAM user is used for last 6 days.',
      service: ['iam'],
      iam: {
        title: 'Inactive IAM account access key(s)',
        text: 'Inactive IAM account key detected',
        text_for_grater: 'Inactive IAM account keys detected',
        description: 'Consider deactivating or deleting them to prevent root access to AWS',
        path: 'key=inactive_keys&service=iam',
        fileName: 'list-of-inactive-key',
        column: 'inactive_keys.iam',
      },
    },
    classic_vpc: {
      name: 'EC2-Classic VPC details',
      breadcrumbTitle: 'Classic VPC rule details',
      description: 'Checks whether there are any EC2 resources which are running in classic VPC.',
      service: ['ec2'],
      ec2: {
        title: 'Migrate Amazon EC2 instances from EC2-Classic VPC to Amazon VPC',
        text: 'Detected AWS EC2 instances in Classic EC2-VPCs',
        description: 'Migrate AWS EC2 instances from EC2-Classic VPC to AWS VPC to protect against external threats',
        path: 'key=classic_vpc&service=ec2',
        fileName: 'list-of-classic-vpc',
        column: 'classic_vpc.ec2',
      },
    },
    check_guardduty_enabled: {
      name: 'Amazon GuardDuty check',
      breadcrumbTitle: 'Amazon GuardDuty',
      description: '',
      service: ['iam'],
      iam: {
        title: 'Amazon GuardDuty',
        text: 'Disabled AWS GuardDuty accounts',
        description: 'Consider Enabling GuardDuty intelligent threat detection and continuous monitoring',
        path: 'key=check_guardduty_enabled&service=iam',
        fileName: 'list-of-guardduty-enabled',
        column: 'check_guardduty_enabled.iam',
      },
    },
    check_waf_used: {
      name: 'AWS web application firewall configuration',
      breadcrumbTitle: 'AWS web application firewall',
      description: '',
      service: ['iam'],
      iam: {
        title: 'Application Servers are not protected by AWS Web Application Firewall',
        text: 'AWS WAF without resource attached',
        description: 'Consider attaching at least a resource to the AWS WAF ACLs',
        path: 'key=check_waf_used&service=iam',
        fileName: 'list-of-waf-used',
        column: 'check_waf_used.iam',
      },
    },
    check_aws_inspector: {
      name: 'Amazon Inspector configuration',
      breadcrumbTitle: 'Amazon Inspector is configured',
      description: '',
      service: ['iam'],
      iam: {
        title: 'Amazon Inspector is utilized',
        text: 'AWS Accounts with non configured Amazon Inspector',
        description:
          'Consider configuring Amazon Inspector to improve the security and compliance of your AWS resources',
        path: 'key=check_aws_inspector&service=iam',
        fileName: 'list-of-aws-inspector',
        column: 'check_aws_inspector.iam',
      },
    },
    unused_roles: {
      name: 'Details of infrequently used IAM roles',
      breadcrumbTitle: 'Unused IAM role',
      description: 'Checks if there are IAM roles have not been used in the past 90 days.',
      service: ['iam_role'],
      iam_role: {
        title: 'infrequently used IAM',
        text: 'Unused IAM Roles',
        description: 'Consider deleting these unused IAM roles for better security',
        path: 'key=unused_roles&service=iam_role',
        fileName: 'list-of-infrequently-used-iam',
        column: 'unused_roles.iam_role',
      },
    },
    public_ebs_snapshot: {
      name: 'Public EBS snapshots details',
      breadcrumbTitle: 'Public EBS snapshot',
      description: 'EBS snapshots have public access enabled. Remove public access to better protect the data.',
      service: ['ebs_snapshot'],
      ebs_snapshot: {
        title: 'EBS snapshots have public access enabled',
        text: 'EBS snapshots with public access',
        description: 'Consider removing public access for EBS snapshots for better data protection',
        path: 'key=public_ebs_snapshot&service=ebs_snapshot',
        fileName: 'list-of-public-ebs-snapshot',
        column: 'public_ebs_snapshot.ebs_snapshot',
      },
    },
    root_access_keys: {
      name: 'Root access key',
      breadcrumbTitle: 'Root access key',
      description: 'AWS Accounts have Root Access Key and Secret Key activated.',
      service: ['iam'],
      iam: {
        title: 'List of Root access active keys',
        text: 'Active root access key and secret key',
        description: 'Consider using IAM roles and disabling active keys',
        path: 'key=root_access_keys&service=iam',
        fileName: 'list-of-root-access-keys',
        column: 'root_access_keys.iam',
      },
    },
    strong_password_policy: {
      name: 'AWS account password policy',
      breadcrumbTitle: 'Strong password policy',
      description: 'AWS accounts with no strong password policy configuration was detected in all the AWS account(s)',
      service: ['iam'],
      iam: {
        title: 'Account list having strong password policy',
        text: 'Detected weak password policy',
        description: 'Consider configuring strong password policy for AWS accounts',
        path: 'key=strong_password_policy&service=iam',
        fileName: 'list-of-strong-password-policy',
        column: 'strong_password_policy.iam',
      },
    },
    cloudtrail_s3_protection: {
      name: 'Cloudtrail s3 protection',
      breadcrumbTitle: 'Cloudtrail s3 protection',
      description: 'AWS accounts do not have the CloudTrail bucket protected from deletion or overwrite',
      service: ['s3'],
      s3: {
        title: 'List of Cloudtrail s3 protection',
        text: 'Disabled CloudTrail File Integrity check',
        description: 'File integrity check validated log files are invaluable in security and forensic investigations',
        path: 'key=cloudtrail_s3_protection&service=s3',
        fileName: 'list-of-cloudtrail-s3-protection',
        column: 'cloudtrail_s3_protection.s3',
      },
    },
    admin_users: {
      name: 'Admin Users',
      breadcrumbTitle: 'Admin Users',
      description: 'Checks for users with Administrator Access policy',
      service: ['iam'],
      iam: {
        title: 'IAM Users with Administrator Access policy',
        text: 'Admin User Detected without Explicit Override',
        description: "Review users with admin privileges and use the 'Override' function to explicitly allow",
        path: 'key=admin_users&service=iam',
        fileName: 'list-of-admin_users',
        column: 'admin_users.iam',
      },
    },
    public_lambda: {
      name: 'Public Lambda',
      breadcrumbTitle: 'Public Lambda',
      description: 'Checks if the Lambda access policy allows public invocation',
      service: ['lambda'],
      lambda: {
        title: 'Public Lambda Functions',
        text: 'Public Lambda function without exception',
        description: 'Public functions can have unauthorized use',
        path: 'key=public_lambda&service=lambda',
        fileName: 'list-of-public-lambda',
        column: 'public_lambda.lambda',
      },
    },
    unencrypted_lambda_variables: {
      name: 'CMK Unencrypted Lambda Variables',
      breadcrumbTitle: 'CMK Unencrypted Lambda Variables',
      description: 'Checks if Lambda environment variables are encrypted using CMK',
      service: ['lambda'],
      lambda: {
        title: 'CMK Unencrypted Lambda Variables',
        text: 'Lambda environment variables without CMK encryption enabled',
        description:
          'Environment variables can contain sensitive environment information and should be encrypted using CMK.',
        path: 'key=unencrypted_lambda_variables&service=lambda',
        fileName: 'list-of-unencrypted-variables-lambda',
        column: 'unencrypted_lambda_variables.lambda',
      },
    },
    triggerless_lambda: {
      name: 'Orphaned Lambda Function',
      breadcrumbTitle: 'Orphaned Lambda Function',
      description: "Checks for Lambda functions that don't have triggers defined",
      service: ['lambda'],
      lambda: {
        title: 'Orphaned Lambda Function',
        text: 'Lambda function without trigger',
        description: 'Lambda functions without triggers lead to operational complexity and unmanaged resources',
        path: 'key=triggerless_lambda&service=lambda',
        fileName: 'list-of-triggerless-lambda',
        column: 'triggerless_lambda.lambda',
      },
    },
    ami_public: {
      name: 'Public AMIs',
      breadcrumbTitle: 'Public AMI',
      description: 'Checks for AMIs that are public',
      service: ['ami'],
      ami: {
        title: 'Public AMI',
        text: 'Public AMI',
        description: 'Public AMIs can expose sensitive data',
        path: 'key=ami_public&service=ami',
        fileName: 'list-of-public-ami',
        column: 'ami_public.ami',
      },
    },
    vpc_flow_logging_disabled: {
      name: 'VPC Flow Logging Disabled',
      breadcrumbTitle: 'VPC Flow Logging Disabled',
      description: 'Checks for VPCs without Flow Logging enabled.',
      service: ['vpc'],
      vpc: {
        title: 'VPCs with Flow Logging Disabled',
        text: 'VPC with Flow Logging Disabled',
        description:
          'VPC Flow Logging can help you with diagnosing overly restrictive security group rules and traffic monitoring.',
        path: 'key=vpc_flow_logging_disabled&service=vpc',
        fileName: 'list-of-vpc-flow-logging-disabled',
        column: 'vpc_flow_logging_disabled.vpc',
      },
    },
    rds_public_snapshot: {
      name: 'Public RDS Snapshot',
      breadcrumbTitle: 'Public RDS Snapshot',
      description: 'Checks for RDS Snapshots that are public',
      service: ['rds_snapshot'],
      rds_snapshot: {
        title: 'Public RDS snapshots',
        text: 'Public RDS Snapshot',
        description: 'Public RDS Snapshots can expose private information',
        path: 'key=rds_public_snapshot&service=rds_snapshot',
        fileName: 'list-of-public-rds_snapshot',
        column: 'rds_public_snapshot.rds_snapshot',
      },
    },
  },
  Cost: {
    s3_underutilized: {
      name: 'Infrequently accessed S3 bucket(s)',
      breadcrumbTitle: 'Infrequently accessed S3 bucket(s)',
      description: 'You may want to consider a lower cost storage option, e.g. infrequent access, Glacier',
      service: ['s3'],
      s3: {
        title: 'List of infrequently accessed S3 bucket',
        text: 'Infrequently accessed S3 buckets',
        description: 'Consider lower cost storage options like infrequent access, Glacier',
        path: 'key=s3_underutilized&service=s3',
        fileName: 'list-of-infrequently-accessed-S3-bucket',
        column: 's3_underutilized.s3',
      },
    },
    underutilized_resources: {
      name: 'Underutilized resources',
      breadcrumbTitle: 'Underutilized resources',
      description: 'Showing resources that are running below 10%, 20%, 30% CPU utilization.',
      service: ['ec2'],
      ec2: {
        title: 'List of all resources with CPU utilization',
        text: 'Underutilized (< 30% capacity on avg for last week) EC2 instances',
        description: 'Review instances to identify opportunities for rightsizing or consolidation',
        path: 'key=underutilized_resources&service=ec2',
        fileName: 'list-of-underutilized-resources',
        column: 'underutilized_resources.ec2',
      },
    },
    efs_low_utilization: {
      name: 'Infrequently accessed EFS',
      breadcrumbTitle: 'EFS Infrequently accessed',
      description:
        'You may want to enable EFS Lifecycle Management on your file system so that files not accessed according to the lifecycle policy you choose will be automatically and transparently moved into EFS IA.',
      service: ['efs'],
      efs: {
        title: 'List of Infrequently accessed EFS',
        text: 'Infrequently accessed EFS resource',
        text_for_grater: 'Infrequently accessed EFS resources',
        description:
          'Consider enabling EFS Lifecycle Management on your file system so that files not accessed according to the lifecycle policy you choose will be automatically and transparently moved into EFS IA',
        path: 'key=efs_low_utilization&service=efs',
        fileName: 'list-of-EFS-Infrequently-accessed',
        column: 'efs_low_utilization.efs',
      },
    },
    ecs_low_utilization: {
      name: 'ECS cluster with underutilized resources',
      breadcrumbTitle: 'ECS cluster with underutilized resources',
      description: '',
      service: ['ecs'],
      ecs: {
        title: 'List of Underutilized ECS resources',
        text: 'Underutilized (<10%) AWS ECS cluster',
        text_for_grater: 'Underutilized (<10%) AWS ECS clusters',
        description:
          'Review AWS ECS cluster with EC2 Launch Type has resources with below 10% utilization across cluster',
        description_for_grater:
          'Review AWS ECS clusters with EC2 Launch Type has resources with below 10% utilization across cluster',
        path: 'key=ecs_low_utilization&service=ecs',
        fileName: 'list-of-ECS-underutilized-resources',
        column: 'ecs_low_utilization.ecs',
      },
    },
    instance_recommendation_rightsizing: {
      name: 'EC2 Instance rightsizing recommendation',
      breadcrumbTitle: 'EC2 Instance rightsizing recommendation',
      description:
        'You may want to lower read/write capacity for EC2 instance recommendation rightsizing or consider switching it from Provisioned Mode to On-Demand Mode.',
      service: ['ec2'],
      ec2: {
        title: 'List of EC2 instances for rightsizing recommendation',
        text: 'Review EC2 instance size',
        description: 'Consider rightsizing EC2 instances to right class and type to save money',
        path: 'key=instance_recommendation_rightsizing&service=ec2',
        fileName: 'list-of-instance-recommendation-rightsizing',
        column: 'instance_recommendation_rightsizing.ec2',
      },
    },
    instance_recommendation: {
      name: 'RDS Instance recommendation',
      breadcrumbTitle: 'RDS Instance recommendation',
      description:
        'You may want to lower read/write capacity for RDS instance recommendation or consider switching it from Provisioned Mode to On-Demand Mode.',
      service: ['rds'],
      rds: {
        title: 'List of RDS instances for rightsizing recommendation',
        text: 'Review RDS instance size',
        description: 'Consider rightsizing RDS instances to right class and type to save money',
        path: 'key=instance_recommendation&service=rds',
        fileName: 'list-of-instance-recommendation',
        column: 'instance_recommendation.rds',
      },
    },
    dynamodb_low_utilization: {
      name: 'DynamoDB low utilization',
      breadcrumbTitle: 'DynamoDB low utilization',
      description:
        'You may want to lower read/write capacity for these tables or consider switching them from Provisioned Mode to On-Demand Mode.',
      service: ['dynamodb'],
      dynamodb: {
        title: 'List of DynamoDB tables with low utilization',
        text: 'Underutilized (<30% read/write) DynamoDB tables',
        description:
          'Consider lowering DynamoDB read/write capacity or switching from Provisioned Mode to On-Demand Mode',
        path: 'key=dynamodb_low_utilization&service=dynamodb',
        fileName: 'list-of-DynamoDB-low-utilization',
        column: 'dynamodb_low_utilization.dynamodb',
      },
    },
    log_group_underutilized: {
      name: 'CloudWatch log groups underutilized resources',
      breadcrumbTitle: 'CloudWatch log groups underutilized resources',
      description: 'Consider setting a desirable log retention time for these CloudWatch log groups to save money.',
      service: ['loggroup'],
      loggroup: {
        title: 'List of CloudWatch Log Groups Underutilized resources',
        text: 'Underutilized AWS CloudWatch Log Group',
        description: 'Consider setting a desirable log retention time for these CloudWatch Log Groups to save money',
        path: 'key=log_group_underutilized&service=loggroup',
        fileName: 'list-of-CloudWatch-log-groups-underutilized-resources',
        column: 'log_group_underutilized.loggroup',
      },
    },
    iops_performance: {
      name: 'IOPS performance check',
      breadcrumbTitle: 'IOPS performance check',
      description: 'Checks whether there is underutilized IOPS resource in your AWS account.',
      service: ['ebs', 'rds'],
      rule_link: '/v3/rules/?rule=iops_performance',
      ebs: {
        title: 'Underutilized Amazon EBS Provisioned IOPS volumes',
        text: 'Underutilized AWS EBS provisioned IOPS',
        description: 'Review the IOPS with standard and fine tune',
        path: 'key=iops_performance&service=ebs',
        fileName: 'list-of-iops-performance-volumes',
        column: 'iops_performance.ebs',
      },
      rds: {
        title: 'Underutilized Amazon RDS Provisioned IOPS volumes',
        text: 'Underutilized AWS RDS provisioned IOPS',
        description: 'Review the IOPS with standard and fine tune',
        path: 'key=iops_performance&service=rds',
        fileName: 'list-of-iops-performance-volumes',
        column: 'iops_performance.rds',
      },
    },
    dynamodb_limit: {
      name: 'Dynamodb Throughput Check',
      breadcrumbTitle: 'Dynamodb throughput check',
      description:
        'Centralizes information about major Dynamodb throughput usage across multiple AWS Managed Services and it alerts.',
      service: ['dynamodb'],
      dynamodb: {
        title: 'Amazon DynamoDB tables have auto scaling enabled',
        text: 'Disabled autoscaling DynamoDB tables',
        description: 'Consider enabling autoscaling to reduce throughput and save money',
        path: 'key=dynamodb_limit&service=dynamodb',
        fileName: 'list-of-dynamodb-throughput-bucket',
        column: 'dynamodb_limit.dynamodb',
      },
    },
    unused_resources: {
      pillars_name: ['Cost', 'Performance'],
      name: 'Unused AWS resources details',
      breadcrumbTitle: 'Unused AWS resources check',
      description: 'Checks whether there is unused resource in your AWS account.',
      service: ['ebs', 'elb', 'nat', 'eip'],
      rule_link: '/v3/rules/?rule=unused_resources',
      ebs: {
        title: 'Unused Amazon EBS volumes',
        text: 'Unused AWS EBS volumes',
        description: 'Consider deleting non used EBS volumes',
        path: 'key=unused_resources&service=ebs',
        fileName: 'list-of-unused-resources-ebs-bucket',
        column: 'unused_resources.ebs',
      },
      elb: {
        title: 'Unused AWS ELB resources',
        text: 'Unused AWS ELB resources',
        description: 'Consider deleting non used ELB resources',
        path: 'key=unused_resources&service=elb',
        fileName: 'list-of-unused-resources-els-bucket',
        column: 'unused_resources.elb',
      },
      nat: {
        title: 'Unused NAT resources',
        text: 'Unused AWS NAT resources',
        description: 'Consider deleting non used NAT resources',
        path: 'key=unused_resources&service=nat',
        fileName: 'list-of-unused-resources-nat-bucket',
        column: 'unused_resources.nat',
      },
      eip: {
        title: 'Unused Elastic IP (EIP) resources',
        text: 'Unused AWS Elastic IP (EIP) resources',
        description: 'Consider deleting non used Elastic IP (EIP) resources',
        path: 'key=unused_resources&service=eip',
        fileName: 'list-of-unused-resources-eip-bucket',
        column: 'unused_resources.eip',
      },
    },
    ec2_low_network_utilization: {
      name: 'EC2 low network traffic details',
      breadcrumbTitle: 'EC2 low network traffic',
      description: '',
      service: ['ec2'],
      ec2: {
        title: 'EC2 Low Network Traffic details',
        text: 'Low traffic AWS EC2 instances',
        description: 'Review or terminate idle instances to save money',
        path: 'key=ec2_low_network_utilization&service=ec2',
        fileName: 'list-of-low-network-utilization',
        column: 'ec2_low_network_utilization.ec2',
      },
    },
    rds_instances_idle: {
      name: 'RDS instance idle',
      breadcrumbTitle: 'RDS idle',
      description: 'Checks whether the average connections RDS instance have been 0',
      service: ['rds'],
      rds: {
        title: 'RDS instance idle',
        text: 'RDS instance idle',
        description: 'Review or terminate idle instances to save money',
        path: 'key=rds_instances_idle&service=rds',
        fileName: 'list-of-idle-rds-instances',
        column: 'rds_instances_idle.rds',
      },
    },
    unattached_workspace_directories: {
      name: 'Unattached Workspace Directories',
      breadcrumbTitle: 'Unattached Workspace Directory',
      description: 'Checks whether workspace directory has any workspaces attached',
      service: ['workspace_directory'],
      workspace_directory: {
        title: 'Unattached workspace directories',
        text: 'Unattached Workspace Directory',
        description: 'Cleanup orphaned workspace directories to save money',
        path: 'key=unattached_workspace_directories&service=workspace_directory',
        fileName: 'list-of-unattached-workspace-directories',
        column: 'unattached_workspace_directories.workspace_directory',
      },
    },
  },
  Reliability: {
    ebs_snapshot: {
      name: 'List of EBS volumes without snapshots',
      breadcrumbTitle: 'EBS volumes without snapshots',
      description:
        'Depending on the type of workload, snapshotting EBS volumes is required. This rule identifies EBS volumes without Snapshots. The user can decide either to enable snapshots or override this rule.',
      service: ['ebs'],
      ebs: {
        title: 'Amazon EBS volumes aren’t using backup snapshots for enhanced reliability',
        text: 'Missing snapshots for AWS EBS volumes',
        description: 'Consider enabling snapshots for EBS volumes for enhanced reliability',
        path: 'key=ebs_snapshot&service=ebs',
        fileName: 'list-of-ebs-snapshot',
        column: 'ebs_snapshot.ebs',
      },
    },
    dynamodb_continuos_backup: {
      name: 'DynamoDB backup check',
      breadcrumbTitle: 'DynamoDB backup check',
      description:
        'This rule checks if DynamoDB backup is enabled. DynamoDB has a Point-in-time recovery feature, which allows restoring the data to point in time.',
      service: ['dynamodb'],
      dynamodb: {
        title: 'AWS DynamoDB tables have default backup enabled',
        text: 'Disabled AWS DynamoDB backup',
        description:
          'Consider enabling point-in-time recovery for DynamoDB tables which is necessary to recover accidentally deleted data',
        path: 'key=dynamodb_continuos_backup&service=dynamodb',
        fileName: 'list-of-dynamodb-continuous-backup',
        column: 'dynamodb_continuos_backup.dynamodb',
      },
    },
    rds_backup_policy: {
      name: 'RDS resources with backup policy details',
      breadcrumbTitle: 'RDS resources with backup policy details',
      description:
        'This rule shows RDS resources with default backup policy where the backup retention period is 1 day. The user can set their own backup retention period for such resources.',
      service: ['rds'],
      rds: {
        title: 'AWS RDS default backup retention policy enabled',
        text: 'Disabled point-in-time recovery for AWS RDS instance',
        text_for_grater: 'Disabled point-in-time recovery for AWS RDS instances',
        description: 'Consider updating default backup policy to provide point-in-time recovery of your RDS instance',
        description_for_grater:
          'Consider updating default backup policy to provide point-in-time recovery of your RDS instances',
        path: 'key=rds_backup_policy&service=rds',
        fileName: 'list-of-rds-with-default-policy',
        column: 'rds_backup_policy.rds',
      },
    },
    check_ec2_autoscaling: {
      name: 'EC2 autoscaling details',
      breadcrumbTitle: 'EC2 Autoscaling',
      description:
        'Resources are part of autoscaling groups. Leverage autoscaling for  high availability and lower cost.',
      service: ['ec2'],
      ec2: {
        title: 'Resources are part of autoscaling groups',
        text: 'Autoscaling disabled for EC2 instances',
        description: 'Consider leveraging autoscaling for high availability and lower cost',
        path: 'key=check_ec2_autoscaling&service=ec2',
        fileName: 'list-of-ec2-autoscaling-bucket',
        column: 'check_ec2_autoscaling.ec2',
      },
    },
    rds_instances_without_multiaz: {
      name: 'Enable Multi-AZ for RDS',
      breadcrumbTitle: 'Enable Multi-AZ for RDS',
      description:
        'RDS instances are enabled for Multi-AZ. It provides high availability to quickly and automatically recover from infrastructure failures.',
      service: ['rds'],
      rds: {
        title: 'RDS instances are running in a Single-AZ deployment',
        text: 'Disabled Multi-AZ RDS instances',
        description:
          'Enable RDS Multi-AZ for High Availability to quickly and automatically recover from infrastructure failures',
        path: 'key=rds_instances_without_multiaz&service=rds',
        fileName: 'list-of-rds-instances-without-multiaz',
        column: 'rds_instances_without_multiaz.rds',
      },
    },
    ami_expired: {
      name: 'Out of date AMI',
      breadcrumbTitle: 'Out of date AMI',
      description:
        'Checks for AMIs that are older than six months old. These resources are likely missing important patches and security updates required for reliable operations.',
      service: ['ami'],
      ami: {
        title: 'Out of date AMIs',
        text: 'Out of date AMIs',
        description:
          'AMIs older than six months old are likely missing critical patches and security updates required for reliable operations',
        path: 'key=ami_expired&service=ami',
        fileName: 'list-of-expired-ami-instances',
        column: 'ami_expired.ami',
      },
    },
    elasticache_disabled_multizone: {
      name: 'Enable Multi-AZ for Elasticache',
      breadcrumbTitle: 'Enable Multi-AZ for Elasticache',
      description:
        'Elasticache instances are enabled for Multi-AZ. It provides high availability to quickly and automatically recover from infrastructure failures.',
      service: ['elasticache'],
      elasticache: {
        title: 'Elasticache instances are running in a single availability zone',
        text: 'Disabled Multi-AZ Elasticache instances',
        description:
          'Enable Elasticache Multi-AZ for High Availability to quickly and automatically recover from infrastructure failures.',
        path: 'key=elasticache_disabled_multizone&service=elasticache',
        fileName: 'list-of-elasticache-disabled-multizone',
        column: 'elasticache_disabled_multizone.elasticache',
      },
    },
    s3_bucket_versioning_disabled: {
      name: 'S3 versioning enabled',
      breadcrumbTitle: 'S3 versioning not enabled',
      description:
        'S3 bucket versioning allows you to easily recover from unintended user actions and application failures',
      service: ['s3'],
      s3: {
        title: 'S3 buckets without versioning enabled',
        text: 'Disabled bucket versioning',
        description:
          'S3 bucket versioning allows you to easily recover from unintended user actions and application failures',
        path: 'key=s3_bucket_versioning_disabled&service=s3',
        fileName: 'list-of-s3-buckets-versioning-disabled',
        column: 's3_bucket_versioning_disabled.s3',
      },
    },
  },
  Operations: {
    check_aws_enterprise_support: {
      name: 'AWS enterprise support',
      breadcrumbTitle: 'AWS Enterprise support',
      description: '',
      service: ['iam'],
      iam: {
        title: 'AWS Enterprise Support',
        text: 'Disabled AWS Enterprise support',
        description: 'AWS Enterprise Support is recommended production and/or mission critical workloads',
        path: 'key=check_aws_enterprise_support&service=iam',
        fileName: 'list-of-aws-enterprise-support',
        column: 'check_aws_enterprise_support.iam',
      },
    },
    vpc_cidr: {
      name: 'List of overlapping VPC CIDR',
      breadcrumbTitle: 'Overlapping VPC CIDR',
      description:
        'It`s best practice not to use overlapping CIDR blocks in the same AWS account having multiple VPC. It causes issues with peering. This rule shows active VPC with the same CIDR in the same account.',
      service: ['vpc'],
      vpc: {
        title: 'List of Overlapping VPC CIDR',
        text: 'Overlapping VPC CIDR',
        description:
          'It`s best practice not to use overlapping CIDR blocks in the same AWS account with multiple VPCs. This can cause issues with peering',
        path: 'key=vpc_cidr&service=vpc',
        fileName: 'list-of-vpc-cidr',
        column: 'vpc_cidr.vpc',
      },
    },
    check_empty_tags: {
      name: 'Tag violation details',
      breadcrumbTitle: 'Tag violation',
      description:
        'Checks whether your resources have the tags beyond the default Key and Value. If your resource does not have user added tags, they will be listed as a violation',
      service: ['ec2', 'ebs'],
      rule_link: '/v3/rules/?rule=check_empty_tags',
      ec2: {
        title: 'Amazon EC2 resource don’t have tags',
        text: 'Missing Tags for EC2 resource',
        text_for_grater: 'Missing Tags for EC2 resources',
        description: 'Consider adding tags for better accountability and visibility to AWS EC2 resource',
        description_for_grater: 'Consider adding tags for better accountability and visibility to AWS EC2 resources',
        path: 'key=check_empty_tags&service=ec2',
        fileName: 'list-of-empty-tags-ec2-bucket',
        column: 'check_empty_tags.ec2',
      },
      ebs: {
        title: 'Amazon EBS resource don’t have any tags',
        text: 'Missing Tags for EBS resource',
        text_for_grater: 'Missing Tags for EBS resources',
        description: 'Consider adding tags for better accountability and visibility to AWS EBS resource',
        description_for_grater: 'Consider adding tags for better accountability and visibility to AWS EBS resources',
        path: 'key=check_empty_tags&service=ebs',
        fileName: 'list-of-empty-tags-ebs-bucket',
        column: 'check_empty_tags.ebs',
      },
    },
    scheduled_events: {
      name: 'Schedule Events details',
      breadcrumbTitle: 'Schedule Events',
      description:
        'EC2 Resources have scheduled maintenance events. Review the resources to avoid service disruptions.',
      service: ['ec2'],
      ec2: {
        title: 'Resources have scheduled maintenance events',
        text: 'EC2 Scheduled maintenance events',
        description: 'Review these resources to avoid service disruptions',
        path: 'key=scheduled_events&service=ec2',
        fileName: 'list-of-scheduled-events-ec2-bucket',
        column: 'scheduled_events.ec2',
      },
    },
    ami_expired: {
      name: 'Out of date AMI',
      breadcrumbTitle: 'Out of date AMI',
      description:
        'Checks for AMIs that are older than six months old. These resources are likely missing important patches and security updates required for reliable operations.',
      service: ['ami'],
      ami: {
        title: 'Out of date AMIs',
        text: 'Out of date AMIs',
        description:
          'AMIs older than six months old are likely missing critical patches and security updates required for reliable operations',
        path: 'key=ami_expired&service=ami',
        fileName: 'list-of-expired-ami-instances',
        column: 'ami_expired.ami',
      },
    },
  },
  Performance: {
    redshift_low_node_utilization: {
      name: 'Redshift cluster nodes with suboptimal utilization',
      breadcrumbTitle: 'Underutilized Redshift Cluster',
      description:
        'You may want to consider downgrading your Redshift node type or decreasing number of nodes to save money.',
      service: ['redshift'],
      redshift: {
        title: 'List of underutilized Redshift Cluster',
        text: 'Underutilized Redshift Cluster Nodes',
        description: 'Consider downgrading Redshift node type or decreasing number of nodes to save money',
        path: 'key=redshift_low_node_utilization&service=redshift',
        fileName: 'list-of-redshift-low-node-utilization',
        column: 'redshift_low_node_utilization.redshift',
      },
    },
  },
};

const WAR_DETAIL = {
  check_resource_encryption: {
    node_key: 'check_resource_encryption',
    title: 'Monitoring resources for encryption at rest',
    link: '/v3/rules/?rule=check_resource_encryption',
    resources: ['ec2', 'rds', 's3'],
    description:
      "<strong>Data at rest encryption</strong> ensures protecting <strong>data</strong> that's not moving through networks. The protection in this case is offered via <strong>encryption</strong>. nOps provides Encryption at Rest status for resources (EBS/RDS/S3).",
  },
  policy_attached_users: {
    node_key: 'policy_attached_users',
    title: 'Policy check for IAM users',
    link: '/v3/rule-details/?key=policy_attached_users&service=iam',
    resources: ['iam'],
    description:
      "It's important to have information about IAM users who have Directly attached policy, Inline Policies & Admin Access Policies within your infrastructure. This helps to protect your infrastructure from unauthorized access. Also you have one centralized documentation for all these users",
  },
  no_mfa_users: {
    node_key: 'no_mfa_users',
    title: 'Implement life cycle, MFA and password policies for all users',
    link: '/v3/rule-details/?key=no_mfa_users&service=iam',
    resources: ['iam'],
    description:
      'The current best practice is for customers to segregate defined roles and responsibilities of system users by creating user groups. User groups can be defined using several different technologies: Identity and Access Management (IAM) groups, IAM roles for cross-account access, Web Identities, via Security Assertion Markup Language (SAML) integration (e.g., defining the roles in Active Directory), or by using a third-party solution (e.g., Okta, Ping Identity, or another custom technique) which usually integrates via either SAML or AWS Security Token Service (STS). Using a shared account is strongly discouraged.',
  },
  public_subnet: {
    node_key: 'public_subnet',
    title: 'Unrestricted Public Access',
    link: '/v3/rules/?rule=public_subnet',
    resources: ['ec2'],
    description:
      'The rule analyzes Virtual Private Cloud(VPC) resources running in a public subnet with 0.0.0.0/[port number] in security groups. This common mistake opens cloud networks to outside threats.',
  },
  s3_bucket_security: {
    node_key: 's3_bucket_security',
    title: 'S3 Security Check',
    link: '/v3/rule-details/?key=s3_bucket_security&service=s3',
    resources: ['s3'],
    description:
      'The rule does below security & best practices checks on S3 buckets. Mismanagement of S3 permissions settings by users is one of most common causes of security breaches and can cause significant data leaks.<br/>A few of the important checks in S3 buckets are:<ul><li>Knowing which public read and public write access settings for S3 Bucket.</li><li>Usage of S3 versioning to store and retrieve any particular data element.</li><li>Encryption of data at server side for S3.</li><li>Enabling logging to track what actions are performed against your S3 buckets.</li></ul>',
  },
  check_aws_config: {
    node_key: 'check_aws_config',
    title: 'AWS Config Check',
    link: '/v3/rule-details/?key=check_aws_config&service=regions',
    resources: ['regions'],
    description:
      'AWS Config provides a detailed view of the configuration of AWS resources in your AWS account. This rule checks whether AWS config is enabled in your AWS account.',
  },
  cloudtrail_log_validation_enabled: {
    node_key: 'cloudtrail_log_validation_enabled',
    title: 'Cloudtrail Check',
    link: '/v3/rule-details/?key=cloudtrail_log_validation_enabled&service=ct_trail',
    resources: ['ct_trail'],
    description: 'Enable CloudTrail log file integrity validation',
  },
  check_root_user_mfa: {
    node_key: 'check_root_user_mfa',
    title: 'Root MFA',
    link: '/v3/rule-details/?key=check_root_user_mfa&service=iam',
    resources: ['iam'],
    description:
      "AWS strongly recommends that you do not use the root user for your everyday tasks, even the administrative ones. Instead, adhere to the <a href='https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html#create-iam-users' target='_blank'>best practice of using the root user only to create your first IAM user</a>. Then securely lock away the root user credentials and use them to perform only a few account and service management tasks.",
  },
  root_account_usage: {
    node_key: 'root_account_usage',
    title: 'Root account usage details',
    link: '/v3/rule-details/?key=root_account_usage&service=iam',
    resources: ['iam'],
    description:
      "This rule scans the workload and provides you with details of the root account usage. It's strongly recommended that you do not use the root user for your everyday tasks, even the administrative ones. Instead, adhere to the best practice of using the root user only to create your first IAM user. Root account should be used to perform only a few account and service management tasks.",
  },
  inactive_keys: {
    node_key: 'inactive_keys',
    title: 'Inactive keys',
    link: '/v3/rule-details/?key=inactive_keys&service=iam',
    resources: ['iam'],
    description:
      "It is highly recommended that you do not use keys for provisioning resources. <!--We advise monitoring inactive keys on a weekly basis by configuring <a href='https://app.nops.io/v2/notifications' target='_blank'>notifications</a> in nOps.io-->",
  },
  classic_vpc: {
    node_key: 'classic_vpc',
    title: 'Resources in Classic AWS VPC',
    link: '/v3/rule-details/?key=classic_vpc&service=ec2',
    resources: ['ec2'],
    description: 'AWS recommends that resources from AWS classic VPC to be moved to AWS VPC. (EC2 Resources)',
  },
  check_guardduty_enabled: {
    node_key: 'check_guardduty_enabled',
    title: 'Amazon Guardduty check',
    link: '/v3/rule-details/?key=check_guardduty_enabled&service=iam',
    resources: ['iam'],
    description: 'Consider Enabling GuardDuty intelligent threat detection and continuous monitoring\n',
  },
  check_waf_used: {
    node_key: 'check_waf_used',
    title: 'AWS web application firewall configuration',
    link: '/v3/rule-details/?key=check_waf_used&service=iam',
    resources: ['iam'],
    description: 'Consider attaching at least a resource to the AWS WAF ACLs',
  },
  check_aws_inspector: {
    node_key: 'check_aws_inspector',
    title: 'Amazon Inspector configuration',
    link: '/v3/rule-details/?key=check_aws_inspector&service=iam',
    resources: ['iam'],
    description: 'Consider configuring Amazon Inspector to improve the security and compliance of your AWS resources',
  },
  unused_roles: {
    node_key: 'unused_roles',
    title: 'Details of infrequently used IAM roles',
    link: '/v3/rule-details/?key=unused_roles&service=iam_role',
    resources: ['iam_role'],
    description: 'Checks if there are IAM roles have not been used in the past 90 days.',
  },
  public_ebs_snapshot: {
    node_key: 'public_ebs_snapshot',
    title: 'Public EBS Snapshot',
    link: '/v3/rule-details/?key=public_ebs_snapshot&service=ebs_snapshot',
    resources: ['ebs_snapshot'],
    description: 'EBS snapshots have public access enabled. Remove public access to better protect the data.',
  },
  root_access_keys: {
    node_key: 'root_access_keys',
    title: 'Root access key',
    link: '/v3/rule-details/?key=root_access_keys&service=iam',
    resources: ['iam'],
    description: 'AWS Accounts have root access key and secret key activated.',
  },
  strong_password_policy: {
    node_key: 'strong_password_policy',
    title: 'AWS account password policy',
    link: '/v3/rule-details/?key=strong_password_policy&service=iam',
    resources: ['iam'],
    description: 'AWS accounts with no strong password policy configuration was detected in all the AWS accounts.',
  },
  cloudtrail_s3_protection: {
    node_key: 'cloudtrail_s3_protection',
    title: 'Cloudtrail s3 protection',
    link: '/v3/rule-details/?key=cloudtrail_s3_protection&service=s3',
    resources: ['s3'],
    description: 'AWS accounts do not have the CloudTrail bucket protected from deletion or overwrite.',
  },
  admin_users: {
    node_key: 'admin_users',
    title: 'Admin Users',
    link: '/v3/rule-details/?key=admin_users&service=iam',
    resources: ['iam'],
    description: 'Checks for users with Administrator Access policy.',
  },
  unencrypted_lambda_variables: {
    node_key: 'unencrypted_lambda_variables',
    title: 'CMK Unencrypted Lambda Variables',
    link: '/v3/rule-details/?key=unencrypted_lambda_variables&service=lambda',
    resources: ['lambda'],
    description: 'Checks if Lambda environment variables are encrypted using CMK.',
  },
  public_lambda: {
    node_key: 'public_lambda',
    title: 'Public Lambda Function',
    link: '/v3/rule-details/?key=public_lambda&service=lambda',
    resources: ['lambda'],
    description: 'Checks if the Lambda access policy allows public invocation.',
  },
  triggerless_lambda: {
    node_key: 'triggerless_lambda',
    title: 'Orphaned Lambda Function',
    link: '/v3/rule-details/?key=triggerless_lambda&service=lambda',
    resources: ['lambda'],
    description: "Checks for Lambda functions that don't have triggers defined.",
  },
  vpc_flow_logging_disabled: {
    node_key: 'vpc_flow_logging_disabled',
    title: 'VPC Flow Logging Disabled',
    link: '/v3/rule-details/?key=vpc_flow_logging_disabled&service=vpc',
    resources: ['vpc'],
    description: 'Checks for VPCs without Flow Logging enabled.',
  },
  ami_public: {
    node_key: 'ami_public',
    title: 'Public AMI',
    link: '/v3/rule-details/?key=ami_public&service=ami',
    resources: ['ami'],
    description: 'Checks for AMIs that are public.',
  },
  rds_public_snapshot: {
    node_key: 'rds_public_snapshot',
    title: 'Public RDS Snapshot',
    link: '/v3/rule-details/?key=rds_public_snapshot&service=rds_snapshot',
    resources: ['rds_snapshot'],
    description: 'Checks for RDS Snapshots that are public.',
  },
  s3_underutilized: {
    node_key: 's3_underutilized',
    title: 'Infrequently accessed S3 buckets',
    link: '/v3/rule-details/?key=s3_underutilized&service=s3',
    resources: ['s3'],
    description: 'Consider lower cost storage options like infrequent access, Glacier.',
  },
  ecs_low_utilization: {
    node_key: 'ecs_low_utilization',
    title: 'ECS cluster with underutilized resources',
    link: '/v3/rule-details/?key=ecs_low_utilization&service=ecs',
    resources: ['ecs'],
    description:
      'Review AWS ECS cluster(s) with EC2 Launch Type has resources with below 10% utilization across cluster.',
  },
  efs_low_utilization: {
    node_key: 'efs_low_utilization',
    title: 'EFS cluster with underutilized resources',
    link: '/v3/rule-details/?key=efs_low_utilization&service=efs',
    resources: ['efs'],
    description:
      'Consider enabling EFS Lifecycle Management on your file system so that files not accessed according to the lifecycle policy you choose will be automatically and transparently moved into EFS IA',
  },
  instance_recommendation_rightsizing: {
    node_key: 'instance_recommendation_rightsizing',
    title: 'EC2 Instance rightsizing recommendation',
    link: '/v3/rule-details/?key=instance_recommendation_rightsizing&service=ec2',
    resources: ['ec2'],
    description:
      'You may want to lower read/write capacity for EC2 instance recommendation rightsizing or consider switching it from Provisioned Mode to On-Demand Mode.',
  },
  instance_recommendation: {
    node_key: 'instance_recommendation',
    title: 'RDS Instance rightsizing recommendation',
    link: '/v3/rule-details/?key=instance_recommendation&service=rds',
    resources: ['rds'],
    description:
      'You may want to lower read/write capacity for RDS instance recommendation or consider switching it from Provisioned Mode to On-Demand Mode.',
  },
  dynamodb_low_utilization: {
    node_key: 'dynamodb_low_utilization',
    title: 'DynamoDB low utilization',
    link: '/v3/rule-details/?key=dynamodb_low_utilization&service=dynamodb',
    resources: ['dynamodb'],
    description:
      'You may want to lower read/write capacity for these tables or consider switching them from Provisioned Mode to On-Demand Mode.',
  },
  log_group_underutilized: {
    node_key: 'log_group_underutilized',
    title: 'RDS Instance rightsizing recommendation',
    link: '/v3/rule-details/?key=log_group_underutilized&service=loggroup',
    resources: ['loggroup'],
    description: 'Consider setting a desirable log retention time for these CloudWatch log groups to save money.',
  },
  iops_performance: {
    node_key: 'iops_performance',
    title: 'IOPS performance Checks',
    link: '/v3/rules/?rule=iops_performance',
    resources: ['ebs', 'rds'],
    description:
      'Provisioned IOPS are a new EBS volume type designed to deliver predictable, high performance for I/O intensive workloads, such as database applications, that rely on consistent and fast response times. Customers can then attach multiple volumes to an Amazon EC2 instance and stripe across them to deliver thousands of IOPS to their application.<br/>Performance check helps to observe utilization and likely cost saving you can achieve by fine tuning it.',
  },
  dynamodb_limit: {
    node_key: 'dynamodb_limit',
    title: 'Dynamodb throughput check',
    link: '/v3/rule-details/?key=dynamodb_limit&service=dynamodb',
    resources: ['dynamodb'],
    description:
      'Centralizes information about major Dynamodb throughput usage across multiple AWS managed services and it alerts.',
  },
  unused_resources: {
    node_key: 'unused_resources',
    title: 'Unused resources in cloud',
    subTitle: 'resource_count resources with likely saving $currency_value',
    link: '/v3/cost/unused/',
    resources: ['ebs'],
    description:
      "With cloud, resources get launched when there is a need. It’s important to turn off inactive or idle resources or those unused resources can continue to add cost to your workload. You can get realtime notifications about <a href='https://app.nops.io/v3/cost/unused/'' target='_blank'>unused</a> resources from nOps.<ul><li>EBS_Unattached - EBS_Stopped - EBS provides persistent block storage volumes for use with Amazon EC2 instances in the AWS Cloud. Without attachment to a running EC2 instance, the EBS cost is more than what it gives back. Consider using EBS Snapshot for backup and storage on S3, and Amazon EFS for low-frequency file access usage.</li><li>EIP_Unused - Elastic IPs are totally free, as long as they are being used by an instance. Amazon will charge you $0.005/hr for each EIP that you reserve and do not use. If it is associated with a stopped instance or an unattached network interface it will cost you.</li><li>EIP_Additional - If you have additional Elastic IP addresses, ensure they are attached to a running instance or else you will incur a per/hr. cost.</li><li>ELB - ELB with no EC2 instance running still costs money. If you don’t have a plan to use these ELBs, just delete them.</li><li>NAT - The nOps uses NAT Gateway metric BytesOutToDestination to list down NAT gateways which are not being actively used for a week. The user can review and terminate NAT gateways which are not required and save the costs</li></ul>",
  },
  underutilized_resources: {
    node_key: 'underutilized_resources',
    title: 'Manage under-utilized resources',
    subTitle: 'resources with likely saving of',
    link: '/v3/cost/underutilized-resources/',
    resources: ['ec2'],
    description:
      "Right-sizing your infrastructure is crucial to ensure that cloud costs are in control. nOps visibility into CPU <a href='https://app.nops.io/v3/rules/underutilized-resources/'' target='_blank'>utilization</a> patterns (EC2, RDS) helps to retire or re-engineer the existing servers.<ul><li>Under-utilized infrastructure (Less than 30%, 20%, 10%)</li><li>Zombie infrastructure</li></ul>",
  },
  ec2_low_network_utilization: {
    node_key: 'ec2_low_network_utilization',
    title: 'EC2 low network traffic details',
    link: '/v3/rule-details/?key=ec2_low_network_utilization&service=ec2',
    resources: ['ec2'],
    description: 'Review or terminate idle instances to save money.',
  },
  rds_instances_idle: {
    node_key: 'rds_instances_idle',
    title: 'RDS instance idle',
    link: '/v3/rule-details/?key=rds_instances_idle&service=rds',
    resources: ['rds'],
    description: 'Checks whether the average connections RDS instance have been 0.',
  },
  unattached_workspace_directories: {
    node_key: 'unattached_workspace_directories',
    title: 'Unattached Workspace Directories',
    link: '/v3/rule-details/?key=unattached_workspace_directories&service=workspace_directory',
    resources: ['workspace_directory'],
    description: 'Checks whether workspace directory has any workspaces attached.',
  },
  ebs_snapshot: {
    node_key: 'ebs_snapshot',
    title: 'EBS volumes without Snapshots',
    link: '/v3/rule-details/?key=ebs_snapshot&service=ebs',
    resources: ['ebs'],
    description:
      'Depending on the type of workload, snapshotting EBS volumes is required. This rule identifies EBS volumes without Snapshots. The user can decide either to enable snapshots or override this rule.',
  },
  dynamodb_continuos_backup: {
    node_key: 'dynamodb_continuos_backup',
    title: 'DynamoDB Backup Check',
    link: '/v3/rule-details/?key=dynamodb_continuos_backup&service=dynamodb',
    resources: ['dynamodb'],
    description:
      'DynamoDB has a Point-in-time recovery feature, which allows restoring the data to point in time. This nOps checks if DynamoDB backup is enabled.',
  },
  rds_backup_policy: {
    node_key: 'rds_backup_policy',
    title: 'RDS resources with default backup policy details',
    link: '/v3/rule-details/?key=rds_backup_policy&service=rds',
    resources: ['rds'],
    description:
      "If you don't set the backup retention period for RDS, the default backup retention period is one day, which is not a best practice. <strong>nOps shows RDS resources with default backup policy where the backup retention period is 1 day</strong>.<br/>The user can set their own backup retention period for such resources.",
  },
  check_ec2_autoscaling: {
    node_key: 'check_ec2_autoscaling',
    title: 'Resources not part of any autoscaling group',
    link: '/v3/rule-details/?key=check_ec2_autoscaling&service=ec2',
    resources: ['ec2'],
    description:
      'These ec2 instances are not part of autoscaling groups. Leverage autoscaling for high availability and lower cost.',
  },
  rds_instances_without_multiaz: {
    node_key: 'rds_instances_without_multiaz',
    title: 'Enable Multi-AZ for RDS',
    link: '/v3/rule-details/?key=rds_instances_without_multiaz&service=rds',
    resources: ['rds'],
    description:
      'RDS instances are enabled for Multi-AZ. It provides high availability to quickly and automatically recover from infrastructure failures.',
  },
  elasticache_disabled_multizone: {
    node_key: 'elasticache_disabled_multizone',
    title: 'Enable Multi-AZ for Elasticache',
    link: '/v3/rule-details/?key=elasticache_disabled_multizone&service=elasticache',
    resources: ['elasticache'],
    description:
      'Elasticache instances are enabled for Multi-AZ. It provides high availability to quickly and automatically recover from infrastructure failures.',
  },
  s3_bucket_versioning_disabled: {
    node_key: 's3_bucket_versioning_disabled',
    title: 'S3 versioning enabled',
    link: '/v3/rule-details/?key=s3_bucket_versioning_disabled&service=s3',
    resources: ['s3'],
    description:
      'S3 bucket versioning allows you to easily recover from unintended user actions and application failures.',
  },
  'ec2-usage': {
    node_key: 'ec2-usage',
    title: 'Workload is resilient to availability zone disruption',
    resources: ['ec2'],
    description:
      "Each AWS region is a separate geographic area. Each region has multiple, isolated locations known as <strong>Availability Zones</strong>. Availability Zones is a high-availability offering that protects your applications and data from datacenter failures. Amazon EC2 provides you the ability to place resources, such as instances, and data in multiple locations. Resources aren't replicated across regions unless you do so specifically.<br/>The solution must continue to operate in the case where all of the services within a single availability zone have been disrupted",
  },
  check_aws_enterprise_support: {
    node_key: 'check_aws_enterprise_support',
    title: 'AWS enterprise support',
    link: '/v3/rule-details/?key=check_aws_enterprise_support&service=iam',
    resources: ['iam'],
    description: 'AWS Enterprise Support is recommended production and/or mission critical workloads.',
  },
  vpc_cidr: {
    node_key: 'vpc_cidr',
    title: 'VPC CIDR',
    link: '/v3/rule-details/?key=vpc_cidr&service=vpc',
    resources: ['vpc'],
    description:
      'VPC resources have overlapping CIDR. Avoid issues in the VPC peering in the future and use non-overlapping IP ranges.',
  },
  check_empty_tags: {
    node_key: 'check_empty_tags',
    title: 'Resources without tags',
    link: '/v3/rules/?rule=check_empty_tags',
    resources: ['ec2', 'ebs'],
    description: 'Amazon resources (EC2 / EBS) don’t have tags. Add tags for better accountability.',
  },
  scheduled_events: {
    node_key: 'scheduled_events',
    title: 'Schedules Events',
    resources: ['ec2'],
    description: 'Resources have scheduled maintenance events. Review the resources to avoid service disruptions.',
  },
  ami_expired: {
    node_key: 'ami_expired',
    title: 'Out of date AMI',
    link: '/v3/rule-details/?key=ami_expired&service=ami',
    resources: ['ami'],
    description:
      'Checks for AMIs that are older than six months old. These resources are likely missing important patches and security updates required for reliable operations.',
  },
  redshift_low_node_utilization: {
    node_key: 'redshift_low_node_utilization',
    title: 'Redshift cluster nodes with suboptimal utilization',
    link: '/v3/rule-details/?key=redshift_low_node_utilization&service=redshift',
    resources: ['redshift'],
    description:
      'You may want to consider downgrading your Redshift node type or decreasing number of nodes to save money.',
  },
};

function rulesBuilder(payload) {
  const RULES_DESCRIPTION_TEXT = {};

  for (const [pillarKey, pillarValue] of Object.entries(payload)) {
    for (const [ruleKey, ruleValue] of Object.entries(pillarValue)) {
      const tempService = ruleValue.service || [];
      let missingColumn = false;
      tempService.forEach(item => {
        const columnPath = ruleValue[item]?.column;
        if (columnPath) {
          ruleValue[item].column = get(COLUMNS_FOR_RULES, columnPath);
        } else {
          missingColumn = true;
          // fix these missing columns on backend
        }
      });
      if (!missingColumn) {
        ruleValue.pillar = pillarKey;
        RULES_DESCRIPTION_TEXT[ruleKey] = ruleValue;
      }
    }
  }
  //just for COST unused page
  RULES_DESCRIPTION_TEXT.unused_resources.all = {};
  if (RULES_DESCRIPTION_TEXT.unused_azure_resources) RULES_DESCRIPTION_TEXT.unused_azure_resources.all = {};
  return RULES_DESCRIPTION_TEXT;
}

export const RULES_DESCRIPTION_TEXT = rulesBuilder(RULE_DETAIL);
export const warDescriptions = WAR_DETAIL;
