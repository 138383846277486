import React from 'react';
import ReactDOM from 'react-dom';
import { EmptySvg } from 'assets/images';

export const CheckboxRenderer = action => params => {
  const data = params.data;
  const element = document.createElement('div');
  const issueElement = (
    <div key={data.id} className={'np-filter'}>
      <div className="np-check">
        <input
          id={`rule-title-${data.id}`}
          type="checkbox"
          checked={data.checked}
          data-did={data.id}
          onChange={action}
        />
        <label id={`rule-label-title-${data.id}`} className="np-check-label" htmlFor={`rule-title-${data.id}`}>
          <EmptySvg />
        </label>
      </div>
    </div>
  );
  ReactDOM.render(issueElement, element);
  return element;
};
