import React from 'react';
import { LinkIcon } from 'assets/images/common';
import { Anchor } from 'components/Custom';

export const getDataOfCMToDisplay = (ruleData, handleRecord) => {
  const { key = '', service = '', count = 0, uniqData = {}, project } = ruleData;
  const { name = '', id = 0, projectId = 0, accessKey = '' } = uniqData;
  const projectLen = project.split(', ').length;
  const lastText = `${projectLen === 1 ? 'account ' : 'accounts '}`;
  let nameLink = (
    <Anchor
      id={name}
      onClick={e => {
        e.stopPropagation();
        handleRecord({ id, projectId });
      }}
    >
      {name}&nbsp;
      <i className="icon-link">
        <LinkIcon />
      </i>
    </Anchor>
  );
  if (key === 'log_group_underutilized') {
    let newName = '';
    if (name) {
      let len = '';
      if (name.includes('/')) {
        len = name.split('/');
      } else {
        len = name.split(':');
      }
      newName = len[len.length - 1];
    }
    nameLink = (
      <Anchor
        id={name}
        onClick={e => {
          e.stopPropagation();
          handleRecord({ id, projectId });
        }}
      >
        {newName}&nbsp;
        <i className="icon-link">
          <LinkIcon />
        </i>
      </Anchor>
    );
  }
  const accessLink = (
    <Anchor
      id={name}
      onClick={e => {
        e.stopPropagation();
        handleRecord({ id, projectId });
      }}
    >
      {accessKey}&nbsp;
      <i className="icon-link">
        <LinkIcon />
      </i>
    </Anchor>
  );
  // console.log('KEY je -----------', key);
  switch (key) {
    case 'check_resource_encryption':
      switch (service) {
        case 'rds':
          if (count === 1) {
            return (
              <p>
                1 new <b>unencrypted Amazon RDS instances</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Amazon RDS instances</b> detected in {lastText} {project}
              </p>
            );
          }
          break;
        case 'ebs':
          if (count === 1) {
            return (
              <p>
                1 new <b>unencrypted Amazon EBS volume</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>unencrypted Amazon EBS volumes</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        case 's3':
          if (count === 1) {
            return (
              <p>
                1 new{' '}
                <b>
                  unencrypted <b>Amazon S3 bucket</b>
                </b>{' '}
                {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>unencrypted Amazon S3 buckets</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'policy_attached_users':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 new <b>IAM user</b> {nameLink} aren't following IAM practices in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} <b>IAM user</b> and {count - 1} other new IAM users aren't following IAM practices in{' '}
              {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'no_mfa_users':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 new IAM user {nameLink} <b>without MFA</b> detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new IAM users <b>without MFA</b> detected in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'underutilized_resources':
      if (service === 'ec2') {
        if (count === 1) {
          return (
            <p>
              1 new <b>underutilized resource</b> {nameLink} detected which is running below 30% on average from last
              week in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>underutilized resources</b> detected which are running below 30% on
              average from last week in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 's3_underutilized':
      if (service === 's3') {
        if (count === 1) {
          return (
            <p>
              1 new <b>infrequently accessed S3</b> bucket {nameLink}
              detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>infrequently accessed S3 bucket</b> detected in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'efs_low_utilization':
      if (service === 'efs') {
        if (count === 1) {
          return (
            <p>
              1 new <b>infrequently accessed </b> EFS resource {nameLink}
              detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>infrequently accessed </b>
              EFS resource detected in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'instance_recommendation_rightsizing':
      if (service === 'ec2') {
        if (count === 1) {
          return (
            <p>
              1 new non-optimally sized <b>EC2 instance</b> {nameLink} is detected in {lastText} {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other non-optimally sized <b>EC2 instances</b> are detected in {lastText}{' '}
              {project}
            </p>
          );
        }
      }
      break;
    case 'instance_recommendation':
      if (service === 'rds') {
        if (count === 1) {
          return (
            <p>
              1 new non-optimally sized <b>RDS instance</b> is detected in {lastText} {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other non-optimally sized <b>RDS instances</b> detected in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'ecs_low_utilization':
      if (service === 'ecs') {
        if (count === 1) {
          return (
            <p>
              1 new <b>underutilized ECS cluster</b> {nameLink} with EC2 Launch Type detected in accounts {project} with
              below 10% utilization on average for the last two weeks.
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>underutilized ECS cluster</b> with EC2 Launch Type detected in{' '}
              {lastText} {project} with below 10% utilization on average for the last two weeks.
            </p>
          );
        }
      }
      break;
    case 'dynamodb_low_utilization':
      if (service === 'dynamodb') {
        if (count === 1) {
          return (
            <p>
              1 <b>DynamoDB table</b> {nameLink} with below 30% read/write utilization detected in {lastText} {project}.
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>DynamoDB table</b> with below 30% read/write utilization detected in{' '}
              {lastText} {project}.
            </p>
          );
        }
      }
      break;
    case 'log_group_underutilized':
      if (service === 'loggroup') {
        if (count === 1) {
          return (
            <p>
              1 new {nameLink} <b>AWS CloudWatch Log Group</b> without I/O traffic detected.
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} <b>AWS CloudWatch Log Group</b> without I/O traffic detected.
            </p>
          );
        }
      }
      break;
    case 'public_subnet':
      if (service === 'rds') {
        if (count === 1) {
          return (
            <p>
              1 new <b>public subnet</b> {nameLink} with open ports to Amazon RDS detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>public subnets</b> with open ports to Amazon RDS detected in{' '}
              {lastText} {project}
            </p>
          );
        }
      } else if (service === 'ec2') {
        if (count === 1) {
          return (
            <p>
              1 new <b>EC2 instance</b> {nameLink} detected with unrestricted public access in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>EC2 instances</b> detected with unrestricted public access in{' '}
              {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 's3_bucket_security':
      if (service === 's3') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Amazon S3 bucket</b> {nameLink} with public read / write access detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>Amazon S3 buckets</b> detected in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'unencrypted_lambda_variables':
      if (service === 'lambda') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Lambda variable</b> {nameLink} detected without <b>encryption</b>
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} <b>Lambda variable</b> detected without
              <b> encryption</b>
            </p>
          );
        }
      }
      break;
    case 'public_lambda':
      if (service === 'lambda') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Lambda function</b> {nameLink} detected with <b>public access policy</b>
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} <b>Lambda function</b> detected with
              <b>public access policy</b>
            </p>
          );
        }
      }
      break;
    case 'triggerless_lambda':
      if (service === 'lambda') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Lambda function</b> {nameLink} detected without <b>trigger</b> defined
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} new <b>Lambda function</b> detected without <b>trigger</b> defined
            </p>
          );
        }
      }
      break;
    case 'check_aws_config':
      if (service === 'region') {
        if (count === 1) {
          return (
            <p>
              1 new <b>AWS Region</b> {nameLink} <b>without AWS Config</b> enabled detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>AWS Regions without AWS Config</b> enabled detected in {lastText}{' '}
              {project}
            </p>
          );
        }
      }
      break;
    case 'cloudtrail_log_validation_enabled':
      if (service === 'ct_trail') {
        if (count === 1) {
          return (
            <p>
              1 new {nameLink} AWS account does not have <b>CloudTrail</b> file integrity check configured on it
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} or {count - 1} other new AWS {lastText} have <b>CloudTrail</b> file integrity check activated
            </p>
          );
        }
      }
      break;
    case 'check_root_user_mfa':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 new <b>AWS login</b> detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              New root login detected in {project} and {count - 1} other <b>AWS {lastText}</b>
            </p>
          );
        }
      }
      break;
    case 'admin_users':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 new AWS account {nameLink} detected <b>with Administrator policies</b> in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new AWS accounts have been detected <b>with Administrator policies</b> in{' '}
              {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'root_account_usage':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 new AWS account {nameLink} detected <b>without root account MFA</b> in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new AWS accounts <b>without MFA</b> detected which{' '}
              <b>don’t have root account MFA</b> in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'inactive_keys':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Inactive access key</b> {accessLink} of user {name} detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {accessLink} and {count - 1} other new <b>Inactive access keys</b> detected in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'classic_vpc':
      if (service === 'ec2') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Amazon EC2 instance</b> {nameLink} in EC2-Classic VPCs detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>Amazon EC2 instances</b> in EC2-Classic VPCs detected in{' '}
              {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'check_aws_enterprise_support':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 new AWS account {nameLink} detected without the
              <b>AWS business support</b>
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new AWS accounts detected without the <b>AWS business support</b>
            </p>
          );
        }
      }
      break;
    case 'check_guardduty_enabled':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 New account {nameLink} detected with/out <b>GuardDuty </b>
              disabled/enabled
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other account detected with/out <b>GuardDuty </b>enabled/disabled
            </p>
          );
        }
      }
      break;
    case 'check_waf_used':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 {nameLink} <b>AWS Web Application Firewall</b> detected that is not attached to any Cloudfront
              Distribution, Load Balancer or API Gateway
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>AWS Web Application Firewall</b> Service detected that is not attached
              to any Cloudfront Distribution, Load Balancer or API Gateway
            </p>
          );
        }
      }
      break;
    case 'check_aws_inspector':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 new {nameLink} account has <b>Amazon Inspector</b> disabled/enabled
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other account has <b>Amazon Inspector</b> disabled/enabled
            </p>
          );
        }
      }
      break;
    case 'vpc_flow_logging_disabled':
      if (service === 'vpc') {
        if (count === 1) {
          return (
            <p>
              1 new {nameLink} <b>VPCs without Flow Logging enabled</b>
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} new <b>VPCs without Flow Logging enabled</b>
            </p>
          );
        }
      }
      break;
    case 'ami_public':
      if (service === 'ami') {
        if (count === 1) {
          return (
            <p>
              1 new {nameLink} new <b>Public AMI</b> discovered
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} new <b>AMIs</b> that are public
            </p>
          );
        }
      }
      break;
    case 'rds_public_snapshots':
      if (service === 'rds') {
        if (count === 1) {
          return (
            <p>
              1 new {nameLink} new <b>Public RDS snapshot</b>
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} new <b>RDS snapshots</b> that are public
            </p>
          );
        }
      }
      break;
    case 'iops_performance':
      if (service === 'ebs') {
        if (count === 1) {
          return (
            <p>
              1 new <b>unattached Amazon EBS Provisioned IOPS volume</b> {nameLink} detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>unattached Amazon EBS Provisioned IOPS volumes</b> detected in{' '}
              {lastText} {project}
            </p>
          );
        }
      }
      if (service === 'rds') {
        if (count === 1) {
          return (
            <p>
              1 new <b>underutilized Amazon RDS Provisioned IOPS volume</b> {nameLink} detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>underutilized Amazon RDS Provisioned IOPS volumes</b> detected in{' '}
              {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'dynamodb_limit':
      if (service === 'dynamodb') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Amazon DynamoDB table</b> {nameLink} detected with auto scaling enabled in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>Amazon DynamoDB tables</b> detected with auto scaling enabled
              detected in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'unused_resources':
      switch (service) {
        case 'ebs':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unused EBS resource</b> {nameLink}
                detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unused EBS resource</b> detected in {lastText} {project}
              </p>
            );
          }
          break;
        case 'elb':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unused AWS ELB resources</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unused AWS ELB resource</b> detected in {lastText} {project}
              </p>
            );
          }
          break;
        case 'nat':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unused NAT resource</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unused NAT resource</b> detected in {lastText} {project}
              </p>
            );
          }
          break;
        case 'eip':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unused EIP resource</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unused EIP resource</b> detected in {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'unused_roles':
      if (count === 1) {
        return (
          <p>
            1 new <b>Unused IAM Roles</b> {nameLink}
            detected in account {project}
          </p>
        );
      } else if (count > 1) {
        return (
          <p>
            {nameLink} and {count - 1} other new <b>Unused IAM Roles</b> detected in {lastText} {project}
          </p>
        );
      }
      break;
    case 'ec2_low_network_utilization':
      if (count === 1) {
        return (
          <p>
            1 new <b>EC2 Low Network Traffic</b> {nameLink}
            detected in account {project}
          </p>
        );
      } else if (count > 1) {
        return (
          <p>
            {nameLink} and {count - 1} other new <b>EC2 Low Network Traffic</b> detected in {lastText} {project}
          </p>
        );
      }
      break;
    case 'ebs_snapshot':
      if (service === 'ebs') {
        if (count === 1) {
          return (
            <p>
              1 new <b>EBS snapshot</b> {nameLink} with public access detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>EBS snapshot</b> detected which are not using backup snapshots
              detected in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'dynamodb_continuos_backup':
      if (service === 'dynamodb') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Amazon DynamoDB tables</b> {nameLink} detected without point-in-time recovery enabled in account{' '}
              {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>Amazon DynamoDB tables</b> detected without point-in-time recovery
              enabled in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'rds_backup_policy':
      if (service === 'rds') {
        if (count === 1) {
          return (
            <p>
              1 new <b>DB instance</b> {nameLink} detected in account {project} with{' '}
              <b>Amazon RDS default backup retention policy enabled</b>
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>DB instances</b> detected in {lastText} {project} with{' '}
              <b>Amazon RDS default backup retention policy enabled</b>
            </p>
          );
        }
      }
      break;
    case 'rds_instances_without_multiaz':
      if (service === 'rds') {
        if (count === 1) {
          return (
            <p>
              1 new <b>single-AZ RDS</b> instance {nameLink} detected in account
              {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>single-AZ RDS </b>
              instances detected in accounts {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'vpc_cidr':
      if (service === 'vpc') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Overlapping VPC CIDR</b> {nameLink} detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>Overlapping VPC CIDR</b> detected in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'check_empty_tags':
      if (service === 'ec2') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Amazon EC2 resource</b> {nameLink} detected without any tags in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>Amazon EC2 resources</b> detected in {lastText} {project} without{' '}
              <b>any tags</b>
            </p>
          );
        }
      } else if (service === 'ebs') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Amazon EBS resource</b> {nameLink} detected without any tags in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>Amazon EBS resources</b> detected in {lastText} {project} without{' '}
              <b>any tags</b>
            </p>
          );
        }
      }
      break;
    case 'check_ec2_autoscaling':
      if (service === 'ec2') {
        if (count === 1) {
          return (
            <p>
              1 new resource {nameLink} detected which is <b>not part of autoscaling groups</b> in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other resources detected which are <b>not part of autoscaling groups</b> in
              account {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'scheduled_events':
      if (service === 'ec2') {
        if (count === 1) {
          return (
            <p>
              1 new resource {nameLink} with <b>scheduled maintenance event</b> detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>scheduled maintenance events</b> detected in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'public_ebs_snapshot':
      if (service === 'ebs_snapshot') {
        if (count === 1) {
          return (
            <p>
              1 new <b>EBS snapshot</b> {nameLink} with public access detected in account {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>EBS snapshots</b> with public access detected in {lastText}{' '}
              {project}
            </p>
          );
        }
      }
      break;
    case 'redshift_low_node_utilization':
      if (service === 'redshift') {
        if (count === 1) {
          return (
            <p>
              1 new <b>AWS Redshift cluster</b> {nameLink} with suboptimal node utilization detected in account{' '}
              {project}
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other new <b>AWS Redshift cluster </b>
              with suboptimal node utilization detected in {lastText} {project}
            </p>
          );
        }
      }
      break;
    case 'root_access_keys':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 new {nameLink} account among your AWS account have <b>root access and secret key</b> activated
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other account among your AWS account have <b>root access and secret key</b>{' '}
              activated
            </p>
          );
        }
      }
      break;
    case 'strong_password_policy':
      if (service === 'iam') {
        if (count === 1) {
          return (
            <p>
              1 new {nameLink} <b>Strong Password Policy</b> is not detected in the AWS account(s)
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>Strong Password Policy</b> is not detected in the AWS account
            </p>
          );
        }
      }
      break;
    case 'cloudtrail_s3_protection':
      if (service === 's3') {
        if (count === 1) {
          return (
            <p>
              1 new {nameLink} S3 Bucket storing <b>CloudTrail logs</b> does not have any configuration protecting it
              against deletion/overwrite
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other S3 Buckets storing CloudTrail Logs has configuration activated against
              the bucket deletion/overwrite
            </p>
          );
        }
      }
      break;
    case 'rds_instances_idle':
      if (service === 'rds') {
        if (count === 1) {
          return (
            <p>
              1 new <b>RDS</b> {nameLink} detected with zero average connections
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} <b>RDS</b> have been idle
            </p>
          );
        }
      }
      break;
    case 'unattached_workspace_directories':
      if (service === 'workspace_directory') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Workspace Directory</b> {nameLink} with no workspaces attached
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} <b>Workspace Directories</b> detected with no workspaces attached
            </p>
          );
        }
      }
      break;
    case 'ami_expired':
      if (service === 'ami') {
        if (count === 1) {
          return (
            <p>
              1 <b>AMI</b> {nameLink} has fallen out of acceptable date range for updates
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} other <b>AMIs</b> out of acceptable date range for updates
            </p>
          );
        }
      }
      break;
    case 'elasticache_disabled_multizone':
      if (service === 'elasticache') {
        if (count === 1) {
          return (
            <p>
              1 new <b>Elasticache instance</b> {nameLink} created without <b>multi-AZ enabled</b>
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} <b>Elasticache instances</b> are not enabled for Multi-AZ
            </p>
          );
        }
      }
      break;
    case 's3_bucket_versioning_disabled':
      if (service === 's3') {
        if (count === 1) {
          return (
            <p>
              1 new <b>S3 bucket</b> {nameLink} created without <b>versioning enabled</b>
            </p>
          );
        } else if (count > 1) {
          return (
            <p>
              {nameLink} and {count - 1} <b>S3 buckets</b> do not have versioning enabled
            </p>
          );
        }
      }
      break;
    case 'azure_resource_encryption':
      switch (service) {
        case 'disk_storage':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unencrypted Azure Disk</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unencrypted Azure Disk</b> detected in {lastText} {project}
              </p>
            );
          }
          break;
        case 'storage_account':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unencrypted Azure Storage Account</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unencrypted Azure Storage Account</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'azure_storage_account_security':
      switch (service) {
        case 'storage_account':
          if (count === 1) {
            return (
              <p>
                1 new <b>Insecure Azure storage account</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Insecure Azure storage accounts</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'azure_strong_password_policy':
      switch (service) {
        case 'rbac_users':
          if (count === 1) {
            return (
              <p>
                1 new <b>User with weak Password Policy</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Users with weak Password Policy</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'azure_public_subnet':
      switch (service) {
        case 'sql_database':
          if (count === 1) {
            return (
              <p>
                1 new {nameLink} <b>Azure SQL Database with public subnets and open ports</b> is detected in the Azure
                account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure SQL Databases with public subnets and open ports</b>{' '}
                detected in {lastText} {project}
              </p>
            );
          }
          break;
        case 'virtual_machine':
          if (count === 1) {
            return (
              <p>
                1 new {nameLink} <b>Azure Virtual Machine with public subnets and open ports</b> is detected in the
                Azure account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure Virtual Machines with public subnets and open ports</b>{' '}
                detected in {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'file_service_underutilized':
      switch (service) {
        case 'file_storage_services':
          if (count === 1) {
            return (
              <p>
                1 new <b>Infrequently Accessed Azure Storage</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Infrequently Accessed Azure Storages</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'storage_account_underutilized':
      switch (service) {
        case 'storage_account':
          if (count === 1) {
            return (
              <p>
                1 new <b>Infrequently accessed Azure Storage Account bucket</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Infrequently accessed Azure Storage Account buckets</b> detected
                in {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'unused_azure_resources':
      switch (service) {
        case 'disk_storage':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unused Azure Disk</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unused Disk</b> detected in {lastText} {project}
              </p>
            );
          }
          break;
        case 'network_interfaces':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unused Azure Network interface</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unused Azure Network interfaces</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        case 'public_ip_address':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unused Public IP Address</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unused Public IP Addresses</b> detected in {lastText} {project}
              </p>
            );
          }
          break;
        case 'virtual_machine':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unused Azure Virtual Machine</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unused Azure Virtual Machines</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        case 'virtual_network_nat':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unused Azure Virtual Network </b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unused Azure Virtual Networks</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'underutilized_azure_resources':
      switch (service) {
        case 'virtual_machine':
          if (count === 1) {
            return (
              <p>
                1 new <b>Underutilized Azure Virtual Machine</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Underutilized Azure Virtual Machine</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'azure_instance_recommendation':
      switch (service) {
        case 'servers':
          if (count === 1) {
            return (
              <p>
                1 new <b>Azure Server size recommendation</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure Servers recommendations</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        case 'virtual_machine':
          if (count === 1) {
            return (
              <p>
                1 new <b>Azure Virtual Machine size reommendation</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure Virtual Machine size recommendations</b> detected in{' '}
                {lastText} {project}
              </p>
            );
          }
          break;
        case 'maria_db':
          if (count === 1) {
            return (
              <p>
                1 new <b>Azure MariaDB size reommendation</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure MariaDB size recommendations</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        case 'postgresql_db':
          if (count === 1) {
            return (
              <p>
                1 new <b>Azure PostgreSQL size reommendation</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure PostgreSQL size recommendations</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        case 'mysql_db':
          if (count === 1) {
            return (
              <p>
                1 new <b>Azure MySQL size reommendation</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure MySQL size recommendations</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'vm_low_network_utilization':
      switch (service) {
        case 'virtual_machine':
          if (count === 1) {
            return (
              <p>
                1 new <b>Azure Virtual Machine with Low Network Traffic</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure Virtual Machines with Low Network Traffic</b> detected in{' '}
                {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'azure_iops_performance':
      switch (service) {
        case 'disk_storage':
          if (count === 1) {
            return (
              <p>
                1 new <b>Unattached Azure Disk Provisioned IOPS Storage</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Unattached Azure Disks Provisioned IOPS Storage</b> detected in{' '}
                {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'cosmosdb_limit':
      switch (service) {
        case 'cosmos_db':
          if (count === 1) {
            return (
              <p>
                1 new <b>CosmosDB with disaled Autoscaling for Container or Databases</b> {nameLink} detected in account{' '}
                {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new{' '}
                <b>CosmosDBs with disaled Autoscaling for Containers or Databases</b> detected in {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'cosmosdb_low_utilization':
      switch (service) {
        case 'cosmos_db':
          if (count === 1) {
            return (
              <p>
                1 new <b>Underutilized CosmosDB container with read/write below 30%</b> {nameLink} detected in account{' '}
                {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Underutilized CosmosDB containers with read/write below 30%</b>{' '}
                detected in {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'disks_snapshots':
      switch (service) {
        case 'disk_storage':
          if (count === 1) {
            return (
              <p>
                1 new <b>Azure Disk storage without snapshot</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure Disk storages without snapshot</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'azure_db_enable_multiaz':
      switch (service) {
        case 'sql_database':
          if (count === 1) {
            return (
              <p>
                1 new <b>Azure SQL Database with disabled Multi-AZ</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure SQL Databases with disabled Multi-AZ</b> detected in{' '}
                {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'azure_vm_autoscaling':
      switch (service) {
        case 'virtual_machine':
          if (count === 1) {
            return (
              <p>
                1 new <b>Azure Virtual Machine without autoscaling</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure Virtual Machines without autoscaling</b> detected in{' '}
                {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'container_instance_underutilized':
      switch (service) {
        case 'container_instance':
          if (count === 1) {
            return (
              <p>
                1 new <b>Underutilized Azure Container Instance with utilization below 10%</b> {nameLink} detected in
                account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new{' '}
                <b>Underutilized Azure Container Instances with utilization below 10%</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'check_azure_security_center':
      switch (service) {
        case 'rbac_users':
          if (count === 1) {
            return <p>1 new Azure Tenant resource with non configured Azure Security Center.</p>;
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new Azure Tenant resources with non configured Azure Security Center.
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'azure_no_mfa_users':
      switch (service) {
        case 'rbac_users':
          if (count === 1) {
            return (
              <p>
                1 new <b>User without MFA</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Users without MFA</b> detected in {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'check_azure_policy':
      switch (service) {
        case 'rbac_users':
          if (count === 1) {
            return (
              <p>
                1 new <b>Azure Tenant has weak password policy</b> detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Azure Tenants has weak password policy</b> detected in{' '}
                {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'azure_empty_tags':
      switch (service) {
        case 'virtual_machine':
          if (count === 1) {
            return (
              <p>
                1 new <b>Virtual Machine without tags</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Virtual Machines without tags</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'azure_scheduled_events':
      switch (service) {
        case 'virtual_machine':
          if (count === 1) {
            return (
              <p>
                1 new <b>Virtual Machine without scheduled maintenance event</b> {nameLink} detected in account{' '}
                {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Virtual Machine without scheduled maintenance event</b> detected
                in {lastText} {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    case 'azure_sql_pool_low_utilization':
      switch (service) {
        case 'synapse_sql_pool':
          if (count === 1) {
            return (
              <p>
                1 new <b>Underutilized Azure SQL Pool</b> {nameLink} detected in account {project}
              </p>
            );
          } else if (count > 1) {
            return (
              <p>
                {nameLink} and {count - 1} other new <b>Underutilized Azure SQL Pools</b> detected in {lastText}{' '}
                {project}
              </p>
            );
          }
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
};
