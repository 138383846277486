import React, { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import Tippy from '@tippyjs/react';
import className from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { BackArrowIcon, NextArrowIcon } from 'assets/images/common';
import { usePrevious } from 'utils/hooks';
import injectReducer from 'utils/injectReducer';
import { Anchor } from 'components/Custom';
import reducer, {
  loadTagValue,
  setPropsFilter,
  setSelectedKeyValue,
  setSelectedTag,
  setSelectedTagCloudPlatform,
  setShowKeyPopUp,
  setShowValuePopUp,
  setInitialState,
} from './reducer';
import {
  makeSelectTagResourcesValue,
  makeSelectPropsFilter,
  makeSelectSelectedKeyValue,
  makeSelectShowKeyPopUp,
  makeSelectShowValuePopUp,
} from './selectors';
import { getTagDataToApplyFilter, setInitialData } from './helper';
import { DefaultSelectedFilter, RenderSelectedTag } from './renderElements';
import { RenderKeyPopup, RenderValuePopup } from './renderKeyValueElement';

export const tag_key = 'nested:tags_kv:tags_kv.key';
export const tag_value = 'nested:tags_kv:tags_kv.value';

const TagFilters = ({ ...props }) => {
  const dispatch = useDispatch();
  const {
    tagResources = [], // only AWS tags from old aggregations
    tagResourcesCloud = [], // both Azure and AWS tags
    tagData = {},
    applyFilter,
    configCatAzure = false,
    disableAWSFilters = false,
    disableAzureFilters = false,
  } = props;

  const tagResourcesData = !props.configCatAzure ? tagResources : props.hideAzure ? tagResources : tagResourcesCloud;

  const showKeyPopUp = useSelector(makeSelectShowKeyPopUp);
  const showValuePopUp = useSelector(makeSelectShowValuePopUp);
  const selectedKeyValue = useSelector(makeSelectSelectedKeyValue);
  const propsFilter = useSelector(makeSelectPropsFilter);
  const tagResourcesValue = useSelector(makeSelectTagResourcesValue);

  const prevTagData = usePrevious(tagData) || {};
  useEffect(() => {
    setInitialData(tagData, dispatch, setSelectedKeyValue, setPropsFilter);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEmpty(tagData) || (isEmpty(tagData?.must) && isEmpty(tagData?.must_not))) {
      dispatch(setInitialState());
    } else if (!isEqual(tagData, prevTagData)) {
      setInitialData(tagData, dispatch, setSelectedKeyValue, setPropsFilter);
    }
  }, [tagData]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTypeChange = (key, isView) => e => {
    const selectedKeyValue_clone = [...selectedKeyValue];
    const rec = selectedKeyValue_clone.findIndex(tag => tag.key === key);
    selectedKeyValue_clone[rec] = {
      ...selectedKeyValue_clone[rec],
      type: e.target.value,
    };
    dispatch(setSelectedKeyValue(selectedKeyValue_clone));
    if (isView) applyTagFilter(selectedKeyValue_clone);
  };

  const closeKeyPopUp = () => {
    if (propsFilter?.length) {
      dispatch(setShowKeyPopUp(false));
      dispatch(setSelectedKeyValue([...propsFilter]));
    } else {
      dispatch(setInitialState());
    }
  };

  const closeValuePopUp = () => {
    dispatch(setShowKeyPopUp(true));
    dispatch(setShowValuePopUp(false));
  };

  const openKeyPopUp = () => {
    if (!tagResourcesData?.length) return null;
    dispatch(setShowKeyPopUp(!showKeyPopUp));
    dispatch(setShowValuePopUp(false));
    dispatch(setSelectedTag(''));
  };

  const openValuePopUp = (key, cloudPlatform) => () => {
    const selectedKeyValue_clone = [...selectedKeyValue];
    const recIndex = selectedKeyValue_clone.findIndex(tag => tag.key === key && tag.cloudPlatform === cloudPlatform);
    const values = tagResourcesValue.find(tag => tag.key === key && tag.cloudPlatform === cloudPlatform);
    if (recIndex === -1) {
      selectedKeyValue_clone.push({
        key,
        type: 'Include',
        value: [],
        cloudPlatform,
      });
    }
    dispatch(setSelectedTagCloudPlatform(cloudPlatform));
    dispatch(setSelectedTag(key));
    dispatch(setSelectedKeyValue(selectedKeyValue_clone));
    dispatch(setShowValuePopUp(!showValuePopUp));
    dispatch(setShowKeyPopUp(false));

    if (values?.data?.length) return;
    dispatch(loadTagValue({ key, cloudPlatform, configCatAzure }));
  };

  const removeValue = (key, value, view) => () => {
    const selectedKeyValue_clone = [...selectedKeyValue];
    const recIndex = selectedKeyValue_clone.findIndex(tag => tag.key === key);
    if (recIndex !== -1) {
      const values = selectedKeyValue_clone[recIndex];
      const valueIndex = values.value.findIndex(valueDetail => valueDetail === value);
      const newValue = [...values.value];
      if (valueIndex !== -1) newValue.splice(valueIndex, 1);
      selectedKeyValue_clone[recIndex] = { ...values, value: newValue };
    }
    dispatch(setSelectedKeyValue(selectedKeyValue_clone));
    if (view) applyTagFilter(selectedKeyValue_clone);
  };

  const removeKeyValue = (key, isView) => () => {
    const selectedKeyValue_clone = [...selectedKeyValue];
    const recIndex = selectedKeyValue_clone.findIndex(tag => tag.key === key);
    if (recIndex !== -1) selectedKeyValue_clone.splice(recIndex, 1);
    dispatch(setSelectedKeyValue(selectedKeyValue_clone));
    if (isView) applyTagFilter(selectedKeyValue_clone);
  };

  const data = selectedKeyValue.filter(tag => tag.value?.length);

  const applyTagFilter = selectedKeyValue_copy => {
    const values = tagResourcesData.find(
      tag =>
        tag.key === data[0]?.key &&
        (!configCatAzure || (configCatAzure && tag.cloudPlatform === data[0]?.cloudPlatform)),
    );
    const detail = getTagDataToApplyFilter(data, selectedKeyValue_copy);
    dispatch(setInitialState());
    applyFilter(detail, values?.cloudPlatform);
  };

  return (
    <div className="np-filtersBlock np-filtersTags np-collapse">
      <div className="np-filtersHead">
        <Tippy content="Click to see tags" placement="left">
          <Anchor id="title" className="title" onClick={openKeyPopUp}>
            Tags
            {!tagResourcesData.length ? null : (
              <span className="np-svgIcon">
                <NextArrowIcon />
              </span>
            )}
          </Anchor>
        </Tippy>
        {!tagResourcesData.length ? null : (
          <DefaultSelectedFilter
            {...props}
            onTypeChange={onTypeChange}
            removeValue={removeValue}
            removeKeyValue={removeKeyValue}
          />
        )}
      </div>
      {tagResourcesData.length ? null : <p>None</p>}

      {showKeyPopUp || showValuePopUp ? (
        <div className="tagsModal">
          <div className="tagsModalWrap tagsModalKeys">
            <div className="tagTitle">
              <div className="np-row">
                <div className="npxs-8">
                  <div className={showValuePopUp ? 'tagKeyTitle' : ''}>
                    {showValuePopUp ? (
                      <Anchor id="openKeyPopUp" className="np-button light" onClick={openKeyPopUp}>
                        <i className="np-tableIcon grey">
                          <BackArrowIcon />
                        </i>
                      </Anchor>
                    ) : null}
                    <b>Tags</b>
                    {showValuePopUp ? (
                      <Anchor id="openKeyPopUp" className="np-button light" onClick={openKeyPopUp}>
                        <i className="np-tableIcon grey">
                          <BackArrowIcon />
                        </i>
                      </Anchor>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <RenderSelectedTag
              data={data}
              onTypeChange={onTypeChange}
              removeValue={removeValue}
              removeKeyValue={removeKeyValue}
            />

            {showKeyPopUp ? (
              <RenderKeyPopup
                tagResources={tagResourcesData}
                openValuePopUp={openValuePopUp}
                configCatAzure={configCatAzure}
                disableAzureFilters={disableAzureFilters}
                disableAWSFilters={disableAWSFilters}
              />
            ) : (
              <RenderValuePopup configCatAzure={configCatAzure} />
            )}
            <div className="tagFoot">
              <Anchor
                className={className('np-button blue light', {
                  disabled: !data.length,
                })}
                onClick={() => applyTagFilter(selectedKeyValue)}
              >
                Apply
              </Anchor>
              <Anchor
                className="np-button light"
                onClick={() => {
                  showValuePopUp ? closeValuePopUp() : closeKeyPopUp();
                }}
              >
                Cancel
              </Anchor>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const withReducer = injectReducer({ key: 'tags_filter', reducer });

export default compose(withReducer)(TagFilters);
