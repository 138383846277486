import React, { useState, useEffect } from 'react';
import { CloudAccountBannerIcon } from 'assets/images';
import { useHistory, useLocation } from 'react-router-dom';
import { SHARE_SAVE_ROUTE } from 'components/ConfigureRiskFreeBanner/constant';

const CloudAccountBanner = ({ onCompleteSetup, sharesave_allowed, has_marketplace_subscription_or_contract }) => {
  const [showShareSaveBtn, setShowShareSaveBtn] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setShowShareSaveBtn(location.pathname !== SHARE_SAVE_ROUTE);
  }, [location.pathname]);

  const onCheckShareSave = () => {
    history.push({ pathname: SHARE_SAVE_ROUTE });
  };

  return (
    <section className="np-cloud-account-banner">
      <div className="content">
        <CloudAccountBannerIcon />
        <div className="content-info">
          <h4 className="m0 font-20 font-bold">Your cloud account setup is pending</h4>
          <p className="color-grey m0 mT5">
            {`To see the data and start using the application you need to complete
            the account setup${
              !has_marketplace_subscription_or_contract ? ' and subscribe to nOps on AWS Marketplace.' : '.'
            }`}
          </p>
        </div>
      </div>
      <aside>
        <button className="np-button blue" onClick={onCompleteSetup}>
          Complete Setup
        </button>
        {sharesave_allowed && showShareSaveBtn ? (
          <button className="np-button blue-outline light d-none" onClick={onCheckShareSave}>
            Check ShareSave
          </button>
        ) : null}
      </aside>
    </section>
  );
};

export default CloudAccountBanner;
