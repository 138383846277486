import moment from 'moment';

const DEFAULT_AUTOSCALING_SCHEDULE_DETAILS = {
  schedule_name: '',
  project: '',
  account_number: '',
  autoscaling_group: '',
  target: '',
  desired_nodes: 0,
  day: moment().utc(),
  time: '08:00',
};

const DEFAULT_SCHEDULE_DETAILS = {
  schedule_name: '',
  project: '',
  account_number: '',
  resources: {
    ec2: [],
    rds: [],
    rds_cluster: [],
    eks_nodegroup: [],
    autoscaling_groups: [],
  },
  target: '',
  days: [],
  start: '08:00',
  end: '20:00',
  hibernate: false,
  scheduled_by_tag_key: 'nOps-Essentials-Scheduler',
  scheduled_by_tag_value: '',
};

const DEFAULT_RECOMMENDED_SCHEDULE = {
  schedule_name: '',
  project: '',
  account_number: '',
  // target: '',
  resources: { ec2: [], rds: [], rds_cluster: [] },
  positions: [],
  scheduled_by_tag_key: 'nOps-Essentials-Scheduler',
  scheduled_by_tag_value: '',
};

const DEFAULT_RECOMMENDED_STORAGE_SCHEDULE = {
  schedule_name: '',
  project: '',
  account_number: '',
  type: 'eventbridge',
  schedule: 'now',
  day: moment().local().add(10, 'minutes'),
  time: moment().local().add(10, 'minutes').format('H:mm'),
  resources: { ec2: [], rds: [], rds_cluster: [] },
};

const WEEK_NUMBER = {
  0: 'mon',
  1: 'tue',
  2: 'wed',
  3: 'thu',
  4: 'fri',
  5: 'sat',
  6: 'sun',
};

const WEEK_LABEL_LONG = {
  Monday: 'mon',
  Tuesday: 'tue',
  Wednesday: 'wed',
  Thursday: 'thu',
  Friday: 'fri',
  Saturday: 'sat',
  Sunday: 'sun',
};

const WEEK_LABEL = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};

const WEEK_LABEL_SHORT = {
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  sun: 'Sun',
};

const WEEK_DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map(value => ({
  value,
  label: WEEK_LABEL[value],
}));

const nest = (baseObj, keys, value) => {
  if (keys.length === 1) {
    baseObj[keys[0]] = value;
  } else {
    const key = keys.shift();
    baseObj[key] = nest(typeof baseObj[key] === 'undefined' ? {} : baseObj[key], keys, value);
  }

  return baseObj;
};

const SCHEDULE_TYPES = {
  'onetime-schedule': 'One Time Configuration',
  'dynamic-schedule': 'Dynamic Configuration',
};

const get_nSwitchSavings = (payload, hoursDown, days) => {
  return payload.reduce((acc, res) => {
    return acc + (((res?.price_per_unit || 0) * hoursDown) / 7) * days;
  }, 0);
};

const get_StorageSavings = (payload, days) => {
  return payload.reduce((acc, res) => {
    return acc + (days === 7 ? (res?.savings || 0) / 4 : res?.savings * days);
  }, 0);
};

export {
  DEFAULT_SCHEDULE_DETAILS,
  DEFAULT_AUTOSCALING_SCHEDULE_DETAILS,
  DEFAULT_RECOMMENDED_SCHEDULE,
  DEFAULT_RECOMMENDED_STORAGE_SCHEDULE,
  WEEK_NUMBER,
  WEEK_DAYS,
  WEEK_LABEL,
  WEEK_LABEL_SHORT,
  WEEK_LABEL_LONG,
  nest,
  SCHEDULE_TYPES,
  get_nSwitchSavings,
  get_StorageSavings,
};
