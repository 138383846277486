import isEmpty from 'lodash/isEmpty';
import { getFilterPayload } from 'utils/helper';
export const T_KEY = 'nested:tags_kv:tags_kv.key';
export const T_VALUE = 'nested:tags_kv:tags_kv.value';
export const MAP_FILTERS_WITH_PAYLOAD = {
  searchString: 'searchString',
  cloudformation: 'cit',
  resource: 'tagData',
  autoScaling: 'selectedAutoScaling',
  ut: 'utState',
  op: 'opState',
  ri: 'is_ri',
  product: 'productState',
  include_credit: 'include_credit',
  user: 'userState',
  event: 'eventState',
  changeType: 'operationState',
  region: { key: 'regionState', value: 'regionState_values' },
  vpc: { key: 'vpcState', value: 'vpcState_values' },
  account: { key: 'accountState', value: 'accountState_values' },
  cloudPlatform: {
    key: 'cloudPlatformState',
    value: 'cloudPlatformState_values',
  },
  instanceType: 'instanceTypeState',
  launchStartEnd: ['launchStart', 'launchEnd'],
};

export const MAP_FILTERS_WITH_LABEL = {
  searchString: 'Keyword: ',
  account: 'Cloud account: ',
  cloudPlatform: 'Cloud: ',
  cloudformation: 'Stack-name: ',
  autoScaling: 'Autoscaling group: ',
  region: 'Region: ',
  vpc: 'VPC: ',
  product: 'Cloud service: ',
  op: 'Operation: ',
  ut: 'Usage type: ',
  user: 'Username: ',
  event: 'Event type: ',
  changeType: 'Operation type: ',
  instanceType: 'Instance type: ',
};

export const getFiltersStructure = (availableFilters, payload) => {
  return availableFilters.reduce((acc, curr) => {
    const mapFilterPayload = MAP_FILTERS_WITH_PAYLOAD[curr];
    const mapFilterLabel = MAP_FILTERS_WITH_LABEL[curr];

    if (Array.isArray(mapFilterPayload)) {
      return mapFilterPayload.reduce((accInner, currInner) => {
        accInner[currInner] = {
          payloadKey: currInner,
          value: payload[currInner],
        };
        if (mapFilterLabel) accInner[currInner].label = mapFilterLabel;
        return accInner;
      }, acc);
    }

    if (typeof mapFilterPayload === 'object' && mapFilterPayload.key) {
      acc[curr] = {
        payloadKey: mapFilterPayload.key,
        key: payload[mapFilterPayload.key] || [],
        value: payload[mapFilterPayload.value] || [],
      };
      if (mapFilterLabel) acc[curr].label = mapFilterLabel;
      return acc;
    }

    acc[curr] = {
      payloadKey: mapFilterPayload,
      value: payload[mapFilterPayload],
    };
    if (mapFilterLabel) acc[curr].label = mapFilterLabel;

    return acc;
  }, {});
};

export const getSelectedFilters = data => {
  const keys = Object.keys(data);
  return keys.reduce((acc, curr) => {
    const currentField = data[curr];
    const { payloadKey, key = null, value } = currentField;

    if (!value || (typeof value === 'object' && isEmpty(value))) return acc;

    if (currentField.payloadKey === 'tagData') {
      return [...acc, ...getTagFilters(value, 'tagData')];
    }

    if (currentField.payloadKey === 'include_credit') {
      const includeCreditLabel = 'Exclude AWS Credits';
      return value
        ? [
            ...acc,
            {
              payloadKey,
              value: includeCreditLabel,
              label: includeCreditLabel,
            },
          ]
        : acc;
    }

    if (currentField.payloadKey === 'is_ri') {
      const includeInstanceLabel = 'Exclude reserved instance';
      return value
        ? [
            ...acc,
            {
              payloadKey,
              value: includeInstanceLabel,
              label: includeInstanceLabel,
            },
          ]
        : acc;
    }

    if (currentField.payloadKey === 'launchStart') {
      const launchStartLabel = `Launched after: ${value.substring(0, 10)}`;
      return [
        ...acc,
        {
          payloadKey,
          value: launchStartLabel,
          label: launchStartLabel,
        },
      ];
    }

    if (currentField.payloadKey === 'launchEnd') {
      const launchEndLabel = `Launched before: ${value.substring(0, 10)}`;
      return [...acc, { payloadKey, value: launchEndLabel, label: launchEndLabel }];
    }

    if (key) {
      const tempArray = [];
      key.forEach((item, index) => {
        tempArray.push({
          payloadKey: payloadKey,
          key: item,
          value: value[index],
          label: `${currentField.label || ''}${value[index]?.value ? value[index]?.value : value[index]}`,
        });
      });
      return [...acc, ...tempArray];
    }

    if (!key && Array.isArray(value)) {
      return [
        ...acc,
        ...value.map(value => ({
          payloadKey,
          value,
          label: `${currentField.label || ''}${value}`,
        })),
      ];
    }

    return [...acc, { payloadKey, value, label: `${currentField.label || ''}${value}` }];
  }, []);
};

export const getHiddenFilters = (data, productBuckets, regionBuckets) => {
  const hiddenFilters = [];
  if (!productBuckets.length) {
    hiddenFilters.push(...data['accountState_values']);
  }
  if (!regionBuckets.length) {
    hiddenFilters.push(...data['regionState']);
  }
  return hiddenFilters;
};

export const getTagFilters = (values = {}, payloadKey) => {
  const data = [];
  const { must = [], must_not = [] } = values;
  if (!must.length && !must_not.length) return [];
  if (must?.length) {
    must.forEach(t => {
      if (!t[T_KEY]) return null;
      const filterMsg = `Include key: ${t[T_KEY][0]}${t[T_VALUE]?.length ? `, value: ${t[T_VALUE][0]}` : ''}`;
      data.push({
        payloadKey,
        value: filterMsg,
        label: filterMsg,
        tagKey: t[T_KEY][0],
      });
    });
  }
  if (must_not?.length) {
    must_not.forEach(t => {
      if (!t[T_KEY]) return null;
      const filterMsg = `Exclude key: ${t[T_KEY][0]}${t[T_VALUE] ? `, value: ${t[T_VALUE][0]}` : ''}`;
      data.push({
        payloadKey,
        value: filterMsg,
        label: filterMsg,
        tagKey: t[T_KEY][0],
      });
    });
  }
  return data;
};

export const removeSelectedFilter = (item, data) => {
  const updatedData = { ...data };
  const fieldData = data[item.payloadKey];
  if (typeof fieldData === 'string') {
    updatedData[item.payloadKey] = '';
    updatedData.qSearch = -1;
  }
  if (typeof fieldData === 'boolean') {
    updatedData[item.payloadKey] = false;
  }

  if (Array.isArray(fieldData)) {
    if (item.key) {
      updatedData[item.payloadKey] = fieldData.filter(currItem => currItem !== item.key);
      updatedData[`${item.payloadKey}_values`] = data[`${item.payloadKey}_values`].filter(
        currItem => currItem !== item.value,
      );
    } else {
      updatedData[item.payloadKey] = fieldData.filter(currItem => currItem !== item.value);
    }
  }
  if (item.tagKey) {
    updatedData[item.payloadKey]['must'] = fieldData['must'].filter(currItem => currItem[T_KEY][0] !== item.tagKey);
    updatedData[item.payloadKey]['must_not'] = fieldData['must_not'].filter(
      currItem => currItem[T_KEY][0] !== item.tagKey,
    );
  }
  return updatedData;
};

export const removeSelectedApiFilter = (data, apiData) => {
  if (!apiData) return null;
  return getFilterPayload(data);
};
