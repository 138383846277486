import React from 'react';

import Wrapper from './Wrapper';
import NestedWrapper from './NestedWrapper';
import NestedSettingsWrapper from './NestedSettingsWrapper';
import Loader from './Loader';

export const NestedLoadingIndicator = () => (
  <NestedWrapper className="np-nops-loading">
    <Loader />
  </NestedWrapper>
);

export const SettingsLoadingIndicator = () => (
  <NestedSettingsWrapper className="np-nops-loading">
    <Loader />
  </NestedSettingsWrapper>
);

const LoadingIndicator = () => (
  <Wrapper className="np-nops-loading" data-testid="wrapper">
    <Loader />
  </Wrapper>
);

export default LoadingIndicator;
