import React from 'react';
import { Anchor } from 'components/Custom';

const TabComponent = ({ ...props }) => {
  const { row, selectedTab } = props;
  const onClick = () => {
    props.onItemClick(props.row.name);
  };
  return (
    <li className={selectedTab === row.name ? 'active' : ''}>
      <Anchor onClick={onClick}>
        <i className="np-svgIcon">{row.icon}</i>
        &nbsp;{row.name}
      </Anchor>
    </li>
  );
};

export default TabComponent;
