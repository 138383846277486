import React from 'react';
import ReactEcharts from 'echarts-for-react';

const nameOptions = {
  nameLocation: 'center',
  nameTextStyle: {
    padding: 15,
  },
};

export const LineChart = ({
  recommendations,
  xAxisLabel = 'x axis label',
  xAxisData = [],
  yAxisLabel = 'y axis label',
  yAxisData = [],
  showYAxis = true,
  // showVisualMap = false,
}) => {
  const option = {
    xAxis: {
      ...nameOptions,
      name: xAxisLabel,
      data: xAxisData,
      boundaryGap: false,
    },
    yAxis: {
      ...nameOptions,
      name: yAxisLabel,
      show: showYAxis,
      max: value => {
        return Math.ceil(value.max);
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    // visualMap: {
    //   show: showVisualMap,
    //   top: 0,
    //   left: 13,
    //   pieces: [
    //     {
    //       lte: 1,
    //       color: '#f95b5b',
    //       label: 'Inactive',
    //     },
    //     {
    //       gt: 2,
    //       color: '#29beb9',
    //       label: 'Active',
    //     },
    //   ],
    //   outOfRange: {
    //     color: '#999',
    //   },
    // },
    series: [
      {
        type: 'line',
        data: yAxisData,
        smooth: true,
        lineStyle: {
          color: '#0071ea',
          width: 3,
        },
        markArea: {
          itemStyle: { color: 'rgba(41, 190, 185, 0.1)' },
          data: recommendations.map(rec => [
            {
              name: 'Recommendation',
              xAxis: rec.stop_start_hour,
            },
            {
              xAxis: rec.stop_end_hour,
            },
          ]),
        },
      },
    ],
  };

  return <ReactEcharts option={option} className="line-chart" />;
};
