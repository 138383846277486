import React, { memo, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import LoadingWrapper from 'components/ContentLoader/LoadingWrapper';
import ResourceDetails from 'containers/Search/Detail';
import { TableLoader } from 'components/ContentLoader/TableLoader';
import { CostControlIcon } from 'assets/images';
import { getResourceName } from 'utils/helper';
import { AG_GRID_DEFAULT_CONFIG } from 'utils/constants';
import { AgGridReact } from 'ag-grid-react';
import { getResourceList } from 'api/attach-resources';
import { api_condition_success } from 'containers/App/saga';
import { TODAY } from 'utils/filter';
import { currencyValueFormatterWithNoValue } from 'containers/Cost/TagExplorer/helper';
import { resourceNameRenderer } from 'components/Schedules/Resources/constants';

export const COLUMNS = dateRange => {
  const getAverageCostHeaderName = () => {
    if (dateRange === '30d') return 'Average Last 30 Days Cost';
    if (dateRange === 'mtd') return 'Average Month to Date Cost';
    return 'Average Monthly Cost';
  };

  return [
    {
      field: 'resource_info',
      headerName: 'Resource Info',
      cellRenderer: 'resourceNameRenderer',
      minWidth: 400,
    },
    {
      field: 'resource_type',
      headerName: 'Resource Type',
      minWidth: 140,
    },
    {
      field: 'average_cost',
      headerName: getAverageCostHeaderName(),
      filter: 'agNumberColumnFilter',
      valueFormatter: currencyValueFormatterWithNoValue,
      minWidth: 140,
    },
    {
      field: 'region',
      headerName: 'Region',
      minWidth: 80,
    },
    {
      field: 'desiredSize',
      headerName: 'Desired Capacity',
      minWidth: 80,
    },
    {
      field: 'minSize',
      headerName: 'Min Capacity',
      minWidth: 80,
    },
    {
      field: 'maxSize',
      headerName: 'Max Capacity',
      minWidth: 80,
    },
  ];
};

export const NodeGroups = memo(
  props => {
    const { data, dateRange } = props;
    const [displayData, setDisplayData] = useState([]);
    const [showModal, setShowModal] = useState({});
    const [loading, setLoading] = useState(false);

    const getAverageCost = pricePerUnit => {
      if (!dateRange || dateRange === '30d') return pricePerUnit * 24 * 30;
      if (dateRange === 'mtd') {
        const diff = TODAY.diff(moment().startOf('M'), 'days');
        return pricePerUnit * 24 * diff;
      }
      return pricePerUnit * 24 * 30;
    };

    useEffect(() => {
      setLoading(true);
      if (isEmpty(data)) return;

      const getNodeGroups = async () => {
        const {
          _source: { name, project },
        } = data;
        const paramsOption = new URLSearchParams();
        paramsOption.append('project_id', project.id);
        paramsOption.append('cluster_name', name);
        paramsOption.append('start_from', 0);
        paramsOption.append('size', 1000);
        paramsOption.append('type', 'eks_nodegroup');
        const response = await getResourceList({ params: paramsOption });
        if (api_condition_success(response.status)) {
          const resourceData = (response?.data?.result || []).map(data => {
            const name = getResourceName(data.name) || getResourceName(data.resource_id);
            return {
              name,
              resource_info: `name: ${name}\nid: ${data?.resource_id}`,
              resource_type: data?.resource_type || '',
              scaling_config: data?.scaling_config || {},
              desiredSize: data?.scaling_config?.desiredSize || 0,
              maxSize: data?.scaling_config?.maxSize || 0,
              minSize: data?.scaling_config?.minSize || 0,
              region: data?.region || '',
              average_cost: getAverageCost(data?.price_per_unit || 0),
              projectId: data?.project_id || '',
              id: data?.item_id,
              item_id: data?.resource_id,
            };
          });
          setDisplayData(resourceData);
        }
        setLoading(false);
      };
      getNodeGroups();
    }, [data]); //eslint-disable-line

    const onResourceClicked = ({ data }) => {
      if (!data?.projectId || !data?.id || !data?.item_id) return;
      setShowModal({ id: data.id, projectId: data.projectId });
    };

    return (
      <div className="np-table2">
        <div className="title">
          <div className="np-titleIcon blue">
            <CostControlIcon />
          </div>
          Node Groups
        </div>
        <div className="npTable">
          <LoadingWrapper isLoading={loading} customLoader={TableLoader}>
            <div className="ag-theme-alpine ag-workload-summary" style={{ width: '100%' }}>
              <AgGridReact
                suppressContextMenu={true}
                columnDefs={COLUMNS(dateRange)}
                defaultColDef={{
                  ...AG_GRID_DEFAULT_CONFIG,
                  sortable: false,
                  filter: false,
                }}
                rowData={displayData || []}
                components={{
                  resourceNameRenderer: resourceNameRenderer(onResourceClicked),
                }}
                animateRows={true}
                pagination
                paginationPageSize={10}
                suppressRowClickSelection={true}
                domLayout="autoHeight"
              />
            </div>
          </LoadingWrapper>
        </div>
        {!isEmpty(showModal) ? <ResourceDetails onClose={() => setShowModal({})} {...showModal} /> : null}
      </div>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data),
);
