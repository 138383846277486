import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import forIn from 'lodash/forIn';
import cloneDeep from 'lodash/cloneDeep';

import { DecreaseIcon, IncreaseIcon } from 'assets/images/common';
import { RuleIcon } from 'assets/images/nOpsRule';
import { WellArchitectedIcon } from 'assets/images/war';
import { GraterThenArrowIcon } from 'assets/images';

import { RULES_PILLAR, elementForLastMonthViolationWrapper, TABLE_DATA } from '../utils';
import { getColorsPalettes } from './colorPalettes';
import { getTextForIssues } from './Table';
import { RULE_DETAIL_PATHS, rules_pillars_array } from '../constants';

const default_service = ['efs', 'ecs', 'eip'];
const default_rules = ['check_empty_tags', 'redshift_low_node_utilization'];

export const breakStyle = {
  whiteSpace: 'pre-wrap',
  wordBreak: 'keep-all',
};
export const getDisplayData = ({ ...props }) => {
  const {
    ruleViolations,
    countBucket,
    changeData = {},
    ruleMapping,
    activeRule,
    isUnusedRepeat = false,
    isLink = false,
    configCatAzure,
    hideAzure,
    hideAWS,
  } = props;
  const tableData_clone = cloneDeep(TABLE_DATA);

  // Loop for all the rules
  forIn(ruleMapping, (rule_detail, rule_key) => {
    // Condition to check for navigation
    if (activeRule && activeRule !== rule_key) return;
    const cloudPlatform = (rule_detail?.cloud_type || 'aws').toLowerCase();

    if (hideAzure && cloudPlatform === 'azure') return;
    if (hideAWS && cloudPlatform === 'aws') return;
    // Loop for all services
    rule_detail.service.forEach(service => {
      // For Rule Detail
      const violations = ruleViolations.find(violation => violation.key === service);
      const violationBucket =
        violations?.violations_counts_per_service_type?.violations_details?.violation_type?.buckets || [];
      const violationDetail = violationBucket.find(violation => violation.key === rule_key);

      // For History Detail
      const counterService = countBucket.find(violation => violation.key === service);
      const countServiceBucket =
        counterService?.violations_counts_history_per_type?.violations_details?.violations_counts_history_type
          ?.buckets || [];
      const ruleHistory = countServiceBucket.find(violation => violation.key === rule_key);

      // For  Unused_rules as they are in cost and performance pillar
      const repeatRule = isUnusedRepeat ? rule_detail?.pillars_name : [];

      // Return if we don't have detail for rule
      const content = { ...rule_detail[service] } || undefined;
      if (!content) return;
      const detail = { ...content, rule: rule_key, service, cloudPlatform };

      detail.count = !violations ? 0 : violationDetail?.doc_count || 0;
      detail.lastCount = !countBucket?.length ? 0 : ruleHistory?.violations_counts_history_resources?.doc_count || 0;
      detail.difference = detail.count - detail.lastCount;
      detail.countElement = rulesIssuesRenderer(detail.count, rule_key, service);
      detail.differenceElement = lastMonthIssueRenderer(detail.count, detail.lastCount, false, rule_key, service);
      detail.path = content.path;
      detail.ruleName = content.text;
      detail.ruleNameElement = rulesRenderer(detail, content, isLink, rule_key, service, configCatAzure);
      detail.newDetection = 0;

      // set cost detail of rules
      if (
        !default_service.includes(service) &&
        (repeatRule?.length || rule_detail.pillar === RULES_PILLAR.COST || default_rules.includes(rule_key))
      ) {
        const cost =
          violationDetail?.cost_data?.total_current_month_cost?.total_current_month_cost?.summary?.value || 0;
        detail.cost = cost;
        detail.formattedCost = Number(cost);
        tableData_clone[RULES_PILLAR.COST].cost += cost;
      }

      if (repeatRule?.length) {
        repeatRule.forEach(pillar => {
          tableData_clone[pillar].count += violationDetail?.doc_count || 0;
          tableData_clone[pillar].lastCount += ruleHistory?.violations_counts_history_resources?.doc_count || 0;
          tableData_clone[pillar].data.push(detail);
        });
      } else {
        tableData_clone[rule_detail.pillar].count += violationDetail?.doc_count || 0;
        tableData_clone[rule_detail.pillar].lastCount +=
          ruleHistory?.violations_counts_history_resources?.doc_count || 0;
        tableData_clone[rule_detail.pillar].data.push(detail);
      }
    });
  });
  tableData_clone[RULES_PILLAR.CM].count = changeData?.doc_count || 0;
  const change_data = getNewDetection(changeData?.change_log?.buckets || []);

  rules_pillars_array.forEach(pillar => {
    let pillarData = cloneDeep(tableData_clone[pillar].data);
    if (!isEmpty(change_data)) {
      pillarData = pillarData.map(pillar_data => ({
        ...pillar_data,
        newDetection: change_data?.[pillar_data.rule]?.[pillar_data.service] || 0,
      }));
    }
    const orderedData = orderBy(pillarData, ['count'], 'desc');
    const colorPalette = getColorsPalettes(orderedData.length);
    orderedData.forEach((data, index) => {
      if (data.count <= 0) return;
      orderedData[index].styles = { ...colorPalette[index] };
    });
    tableData_clone[pillar].data = [...orderedData];
  });
  return tableData_clone;
};

export const lastMonthIssueRenderer = (count, lastCount, showText, rule_key, service) => {
  if (!count && !lastCount) return <span key={'rule-violation-' + rule_key + '-' + service}>no violation</span>;
  else if (count === lastCount) return <span key={'rule-violation-' + rule_key + '-' + service}>no change</span>;
  else {
    return elementForLastMonthViolationWrapper(
      count > lastCount ? <IncreaseIcon /> : <DecreaseIcon />,
      getTextForIssues(count, lastCount),
      count > lastCount ? 'red' : 'green',
      showText,
      rule_key,
      service,
    );
  }
};

export const defaultRulesClassName = count => {
  return classNames({
    'np-tableIcon': true,
    'red-fill': count > 1,
    'yellow-fill': count === 1,
    'green-fill': count === 0,
  });
};

export const rulesIssuesRenderer = (count, rule_key, rule_service) => {
  return (
    <>
      <i key={'rule-count-' + rule_key + '-' + rule_service} className={defaultRulesClassName(count)}>
        {count ? <RuleIcon /> : <WellArchitectedIcon />}
      </i>{' '}
      {count}
    </>
  );
};

export const rulesRenderer = (
  { count, lastCount, path = '', cloudPlatform },
  content,
  isLink,
  rule_key,
  rule_service,
  configCatAzure,
) => {
  const { text, text_for_grater = '', description, description_for_grater = '' } = content;
  return (
    <div className="ag-rule-iconCell">
      <div className="ag-rule-brand-icon-container">
        {configCatAzure && (
          <div
            id={`brandIcon-${rule_key}-${rule_service}`}
            className={`brandIcon ${cloudPlatform === 'azure' ? 'azureBrand' : 'amazonBrand'}`}
          ></div>
        )}
      </div>
      <div
        key={'rule-' + rule_key + '-' + rule_service}
        id={rule_key + '-' + rule_service}
        style={{
          lineHeight: '18px',
          padding: configCatAzure ? '12px 10px' : '12px 0',
        }}
      >
        <b data-ruletitle="rule-title" style={{ ...breakStyle, display: 'block' }}>
          {count > 1 ? text_for_grater || text : text} {isLink ? <GraterThenArrowIcon /> : null}
        </b>
        {!isLink ? (
          <div className="no-link">
            <p
              data-ruleparagraph="rule-paragraph"
              style={{
                ...breakStyle,
                lineHeight: '18px',
              }}
            >
              {count > 1 ? description_for_grater || description : description}
            </p>
          </div>
        ) : (
          <a
            data-ruleanchor="rule-anchor"
            href={RULE_DETAIL_PATHS + '?' + path}
            target={'_blank'}
            rel="noopener noreferrer"
            onClick={e => e.preventDefault()}
            className="rule-description"
          >
            <span
              data-ruleparagraph="rule-paragraph"
              style={{
                ...breakStyle,
                lineHeight: '18px',
              }}
            >
              {count > 1 ? description_for_grater || description : description}
            </span>
          </a>
        )}
      </div>
    </div>
  );
};

export const chartFilterData = (counts = {}, accounts = [], vpcData = [], pillarWiseRules = {}) => {
  const {
    violations_counts_per_project: { buckets: projectBuckets = [] } = {},
    violations_counts_per_region: { buckets: regionBuckets = [] } = {},
    violations_counts_per_vpc: { buckets: vpcBuckets = [] } = {},
  } = counts;
  const data = {};
  rules_pillars_array.forEach(pillar => {
    const ruleList = pillarWiseRules[pillar];
    const per_project = [],
      per_region = [],
      per_vpc = [];

    projectBuckets.forEach(project => {
      const record = accounts.find(account => +account?.id === +project?.key);
      if (!record?.value) return;
      const buckets = project?.violations_counts_per_project_data?.violations_details?.violation_type?.buckets || [];
      const count = getCount(buckets, ruleList);
      if (!count) return;
      per_project.push({
        key: project.key,
        name: record.value,
        count,
        cloudPlatform: project.cloudPlatform,
      });
    });

    regionBuckets.forEach(region => {
      if (!region.key) return;
      const buckets = region?.violations_counts_per_region?.violations_details?.violation_type?.buckets || [];
      const count = getCount(buckets, ruleList);
      if (!count) return;
      per_region.push({
        key: region.key,
        name: region.key,
        count,
        cloudPlatform: region.cloudPlatform,
      });
    });

    vpcBuckets.forEach(vpc => {
      const record = vpcData.find(item => item?.key === vpc?.key);
      if (!record?.name) return;
      const buckets = vpc?.violations_counts_per_vpc?.violations_details?.violation_type?.buckets || [];
      const count = getCount(buckets, ruleList);
      if (!count) return;
      per_vpc.push({
        key: vpc.key,
        name: record.name,
        count,
        cloudPlatform: vpc.cloudPlatform,
      });
    });
    data[pillar] = {
      per_project: orderBy(per_project, 'count', 'desc'),
      per_region: orderBy(per_region, 'count', 'desc'),
      per_vpc: orderBy(per_vpc, 'count', 'desc'),
    };
  });
  return data;
};

const getCount = (buckets, ruleList) => {
  let count = 0;
  buckets.forEach(bucket => {
    if (ruleList.includes(bucket?.key) && bucket?.doc_count) count += bucket.doc_count;
  });
  return count;
};

const getNewDetection = change_data => {
  const dataAWS = (change_data || []).filter(data => data.cloudPlatform === 'aws');
  const dataAzure = (change_data || []).filter(data => data.cloudPlatform === 'azure');
  const data = (dataAWS.slice(dataAWS.length - 7, dataAWS.length) || []).concat(
    dataAzure.slice(dataAzure.length - 7, dataAzure.length),
  );
  if (!data.length) return {};
  const rule_change_data = {};
  data.forEach(data_by_date => {
    const buckets = data_by_date?.violations_changes_type?.buckets || [];
    if (!buckets.length) return;
    buckets.forEach(bucket => {
      let hits = bucket.violations_documents?.violations_change_data?.hits?.hits || [];
      hits = hits.map(hit => ({ type: hit?._source?.type }));
      hits = groupBy(hits, 'type');
      if (isEmpty(hits)) return {};
      forIn(hits, (value, key) => {
        if (rule_change_data?.[bucket.key]?.[key] === undefined) {
          rule_change_data[bucket.key] = {
            ...rule_change_data[bucket.key],
            [key]: value.length,
          };
        } else {
          rule_change_data[bucket.key][key] += value.length;
        }
      });
    });
  });
  return rule_change_data;
};
