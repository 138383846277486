import axiosObj from './config';
import { getCookieByName } from 'utils/getCookie';

export const getBucketSettings = projects => {
  const promises = [];
  projects.forEach(id => {
    promises.push(
      axiosObj({
        method: 'GET',
        url: '/c/admin/awsbillingbucket/',
        params: {
          project: id,
        },
      }),
    );
  });
  return Promise.all(promises);
};

export const getProjectStatus = () => {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/healthcheck/',
  });
};

export const getAzureAccountStatus = project_id => {
  return axiosObj({
    method: 'GET',
    url: `/c/admin/projectaws/check_azure_connection/?project_id=${project_id}`,
  });
};

export const getAzureCPVLink = () => {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/projectaws/get_azure_cpv_link/',
  });
};

export const getAWSBillingBucket = () => {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/awsbillingbucket/',
  });
};

export const getAuthLink = payload => {
  return axiosObj({
    method: 'GET',
    url: '/c/aws/integration/',
    params: payload,
  });
};

export const getMultipleIntegrationAuthLink = () => {
  return axiosObj({
    method: 'GET',
    url: '/c/aws/multiple-integration/',
  });
};

export const shareSaveIntegration = payload => {
  return axiosObj({
    method: 'GET',
    url: '/c/sharesave-integration/',
    params: payload,
  });
};

export const updateShareSaveIntegration = payload => {
  return axiosObj({
    method: 'POST',
    url: '/c/sharesave-integration/',
    data: payload,
  });
};

export const renameAWSAccount = payload => {
  return axiosObj({
    method: 'PATCH',
    url: `/c/admin/projectaws/${payload.id}/`,
    data: payload.param,
  });
};

export const addAWSAccount = payload => {
  const { params = {}, id = '' } = payload;
  let url = '/c/admin/projectaws/';
  let updateParams = {};
  if (id) {
    url = url + `${id}/`;
    updateParams = {
      name: params.name,
      access_type: params.access_type,
      arn: params.arn,
      external_id: params.external_id,
    };
  }
  return axiosObj({
    method: id ? 'PATCH' : 'POST',
    url: url,
    data: id ? updateParams : params,
  });
};

export const azureTemplateDetails = () => {
  return axiosObj({
    method: 'GET',
    url: `/c/admin/azure-projects/generate_template_json/`,
  });
};

export const getOnboardingScriptURL = () => {
  return axiosObj({
    method: 'GET',
    url: `/c/admin/azure-projects/onboarding_script_url/`,
  });
};

export const addAzureAccount = ({ params = {} }) => {
  const url = '/c/admin/azure-projects/';
  return axiosObj({
    method: 'POST',
    url: url,
    data: params,
  });
};

export const deleteAzureAccount = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/c/admin/azure-projects/${id}/`,
  });
};

export const updateBucket = payload => {
  const { params = {}, id = 0 } = payload;
  return axiosObj({
    method: 'POST',
    url: '/c/admin/awsbillingbucket/',
    data: {
      bucket: params.bucket,
      project: id,
      report_name: params.report_name,
      report_path_prefix: params.report_path_prefix,
    },
  });
};

export const setAzureSubscription = payload => {
  const { params = {} } = payload;
  const url = '/c/admin/azure_subscription/';
  return axiosObj({
    method: 'POST',
    url: url,
    data: params,
  });
};

export const checkAWSConnection = payload => {
  const { params = {} } = payload;
  const url = '/c/admin/projectaws/check_connection/';
  return axiosObj({
    method: 'POST',
    url: url,
    data: params,
  });
};

export const checkIntegration = payload => {
  const { params = {}, projectId = '' } = payload;
  const url = `/c/project/${projectId}/integration/`;
  return axiosObj({
    method: 'GET',
    url: url,
    data: params,
  });
};

export const loadAllProjects = () => {
  return axiosObj({
    method: 'GET',
    url: `/c/admin/projectaws/`,
  });
};

export const deleteAWSAccount = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/c/admin/projectaws/${id}/`,
  });
};

export const destroyClientProjects = () => {
  return axiosObj({
    method: 'DELETE',
    url: `/c/admin/projectaws/destroy_client_projects/`,
  });
};

export const healthcheckAWSAccount = payload => {
  return axiosObj({
    method: 'POST',
    url: '/c/healthcheck/',
    data: payload,
  });
};

export const changePassword = payload => {
  return axiosObj({
    method: 'POST',
    url: '/change_password/',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRFToken': getCookieByName('csrftoken'),
    },
    transformRequest: function (obj) {
      const str = [];
      for (const p in obj) str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      return str.join('&');
    },
    data: payload,
  });
};

export const getSSOSettings = url => {
  return axiosObj({
    method: 'GET',
    url,
  });
};

export const createOrUpdateSSOSettings = (payload, id, url) => {
  let method = 'POST',
    apiURL = url;
  if (id) {
    method = 'PATCH';
    apiURL = `${apiURL + id}/`;
  }
  return axiosObj({
    method,
    url: apiURL,
    data: payload,
  });
};

export const deleteSSOSettings = (id, url) => {
  return axiosObj({
    method: 'DELETE',
    url: `${url + id}/`,
  });
};

export const slackSettings = () => {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/notification/slacksettings/',
  });
};

export const slackOperation = (id, payload) => {
  const method = id ? 'PATCH' : 'POST';
  let url = '/c/admin/notification/slacksettings/';
  if (id) {
    url = url + id + '/';
  }
  return axiosObj({
    method,
    url,
    data: payload,
  });
};

export const deleteSlackSetting = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/c/admin/notification/slacksettings/${id}/`,
  });
};

export const pagerDutyIntegration = () => {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/notification/pagerdutysettings/',
  });
};

export const setPagerDutyIntegration = (id, payload) => {
  const method = !id ? 'POST' : 'PATCH';
  const url = !id ? '/c/admin/notification/pagerdutysettings/' : `/c/admin/notification/pagerdutysettings/${id}/`;

  return axiosObj({
    method,
    url,
    data: payload,
  });
};

export const deletePagerDutyIntegration = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/c/admin/notification/pagerdutysettings/${id}/`,
  });
};

export const getApiKey = type => {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/client_api_setting/',
  });
};

export const createApiKey = (data, id) => {
  let url = '/c/admin/client_api_setting/';
  let method = 'POST';
  if (id) {
    url = `${url + id}/`;
    method = 'PATCH';
  }
  return axiosObj({
    method,
    url,
    data,
  });
};

export const deleteApiKey = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/c/admin/client_api_setting/${id}/`,
  });
};

export function createNotification({ payload, url }) {
  return axiosObj({
    method: 'POST',
    url: `/notification/rest/${url}/`,
    data: payload,
  });
}

export function listNotification(url) {
  return axiosObj({
    method: 'GET',
    url: `/notification/rest/${url}/`,
  });
}

export function updateNotification({ payload, id, url }) {
  return axiosObj({
    method: 'PATCH',
    url: `/notification/rest/${url}/${id}/`,
    data: payload,
  });
}

export function deleteNotification({ id, url }) {
  return axiosObj({
    method: 'DELETE',
    url: `/notification/rest/${url}/${id}/`,
  });
}

export function getDefaultTaggingData() {
  return axiosObj({
    method: 'GET',
    url: '/c/client/settings/',
  });
}

export function addDefaultTaggingDataAPI(payload) {
  return axiosObj({
    method: 'POST',
    url: '/c/client/settings/',
    data: payload,
  });
}

export function getARPTotal() {
  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters: {
        aggregations: 'arp_total',
        show_active: null,
      },
    },
  });
}

export function getAllRules() {
  return axiosObj({
    method: 'GET',
    url: '/c/dsl-rules/',
  });
}

export function createCustomRule(payload) {
  return axiosObj({
    method: 'POST',
    url: '/c/dsl-rules/',
    data: payload,
  });
}

export function deleteCustomRule(id) {
  return axiosObj({
    method: 'DELETE',
    url: `/c/dsl-rules/${id}`,
  });
}

export function updateCustomRule(id, payload) {
  return axiosObj({
    method: 'PATCH',
    url: `/c/dsl-rules/${id}`,
    data: payload,
  });
}

export function getUserProfile() {
  return axiosObj({
    method: 'GET',
    url: '/c/current/user_profile/',
  });
}

export function setUserProfile(payload) {
  return axiosObj({
    method: 'PATCH',
    url: '/c/current/user_profile/',
    data: payload,
  });
}

export function getDataDogIntegrationDetails() {
  return axiosObj({
    method: 'GET',
    url: '/c/v2/datadog/integration/',
  });
}

export function getCloudWatchIntegrationDetails() {
  return axiosObj({
    method: 'GET',
    url: '/svc/k8s_cost/recommendations/essentials/rightsizing/cw-agent-integration/',
  });
}

export function updateDataDogIntegrationDetails(payload) {
  return axiosObj({
    method: 'POST',
    url: '/c/v2/datadog/integration/',
    data: payload,
  });
}

export function deleteDataDogIntegrationDetails() {
  return axiosObj({
    method: 'DELETE',
    url: '/c/v2/datadog/integration/',
  });
}

export function getContainerCostBucket() {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/container_cost_bucket/',
  });
}

export function createContainerCostBucket(payload) {
  return axiosObj({
    method: 'POST',
    url: '/c/admin/container_cost_bucket/',
    data: payload,
  });
}

export function deleteContainerCostBucket(id) {
  return axiosObj({
    method: 'DELETE',
    url: `/c/admin/container_cost_bucket/${id}/`,
  });
}

export function updateContainerCostBucket(payload, id) {
  return axiosObj({
    method: 'PATCH',
    url: `/c/admin/container_cost_bucket/${id}/`,
    data: payload,
  });
}

export function checkContainerCostStatus(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/admin/container_cost_bucket/${id}/check_status_update/`,
  });
}

export function checkContainerAgentStatus(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/admin/container_cost_bucket/${id}/check_agent_status/`,
  });
}

export function generateScript(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/admin/container_cost_bucket/${id}/generate_script/`,
  });
}

export function generateAgentScript(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/admin/container_cost_bucket/${id}/install_agent/`,
  });
}

export function containerCostSetUp(id) {
  return axiosObj({
    method: 'POST',
    url: `/c/admin/container_cost_bucket/setup/`,
    data: { project: id },
  });
}
