import {
  LOAD_RULE_VIOLATION_DATA,
  LOAD_RULE_VIOLATION_DATA_SUCCESS,
  LOAD_RULE_VIOLATION_DATA_ERROR,
  LOAD_RULE_DETAIL_VIOLATION_DATA,
  LOAD_RULE_DETAIL_VIOLATION_DATA_SUCCESS,
  LOAD_RULE_DETAIL_VIOLATION_DATA_ERROR,
  LOAD_RULE_FILTER_DATA,
  LOAD_RULE_FILTER_DATA_SUCCESS,
  LOAD_RULE_FILTER_DATA_ERROR,
  LOAD_CM_RULE_VIOLATION_DATA_REQUEST,
  LOAD_CM_RULE_VIOLATION_DATA_SUCCESS,
  LOAD_CM_RULE_VIOLATION_DATA_ERROR,
  LOAD_CM_RULE_DETAIL_VIOLATION_DATA_REQUEST,
  LOAD_CM_RULE_DETAIL_VIOLATION_DATA_ERROR,
  LOAD_CM_RULE_DETAIL_VIOLATION_DATA_SUCCESS,
  LOAD_RULE_DETAIL_FILTER_DATA,
  LOAD_RULE_DETAIL_FILTER_DATA_SUCCESS,
  LOAD_RULE_DETAIL_FILTER_DATA_ERROR,
} from './constants';

export function loadData(payload) {
  return {
    type: LOAD_RULE_VIOLATION_DATA,
    payload,
  };
}

export function loadDataSuccess(response) {
  return {
    type: LOAD_RULE_VIOLATION_DATA_SUCCESS,
    response,
  };
}

export function loadDataError() {
  return {
    type: LOAD_RULE_VIOLATION_DATA_ERROR,
  };
}

export function loadCMDataRequest(payload) {
  return {
    type: LOAD_CM_RULE_VIOLATION_DATA_REQUEST,
    payload,
  };
}

export function loadCMDataSuccess(response) {
  return {
    type: LOAD_CM_RULE_VIOLATION_DATA_SUCCESS,
    response,
  };
}

export function loadCMDataError() {
  return {
    type: LOAD_CM_RULE_VIOLATION_DATA_ERROR,
  };
}

export function loadRuleDetailData(payload) {
  return {
    type: LOAD_RULE_DETAIL_VIOLATION_DATA,
    payload,
  };
}

export function loadRuleDetailDataSuccess(response) {
  return {
    type: LOAD_RULE_DETAIL_VIOLATION_DATA_SUCCESS,
    response,
  };
}

export function loadRuleDetailDataError() {
  return {
    type: LOAD_RULE_DETAIL_VIOLATION_DATA_ERROR,
  };
}

export function loadCMRuleDetailDataRequest(payload) {
  return {
    type: LOAD_CM_RULE_DETAIL_VIOLATION_DATA_REQUEST,
    payload,
  };
}

export function loadCMRuleDetailDataSuccess(response) {
  return {
    type: LOAD_CM_RULE_DETAIL_VIOLATION_DATA_SUCCESS,
    response,
  };
}

export function loadCMRuleDetailDataError() {
  return {
    type: LOAD_CM_RULE_DETAIL_VIOLATION_DATA_ERROR,
  };
}

export function loadRuleFilterDataRequest(payload) {
  return {
    type: LOAD_RULE_FILTER_DATA,
    payload,
  };
}

export function loadRuleFilterDataSuccess(response) {
  return {
    type: LOAD_RULE_FILTER_DATA_SUCCESS,
    response,
  };
}

export function loadRuleFilterDataError() {
  return {
    type: LOAD_RULE_FILTER_DATA_ERROR,
  };
}

export function loadRuleDetailFilterDataRequest(payload) {
  return {
    type: LOAD_RULE_DETAIL_FILTER_DATA,
    payload,
  };
}

export function loadRuleDetailFilterDataSuccess(response) {
  return {
    type: LOAD_RULE_DETAIL_FILTER_DATA_SUCCESS,
    response,
  };
}

export function loadRuleDetailFilterDataError() {
  return {
    type: LOAD_RULE_DETAIL_FILTER_DATA_ERROR,
  };
}
