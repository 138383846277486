import { LOAD_CUSTOM_RULE_REQUEST, LOAD_CUSTOM_RULE_SUCCESS, LOAD_CUSTOM_RULE_ERROR } from './constants';

export function loadCustomRulesRequest() {
  return {
    type: LOAD_CUSTOM_RULE_REQUEST,
  };
}

export function loadCustomRulesSuccess(response) {
  return {
    type: LOAD_CUSTOM_RULE_SUCCESS,
    response,
  };
}

export function loadCustomRulesError() {
  return {
    type: LOAD_CUSTOM_RULE_ERROR,
  };
}
