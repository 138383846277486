import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { FILTER_DATE_FORMAT, START_OF_LAST_MONTH, START_OF_MONTH, YESTERDAY } from 'utils/filter';
import { getMonth } from 'utils/cost-helper';
import forIn from 'lodash/forIn';
import uniq from 'lodash/uniq';
import { CLOUD_RESOURCE_COST_KEYS, CLOUD_RESOURCE_COST_TYPE, CLOUD_RESOURCE_FILTER_DIMENSION } from './constants';
import uniqBy from 'lodash/uniqBy';
import { cost_presets } from 'containers/Filters-new/constants';

export const field_keys = [
  'account_id',
  'item_type',
  'nops_purchase_option',
  'product',
  'region',
  'nops_region_name',
  'operation',
  'usagetype',
  'resource_id',
  'nops_compute_types',
  'resource_name',
  'description',
];

export const getReportParams = (data, defaultPayload) => {
  if (isEmpty(data)) return defaultPayload;
  const getData = (key, type) => {
    return (data?.[key] || []).filter(item => !item?.contain && item.type === type).map(item => item.value);
  };
  const getContainData = (key, type) => {
    return (data?.[key] || []).filter(item => !item?.type && item?.contain === type).map(item => item.value);
  };
  const getTagsData = (key, type) => {
    return (data?.[key] || [])
      .filter(item => item.type === type && item?.tag_value && !item?.contain)
      .map(item => ({
        [item.value]: item.tag_value,
      }));
  };
  const getTagsContainData = (key, type) => {
    return (data?.[key] || [])
      .filter(item => !item?.type && item?.tag_key && item?.contain === type)
      .map(item => ({
        [item.value]: item.tag_value,
      }));
  };
  const getTagKeysData = (key, type) => {
    return (data?.[key] || [])
      .filter(item => item.type === type && !item?.tag_value && !item?.contain)
      .map(item => item.value);
  };
  const getTagKeysContainData = (key, type) => {
    return (data?.[key] || [])
      .filter(item => !item?.type && !item?.tag_key && item.contain === type)
      .map(item => item.value);
  };

  let payload = { ...defaultPayload };

  field_keys.forEach(item => {
    payload = {
      ...payload,
      [item]: getData(item, 'Include'),
      [`exclude_${item}`]: getData(item, 'Exclude'),
      [`like_${item}`]: getContainData(item, 'like'),
      [`not_like_${item}`]: getContainData(item, 'not_like'),
    };
  });

  return {
    ...payload,
    tag_keys: getTagKeysData('tag_keys', 'Include'),
    exclude_tag_keys: getTagKeysData('tag_keys', 'Exclude'),
    like_tag_keys: getTagKeysContainData('tag_keys', 'like'),
    not_like_tag_keys: getTagKeysContainData('tag_keys', 'not_like'),
    tag: getTagsData('tag_keys', 'Include'),
    exclude_tag: getTagsData('tag_keys', 'Exclude'),
    like_tag: getTagsContainData('tag_keys', 'like'),
    not_like_tag: getTagsContainData('tag_keys', 'not_like'),
    tag_keys_and: getTagKeysData('tag_keys_and', 'Include'),
    exclude_tag_keys_and: getTagKeysData('tag_keys_and', 'Exclude'),
    like_tag_keys_and: getTagKeysContainData('tag_keys_and', 'like'),
    not_like_tag_keys_and: getTagKeysContainData('tag_keys_and', 'not_like'),
    tag_and: getTagsData('tag_keys_and', 'Include'),
    exclude_tag_and: getTagsData('tag_keys_and', 'Exclude'),
    like_tag_and: getTagsContainData('tag_keys_and', 'like'),
    not_like_tag_and: getTagsContainData('tag_keys_and', 'not_like'),
  };
};

export const setFilterParams = (data, params, projects) => {
  if (isEmpty(data)) return params;
  const getName = value => {
    if (!projects?.length) return value;
    const rec = projects.find(item => item.account_number === value);
    return rec?.name || value;
  };

  const getPayloadData = (dimension, paramsData = [], item, type = {}) => {
    return [...paramsData].map(detail => ({
      value: detail,
      label:
        item === 'account_id'
          ? getName(detail)
          : detail === 'null'
            ? dimension === CLOUD_RESOURCE_COST_KEYS.TAGS
              ? 'untagged'
              : `No ${dimension}`
            : detail,
      ...type,
    }));
  };

  const getTagPayloadData = (key, paramsData = [], type = {}) => {
    return [...paramsData].map(item => ({
      value: Object.keys(item)?.[0] || '',
      [key]: Object.keys(item)?.[0] === 'null' ? 'untagged' : Object.keys(item)?.[0] || '',
      tag_value: Object.values(item)?.[0] || '',
      ...type,
    }));
  };

  const getTagContainPayloadData = (paramsData = [], type = {}) => {
    return [...paramsData].map(item => ({
      tag_key: Object.keys(item)?.[0] === 'null' ? 'untagged' : Object.keys(item)?.[0] || '',
      value: Object.keys(item)?.[0] === 'null' ? 'untagged' : Object.keys(item)?.[0] || '',
      tag_value: Object.values(item)?.[0] === 'null' ? 'untagged' : Object.values(item)?.[0] || '',
      ...type,
    }));
  };

  let payload = { ...params };
  if (data?.custom_filters) {
    const jsonData = JSON.parse(data.custom_filters);
    if (jsonData?.saveDateRange) {
      const rec = cost_presets.find(item => item.text === jsonData?.selectedRange);
      payload = {
        ...payload,
        ...JSON.parse(data.custom_filters || '{}'),
        date_start: rec?.start.format(FILTER_DATE_FORMAT) || jsonData.date_start,
        date_end: rec?.end.format(FILTER_DATE_FORMAT) || jsonData.date_end,
      };
    } else {
      payload = {
        ...payload,
        ...JSON.parse(data.custom_filters || '{}'),
      };
    }
  }

  const reversedObj = {};

  for (const key in CLOUD_RESOURCE_FILTER_DIMENSION) {
    reversedObj[CLOUD_RESOURCE_FILTER_DIMENSION[key]] = CLOUD_RESOURCE_COST_TYPE[key];
  }
  field_keys.forEach(item => {
    payload = {
      ...payload,
      [item]: [
        ...getPayloadData(reversedObj[item], data?.[item], item, {
          type: 'Include',
        }),
        ...getPayloadData(reversedObj[item], data?.[`exclude_${item}`], item, {
          type: 'Exclude',
        }),
        ...getPayloadData(reversedObj[item], data?.[`like_${item}`], item, {
          contain: 'like',
        }),
        ...getPayloadData(reversedObj[item], data?.[`not_like_${item}`], item, {
          contain: 'not_like',
        }),
      ],
    };
  });
  payload = {
    ...payload,
    tag_keys: [
      ...getPayloadData(CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.TAGS], data?.['tag_keys'], 'tag_keys', {
        tag_value: '',
        type: 'Include',
      }),
      ...getPayloadData(
        CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.TAGS],
        data?.['exclude_tag_keys'],
        'tag_keys',
        {
          tag_value: '',
          type: 'Exclude',
        },
      ),
      ...getPayloadData(CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.TAGS], data?.['like_tag_keys'], 'tag_keys', {
        tag_key: '',
        contain: 'like',
      }),
      ...getPayloadData(
        CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.TAGS],
        data?.['not_like_tag_keys'],
        'tag_keys',
        {
          tag_key: '',
          contain: 'not_like',
        },
      ),
      ...getTagPayloadData('label', data?.['tag'] || [], { type: 'Include' }),
      ...getTagPayloadData('label', data?.['exclude_tag'] || [], {
        type: 'Exclude',
      }),
      ...getTagContainPayloadData(data?.['like_tag'] || [], {
        contain: 'like',
      }),
      ...getTagContainPayloadData(data?.['not_like_tag'] || [], {
        contain: 'not_like',
      }),
    ],
    tag_keys_and: [
      ...getPayloadData(
        CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.TAGS],
        data?.['tag_keys_and'],
        'tag_keys_and',
        {
          tag_value: '',
          type: 'Include',
        },
      ),
      ...getPayloadData(
        CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.TAGS],
        data?.['exclude_tag_keys_and'],
        'tag_keys_and',
        {
          tag_value: '',
          type: 'Exclude',
        },
      ),
      ...getPayloadData(
        CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.TAGS],
        data?.['like_tag_keys_and'],
        'tag_keys_and',
        {
          tag_key: '',
          contain: 'like',
        },
      ),
      ...getPayloadData(
        CLOUD_RESOURCE_COST_TYPE[CLOUD_RESOURCE_COST_KEYS.TAGS],
        data?.['not_like_tag_keys_and'],
        'tag_keys_and',
        {
          tag_key: '',
          contain: 'not_like',
        },
      ),
      ...getTagPayloadData('label', data?.['tag_and'] || [], {
        type: 'Include',
      }),
      ...getTagPayloadData('label', data?.['exclude_tag_and'] || [], {
        type: 'Exclude',
      }),
      ...getTagContainPayloadData(data?.['like_tag_and'] || [], {
        contain: 'like',
      }),
      ...getTagContainPayloadData(data?.['not_like_tag_and'] || [], {
        contain: 'not_like',
      }),
    ],
  };
  return payload;
};

export const initialColumns = [
  { key: 'name', label: 'Name', order: '' },
  { key: 'total', label: 'Total', order: '' },
  {
    key: 'spend',
    label: 'Total Spend(%)',
    small: '',
    order: '',
  },
  {
    key: 'previous_interval_spend',
    label: "Last period's",
    small: 'spend',
    order: '',
  },
];

export const getColumns = (title, colsData) => {
  return [
    ...colsData.map(item => ({
      key: item.date,
      label: item.label,
      order: '',
    })),
  ];
};

export function getDaysBetweenRange(startDate = null, endDate = null) {
  if (!moment(startDate, FILTER_DATE_FORMAT).isValid() || !moment(endDate, FILTER_DATE_FORMAT).isValid()) return [];
  const dateArray = [];
  const startDateClone = moment(startDate, FILTER_DATE_FORMAT);
  const endDateClone = moment(endDate, FILTER_DATE_FORMAT);
  while (startDateClone.format(FILTER_DATE_FORMAT) <= endDateClone.format(FILTER_DATE_FORMAT)) {
    dateArray.push({
      key: startDateClone.format(FILTER_DATE_FORMAT) + 'T00:00:00',
      label: startDateClone.format('DD MMM YYYY'),
      date_start: startDateClone.format(FILTER_DATE_FORMAT),
      date_end: startDateClone.format(FILTER_DATE_FORMAT),
      order: '',
    });
    startDateClone.add(1, 'days');
  }
  return dateArray;
}

export function getWeeksBetweenRange(startDate = null, endDate = null) {
  if (!moment(startDate, FILTER_DATE_FORMAT).isValid() || !moment(endDate, FILTER_DATE_FORMAT).isValid()) return [];
  const dateArray = [];

  let startDateClone = moment(startDate, FILTER_DATE_FORMAT).isoWeekday(1);
  let endDateClone = moment(endDate, FILTER_DATE_FORMAT).isoWeekday(7);
  if (endDateClone.format(FILTER_DATE_FORMAT) > YESTERDAY.format(FILTER_DATE_FORMAT)) {
    endDateClone = moment().subtract(1, 'week').isoWeekday(7);
  }

  while (startDateClone.format(FILTER_DATE_FORMAT) <= endDateClone.format(FILTER_DATE_FORMAT)) {
    let newDate = moment(startDateClone);
    newDate = newDate.add(1, 'weeks');
    if (newDate.format(FILTER_DATE_FORMAT) >= endDateClone) {
      dateArray.push({
        date_start: startDateClone.format(FILTER_DATE_FORMAT),
        date_end: endDateClone.format(FILTER_DATE_FORMAT),
        key: startDateClone.format(FILTER_DATE_FORMAT) + 'T00:00:00',
        label: `${startDateClone.format('DD')}-${endDateClone.format('DD MMM YYYY')}`,
        order: '',
      });
    } else {
      const ed = moment(startDateClone).add(6, 'days');
      dateArray.push({
        date_start: startDateClone.format(FILTER_DATE_FORMAT),
        date_end: ed.format(FILTER_DATE_FORMAT),
        key: startDateClone.format(FILTER_DATE_FORMAT) + 'T00:00:00',
        label: `${startDateClone.format('DD')}-${ed.format('DD MMM YYYY')}`,
        order: '',
      });
    }
    startDateClone = newDate;
  }
  return dateArray;
}

export function getMonthsBetweenRange(startDate = null, endDate = null) {
  if (!moment(startDate, FILTER_DATE_FORMAT).isValid() || !moment(endDate, FILTER_DATE_FORMAT).isValid()) return [];
  const dateArray = [];
  const startDateClone = moment(startDate, FILTER_DATE_FORMAT).set('date', 1);
  let endDateClone = endDate;
  if (!moment(endDate).isSame(new Date(), 'month')) {
    endDateClone = moment(endDate).endOf('month').format(FILTER_DATE_FORMAT);
  }
  while (startDateClone.format(FILTER_DATE_FORMAT) <= endDateClone) {
    dateArray.push({
      key: startDateClone.format(FILTER_DATE_FORMAT) + 'T00:00:00',
      label: `${startDateClone.format('MMM-YYYY')}`,
      date_start: startDateClone.format(FILTER_DATE_FORMAT),
      date_end: !moment(moment(startDateClone).endOf('month')).isSame(new Date(), 'month')
        ? moment(startDateClone).endOf('month').format(FILTER_DATE_FORMAT)
        : YESTERDAY.format(FILTER_DATE_FORMAT),
      order: '',
    });
    startDateClone.add(1, 'month');
  }
  return dateArray;
}

export function getHoursBetweenRange(startDate = null, endDate = null) {
  if (!moment(startDate, FILTER_DATE_FORMAT).isValid() || !moment(endDate, FILTER_DATE_FORMAT).isValid()) return [];
  const dateArray = [];
  const startDateClone = moment(startDate, FILTER_DATE_FORMAT);
  const endDateClone = moment(endDate, FILTER_DATE_FORMAT);
  while (startDateClone.format(FILTER_DATE_FORMAT) <= endDateClone.format(FILTER_DATE_FORMAT)) {
    for (let i = 0; i < 24; i++) {
      dateArray.push({
        key: startDateClone.format(FILTER_DATE_FORMAT) + `T${i < 10 ? `0${i}` : i}:00:00`,
        label: `${i < 10 ? `0${i}` : i}:00 ` + startDateClone.format('MM/DD/YYYY'),
        date_start: startDateClone.format(FILTER_DATE_FORMAT) + `T${i < 10 ? `0${i}` : i}:00:00`,
        date_end: startDateClone.format(FILTER_DATE_FORMAT) + `T${i < 10 ? `0${i}` : i}:00:00`,
        order: '',
      });
    }
    startDateClone.add(1, 'days');
  }
  return dateArray;
}

const getMonthDate = (quarter, year) => {
  let eDate = moment();
  switch (quarter) {
    case 1: {
      eDate = moment(moment().set({ date: 1, month: 0, year }))
        .add(2, 'month')
        .endOf('month');
      break;
    }
    case 2: {
      eDate = moment(moment().set({ date: 1, month: 3, year }))
        .add(2, 'month')
        .endOf('month');
      break;
    }
    case 3: {
      eDate = moment(moment().set({ date: 1, month: 6, year }))
        .add(2, 'month')
        .endOf('month');
      break;
    }
    case 4: {
      eDate = moment(moment().set({ date: 1, month: 9, year }))
        .add(2, 'month')
        .endOf('month');
      break;
    }
    default:
      break;
  }
  return moment(eDate).isSame(new Date(), 'month') && moment(eDate).isSame(new Date(), 'year') ? YESTERDAY : eDate;
};

export function getQuarterBetweenRange(startDate = null, endDate = null) {
  if (!moment(startDate, FILTER_DATE_FORMAT).isValid() || !moment(endDate, FILTER_DATE_FORMAT).isValid()) return [];
  const dateArray = [];
  const startDateClone = moment(startDate, FILTER_DATE_FORMAT).set('date', 1);
  let endDateClone = endDate;
  if (!moment(endDate).isSame(new Date(), 'month')) {
    endDateClone = moment(endDate).endOf('month').format(FILTER_DATE_FORMAT);
  }
  let quarter = startDateClone.quarter();
  let firstDate = getMonth(quarter, startDateClone.year());
  let secondDate = getMonthDate(quarter, startDateClone.year());
  let year = firstDate.year();
  while (firstDate.format(FILTER_DATE_FORMAT) <= endDateClone) {
    dateArray.push({
      key: firstDate.format(FILTER_DATE_FORMAT) + 'T00:00:00',
      date_start: firstDate.format(FILTER_DATE_FORMAT),
      date_end: secondDate.format(FILTER_DATE_FORMAT),
      label: `Quarter ${quarter} - ${year}`,
      order: '',
    });
    year = quarter === 4 ? year + 1 : year;
    quarter = quarter === 4 ? 1 : quarter + 1;
    firstDate = getMonth(quarter, year);
    secondDate = getMonthDate(quarter, year);
  }
  return dateArray;
}

export function getDateRange(type, { date_start, date_end }) {
  switch (type) {
    case 'week': {
      const startDateClone = moment(date_start, FILTER_DATE_FORMAT).isoWeekday(1).format(FILTER_DATE_FORMAT);
      let endDateClone = moment(date_end, FILTER_DATE_FORMAT).isoWeekday(7).format(FILTER_DATE_FORMAT);

      if (endDateClone > YESTERDAY.format(FILTER_DATE_FORMAT)) {
        endDateClone = moment().subtract(1, 'week').isoWeekday(7).format(FILTER_DATE_FORMAT);
      }
      return {
        date_start: startDateClone,
        date_end: endDateClone,
      };
    }
    case 'month': {
      const startDateClone = moment(date_start, FILTER_DATE_FORMAT).set('date', 1);
      let endDateClone = date_end;
      if (!moment(date_end).isSame(new Date(), 'month')) {
        endDateClone = moment(date_end).endOf('month').format(FILTER_DATE_FORMAT);
      }
      return {
        date_start: startDateClone.format(FILTER_DATE_FORMAT),
        date_end: endDateClone,
      };
    }
    case 'quarter': {
      const startDateClone = moment(date_start, FILTER_DATE_FORMAT).set('date', 1);
      const quarter = startDateClone.quarter();
      const firstDate = getMonth(quarter, startDateClone.year());
      let endDateClone = date_end;
      if (!moment(date_end).isSame(new Date(), 'month')) {
        endDateClone = moment(date_end).endOf('month').format(FILTER_DATE_FORMAT);
      }
      return {
        date_start: moment(firstDate).format(FILTER_DATE_FORMAT),
        date_end: endDateClone,
      };
    }
    default:
      return { date_start, date_end };
  }
}

export function getAllShowbackValueData(data, unallocated) {
  let tag_key = Object.keys(data?.cost_distribution || {});
  forIn(data.allocation_rules_cost_distribution, (value, key) => {
    if (!tag_key.includes(key)) {
      tag_key.push(key);
    }
  });
  forIn(data.percentage_allocation_rules_distribution, percentageData => {
    forIn(percentageData, (tag_value_data, key) => {
      if (key !== 'cost_distribution_rules') return;
      forIn(tag_value_data, (amount, tag_value) => {
        if (!tag_key.includes(tag_value)) {
          tag_key.push(tag_value);
        }
      });
    });
  });
  tag_key = [...tag_key, ...data.extra_values];
  tag_key = uniq(tag_key);
  let costDistribution = {};
  tag_key.forEach(key => {
    const costResponse = {};
    let summaryResponse = {};

    forIn(data?.cost_distribution?.[key]?.cost?.granular || {}, (amount = 0, range) => {
      costResponse[range] = (costResponse?.[range] || 0) + amount;
    });
    forIn(data?.allocation_rules_cost_distribution?.[key]?.cost || {}, (amount = 0, range) => {
      costResponse[range] = (costResponse?.[range] || 0) + amount;
    });
    forIn(data?.percentage_allocation_rules_distribution || {}, percentageData => {
      forIn(percentageData?.costs || {}, (amount = 0, range) => {
        costResponse[range] =
          (costResponse?.[range] || 0) + (amount * (percentageData?.cost_distribution_rules?.[key] || 0)) / 100;
      });
    });
    summaryResponse = {
      interval_spend:
        (data?.allocation_rules_cost_distribution?.[key]?.summary?.interval_spend || 0) +
        (data?.cost_distribution?.[key]?.summary?.interval_spend || 0),
      previous_interval_spend:
        (data?.allocation_rules_cost_distribution?.[key]?.summary?.previous_interval_spend || 0) +
        (data?.cost_distribution?.[key]?.summary?.previous_interval_spend || 0),
    };
    forIn(data?.percentage_allocation_rules_distribution || {}, percentageData => {
      summaryResponse = {
        interval_spend:
          (summaryResponse?.interval_spend || 0) +
          (percentageData?.summary?.interval_spend || 0) *
            ((percentageData?.cost_distribution_rules?.[key] || 0) / 100),
        previous_interval_spend:
          (summaryResponse?.previous_interval_spend || 0) +
          (percentageData?.summary?.previous_interval_spend || 0) *
            ((percentageData?.cost_distribution_rules?.[key] || 0) / 100),
      };
    });
    costDistribution = {
      ...costDistribution,
      [key]: {
        costResponse,
        summaryResponse,
      },
    };
  });
  data.combined_values.forEach(item => {
    const key = item.name;
    item.combined_values.forEach((combined, index) => {
      if (index !== 0 && costDistribution?.[combined]) {
        forIn(costDistribution[combined].costResponse, (amount = 0, range) => {
          costDistribution = {
            ...costDistribution,
            [key]: {
              ...(costDistribution?.[key] || {}),
              costResponse: {
                ...(costDistribution?.[key]?.costResponse || {}),
                [range]: (costDistribution?.[key]?.costResponse?.[range] || 0) + amount,
              },
            },
          };
        });
        costDistribution = {
          ...costDistribution,
          [key]: {
            ...(costDistribution?.[key] || {}),
            summaryResponse: {
              interval_spend:
                (costDistribution?.[key]?.summaryResponse?.interval_spend || 0) +
                (costDistribution?.[combined]?.summaryResponse?.interval_spend || 0),
              previous_interval_spend:
                (costDistribution?.[key]?.summaryResponse?.previous_interval_spend || 0) +
                (costDistribution?.[combined]?.summaryResponse?.previous_interval_spend || 0),
            },
          },
        };
        delete costDistribution[combined];
      }
    });
  });
  costDistribution = {
    ...costDistribution,
    Unallocated: {
      costResponse: unallocated?.cost || {},
      summaryResponse: unallocated?.summary || {},
    },
  };
  forIn(costDistribution, (value, key) => {
    costDistribution[key] = {
      ...costDistribution[key],
      total: Object.values(costDistribution?.[key]?.costResponse || {}).reduce((a, b) => a + b, 0),
    };
  });
  return { ...data, cost_distribution: costDistribution };
}

export const getParams = (
  activeShowback = {},
  allocationData = [],
  value = '',
  isAllocated = true,
  allocationRecord = {},
) => {
  const rec = (activeShowback?.combined_values || []).find(item => item.name === value);
  let ids = [];
  if (rec?.combined_values?.length) {
    rec.combined_values.forEach(item => {
      const id = allocationData.find(allocation => allocation.showback_value === item);
      if (id) ids.push(id);
    });
  } else {
    allocationData
      .filter(item => item.showback_value === value)
      .forEach(item => {
        ids.push(item);
      });
  }
  let payload = {
    showback_value:
      isAllocated && value
        ? !rec
          ? [{ value, type: 'Include' }]
          : rec.combined_values.map(item => ({
              value: item,
              type: 'Include',
            }))
        : undefined,
  };
  if ((!value && allocationRecord?.id) || allocationRecord?.id) {
    payload = {
      ...payload,
      include_allocation_rule_id: [{ value: allocationRecord.id, type: 'Include' }],
    };
  } else if (isAllocated) {
    ids = uniqBy(ids, 'id');
    payload = {
      ...payload,
      include_allocation_rule_id: ids.map(item => ({
        value: item.id,
        type: 'Include',
      })),
      distribution_rule_id:
        allocationData
          .filter(item => {
            if (item.distribution_by_percent) {
              const keys = Object.keys(item.cost_distribution_rules);
              if (keys.includes(value)) return true;
            }
            return false;
          })
          .map(item => ({ value: item.id, type: 'Include' })) || [],
    };
  } else {
    payload = {
      ...payload,
      allocation_rule_id: allocationData.map(item => ({
        value: item.id,
        type: 'Exclude',
      })),
    };
  }
  return payload;
};

export const getParamByDimension = (item, activeTab, params, flag, detailItem) => {
  if (flag) {
    const label = 'tag';
    return {
      ...params,
      [`${label}_keys`]: [
        ...(params?.[`${label}_keys`] || []),
        {
          value: detailItem.name,
          type: 'Include',
          label: detailItem.name,
          [`${label}_value`]: item.name,
        },
      ],
    };
  }

  const key = CLOUD_RESOURCE_FILTER_DIMENSION[activeTab] || '';
  if (!key) return params;
  return {
    ...params,
    [key]: uniqBy(
      [
        ...(params?.[key] || []),
        {
          value: item.key,
          type: 'Include',
          label: item.name,
        },
      ],
      'value',
    ),
  };
};

export const getIsFullMonth = payload => {
  const start_date = moment(payload.date_start, FILTER_DATE_FORMAT).date();
  const end_date = moment(payload.date_end, FILTER_DATE_FORMAT).date();
  const start_month = moment(payload.date_start, FILTER_DATE_FORMAT).month();
  const end_month = moment(payload.date_end, FILTER_DATE_FORMAT).month();
  const start_year = moment(payload.date_start, FILTER_DATE_FORMAT).year();
  const end_year = moment(payload.date_end, FILTER_DATE_FORMAT).year();
  const startDay = moment({
    year: moment(payload.date_start, FILTER_DATE_FORMAT).year(),
    month: moment(payload.date_start, FILTER_DATE_FORMAT).month(),
    day: 1,
  });
  const end = startDay.endOf('month').date();

  const month = start_month === end_month && start_year === end_year && start_date === 1 && end_date === end;

  const quarterRange = getQuarterBetweenRange(payload.date_start, payload.date_end);
  let quarter = false,
    new_end_date = payload.date_end;
  if (!month) {
    quarterRange.forEach(item => {
      if (
        payload.granularity === 'quarter' ||
        (item.date_start === payload.date_start && item.date_end === payload.date_end) ||
        (payload.granularity === 'quarter' && YESTERDAY.format(FILTER_DATE_FORMAT) === payload.date_end)
      ) {
        quarter = true;
        new_end_date = moment(quarterRange[quarterRange.length - 1].date_end, 'YYYY-MM-DD')
          .subtract(quarterRange.length * 3, 'month')
          .endOf('month')
          .format(FILTER_DATE_FORMAT);
      }
    });
  }

  const dates = {
    date_start: month
      ? moment(payload.date_start, 'YYYY-MM-DD').subtract(1, 'month').startOf('month').format(FILTER_DATE_FORMAT)
      : moment(payload.date_start, 'YYYY-MM-DD')
          .subtract(quarterRange.length * 3, 'month')
          .startOf('month')
          .format(FILTER_DATE_FORMAT),
    date_end: month
      ? moment(payload.date_start, 'YYYY-MM-DD').subtract(1, 'month').endOf('month').format(FILTER_DATE_FORMAT)
      : new_end_date,
  };
  return {
    flag: month || quarter,
    ...dates,
  };
};

export const getMTDDates = params => {
  const flag =
    moment(params.date_end, 'YYYY-MM-DD').format(FILTER_DATE_FORMAT) === YESTERDAY.format(FILTER_DATE_FORMAT) &&
    ['day', 'hour', 'month', 'week'].includes(params.granularity);

  const dates = {
    date_start: START_OF_LAST_MONTH.format(FILTER_DATE_FORMAT),
    date_end: moment().utc().isSame(moment().utc().subtract(1, 'days'), 'month')
      ? YESTERDAY.clone().subtract(1, 'month').format(FILTER_DATE_FORMAT)
      : START_OF_LAST_MONTH.format(FILTER_DATE_FORMAT),
  };
  return {
    flag,
    afterStartDate: moment(params.date_start, 'YYYY-MM-DD').isAfter(START_OF_MONTH),
    ...dates,
  };
};

export const getAllSummaryData = (
  allocatedSummaryData,
  prevAllocatedSummaryData,
  unAllocatedSummaryData,
  prevUnallocatedSummaryData,
  mtdAllocatedSummaryData,
  mtdUnallocatedSummaryData,
  mtdIntervalAllocatedSummaryData,
  mtdIntervalUnallocatedSummaryData,
  flag,
  mtdFlag,
  mtdAfterStartDate,
) => {
  return {
    interval_spend: (allocatedSummaryData?.interval_spend || 0) + (unAllocatedSummaryData?.interval_spend || 0),
    mtd_delta_spend: mtdFlag
      ? (mtdAllocatedSummaryData?.interval_spend || 0) + (mtdUnallocatedSummaryData?.interval_spend || 0)
      : (allocatedSummaryData?.mtd_delta_spend || 0) + (unAllocatedSummaryData?.mtd_delta_spend || 0),
    mtd_spend: mtdAfterStartDate
      ? (mtdIntervalAllocatedSummaryData?.interval_spend || 0) +
        (mtdIntervalUnallocatedSummaryData?.interval_spend || 0)
      : (allocatedSummaryData?.mtd_spend || 0) + (unAllocatedSummaryData?.mtd_spend || 0),
    previous_interval_spend:
      (flag ? prevAllocatedSummaryData?.interval_spend || 0 : allocatedSummaryData?.previous_interval_spend || 0) +
      (flag ? prevUnallocatedSummaryData?.interval_spend : unAllocatedSummaryData?.previous_interval_spend || 0),
    week_delta_spend: (allocatedSummaryData?.week_delta_spend || 0) + (unAllocatedSummaryData?.week_delta_spend || 0),
    week_spend: (allocatedSummaryData?.week_spend || 0) + (unAllocatedSummaryData?.week_spend || 0),
    yesterday_delta_spend:
      (allocatedSummaryData?.yesterday_delta_spend || 0) + (unAllocatedSummaryData?.yesterday_delta_spend || 0),
    yesterday_spend: (allocatedSummaryData?.yesterday_spend || 0) + (unAllocatedSummaryData?.yesterday_spend || 0),
  };
};

export const getCombinationResult = (data = {}, prevData = {}) => {
  let result = { ...data };
  Object.keys(data).forEach(item => {
    result = {
      ...result,
      [item]: {
        ...data[item],
        summary: {
          ...data[item].summary,
          previous_interval_spend: prevData?.[item]?.summary?.interval_spend || 0,
        },
      },
    };
  });
  return result;
};

export const getShowbackResult = (
  apiResponse = [],
  apiStackResponse = {},
  result = {},
  mtdDates = {},
  toCallUnallocatedOnly = true,
  toCallAllocatedOnly = true,
) => {
  let index = 0,
    data = {};
  Object.keys(apiStackResponse).forEach(item => {
    if (apiStackResponse[item]) {
      data = {
        ...data,
        [item]: apiResponse[index],
      };
      index++;
    } else {
      data = {
        ...data,
        [item]: {},
      };
    }
  });
  const {
    response = {},
    allocated = {},
    unallocated = {},
    prevResponse = {},
    prevUnallocated = {},
    prevAllocated = {},
    mtdAllocated = {},
    mtdUnallocated = {},
    mtdIntervalAllocated = {},
    mtdIntervalUnallocated = {},
  } = data;
  let allocation_rules_modified = response?.data?.allocation_rules_cost_distribution || {},
    percentage_rules_modified = response?.data?.percentage_allocation_rules_distribution || {},
    cost_distribution_modified = response?.data?.cost_distribution || {};
  const prev_allocation_rules_modified = prevResponse?.data?.allocation_rules_cost_distribution || {};
  const prev_percentage_rules_modified = prevResponse?.data?.percentage_allocation_rules_distribution || {};
  const prev_cost_distribution_modified = prevResponse?.data?.cost_distribution || {};
  const allocatedSummaryData = allocated?.data?.summary || {},
    prevAllocatedSummaryData = prevAllocated?.data?.summary || {},
    unAllocatedSummaryData = unallocated?.data?.summary || {},
    prevUnallocatedSummaryData = prevUnallocated?.data?.summary || {},
    mtdAllocatedSummaryData = mtdAllocated?.data?.summary || {},
    mtdUnallocatedSummaryData = mtdUnallocated?.data?.summary || {},
    mtdIntervalAllocatedSummaryData = mtdIntervalAllocated?.data?.summary || {},
    mtdIntervalUnallocatedSummaryData = mtdIntervalUnallocated?.data?.summary || {};

  if (result.flag) {
    cost_distribution_modified = getCombinationResult(
      cost_distribution_modified || {},
      prev_cost_distribution_modified || {},
    );
    allocation_rules_modified = getCombinationResult(allocation_rules_modified, prev_allocation_rules_modified);
    percentage_rules_modified = getCombinationResult(percentage_rules_modified, prev_percentage_rules_modified);
  }

  return {
    data: getAllShowbackValueData(
      {
        ...response.data,
        allocation_rules_cost_distribution: allocation_rules_modified,
        percentage_allocation_rules_distribution: percentage_rules_modified,
        cost_distribution: cost_distribution_modified,
      },
      {
        ...unallocated?.data,
        summary: {
          ...unAllocatedSummaryData,
          previous_interval_spend: result.flag
            ? prevUnallocatedSummaryData?.interval_spend
            : unAllocatedSummaryData?.previous_interval_spend || 0,
        },
      },
      prevUnallocated,
    ),
    summary: getAllSummaryData(
      !toCallAllocatedOnly ? {} : allocatedSummaryData,
      !toCallAllocatedOnly ? {} : prevAllocatedSummaryData,
      !toCallUnallocatedOnly ? {} : unAllocatedSummaryData,
      !toCallUnallocatedOnly ? {} : prevUnallocatedSummaryData,
      !toCallAllocatedOnly ? {} : mtdAllocatedSummaryData,
      !toCallUnallocatedOnly ? {} : mtdUnallocatedSummaryData,
      !toCallAllocatedOnly ? {} : mtdIntervalAllocatedSummaryData,
      !toCallUnallocatedOnly ? {} : mtdIntervalUnallocatedSummaryData,
      result?.flag,
      mtdDates?.flag,
      mtdDates?.afterStartDate,
    ),
  };
};

export const getTabName = activeTab => {
  return CLOUD_RESOURCE_COST_TYPE[activeTab];
};

export const manageDuplicateData = (tabData, activeTab) => {
  const granularNull = tabData['null']?.granular || {};
  const granularEmpty = tabData['']?.granular || {};
  const summaryNull = tabData['null']?.summary || {};
  const summaryEmpty = tabData['']?.summary || {};

  const mergedGranular = {},
    mergedSummary = {};
  Object.keys(granularNull).forEach(key => {
    mergedGranular[key] = (mergedGranular?.[key] || 0) + (granularNull?.[key] || 0);
  });
  Object.keys(granularEmpty).forEach(key => {
    mergedGranular[key] = (mergedGranular?.[key] || 0) + (granularEmpty?.[key] || 0);
  });
  Object.keys(summaryNull).forEach(key => {
    mergedSummary[key] = (mergedSummary?.[key] || 0) + (summaryNull?.[key] || 0);
  });
  Object.keys(summaryEmpty).forEach(key => {
    mergedSummary[key] = (mergedSummary?.[key] || 0) + (summaryEmpty?.[key] || 0);
  });
  return {
    key: 'null',
    name: activeTab === CLOUD_RESOURCE_COST_KEYS.TAGS ? 'untagged' : `No ${getTabName(activeTab)}`,
    total: (tabData['null']?.total || 0) + (tabData['']?.total || 0),
    ...mergedGranular,
    ...mergedSummary,
  };
};
