import { RULES_DESCRIPTION_TEXT } from 'utils/RuleDetail';

export const getRulesDetail = (payload = {}, key = '', service = '') => {
  if (!key || !service) return null;
  const ruleStaticData = RULES_DESCRIPTION_TEXT?.[key]?.[service];
  if (!ruleStaticData || !key || !service)
    return {
      column: [],
      data: [],
    };
  else
    return {
      [key]: {
        [service]: {
          column: ruleStaticData?.column || [],
          data: [],
        },
      },
    };
};
